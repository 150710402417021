import { HTMLBuilder } from '../builder';

const defaultClasses = `
.container--default {
display:block;
width:300px;
height:300px;
background-color:#f2f2f2;
border-radius:4px;
overflow:hidden;
}
`;

function image(variation) {
  const builder = new HTMLBuilder(defaultClasses);

  const image = builder.image({
    src: variation.image,
    width: '100%',
    objectFilt: 'cover',
    objectPosition: 'center',
  });

  builder.injectChildren(image);

  return builder.build();
}

export { image };
