/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Upload,
} from 'antd';
import styles from './ConfigurationUpdateComponent.module.less';
import { emailRegEx } from '@Utils/FormUtils';
import { isEmpty } from 'lodash';
import TextareaConfiguration from './TextareaConfiguration';
import Card from '@Utils/Card';
import { useDispatch } from 'react-redux';
import { uploadSystemFont } from '../ConfigurationActions';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { AuthorityProvider, hasAnyAuthority } from '@Utils/AuthorityProvider';

export default function ConfigurationUpdateComponent(props) {
  const [textBoxValue, setTextBoxValue] = useState('');
  const [fromNameValue, setFromNameValue] = useState('');
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({
      fromName: fromNameValue,
      reportEmailBodyTemplate: textBoxValue,
    });
  }, [textBoxValue, fromNameValue]);

  useEffect(() => {
    props.configurationUpdateFetch();
    props.configurationtListAttributesFetch();
  }, []);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(props.currentConfiguration);
    setTextBoxValue(props.currentConfiguration?.reportEmailBodyTemplate);
    setFromNameValue(props.currentConfiguration?.fromName);
  }, [props.currentConfiguration]);

  const EmailIsValid = (value) => {
    if (!isEmpty(value) && !emailRegEx.test(value.trim())) {
      return false;
    }
    return true;
  };
  const formIsValid = EmailIsValid(props.currentConfiguration.fromEmail);
  const onFinish = (values) => {
    props.configurationUpdateSubmitFetch(values);
  };
  const renderData = (items) => {
    let userChild = [];
    let othersChild = [
      { key: '{{type}}', value: 'Type' },
      { key: '{{collectionName}}', value: 'Collection name' },
      { key: '{{creationTime}}', value: 'Creation time' },
      { key: '{{productName}}', value: 'Product name' },
      { key: '{{fileLifeSpan}}', value: 'File life span' },
      { key: '{{deadline}}', value: 'Deadline' },
    ];
    items.map((item) => {
      userChild.push({
        key: `{{user["system"]["${item.name}"]}}`,
        value: item.title,
      });
    });
    const data = [
      { key: 'user', value: 'User Attributes', child: userChild },
      { key: 'others', value: 'Others', child: othersChild },
    ];
    return data;
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 19,
    },
  };
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
    },
  };

  const customRequest = async ({ file, onError }) => {
    if (file.type !== 'font/ttf' && !file.name.endsWith('.ttf')) {
      message.error('Only TTF font files are accepted.');
      onError(new Error('Invalid file type.'));
      return;
    }

    dispatch(
      uploadSystemFont({
        file,
        cbLoading: setUploading,
      })
    );
  };

  const hasUploadFontAuthority = hasAnyAuthority(
    AuthorityProvider.ROLE_FONT_UPLOAD
  );

  return (
    <div>
      <Card styles={{ padding: 30 }} loading={props.loadingUpdate}>
        <Form
          form={form}
          name="configuration"
          id="configuration"
          onFinish={onFinish}
          autoComplete="off"
          className={styles.configuration}
          style={{ maxWidth: '100%' }}
          onValuesChange={(changedValues, allValues) => {
            props.configurationChangeCurrent(allValues);
          }}
          {...layout}
          validateMessages={validateMessages}
        >
          <h3>Email Provider</h3>
          <Row>
            <Col span={12}>
              <div className={styles.item}>
                <div className={styles.itemInput}>
                  <Form.Item
                    name={['emailChannel', 'name']}
                    label={'name'}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className={styles.item}>
                <div className={styles.itemInput}>
                  <Form.Item
                    name={['emailChannel', 'vendor']}
                    label={'vendor'}
                    rules={[{ required: true }]}
                  >
                    <Select style={{ width: 180 }}>
                      <Select.Option value={'SMTP'} key={'SMTP'}>
                        SMTP
                      </Select.Option>
                      <Select.Option value={'MANDRILL'} key={'MANDRILL'}>
                        Mandrill
                      </Select.Option>
                      <Select.Option value={'SENDMOST'} key={'SENDMOST'}>
                        SendMost
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </Col>
            {(props.currentConfiguration.emailChannel?.vendor === 'MANDRILL' ||
              props.currentConfiguration.emailChannel?.vendor ===
                'SENDMOST') && (
              <Col span={12}>
                <div className={styles.item}>
                  <div className={styles.itemInput}>
                    <Form.Item
                      name={['emailChannel', 'apiKey']}
                      label={'api Key'}
                    >
                      <Input.Password autoComplete="new-password" />
                    </Form.Item>
                  </div>
                </div>
              </Col>
            )}
            {props.currentConfiguration.emailChannel?.vendor === 'SMTP' && (
              <>
                <Col span={12}>
                  <div className={styles.item}>
                    <div className={styles.itemInput}>
                      <Form.Item name={['emailChannel', 'host']} label={'host'}>
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className={styles.item}>
                    <div className={styles.itemInput}>
                      <Form.Item name={['emailChannel', 'port']} label={'port'}>
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className={styles.item}>
                    <div className={styles.itemInput}>
                      <Form.Item
                        name={['emailChannel', 'security']}
                        label={'security'}
                      >
                        <Select style={{ width: 180 }}>
                          <Select.Option value={'RAW'}>Raw</Select.Option>
                          <Select.Option value={'TLS'}>TLS</Select.Option>
                          <Select.Option value={'SSL'}>SSL</Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className={styles.item}>
                    <div className={styles.itemInput}>
                      <Form.Item
                        name={['emailChannel', 'username']}
                        label={'username'}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className={styles.item}>
                    <div className={styles.itemInput}>
                      <Form.Item
                        name={['emailChannel', 'password']}
                        label={'password'}
                      >
                        <Input.Password autoComplete="new-password" />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
              </>
            )}
          </Row>
          <hr />
          <h3>Email Sender Info</h3>
          <div className={styles.item}>
            <div className={styles.itemInput}>
              <Form.Item
                name="fromEmail"
                label="from email"
                wrapperCol={{
                  span: 19,
                }}
                rules={[
                  {
                    required: true,
                    type: 'email',
                  },
                ]}
                // validateStatus={
                //   EmailIsValid(props.currentConfiguration.fromEmail)
                //     ? ''
                //     : 'error'
                // }
                // help={
                //   EmailIsValid(props.currentConfiguration.fromEmail)
                //     ? ''
                //     : 'The input is not valid E-mail!'
                // }
              >
                <Input />
              </Form.Item>
            </div>
            <div className={styles.itemInput}>
              <TextareaConfiguration
                data={renderData(props.attributes)}
                textBoxValue={fromNameValue}
                setTextBoxValue={setFromNameValue}
                label={'from name'}
                minRows={1}
                name={'fromName'}
                item={'formName-body'}
                required
              />
            </div>
          </div>
          <hr />
          <h3>User Invitation Email Template</h3>
          <div className={styles.item}>
            <div className={styles.itemInput}>
              <Form.Item
                name="invitationEmailSubjectTemplate"
                label="invite email subject"
                wrapperCol={{
                  span: 19,
                }}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </div>

            <div className={styles.itemInput}>
              <Form.Item
                name="invitationEmailBodyTemplate"
                label="invite email body"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.TextArea rows={8} />
              </Form.Item>
            </div>
          </div>
          <Col span={12} offset={6} className={styles.itemHint}>
            <h2>Available variables (for email subject and body): </h2>
            <ul>
              <li>invitedUser[ firstName or email or presentation ]</li>
              <li>
                inviteeUser [ any user property such as firstName, email, phone,
                presentation ....]
              </li>
              <li>product["name"]</li>
              <li>inviteUrl</li>
              <li>roles</li>
            </ul>
          </Col>
          <div className={styles.item}>
            <div className={styles.itemInput}>
              <Form.Item
                name="expireDurationInMinutes"
                label="expire duration minutes"
                wrapperCol={{
                  span: 12,
                }}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </div>
          </div>
          <hr />
          <h3>User CSV Email Template</h3>
          <div className={styles.item}>
            <div className={styles.itemInput}>
              <Form.Item
                name="reportEmailSubjectTemplate"
                label="report email subject"
                rules={[
                  {
                    required: true,
                  },
                ]}
                wrapperCol={{
                  span: 12,
                }}
              >
                <Input />
              </Form.Item>
            </div>

            <div className={styles.itemInput}>
              <TextareaConfiguration
                data={renderData(props.attributes)}
                textBoxValue={textBoxValue}
                setTextBoxValue={setTextBoxValue}
                label={'report email body'}
                minRows={5}
                name={'reportEmailBodyTemplate'}
                item={'message-body'}
                required
              />
            </div>
          </div>
          <Col span={12} offset={6} className={styles.itemHint}>
            <h2>Available variables in others part: </h2>
            <ul>
              <li>
                Type: Static segment, Live segment, journey engaged user,
                campaign engaged user
              </li>
              <li>
                Collection name: name of live or static segment, name of
                campaign or journey
              </li>
              <li>Creation time: time of CSV file creation</li>
              <li>Product name: the name of current product</li>
              <li>
                File life span: the time period that the CSV file will be
                available for downloading
              </li>
              <li>Deadline: end time of CSV validity period</li>
            </ul>
          </Col>
          <hr />
          <h3>User OTP Email Template</h3>
          <div className={styles.itemInput}>
            <Form.Item
              name="otpEmailSubjectTemplate"
              label="otp email subject"
              wrapperCol={{
                span: 19,
              }}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className={styles.itemInput}>
            <Form.Item
              name="otpEmailBodyTemplate"
              label="otp email body"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.TextArea rows={8} />
            </Form.Item>
          </div>
          <Col span={12} offset={6} className={styles.itemHint}>
            <h2>Variables in OTP email subject and body: </h2>
            <ul>
              <li>
                Subject Intention: Either Email Verification, Login, or Reset
                Password
              </li>
              <li>Page: Either profile or login</li>
              <li>
                OTP Code: The OTP code which was generated for the dashboard
                user
              </li>
              <li>
                Action Description: Some further description which will fill the
                email body
              </li>
            </ul>
          </Col>
          <hr />
          <h3>Alert Email Template</h3>
          <div className={styles.itemInput}>
            <Form.Item
              name="alertEmailSubjectTemplate"
              label="alert email subject"
              wrapperCol={{
                span: 19,
              }}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className={styles.itemInput}>
            <Form.Item
              name="alertEmailBodyTemplate"
              label="alert email body"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.TextArea rows={8} />
            </Form.Item>
          </div>
          <hr />
          <h3>Upload System Fonts</h3>
          <div style={{ marginTop: 16 }}>
            <Form.Item name="font">
              <Upload
                disabled={!hasUploadFontAuthority}
                customRequest={customRequest}
                showUploadList={false}
                accept=".ttf,application/x-font-ttf"
              >
                <Button
                  icon={uploading ? <LoadingOutlined /> : <UploadOutlined />}
                  disabled={uploading || !hasUploadFontAuthority}
                >
                  {uploading ? 'Uploading...' : 'Click to Upload'}
                </Button>
              </Upload>
            </Form.Item>
            {!hasUploadFontAuthority && (
              <Alert
                message="You have no permission to upload font"
                type="warning"
                showIcon
                style={{ marginBottom: 8 }}
              />
            )}
          </div>
          <hr />
          <h3>Others</h3>
          <div className={styles.item}>
            <div className={styles.itemInput}>
              <Form.Item
                name="changeme"
                label="change me"
                rules={[
                  {
                    required: true,
                  },
                ]}
                wrapperCol={{
                  span: 12,
                }}
              >
                <Input />
              </Form.Item>
            </div>

            <div className={styles.itemInput}>
              <Form.Item
                name="maximumFunnelEntryCount"
                label="maximum funnel entry count"
                rules={[
                  {
                    required: true,
                  },
                ]}
                wrapperCol={{
                  span: 12,
                }}
              >
                <InputNumber min={1000} />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            wrapperCol={{
              offset: 20,
              span: 4,
            }}
          >
            <Button
              type="primary"
              shape="round"
              size={'large'}
              htmlType="submit"
              className="w-200"
              disabled={formIsValid ? false : true}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}
