import React, { useState } from 'react';
import styles from './HomeComponent.module.less';
import { Input, Button } from 'antd';
import EmailIcon from '../../../images/Email.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  loginSecondStepFetch,
  resendOTPCodeFetch,
} from '@Modules/account/auth/AuthActions';
import { useLocation } from 'react-router-dom';
import { useCounter } from '../../../hook/useCounter';

const LoginSecondStep = ({ goToLoginFirstStep }) => {
  const { state, search } = useLocation();
  const dispatch = useDispatch();

  const [verificationCode, setVerificationCode] = useState('');
  const { loginSecondStepStatus } = useSelector((state) => state.account.auth);

  const handleResendOtpBtn = () => {
    dispatch(resendOTPCodeFetch(state?.otpId));
  };

  const { isCounting, timer, reset } = useCounter({
    duration: 180,
    immediate: true,
    onReset: handleResendOtpBtn,
  });

  const verifyOtpCode = () => {
    const params = new URLSearchParams(search);

    const returnUrl = params.get('returnUrl');

    let body = {
      rememberMe: state?.rememberMe ?? false,
      otpId: state?.otpId,
      otpCode: verificationCode,
    };

    dispatch(loginSecondStepFetch({ body, returnUrl }));
  };

  return (
    <>
      <div className={styles.form}>
        <div className={styles.email_verification__logo}>
          <img src={EmailIcon} />
        </div>
        <div className={styles.form__email__title}>
          We&apos;ve just sent a verification code to your inbox. Please check
          your email and enter the verification code
        </div>
        <div className={styles.form__item}>
          <div className={styles.form__title}>Verification Code</div>
          <Input
            id="verification-code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            className={styles.form__input}
          />
        </div>
        <div className={styles['form__button-container']}>
          <Button
            id="verification-confirm"
            loading={loginSecondStepStatus?.loading}
            onClick={verifyOtpCode}
            type="primary"
            shape="round"
            className={styles.form__login}
            data-badge="inline"
            disabled={
              loginSecondStepStatus?.disabled || verificationCode === ''
            }
          >
            CONFIRM
          </Button>
          <div className={styles.form__singup}>
            <span className={styles.form__singup__description}>
              Didn&apos;t get the code?
            </span>
            <span
              className={
                isCounting
                  ? styles.disabled_resend__button
                  : styles.enabled_resend__button
              }
              onClick={isCounting ? () => {} : reset}
            >
              Resend
            </span>
            {isCounting && <span className={styles.timer}>{`(${timer})`}</span>}
          </div>
          <div className={styles.form__singup}>
            <span
              className={styles.enabled_resend__button}
              onClick={goToLoginFirstStep}
            >
              Back To Login
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginSecondStep;
