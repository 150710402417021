/* eslint-disable max-len */
import React from 'react';
import { Select, Table, DatePicker } from 'antd';
import moment from 'moment';
import { isEmpty, isNil } from 'lodash';
import { getProductId } from '../../../../utils/AuthorityProvider';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Line } from '@ant-design/charts';
import chart from '../../../../theme/images/icons/chart.png';
import chartBar from '../../../../theme/images/icons/chart-bar.png';
import chartTable from '../../../../theme/images/icons/chart-table.png';
import { numberWithCommas, percentage } from '../../../../utils/RenderUtils';
import OverviewBox from '../../../../component/form/OverviewBox';
import Card from '@Utils/Card';

class UsersOverviewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overallMonthlyActiveUsers: null,
      webMonthlyActiveUsers: null,
      androidMonthlyActiveUsers: null,
      iosMonthlyActiveUsers: null,

      activityReportType: 'monthlyActiveUsers', // 'dailyActiveUsers', 'weeklyActiveUsers', 'dailyDividedMonthly'
      filterChanged: false,
      activityReportDuration: {
        toDate: moment().format('YYYY-MM-DD'),
        fromDate: moment().subtract(6, 'days').format('YYYY-MM-DD'),
      },
    };
  }

  componentDidMount() {
    let pid = getProductId(this.props.pathname);
    if (!isNil(pid)) {
      this.activityReportFetch(this.state.activityReportDuration);
      this.props.insightProductUserReachabilityReportFetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.pathname !== prevProps.pathname) {
      this.componentDidMount();
    }
    if (
      !this.props.activityLoading &&
      prevProps.activityLoading &&
      isEmpty(this.props.activityErrors) &&
      !isEmpty(this.props.activity)
    ) {
      const foundItem =
        this.props?.activity[this.props?.activity?.length - 1] || null;
      let overallMonthlyActiveUsers = null;
      let androidMonthlyActiveUsers = null;
      let iosMonthlyActiveUsers = null;
      let webMonthlyActiveUsers = null;
      if (foundItem && foundItem.overallActiveUsers) {
        overallMonthlyActiveUsers =
          foundItem.overallActiveUsers.monthlyActiveUsers;
      }
      if (foundItem && foundItem.androidActiveUsers) {
        androidMonthlyActiveUsers =
          foundItem.androidActiveUsers.monthlyActiveUsers;
      }
      if (foundItem && foundItem.iosActiveUsers) {
        iosMonthlyActiveUsers = foundItem.iosActiveUsers.monthlyActiveUsers;
      }
      if (foundItem && foundItem.webActiveUsers) {
        webMonthlyActiveUsers = foundItem.webActiveUsers.monthlyActiveUsers;
      }
      this.setState({
        overallMonthlyActiveUsers,
        androidMonthlyActiveUsers,
        iosMonthlyActiveUsers,
        webMonthlyActiveUsers,
      });
    }
    // if (!this.props.reachabilityLoading &&
    //     prevProps.reachabilityLoading &&
    //     isEmpty(this.props.reachabilityErrors) &&
    //     !isEmpty(this.props.reachability)) {
    // }
    if (
      !this.props.activityLoading &&
      prevProps.activityLoading &&
      isEmpty(this.props.activityErrors) &&
      isEmpty(this.props.activity)
    ) {
      this.setState({ overallMonthlyActiveUsers: 0 });
    }
  }

  activityReportFetch = (duration) => {
    this.props.insightProductUserActivityReportFetch(duration);
  };

  renderNumber = (number) => {
    if (isNil(number)) {
      return 0;
    }
    return numberWithCommas(number);
  };

  renderKnownUsers = () => {
    if (
      this.props.reachability &&
      !isNil(this.props.reachability.knownUsersCount)
    ) {
      return this.props.reachability.knownUsersCount;
    }
    return 0;
  };

  renderKnownUsersPercentage = () => {
    if (this.props.reachability) {
      return percentage(
        this.props.reachability.knownUsersCount,
        Number(this.props.reachability.knownUsersCount) +
          Number(this.props.reachability.unknownUsersCount)
      );
    }
    return 0;
  };

  renderTotalUsers = () => {
    if (
      this.props.reachability &&
      (!isNil(this.props.reachability.knownUsersCount) ||
        !isNil(this.props.reachability.unknownUsersCount))
    ) {
      return (
        this.props.reachability.knownUsersCount +
        this.props.reachability.unknownUsersCount
      );
    }
    return 0;
  };

  renderOverview = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <OverviewBox
          usersNumbers={this.renderTotalUsers()}
          activityLoading={this.props.activityLoading}
          usersStatus="TOTAL USERS"
          segmentUsers={false}
        ></OverviewBox>
        <OverviewBox
          usersNumbers={this.renderKnownUsers()}
          usersStatus="KNOWN USERS"
          knownUsers={true}
          knownUsersnumbers={this.renderKnownUsersPercentage() + '%'}
          activityLoading={this.props.activityLoading}
          segmentUsers={false}
        ></OverviewBox>
        <OverviewBox
          usersNumbers={this.state.overallMonthlyActiveUsers}
          usersStatus="MONTHLY ACTIVE USERS (MAU)"
          activityLoading={this.props.activityLoading}
          segmentUsers={false}
        ></OverviewBox>
      </div>
    );
  };

  handleChangeDateRange = (dates) => {
    const activityReportDuration = {
      fromDate: dates[0].format('YYYY-MM-DD'),
      toDate: dates[1].format('YYYY-MM-DD'),
    };
    this.setState({ activityReportDuration }, () => {
      this.activityReportFetch(activityReportDuration);
    });
  };

  disabledDate = (current) => {
    return current && current > moment().startOf('day');
  };

  divideDailyToMonthly = (monthly, daily) => {
    if (daily === 0) {
      return 0;
    } else {
      return Math.floor((daily / monthly) * 100);
    }
  };

  renderReport = () => {
    const data = [];
    const values = {
      Android: 'androidActiveUsers',
      iOS: 'iosActiveUsers',
      Web: 'webActiveUsers',
      Overall: 'overallActiveUsers',
    };
    this.props.activity?.forEach((item) => {
      const date = moment(item.date, 'YYYY-MM-DD').format('DD MMM');
      if (this.state.activityReportType === 'dailyDividedMonthly') {
        Object.keys(values).forEach((category) => {
          data.push({
            date,
            value: this.divideDailyToMonthly(
              item[values[category]]['monthlyActiveUsers'],
              item[values[category]]['dailyActiveUsers']
            ),
            category,
          });
        });
      } else {
        Object.keys(values).forEach((category) => {
          data.push({
            date,
            value: item[values[category]][this.state.activityReportType],
            category,
          });
        });
      }
    });
    const config = {
      data,
      height: 400,
      xField: 'date',
      yField: 'value',
      meta: {
        value: {
          formatter: (v) => {
            if (this.state.activityReportType === 'dailyDividedMonthly') {
              return this.renderNumber(v) + '%';
            } else {
              return this.renderNumber(v);
            }
          },
        },
      },
      padding: 'auto',
      seriesField: 'category',
      color: ['#FD578D', '#008BFA', '#FFC009', '#58FEA1'],
      lineStyle: function lineStyle() {
        return {
          lineDash: [4, 4],
          opacity: 1,
        };
      },
      yAxis: {
        label: {
          formatter: function formatter(v) {
            return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
              return ''.concat(s, ',');
            });
          },
        },
      },
      point: {
        size: 5,
        shape: 'circle',
      },
      legend: {
        position: 'right',
        verticalAlign: 'middle',
      },
    };
    return (
      <Card loading={this.props.activityLoading}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '12px',
            paddingLeft: '20px',
            borderBottom: 'solid 2px #f4f9ff',
          }}
        >
          <div style={{ color: '#1c1c1c', fontSize: 16, fontWeight: '600' }}>
            Activity (MAU, WAU, DAU, DAU/MAU)
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <DatePicker.RangePicker
              allowClear={false}
              disabledDate={(date) => this.disabledDate(date)}
              value={[
                moment(
                  this.state.activityReportDuration.fromDate,
                  'YYYY-MM-DD'
                ),
                moment(this.state.activityReportDuration.toDate, 'YYYY-MM-DD'),
              ]}
              onChange={(dates) => this.handleChangeDateRange(dates)}
            />
            <Select
              value={this.state.activityReportType}
              onChange={(activityReportType) =>
                this.setState({ activityReportType })
              }
              style={{ width: 120, marginLeft: 20, marginRight: 10 }}
              className={'chart-selector'}
              bordered={false}
            >
              <Select.Option value="monthlyActiveUsers">MAU</Select.Option>
              <Select.Option value="weeklyActiveUsers">WAU</Select.Option>
              <Select.Option value="dailyActiveUsers">DAU</Select.Option>
              <Select.Option value="dailyDividedMonthly">DAU/MAU</Select.Option>
            </Select>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginRight: 10,
                marginBottom: 5,
              }}
            >
              {false && (
                <div style={{ cursor: 'pointer', marginLeft: 25 }}>
                  <img src={chart} style={{ width: 16, height: 'auto' }} />
                </div>
              )}
              {false && (
                <div
                  style={{
                    cursor: 'pointer',
                    marginRight: 15,
                    marginLeft: 15,
                  }}
                >
                  <img src={chartBar} style={{ width: 16, height: 'auto' }} />
                </div>
              )}
              {false && (
                <div style={{ cursor: 'pointer' }}>
                  <img src={chartTable} style={{ width: 16, height: 'auto' }} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              writingMode: 'vertical-rl',
              textOrientation: 'mixed',
              textAlign: 'center',
              paddingLeft: 14,
            }}
          >
            {this.state.activityReportType === 'monthlyActiveUsers' &&
              'MONTHLY ACTIVE USERS (MAU)'}
            {this.state.activityReportType === 'dailyActiveUsers' &&
              'DAILY ACTIVE USERS (DAU)'}
            {this.state.activityReportType === 'weeklyActiveUsers' &&
              'WEEKLY ACTIVE USERS (WAU)'}
            {this.state.activityReportType === 'dailyDividedMonthly' &&
              'DAILY/MONTHLY ACTIVE USERS (DAU/MAU)'}
          </div>
          <Line
            {...config}
            style={{
              flex: 1,
              paddingRight: 30,
              paddingLeft: 20,
              paddingBottom: 20,
              paddingTop: 20,
              width: window.innerWidth - 300,
            }}
          />
        </div>
      </Card>
    );
  };

  reachabilityCount = (channel, count = 'reachableCount') => {
    let result = 0;
    if (
      this.props.reachability &&
      this.props.reachability[channel] &&
      this.props.reachability[channel][count]
    ) {
      result = this.props.reachability[channel][count];
    }
    return result;
  };

  renderChannels = () => {
    const channels = {
      push: 'Push',
      sms: 'SMS',
      webPush: 'Web Push',
      email: 'Email',
      onSite: 'On Site',
      inApp: 'In App',
      whatsApp: 'WhatsApp',
      overall: 'Overall',
    };
    const data = [];
    Object.keys(channels).map((channelKey) => {
      data.push({
        key: channelKey,
        name: channels[channelKey],
        reachability: percentage(
          this.reachabilityCount(channelKey),
          Number(
            this.reachabilityCount(channelKey) +
              Number(this.reachabilityCount(channelKey, 'unreachableCount'))
          )
        ),
        reachabilityCount: this.renderNumber(
          this.reachabilityCount(channelKey)
        ),
      });
    });
    const columns = [
      {
        title: '',
        dataIndex: 'name',
        key: 'name',
        render: (text) => (
          <span style={{ fontSize: 14, fontWeight: '600' }}>{text}</span>
        ),
      },
      {
        title: 'reachability',
        dataIndex: 'reachability',
        key: 'reachability',
        render: (text) => (
          <span style={{ fontSize: 14, fontWeight: '600' }}>{text + ' %'}</span>
        ),
      },
      {
        title: 'reachability (#)',
        dataIndex: 'reachabilityCount',
        key: 'reachabilityCount',
        render: (text) => (
          <span style={{ fontSize: 14, fontWeight: '600' }}>{text}</span>
        ),
      },
    ];
    //const loadingSpin = <LoadingOutlined style={{ fontSize: 30 }} spin />;
    return (
      <Card loading={this.props.reachabilityLoading}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '12px',
            paddingBottom: 0,
            paddingLeft: '20px',
          }}
        >
          <div style={{ color: '#000000', fontSize: 16, fontWeight: '600' }}>
            Channel Reachability
            <span style={{ paddingLeft: 10 }}>
              {false && (
                <InfoCircleOutlined
                  style={{ color: '#8C8C8C', fontSize: 12 }}
                />
              )}
            </span>
          </div>
        </div>
        <Table
          className={'custom-table'}
          columns={columns}
          dataSource={data}
          pagination={false}
          rowClassName="editable-row"
        />
      </Card>
    );
  };

  render() {
    return (
      <>
        {this.renderOverview()}
        {this.renderReport()}
        {this.renderChannels()}
      </>
    );
  }
}
export default UsersOverviewComponent;
