import React from 'react';
const Loading = () => {
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 1000,
        inset: 0,
        width: '100%',
        height: '100%',
        // marginTop: '-60px',
        backgroundColor: '#FFFFFF',
        color: '#3D404E',
        fontSize: 44,
        fontWeight: '600',
        direction: 'ltr',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        userSelect: 'none',
      }}
    >
      Loading ...
    </div>
  );
};

export default Loading;
