exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ---blue---*/\n/* ---light theme---*/\n/* ---status---*/\n.CsvExportLog-module__wrapper--19UVv .CsvExportLog-module__mainContent--1Lsz1 {\n  background-color: white;\n  border-radius: 5px;\n  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);\n}\n.CsvExportLog-module__wrapper--19UVv header {\n  text-align: left;\n  padding: 14px 0 12px 12px;\n  border-bottom: 2px solid #f4f9ff;\n}\n.CsvExportLog-module__wrapper--19UVv header h3 {\n  color: #000;\n  font-size: 16px;\n  font-weight: 600;\n  padding-left: 5px;\n}\n.CsvExportLog-module__loadingSpinStyle--3Zf-b {\n  height: 180px;\n}\n.CsvExportLog-module__loadingSpinStyle--3Zf-b,\n.CsvExportLog-module__inputLoadingSpinStyle--v_ikb,\n.CsvExportLog-module__editContainer--kTEuV {\n  align-items: center;\n  justify-content: center;\n  display: flex;\n}\n.CsvExportLog-module__loadingSpinStyle--3Zf-b,\n.CsvExportLog-module__editContainer--kTEuV {\n  padding-top: 10px;\n  padding-bottom: 10px;\n}\n.CsvExportLog-module__editContainer--kTEuV {\n  direction: ltr;\n  text-align: left;\n  flex-direction: row;\n}\n.CsvExportLog-module__editLabel--1UGdV {\n  color: rgba(61, 64, 78, 0.55);\n  margin-right: 5px;\n  font-size: 12px;\n  text-transform: uppercase;\n  font-weight: 600;\n  flex: 1;\n  text-align: right;\n}\n.CsvExportLog-module__editField--1L2PV {\n  margin-left: 20px;\n  width: 350px;\n}\n.CsvExportLog-module__serachInput--23Drb {\n  width: 300px !important;\n  margin-right: 30px;\n  border-radius: 5px !important;\n}\n.CsvExportLog-module__serachInput--23Drb input {\n  width: 100%;\n}\n.CsvExportLog-module__inputLoadingSpinStyle--v_ikb {\n  align-items: center;\n  justify-content: center;\n  display: flex;\n  font-size: 34px;\n  color: #1d88f6;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "CsvExportLog-module__wrapper--19UVv",
	"mainContent": "CsvExportLog-module__mainContent--1Lsz1",
	"loadingSpinStyle": "CsvExportLog-module__loadingSpinStyle--3Zf-b",
	"inputLoadingSpinStyle": "CsvExportLog-module__inputLoadingSpinStyle--v_ikb",
	"editContainer": "CsvExportLog-module__editContainer--kTEuV",
	"editLabel": "CsvExportLog-module__editLabel--1UGdV",
	"editField": "CsvExportLog-module__editField--1L2PV",
	"serachInput": "CsvExportLog-module__serachInput--23Drb"
};