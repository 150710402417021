import React from 'react';
import { history } from '../../../../redux/store';
import {
  AuthorityProvider,
  getChannel,
  getCommunicationId,
  getCommunicationStep,
  hasAnyAuthority,
} from '../../../../utils/AuthorityProvider';
import { variationLetter } from '../../../../utils/FormUtils';
import {
  isEmpty,
  filter as lodashFilter,
  isNil,
  find as lodashFind,
} from 'lodash';
import { Button, Select } from 'antd';
import CommunicationStepsComponent from './CommunicationStepsComponent';
import CommunicationFooterComponent from './CommunicationFooterComponent';
import {
  journeySteps,
  transactionalSteps,
} from '../../../../utils/CommunicationSteps';
import {
  numberWithCommas,
  showMessageError,
} from '../../../../utils/RenderUtils';
import Card from '@Utils/Card';

const styles = {
  editContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
  },
  editFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 6,
    fontSize: 13,
    lineHeight: 1.43,
    color: '#3d404e',
  },
  editField: {
    width: 350,
  },
  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
    flex: 1,
    textAlign: 'right',
  },
};

class CommunicationTestComponent extends React.Component {
  constructor(props) {
    super(props);
    this.step = getCommunicationStep(this.props.pathname);
    this.channel = getChannel(this.props.pathname);
    // this.communicationId = getCommunicationId(this.props.pathname);
    if (props.journey) {
      this.communicationId =
        (props.communicationData && props.communicationData.id) ||
        props.communicationId;
    } else {
      this.communicationId = getCommunicationId(this.props.pathname);
    }
    this.state = {
      segment: undefined,
      variation: undefined,
    };
  }

  componentDidMount() {
    if (typeof this.state.segment !== 'undefined') {
      if (!hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_CREATE)) {
        this.props.communicationAudienceSegmentReportFetch({
          segments: [this.state.segment],
          ignoreSegments: [],
          segmentsConjunction: 'AND',
          ignoreSegmentsConjunction: 'AND',
        });
      }
    }
    this.props.communicationListSegmentsFetch(this.communicationId);
    this.props.communicationUpdateConversionFetch(this.communicationId);
    if (!isNil(this.props.communicationDraft) && !this.props.journey) {
      this.setState(this.props.communicationDraft, () => {
        this.props.communicationDraftSaveDiscard();
      });
    }
  }
  componentWillUnmount() {
    this.props.segmentReportCurrent({});
  }

  // componentDidUpdate(prevProps, preStates) {
  //     if (this.props.journey &&
  //         prevProps.currentCommunication !== this.props.currentCommunication &&
  //         this.props.currentCommunication
  //     ) {
  //         // this.props.goToStepOnJourney('preview');
  //         this.props.updateJourney('update-step',
  //             Object.assign({}, this.props.dataNode , {
  //                 communication: this.communicationId,
  //             })
  //         );
  //         // this.props.goToStepOnJourney('test');
  //         setTimeout(() => this.props.closeModal(), 1000);
  //         // this.props.closeModal();
  //         // this.props.communicationLaunchFetch(this.communicationId, {});
  //     }
  // }

  viewItem(id) {
    if (hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CUSTOM_ATTRIBUTE_VIEW)) {
      history.push(`view/${id}`);
    }
  }

  handleClickTest = () => {
    this.props.communicationTestFetch(this.communicationId, {
      variation: this.state.variation,
      segment: this.state.segment,
    });
  };

  handleClickNext = () => {
    this.props.communicationDraftSaveDiscard();
    if (!this.props.journey) {
      history.push('preview');
    } else {
      // this.props.goToStepOnJourney('preview');
      const node = { ...this.props.dataNode };
      delete node.ui.error;
      this.props.updateJourney(
        'update-step',
        Object.assign({}, node, {
          error: null,
          communication: this.communicationId,
        }),
        true
      );
      // this.props.goToStepOnJourney('test');
      setTimeout(() => this.props.closeModal(false, true), 1000);
    }
  };

  handleClickBack = () => {
    this.props.communicationDraftSaveDiscard();
    if (!this.props.journey) {
      history.push('conversion');
    } else {
      this.props.goToStepOnJourney('conversion');
    }
  };

  getNavigation() {
    return [{ name: 'Custom Attribute List', path: '' }];
  }

  communicationHasJourneyType = () => {
    if (
      !this.props.journey &&
      this.props.currentConversion &&
      ['JOURNEY', 'RELAY'].includes(this.props.currentConversion.type)
    ) {
      return true;
    }
    return false;
  };
  renderReachabilityType = () => {
    if (this.channel === 'EMAIL') {
      return (
        <div>
          Email Reachable Users: <br />
          {isEmpty(this.props.segmentReport)
            ? null
            : this.props.segmentReport &&
              numberWithCommas(
                this.props.segmentReport.emailReachableUsersCount
              )}
        </div>
      );
    } else if (this.channel === 'SMS') {
      return (
        <div>
          Sms Reachable Users: <br />
          {isEmpty(this.props.segmentReport)
            ? null
            : this.props.segmentReport &&
              numberWithCommas(
                this.props.segmentReport.phoneReachableUsersCount
              )}
        </div>
      );
    } else if (this.channel === 'PUSH') {
      return (
        <div>
          Push Reachable Users: <br />
          {isEmpty(this.props.segmentReport)
            ? null
            : this.props.segmentReport &&
              numberWithCommas(
                this.props.segmentReport.pushReachableUsersCount
              )}
        </div>
      );
    } else if (this.channel === 'WEB_PUSH') {
      return (
        <div>
          Web Push Reachable Users:
          <br />
          {isEmpty(this.props.segmentReport)
            ? null
            : this.props.segmentReport &&
              numberWithCommas(
                this.props.segmentReport.webPushReachableUsersCount
              )}
        </div>
      );
    }
  };

  renderVariationSelector = () => {
    let result = [];
    if (
      this.props.currentConversion &&
      this.props.currentConversion.variations &&
      !isEmpty(this.props.currentConversion.variations)
    ) {
      this.props.currentConversion.variations.forEach((item, index) => {
        result.push(
          <Select.Option value={item.id}>
            {'Variation ' +
              variationLetter[index] +
              ' (' +
              item.percentage +
              ' %)'}
          </Select.Option>
        );
      });
    }
    return result;
  };

  renderSegmentSelector = () => {
    let result = [];
    if (this.props.segments && !isEmpty(this.props.segments)) {
      lodashFilter(this.props.segments, (o) => o.test === true).forEach(
        (item) => {
          result.push(
            <Select.Option value={item.id}>{item.name}</Select.Option>
          );
        }
      );
    }
    return result;
  };

  handleChangeVariation = (variation) => {
    this.setState({ variation });
  };

  handleChangeSegment = (segment) => {
    this.setState({ segment });
  };

  addTestSegment = () => {
    return '../../../segments/live/create/test';
  };

  editTestSegment() {
    if (!isNil(this.state.segment)) {
      return '../../../segments/live/update/' + this.state.segment + '/test';
    }
    return null;
  }

  renderSegmentControl = () => {
    if (!isNil(this.state.segment)) {
      const foundItem = lodashFind(
        this.props.segments,
        (o) => o.id === this.state.segment
      );
      let isDynamic = true;
      if (!isNil(foundItem) && foundItem.type !== 'DYNAMIC') {
        isDynamic = false;
      }
      if (isDynamic) {
        return (
          <div
            id="int-communication-test-segment-edit"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              this.props.communicationDraftSave(this.state);
              history.push(this.editTestSegment());
            }}
          >
            <i
              style={{
                border: '1px solid',
                borderRadius: '50%',
                fontSize: '28px',
                lineHeight: '28px',
                marginLeft: 20,
              }}
              className="fl-dark fl-edit"
            ></i>
          </div>
        );
      } else {
        return (
          <div
            id="int-communication-test-segment-edit"
            onClick={() => {
              showMessageError({
                status: 'Error',
                message: 'Selected segment is static and could not be updated.',
              });
            }}
          >
            <i
              style={{
                border: '1px solid',
                borderRadius: '50%',
                fontSize: '28px',
                lineHeight: '28px',
                marginLeft: 20,
              }}
              className="fl-dark fl-edit"
            ></i>
          </div>
        );
      }
    }
    return (
      <div
        id="int-communication-test-segment-edit"
        onClick={() => {
          showMessageError({
            status: 'Error',
            message: 'Select a segment to edit',
          });
        }}
      >
        <i
          style={{
            border: '1px solid',
            borderRadius: '50%',
            fontSize: '28px',
            lineHeight: '28px',
            marginLeft: 20,
          }}
          className="fl-dark fl-edit"
        ></i>
      </div>
    );
  };

  renderAdvanceSettings = () => {
    return (
      <Card
        styles={{
          marginTop: 20,
          paddingTop: 20,
          paddingBottom: 40,
          marginBottom: 60,
        }}
      >
        <div
          style={{
            ...styles.editContainer,
            marginTop: 20,
            alignItems: 'flex-start',
          }}
        >
          <div style={{ ...styles.editLabel, position: 'relative', top: 5 }}>
            VARIATION TO TEST
          </div>
          <div style={{ ...styles.editFieldContainer, marginLeft: 20 }}>
            <Select
              loading={this.props.loadingUpdateConversion}
              id="int-communication-test-variation"
              disabled={this.props.readOnly}
              value={this.state.variation}
              style={{ width: 300 }}
              onChange={(value) => this.handleChangeVariation(value)}
            >
              {this.props.loadingUpdateConversion
                ? null
                : this.renderVariationSelector()}
            </Select>
          </div>
        </div>
        <div
          style={{
            ...styles.editContainer,
            marginTop: 20,
            alignItems: 'flex-start',
          }}
        >
          <div style={{ ...styles.editLabel, position: 'relative', top: 5 }}>
            SEND TEST MESSAGE TO
          </div>
          <div
            style={{
              ...styles.editFieldContainer,
              marginLeft: 20,
              flexDirection: 'row',
            }}
          >
            <Select
              loading={this.props.loadingSegment}
              disabled={this.props.readOnly}
              id="int-communication-test-segment"
              filterOption={(input, option) =>
                option.props.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              value={this.state.segment}
              style={{ width: 300 }}
              onChange={(value) => {
                this.handleChangeSegment(value);
                if (value !== 'undefined') {
                  if (
                    !hasAnyAuthority(
                      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_CREATE
                    )
                  ) {
                    this.props.communicationAudienceSegmentReportFetch({
                      segments: [value],
                      ignoreSegments: [],
                      segmentsConjunction: 'AND',
                      ignoreSegmentsConjunction: 'AND',
                    });
                  }
                }
              }}
            >
              {this.props.loadingSegment ? null : this.renderSegmentSelector()}
            </Select>
            {!this.props.journey && this.renderSegmentControl()}
            {!this.props.journey && (
              <div
                style={{ cursor: 'pointer' }}
                id="int-communication-test-segment-add"
                onClick={() => {
                  this.props.communicationDraftSave(this.state);
                  history.push(this.addTestSegment());
                }}
              >
                <div>
                  <i
                    style={{
                      border: '1px solid',
                      borderRadius: '50%',
                      fontSize: '28px',
                      lineHeight: '28px',
                      marginLeft: 10,
                    }}
                    className="fl-dark fl-add"
                  ></i>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            ...styles.editContainer,
            marginTop: 20,
            alignItems: 'flex-start',
          }}
        >
          <div
            style={{ ...styles.editLabel, position: 'relative', top: 5 }}
          ></div>
          <div style={{ ...styles.editFieldContainer, marginLeft: 20 }}>
            <div
              style={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'row',
                width: 630,
                justifyContent: 'space-between',
                borderRadius: 5,
                alignItems: 'center',
                paddingLeft: 30,
                paddingTop: 30,
                paddingBottom: 30,
                border: '1px solid #e2e2e2',
                boxShadow: 'inset 0 0 6px 0 #e7e8e9',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div
                  style={{
                    color: 'rgba(61,64,78,.55)',
                    fontSize: 15,
                    marginRight: 40,
                  }}
                >
                  Total Users: <br />
                  {isEmpty(this.props.segmentReport)
                    ? null
                    : this.props.segmentReport &&
                      numberWithCommas(
                        this.props.segmentReport.unknownUsersCount +
                          this.props.segmentReport.knownUsersCount
                      )}
                </div>
                <div
                  style={{
                    color: 'rgba(61,64,78,.55)',
                    fontSize: 15,
                    marginRight: 40,
                  }}
                >
                  Known Users: <br />
                  {isEmpty(this.props.segmentReport)
                    ? null
                    : this.props.segmentReport &&
                      numberWithCommas(
                        this.props.segmentReport.knownUsersCount
                      )}
                </div>
                <div
                  style={{
                    color: 'rgba(61,64,78,.55)',
                    fontSize: 15,
                  }}
                >
                  {this.renderReachabilityType()}
                </div>
              </div>{' '}
              <Button
                type="primary"
                shape="circle"
                size={'middle'}
                style={{
                  marginLeft: 30,
                  marginRight: 10,
                  marginTop: -30,
                }}
                onClick={() => {
                  if (typeof this.state.segment !== 'undefined') {
                    if (
                      !hasAnyAuthority(
                        AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_CREATE
                      )
                    ) {
                      this.props.communicationAudienceSegmentReportFetch({
                        segments: [this.state.segment],
                        ignoreSegments: [],
                        segmentsConjunction: 'AND',
                        ignoreSegmentsConjunction: 'AND',
                      });
                    }
                  }
                }}
              >
                {' '}
                <i className="fl-sync fl-light" style={{ fontSize: 25 }} />
              </Button>
            </div>
          </div>
        </div>
      </Card>
    );
  };
  isEdited = () => {
    return false;
  };
  renderFooter = () => {
    let steps = null;
    if (this.props.journey) {
      steps = this.props.journeySteps;
    }
    if (this.communicationHasJourneyType()) {
      steps = journeySteps;
    }
    if (this.props.currentConversion.type === 'TRANSACTIONAL') {
      steps = transactionalSteps;
    }
    return (
      <CommunicationFooterComponent
        step={'test'}
        isEdited={this.isEdited}
        loadingNext={false}
        journey={this.props.journey}
        readOnly={this.props.readOnly}
        hideNextButton={this.communicationHasJourneyType()}
        loadingTest={this.props.loadingTest}
        steps={steps}
        handleClickNext={() => this.handleClickNext()}
        handleClickBack={() => this.handleClickBack()}
        handleClickTest={() => this.handleClickTest()}
      />
    );
  };

  renderSteps = () => {
    let steps = null;
    if (this.props.journey) {
      steps = this.props.journeySteps;
    }
    if (this.communicationHasJourneyType()) {
      steps = journeySteps;
    }
    if (this.props.currentConversion.type === 'TRANSACTIONAL') {
      steps = transactionalSteps;
    }
    return (
      <CommunicationStepsComponent
        step={'test'}
        isEdited={this.isEdited}
        creationStep={
          this.props.currentConversion &&
          this.props.currentConversion.creationStep
            ? this.props.currentConversion.creationStep
            : null
        }
        journey={this.props.journey}
        steps={steps}
        goToStepOnJourney={this.props.goToStepOnJourney}
        communicationDraftSaveDiscard={() =>
          this.props.communicationDraftSaveDiscard()
        }
      />
    );
  };

  render() {
    return (
      <div style={{ padding: 30, position: 'relative' }}>
        {this.renderSteps()}
        {this.renderAdvanceSettings()}
        {this.renderFooter()}
      </div>
    );
  }
}

export default CommunicationTestComponent;
