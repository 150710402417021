/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  filter,
  find as lodashFind,
  isEmpty,
  isNil,
  isObject,
  isArray,
  isEqual,
} from 'lodash';
import {
  numberWithCommas,
  showMessageError,
} from '../../../../utils/RenderUtils';
import TagInput from '../../../../component/form/TagInput';
import { history } from '../../../../redux/store';
import {
  journeySteps,
  transactionalSteps,
} from '../../../../utils/CommunicationSteps';
import {
  Button,
  Select,
  Radio,
  Input,
  Tooltip,
  Modal,
  Spin,
  Checkbox,
  Form,
} from 'antd';
import {
  AuthorityProvider,
  getChannel,
  getCommunicationId,
  getCommunicationStep,
  getProductId,
  hasAnyAuthority,
  renderCampaignType,
} from '../../../../utils/AuthorityProvider';
import {
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import onetimeSvg from '../../../../theme/images/onetime.svg';
import triggerdSvg from '../../../../theme/images/triggerd.svg';
import recurringSvg from '../../../../theme/images/recurring.svg';
import CommunicationStepsComponent from './CommunicationStepsComponent';
import transactionalSvg from '../../../../theme/images/transactional.svg';
import CommunicationFooterComponent from './CommunicationFooterComponent';
import { LoadingOutlined } from '@ant-design/icons';
import texts from '../../../../utils/texts';
import vars from '@Theme/styles/vars';
import { findProductThrottlingEnabled } from '@Utils/hasThrottling';
import { isParentProduct } from '@Utils/isParentProduct';
const styles = {
  nextIcon: {
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '40px',
    color: '#bbbcc1',
  },
  normalLevel: {
    cursor: 'pointer',
    fontSize: 13,
    lineHeight: '60px',
    color: '#94969e',
  },
  levelContainer: {
    justifyContent: 'space-around',
    width: '200px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  levelsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 1,
    backgroundColor: '#FFFFFF',
    flexDirection: 'row',
    padding: '0 15px',
    borderBottom: '1px solid rgba(61,64,78,.12)',
  },
  editContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
  },
  editFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 6,
    fontSize: 13,
    lineHeight: 1.43,
    color: '#3d404e',
  },
  editField: {
    width: 350,
  },
  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
    flex: 1,
    textAlign: 'right',
  },
  radioStyle: {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    height: 60,
    backgroundColor: '#ffffff',
    boxShadow:
      '0 -2px 3px 0 rgba(61,64,78,.12), inset 0 -1px 0 0 rgba(61,64,78,.1)',
    padding: '10px 30px',
    transition: 'all .3s ease-in-out',
    left: 247,
    width: 'calc(100% - 247px)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    zIndex: '5',
  },
  learnMore: {
    color: vars.primaryColor,
    fontSize: 13,
    cursor: 'pointer',
    paddingTop: 20,
  },
  methodsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  methodsContainerWebPush: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  methodDescription: {
    textAlign: 'center',
    fontSize: 13,
    lineHeight: 1.5,
    color: 'rgb(61, 64, 78)',
  },
  methodImage: {
    width: 'auto',
    height: 58,
    marginTop: 30,
    marginBottom: 20,
  },
  methodTitle: {
    fontWeight: '600',
    color: '#3d404e',
    lineHeight: '1.43',
    fontSize: 13,
  },
  methodContainer: {
    border: '1px solid #e7e8e9',
    borderRadius: '5px',
    padding: '20px',
    height: 460,
    width: 247,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  methodContainerWebPush: {
    border: '1px solid #e7e8e9',
    borderRadius: '5px',
    padding: '20px',
    height: 370,
    width: 330,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
  },
  addNewSlotButton: {
    position: 'relative',
    left: -10,
    top: -10,
  },
  tagPill: {
    borderRadius: 5,
    borderTop: '1px solid rgba(61,64,78,.2)',
    borderBottom: '1px solid rgba(61,64,78,.2)',
    borderRight: '1px solid rgba(61,64,78,.2)',
    padding: '6px 10px 6px 13px',
    position: 'relative',
  },
  pillWrapper: {
    overflow: 'hidden',
  },
  pillBadge: {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: 5,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    backgroundColor: 'gray',
  },
  pillText: {
    fontSize: 12,
  },
  communicationTagContainer: {
    marginLeft: '140px',
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
    textAlign: 'right',
    marginTop: '-40px',
  },
};

const CommunicationNameStatus = {
  DEFAULT: 'DEFAULT',
  NOT_SELECTED: 'NOT_SELECTED',
  SELECTED: 'SELECTED',
};

export const FormLayout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 16,
  },
};
class CommunicationAudienceComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      throttlingType: 'do_not',
      defaultSecondThrottling: null,
      defaultDayThrottling: null,
      followThrottling: null,
      addPushModalVisible: false,

      audienceType: 'single',
      addCriteria: false,

      name: '',
      tags: null,
      mobileDevice: this.props.currentAudience.pushChannels
        ? this.props.currentAudience.mobileDevice
        : 'ANDROID',
      segments: [],
      emptySegment: [],
      segmentsConjunction: 'OR',
      ignoreSegments: [],
      emptyIgnoreSegment: [],
      ignoreSegmentsConjunction: 'OR',
      androidPushChannels: [],
      iosPushChannels: [],
      type: 'ONE_TIME',
      transactional: false,
      removeDuplicates: true,
      communicationNameSelected: CommunicationNameStatus.DEFAULT,
      runCampaignWarningModal: false,
      runCampaignOk: false,
      segmentUsers: [],
    };
    this.step = getCommunicationStep(this.props.pathname);
    this.channel = getChannel(this.props.pathname);
    this.isParent = isParentProduct(this.props.currentUser, location);
    // this.communicationId = getCommunicationId(this.props.pathname);
    if (props.journey) {
      this.communicationId =
        props.communicationId ||
        (props.communicationData && props.communicationData.id);
      this.channel = props.channel || 'SMS';
    } else {
      this.communicationId = getCommunicationId(this.props.pathname);
    }
  }

  componentDidMount() {
    if (!hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_USER_OVERVIEW)) {
      this.props.insightProductUserReachabilityReportFetch();
    }
    this.props.communicationListSegmentsFetch();
    this.props.communicationListPushChannelsFetch();
    this.props.communicationListTagsFetch();
    if (!hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_CREATE)) {
      this.props.communicationAudienceSegmentReportFetch({
        segments: this.state.segments,
        ignoreSegments: this.state.ignoreSegments,
        segmentsConjunction: this.state.segmentsConjunction,
        ignoreSegmentsConjunction: this.state.ignoreSegmentsConjunction,
      });
    }
    if (this.communicationId) {
      this.props.communicationUpdateAudienceFetch(this.communicationId);
    } else {
      this.props.communicationAudienceReset();
      this.props.communicationChangeCurrent({});
    }
    if (
      !isNil(this.props.communicationDraft) &&
      !this.props.journey &&
      isNil(getCommunicationId(this.props.pathname))
    ) {
      this.setState(this.props.communicationDraft, () => {
        this.props.communicationDraftSaveDiscard();
      });
    }
    if (this.isJourney()) {
      this.props.communicationUpdateWhenThrottlingFetch();
    }
  }
  componentWillUnmount() {
    this.props.CommunicationAudienceClearCreateError();
    this.props.CommunicationAudienceClearUpdateError();
    this.props.segmentReportCurrent({});
  }

  componentDidUpdate(prevProps) {
    let throttlingDefaultSecondValue = null;
    let throttlingDefaultDayValue = null;
    if (
      (prevProps.loadingUpdateThrottlingWhen &&
        !this.props.loadingUpdateThrottlingWhen &&
        this.props.defaultThrottling &&
        Array.isArray(this.props.defaultThrottling.throttlings) &&
        this.props.currentAudience) ||
      (!this.props.loadingUpdateAudience &&
        prevProps.loadingUpdateAudience &&
        this.props.currentAudience &&
        this.props.defaultThrottling &&
        Array.isArray(this.props.defaultThrottling.throttlings))
    ) {
      let followThrottling = null;
      this.props.defaultThrottling.throttlings.forEach((item) => {
        if (this.channel === item.channelType) {
          if (item.interval === 'SECOND') {
            throttlingDefaultSecondValue = item.threshold;
          }
          if (item.interval === 'DAY') {
            throttlingDefaultDayValue = item.threshold;
          }
        }
      });
      if (
        (!isNil(throttlingDefaultDayValue) ||
          !isNil(throttlingDefaultSecondValue)) &&
        isEmpty(this.props.currentAudience)
      ) {
        followThrottling = true;
      } else if (
        isNil(throttlingDefaultDayValue) &&
        isNil(throttlingDefaultSecondValue) &&
        isEmpty(this.props.currentAudience)
      ) {
        followThrottling = false;
      } else {
        followThrottling = this.props.currentAudience.followThrottling;
      }
      this.setState({
        followThrottling: followThrottling,
        throttlingType: followThrottling === true ? 'limits' : 'do_not',
      });
    }
    if (
      prevProps.loadingUpdateThrottlingWhen &&
      !this.props.loadingUpdateThrottlingWhen &&
      this.props.defaultThrottling &&
      Array.isArray(this.props.defaultThrottling.throttlings)
    ) {
      this.props.defaultThrottling.throttlings.forEach((item) => {
        if (this.channel === item.channelType) {
          if (item.interval === 'SECOND') {
            throttlingDefaultSecondValue = item.threshold;
          }
          if (item.interval === 'DAY') {
            throttlingDefaultDayValue = item.threshold;
          }
        }
      });
      this.setState({
        defaultSecondThrottling: throttlingDefaultSecondValue,
        defaultDayThrottling: throttlingDefaultDayValue,
      });
    }
    if (
      !this.props.loadingUpdateAudience &&
      prevProps.loadingUpdateAudience &&
      this.props.currentAudience
    ) {
      const filledSegment = this.fillSegments();
      let filledPushChannels = {
        androidPushChannels: [],
        iosPushChannels: [],
      };
      const emptySegment = new Array(
        this.props.currentAudience.segments.length
      ).fill(0);
      const emptyIgnoreSegment = new Array(
        this.props.currentAudience.ignoreSegments.length
      ).fill(0);
      if (
        Array.isArray(this.props.pushChannels) &&
        this.props.pushChannels.length > 0
      ) {
        filledPushChannels = this.fillPushChannels();
      }
      if (!isNil(this.props.communicationDraft)) {
        // todo emptySegment
        this.setState(this.props.communicationDraft, () => {
          this.props.communicationDraftSaveDiscard();
        });
      } else {
        this.setState({
          emptySegment,
          emptyIgnoreSegment,
          name: this.props.currentAudience.name,
          tags:
            this.props.currentAudience.tags &&
            Array.isArray(this.props.currentAudience.tags)
              ? this.props.currentAudience.tags
              : null,
          mobileDevice: this.props.currentAudience.mobileDevice,
          segments: this.props.currentAudience.segments,
          segmentsConjunction: this.props.currentAudience.segmentsConjunction,
          ignoreSegments: this.props.currentAudience.ignoreSegments,
          ignoreSegmentsConjunction:
            this.props.currentAudience.ignoreSegmentsConjunction,
          type: this.props.currentAudience.type,
          transactional: this.props.currentAudience.transactional,
          removeDuplicates: this.props.currentAudience.removeDuplicates,
          ...filledSegment,
          ...filledPushChannels,
        });
      }
      if (
        Array.isArray(this.props.currentAudience.segments) &&
        Array.isArray(this.props.currentAudience.ignoreSegments) &&
        !hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_CREATE)
      ) {
        this.props.communicationAudienceSegmentReportFetch({
          segments: this.props.currentAudience.segments,
          ignoreSegments: this.props.currentAudience.ignoreSegments,
          segmentsConjunction: this.props.currentAudience.segmentsConjunction,
          ignoreSegmentsConjunction:
            this.props.currentAudience.ignoreSegmentsConjunction,
        });
      }
    }
    if (
      !isEmpty(this.props.currentReport) &&
      !this.props.loadingReportView &&
      prevProps.loadingReportView
    ) {
      this.setState({
        segmentUsers: [
          ...this.state.segmentUsers,
          {
            segmentID: this.props.currentSegmentId,
            totalUsers:
              Number(this.props.currentReport.knownUsersCount) +
              Number(this.props.currentReport.unknownUsersCount),
            knownUsers: Number(this.props.currentReport.knownUsersCount),
          },
        ],
      });
    }
    if (
      !this.props.loadingPushChannels &&
      prevProps.loadingPushChannels &&
      Array.isArray(this.props.pushChannels) &&
      this.props.pushChannels.length > 0
    ) {
      if (
        (isNil(this.state.androidPushChannels) ||
          isEmpty(this.state.androidPushChannels)) &&
        (isEmpty(this.state.iosPushChannels) ||
          isNil(this.state.iosPushChannels))
      ) {
        const filledPushChannels = this.fillPushChannels();
        this.setState({ ...filledPushChannels });
      }
    }
    if (this.props.journey) {
      if (
        prevProps.successCreated !== this.props.successCreated &&
        this.props.successCreated
      ) {
        this.props.updateJourney(
          'update-step',
          Object.assign({}, this.props.dataNode, {
            communication: this.props.communicationData.id,
          })
        );
        if (
          this.props.channel === 'IN_APP' ||
          this.props.channel === 'ON_SITE' ||
          this.props.channel === 'PUSH' ||
          this.props.channel === 'WEB_PUSH'
        ) {
          this.props.goToStepOnJourney('when');
        } else {
          this.props.goToStepOnJourney('message');
        }
      } else if (prevProps.currentWhen !== this.props.currentWhen) {
        this.props.channel === 'IN_APP' ||
        this.props.channel === 'ON_SITE' ||
        this.props.channel === 'PUSH' ||
        this.props.channel === 'WEB_PUSH'
          ? this.props.goToStepOnJourney('when')
          : this.props.goToStepOnJourney('message');
      }
    }
  }

  viewItem(id) {
    if (hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CUSTOM_ATTRIBUTE_VIEW)) {
      history.push(`view/${id}`);
    }
  }

  onChangeAndroidPushChannels = (androidPushChannels) => {
    this.setState({ androidPushChannels });
  };
  handleChangeThrottlingType = (throttlingType) => {
    this.setState({
      throttlingSecondValue: null,
      throttlingDayValue: null,
      throttlingType,
      followThrottling: throttlingType === 'limits' ? true : false,
      throttlings: [],
    });
  };
  onChangeIosPushChannels = (iosPushChannels) => {
    this.setState({ iosPushChannels });
  };

  communicationHasJourneyType = () => {
    if (
      !this.props.journey &&
      this.props.currentAudience &&
      ['JOURNEY', 'RELAY'].includes(this.props.currentAudience.type)
    ) {
      return true;
    }
    return false;
  };
  isJourney = () => {
    if (
      (this.props.journey && this.props.journey === 'JOURNEY') ||
      (!this.props.journey &&
        this.props.currentAudience &&
        ['JOURNEY'].includes(this.props.currentAudience.type))
    ) {
      return true;
    }
    return false;
  };

  getNavigation() {
    return [{ name: 'Custom Attribute List', path: '' }];
  }

  fillPushChannels = () => {
    let androidPushChannels = [];
    let iosPushChannels = [];
    if (
      this.props.currentAudience &&
      this.props.currentAudience.pushChannels &&
      Array.isArray(this.props.currentAudience.pushChannels)
    ) {
      this.props.currentAudience.pushChannels.forEach((item) => {
        const foundItem = lodashFind(
          this.props.pushChannels,
          (o) => o.id === item
        );
        if (foundItem.mobileDevice === 'ANDROID') {
          androidPushChannels.push(item);
        } else if (foundItem.mobileDevice === 'IOS') {
          iosPushChannels.push(item);
        }
      });
    }
    return { androidPushChannels, iosPushChannels };
  };

  fillSegments = () => {
    let audienceType = 'single';
    let addCriteria = false;
    if (
      this.props.currentAudience.segments.length > 1 ||
      this.props.currentAudience.ignoreSegments.length > 0
    ) {
      audienceType = 'multiple';
    }
    if (this.props.currentAudience.ignoreSegments.length > 0) {
      addCriteria = true;
    }
    this.setState({ audienceType, addCriteria });
  };

  setMultipleSegment = (index, id) => {
    const { segments } = this.state;
    segments[index] = id;

    const updateEmptySegment = [...this.state.emptySegment];
    updateEmptySegment[index] = false;

    this.setState({ segments, emptySegment: updateEmptySegment }, () => {
      this.forceUpdate();

      if (!hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_CREATE)) {
        this.props.communicationAudienceSegmentReportFetch({
          segments: this.state.segments,
          ignoreSegments: this.state.ignoreSegments,
          segmentsConjunction: this.state.segmentsConjunction,
          ignoreSegmentsConjunction: this.state.ignoreSegmentsConjunction,
        });
      }
    });
  };

  changeCampaignType = (type) => {
    this.setState({ addPushModalVisible: false, type }, () =>
      this.forceUpdate()
    );
  };

  setMultipleIgnoreSegment = (index, id) => {
    const { ignoreSegments } = this.state;
    ignoreSegments[index] = id;

    const updateIgnoreEmptySegment = [...this.state.emptyIgnoreSegment];
    updateIgnoreEmptySegment[index] = false;

    this.setState(
      { ignoreSegments, emptyIgnoreSegment: updateIgnoreEmptySegment },
      () => {
        this.forceUpdate();
        if (
          !hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_CREATE)
        ) {
          this.props.communicationAudienceSegmentReportFetch({
            segments: this.state.segments,
            ignoreSegments: this.state.ignoreSegments,
            segmentsConjunction: this.state.segmentsConjunction,
            ignoreSegmentsConjunction: this.state.ignoreSegmentsConjunction,
          });
        }
      }
    );
  };

  handleChangeDeviceType = (mobileDevice) => {
    this.setState({ mobileDevice });
  };

  addSegmentList = () => {
    const { segments, emptySegment } = this.state;
    segments.push(null);
    emptySegment.push(null);
    this.setState({ segments, emptySegment });
  };

  handleRemoveSegmentItem = (index) => {
    if (this.state.segments.length === 1 && index === 0) {
      this.setState({ segments: [] }, () => {
        if (
          !hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_CREATE)
        ) {
          this.props.communicationAudienceSegmentReportFetch({
            segments: this.state.segments,
            ignoreSegments: this.state.ignoreSegments,
            segmentsConjunction: this.state.segmentsConjunction,
            ignoreSegmentsConjunction: this.state.ignoreSegmentsConjunction,
          });
        }
      });
    } else {
      this.setState(
        {
          segments: this.state.segments?.filter((_, i) => i !== index) || [],
          emptySegment:
            this.state.emptySegment?.filter((_, i) => i !== index) || [],
        },
        () => {
          if (
            !hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_CREATE)
          ) {
            this.props.communicationAudienceSegmentReportFetch({
              segments: this.state.segments,
              ignoreSegments: this.state.ignoreSegments,
              segmentsConjunction: this.state.segmentsConjunction,
              ignoreSegmentsConjunction: this.state.ignoreSegmentsConjunction,
            });
          }
        }
      );
    }
  };

  isEditDisable = () => {
    return (
      !this.props.journey &&
      this.props.currentAudience &&
      this.props.currentAudience.status === 'ENDED'
    );
  };

  handleClickAddExcludeCriteria = () => {
    const { ignoreSegments, emptyIgnoreSegment } = this.state;
    if (ignoreSegments.length === 0) {
      ignoreSegments.push(null);
    }
    // todo
    emptyIgnoreSegment.push(null);
    this.setState({
      addCriteria: true,
      ignoreSegments,
      emptyIgnoreSegment,
    });
  };

  concatPushChannels = () => {
    let result = null;
    if (this.channel === 'PUSH' || this.channel === 'IN_APP') {
      if (
        (isNil(this.state.mobileDevice) || this.state.mobileDevice === 'IOS') &&
        !isNil(this.state.iosPushChannels) &&
        Array.isArray(this.state.iosPushChannels) &&
        this.state.iosPushChannels.length > 0
      ) {
        result = this.state.iosPushChannels;
      }
      if (
        (isNil(this.state.mobileDevice) ||
          this.state.mobileDevice === 'ANDROID') &&
        !isNil(this.state.androidPushChannels) &&
        Array.isArray(this.state.androidPushChannels) &&
        this.state.androidPushChannels.length > 0
      ) {
        if (isNil(result)) {
          result = this.state.androidPushChannels;
        } else {
          result = result.concat(this.state.androidPushChannels);
        }
      }
    }
    return result;
  };

  addIgnoreSegmentList = () => {
    const { ignoreSegments, emptyIgnoreSegment } = this.state;
    ignoreSegments.push(null);
    emptyIgnoreSegment.push(null);
    this.setState({ ignoreSegments, emptyIgnoreSegment });
  };

  handleRemoveIgnoreSegmentItem = (index) => {
    if (this.state.ignoreSegments.length === 1 && index === 0) {
      this.setState({ ignoreSegments: [] }, () => {
        if (
          !hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_CREATE)
        ) {
          this.props.communicationAudienceSegmentReportFetch({
            segments: this.state.segments,
            ignoreSegments: this.state.ignoreSegments,
            segmentsConjunction: this.state.segmentsConjunction,
            ignoreSegmentsConjunction: this.state.ignoreSegmentsConjunction,
          });
        }
      }); //this.setState({ ignoreSegments: [null] });
    } else {
      this.setState(
        {
          ignoreSegments:
            this.state.ignoreSegments?.filter((_, i) => i !== index) || [],
          emptyIgnoreSegment:
            this.state.emptyIgnoreSegment?.filter((_, i) => i !== index) || [],
        },
        () => {
          if (
            !hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_CREATE)
          ) {
            this.props.communicationAudienceSegmentReportFetch({
              segments: this.state.segments,
              ignoreSegments: this.state.ignoreSegments,
              segmentsConjunction: this.state.segmentsConjunction,
              ignoreSegmentsConjunction: this.state.ignoreSegmentsConjunction,
            });
          }
        }
      );
    }
  };

  renderSteps = () => {
    let steps = null;
    if (this.props.journey) {
      steps = this.props.journeySteps;
    }
    if (this.communicationHasJourneyType()) {
      steps = journeySteps;
    }
    if (this.state.type === 'TRANSACTIONAL') {
      steps = transactionalSteps;
    }

    return (
      <CommunicationStepsComponent
        step={'audience'}
        isEdited={this.isEdited}
        creationStep={
          this.props.currentAudience && this.props.currentAudience.creationStep
            ? this.props.currentAudience.creationStep
            : null
        }
        journey={this.props.journey}
        campaignType={
          this.props.currentAudience && this.props.currentAudience.type
        }
        steps={steps}
        goToStepOnJourney={this.props.goToStepOnJourney}
        communicationDraftSaveDiscard={() =>
          this.props.communicationDraftSaveDiscard()
        }
        channel={this.channel}
      />
    );
  };
  handleChangeCommunicationName = (communicationName) => {
    this.setState(
      {
        name: communicationName,
        communicationNameSelected: isEmpty(communicationName)
          ? CommunicationNameStatus.NOT_SELECTED
          : CommunicationNameStatus.SELECTED,
      },
      () => {}
    );
    this.props.CommunicationAudienceClearCreateError();
    this.props.CommunicationAudienceClearUpdateError();
  };
  renderAddPushModal = () => {
    const productId = getProductId(this.props.pathname);
    const currentProduct = this.props.currentUser?.products?.find(
      (p) => p.id === +productId
    );
    if (this.state.addPushModalVisible) {
      return (
        <Modal
          width={810}
          title={'Select a Communication Type'}
          centered
          footer={null}
          visible={this.state.addPushModalVisible}
          onOk={() => this.setState({ addPushModalVisible: false })}
          onCancel={() => this.setState({ addPushModalVisible: false })}
          okText={'Delete'}
        >
          <div style={styles.methodsContainer}>
            <div
              onClick={() => this.changeCampaignType('ONE_TIME')}
              className="method-push-container"
              style={styles.methodContainer}
            >
              <div style={styles.methodTitle}>One-Time</div>
              <div>
                <img src={onetimeSvg} style={styles.methodImage} alt="" />
              </div>
              <div style={styles.methodDescription}>
                Send the communication only once at a specified date and time
              </div>
              {false && (
                <div
                  onClick={() =>
                    window.open('https://usermost.com/documents/', '_blank')
                  }
                  style={styles.learnMore}
                >
                  Learn More
                </div>
              )}
            </div>
            {!currentProduct?.sendify && (
              <>
                <div
                  onClick={() => this.changeCampaignType('TRIGGERED')}
                  className="method-push-container"
                  style={styles.methodContainer}
                >
                  <div style={styles.methodTitle}>Triggered</div>
                  <div>
                    <img src={triggerdSvg} style={styles.methodImage} alt="" />
                  </div>
                  <div style={styles.methodDescription}>
                    Send the communication on the occurrence of a custom event
                  </div>
                  {false && (
                    <div
                      onClick={() => window.open(texts.documents, '_blank')}
                      style={styles.learnMore}
                    >
                      Learn More
                    </div>
                  )}
                </div>
                <div
                  onClick={() => this.changeCampaignType('RECURRING')}
                  className="method-push-container"
                  style={styles.methodContainer}
                >
                  <div style={styles.methodTitle}>Recurring</div>
                  <div>
                    <img src={recurringSvg} style={styles.methodImage} alt="" />
                  </div>
                  <div style={styles.methodDescription}>
                    Schedule the communication to be sent at regular intervals
                  </div>
                  {false && (
                    <div
                      onClick={() => window.open(texts.documents, '_blank')}
                      style={styles.learnMore}
                    >
                      Learn More
                    </div>
                  )}
                </div>
                <div
                  onClick={() => this.changeCampaignType('TRANSACTIONAL')}
                  className="method-push-container"
                  style={styles.methodContainer}
                >
                  <div style={styles.methodTitle}>Transactional</div>
                  <div>
                    <img
                      src={transactionalSvg}
                      style={styles.methodImage}
                      alt=""
                    />
                  </div>
                  <div style={styles.methodDescription}>
                    Send critical messages through our transactional API
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal>
      );
    }
    return null;
  };
  renderArchiveHint = (segment) => {
    const foundItem = lodashFind(this.props.segments, (o) => o.id === segment);
    let isArchive;
    if (!isNil(foundItem) && foundItem.status === 'ARCHIVED') {
      isArchive = (
        <span
          style={{
            fontSize: 10,
            color: 'red',
            paddingLeft: '5px',
            paddingTop: '5px',
            display: 'block',
          }}
        >
          {'This segment has been archived!'}
        </span>
      );
    }
    return isArchive;
  };

  segmentProssedLists = () => {
    const currentSegmentIds = this.state.segments;
    const prossedLists =
      this.props.segments?.filter((segment) => {
        return segment.status !== 'ARCHIVED';
      }) || [];
    currentSegmentIds?.forEach((item) => {
      const foundItem = lodashFind(this.props.segments, (o) => o.id === item);
      if (foundItem?.status === 'ARCHIVED') {
        prossedLists.push(foundItem);
      }
    });
    return prossedLists;
  };

  renderSegmentsItems = (showAll = true) => {
    const result = [];
    if (showAll) {
      result.push(
        <Select.Option value={null} key={null}>
          All Users
        </Select.Option>
      );
    }
    const prossedLists = this.segmentProssedLists();
    if (prossedLists && Array.isArray(prossedLists)) {
      prossedLists.forEach((item) => {
        if (!item.test) {
          result.push(
            <Select.Option value={item.id} key={item.id}>
              {item.name}
            </Select.Option>
          );
        }
      });
    }
    return result;
  };

  addSegment = () => {
    if (this.communicationId) {
      return '../../../segments/live/create/audience';
    } else {
      return '../../segments/live/create/audience';
    }
  };

  editSegment(segmentId) {
    if (!isNil(segmentId)) {
      if (this.communicationId) {
        return '../../../segments/live/update/' + segmentId + '/audience';
      } else {
        return '../../segments/live/update/' + segmentId + '/audience';
      }
    }
    return null;
  }

  renderSegmentControl = (segment, index = 0) => {
    const foundItem = lodashFind(this.props.segments, (o) => o.id === segment);
    let isDynamic = true;
    if (!isNil(foundItem) && foundItem.type !== 'DYNAMIC') {
      isDynamic = false;
    }
    if (!this.isEditDisable()) {
      return (
        <div
          style={{
            paddingTop: 5,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {isDynamic ? (
            <div
              style={{ cursor: 'pointer' }}
              id="int-communication-audience-segment-edit"
              onClick={() => {
                this.props.communicationDraftSave(this.state);
                history.push(this.editSegment(segment));
              }}
            >
              <i
                style={{
                  border: '1px solid',
                  borderRadius: '50%',
                  fontSize: '28px',
                  lineHeight: '28px',
                  marginLeft: 10,
                }}
                className="fl-dark fl-edit"
              ></i>
            </div>
          ) : (
            <div
              onClick={() => {
                showMessageError({
                  status: 'Error',
                  message:
                    'Selected segment is static and could not be updated.',
                });
              }}
            >
              <i
                style={{
                  border: '1px solid',
                  borderRadius: '50%',
                  fontSize: '28px',
                  lineHeight: '28px',
                  marginLeft: 20,
                }}
                className="fl-dark fl-edit"
              ></i>
            </div>
          )}
          {index === 0 && (
            <div
              style={{ cursor: 'pointer' }}
              id="int-communication-audience-segment-add"
              onClick={() => {
                this.props.communicationDraftSave(this.state);
                history.push(this.addSegment());
              }}
            >
              <div>
                <i
                  style={{
                    border: '1px solid',
                    borderRadius: '50%',
                    fontSize: '28px',
                    lineHeight: '28px',
                    marginLeft: 10,
                  }}
                  className="fl-dark fl-add"
                ></i>
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  renderRemoveDuplicate = () => {
    const smsContent =
      'Duplicate phone numbers would be removed from the target audience to ensure that the same phone number does not receive the campaign multiple times.';
    const emailContent =
      'Duplicate email addresses would be removed from the target audience to ensure that the same email address does not receive the campaign multiple times';
    if (
      !this.props.journey &&
      this.channel &&
      (this.channel === 'EMAIL' || this.channel === 'SMS') &&
      (this.state.type === 'ONE_TIME' || this.state.type === 'RECURRING')
    ) {
      return (
        <div style={styles.editContainer}>
          <div style={styles.editLabel} />
          <div
            style={{
              ...styles.editFieldContainer,
              marginLeft: 20,
              marginTop: 20,
            }}
          >
            <Checkbox
              checked={!!this.state.removeDuplicates}
              disabled={this.props.readOnly || this.isEditDisable()}
              onChange={(e) =>
                this.setState({
                  removeDuplicates: e.target.checked,
                })
              }
              data-cy="remove-duplicate"
            >
              <React.Fragment>
                {'Remove Duplicates'}
                <Tooltip
                  placement="top"
                  title={this.channel === 'EMAIL' ? emailContent : smsContent}
                >
                  <i
                    className="fl-info fl-dark"
                    style={{
                      fontSize: 24,
                      position: 'absolute',
                    }}
                  ></i>
                </Tooltip>
              </React.Fragment>
            </Checkbox>
          </div>
        </div>
      );
    }
    return null;
  };
  getDefaultThrottling = () => {
    let result = '';
    if (
      isNil(this.state.defaultDayThrottling) &&
      !isNil(this.state.defaultSecondThrottling)
    ) {
      result = `${this.state.defaultSecondThrottling} messages per second`;
    } else if (
      !isNil(this.state.defaultDayThrottling) &&
      isNil(this.state.defaultSecondThrottling)
    ) {
      result = `${this.state.defaultDayThrottling} messages per day`;
    } else if (
      isNil(this.state.defaultDayThrottling) &&
      isNil(this.state.defaultSecondThrottling)
    ) {
      result = 'No setting';
    } else {
      result = `${this.state.defaultDayThrottling} messages per day and ${this.state.defaultSecondThrottling}
        messages per second`;
    }
    return result;
  };
  formHelpCommunication = () => {
    if (
      this.state.communicationNameSelected ===
      CommunicationNameStatus.NOT_SELECTED
    ) {
      return 'this field is required';
    } else if (!isEmpty(this.props.errorCreate)) {
      if (
        this.props.errorCreate !== undefined &&
        Array.isArray(this.props.errorCreate.errors) &&
        this.props.errorCreate.errors[0].key === 'name'
      ) {
        return this.props.errorCreate.errors[0].message;
      }
      return null;
    } else if (!isEmpty(this.props.errorUpdateAudienceSubmit)) {
      if (
        this.props.errorUpdateAudienceSubmit !== undefined &&
        Array.isArray(this.props.errorUpdateAudienceSubmit.errors) &&
        this.props.errorUpdateAudienceSubmit.errors[0].key === 'name'
      ) {
        return this.props.errorUpdateAudienceSubmit.errors[0].message;
      }
      return null;
    } else if (!isEmpty(this.props.errorCreateCommunication)) {
      if (
        this.props.errorCreateCommunication !== undefined &&
        Array.isArray(this.props.errorCreateCommunication.errors) &&
        this.props.errorCreateCommunication.errors[0].key === 'name'
      ) {
        return this.props.errorCreateCommunication.errors[0].message;
      }
      return null;
    } else {
      return null;
    }
  };
  renderTotalUsers = () => {
    if (
      this.props.reachability &&
      (!isNil(this.props.reachability.knownUsersCount) ||
        !isNil(this.props.reachability.unknownUsersCount))
    ) {
      return (
        this.props.reachability.knownUsersCount +
        this.props.reachability.unknownUsersCount
      );
    }
    return 0;
  };
  renderKnownUsers = () => {
    if (
      this.props.reachability &&
      !isNil(this.props.reachability.knownUsersCount)
    ) {
      return this.props.reachability.knownUsersCount;
    }
    return 0;
  };
  renderSegmentTotalUsers = (id) => {
    if (id) {
      const findItems = lodashFind(
        this.state.segmentUsers,
        (item) => id === item.segmentID
      );
      return findItems ? findItems.totalUsers : 0;
    }
  };
  renderSegmentKnownUsers = (id) => {
    if (id) {
      const findItems = lodashFind(
        this.state.segmentUsers,
        (item) => id === item.segmentID
      );
      return findItems ? findItems.knownUsers : 0;
    }
  };
  renderReachabilityType = () => {
    if (this.channel === 'EMAIL') {
      return (
        <div>
          Email Reachable Users: <br />
          {this.props.segmentReport &&
            numberWithCommas(this.props.segmentReport.emailReachableUsersCount)}
        </div>
      );
    } else if (this.channel === 'SMS') {
      return (
        <div>
          Sms Reachable Users: <br />
          {this.props.segmentReport &&
            numberWithCommas(this.props.segmentReport.phoneReachableUsersCount)}
        </div>
      );
    } else if (this.channel === 'PUSH') {
      return (
        <div>
          Push Reachable Users: <br />
          {this.props.segmentReport &&
            numberWithCommas(this.props.segmentReport.pushReachableUsersCount)}
        </div>
      );
    } else if (this.channel === 'ON_SITE') {
      return (
        <div>
          On Site Reachable: <br />
          {this.props.segmentReport &&
            numberWithCommas(
              this.props.segmentReport.onSiteReachableUsersCount
            )}
        </div>
      );
    } else if (this.channel === 'IN_APP') {
      return (
        <div>
          In App Reachable: <br />
          {this.props.segmentReport &&
            numberWithCommas(this.props.segmentReport.inAppReachableUsersCount)}
        </div>
      );
    } else if (this.channel === 'WEB_PUSH') {
      return (
        <div>
          Web Push Reachable Users:
          <br />
          {this.props.segmentReport &&
            numberWithCommas(
              this.props.segmentReport.webPushReachableUsersCount
            )}
        </div>
      );
    } else if (this.channel === 'WHATSAPP') {
      return (
        <div>
          WhatsApp Reachable Users:
          <br />
          {this.props.segmentReport &&
            numberWithCommas(
              this.props.segmentReport.whatsAppReachableUsersCount
            )}
        </div>
      );
    }
  };

  renderEditor = () => {
    const activeProductId = getProductId(this.props.pathname);
    const segmentDuplicates =
      this.state.segments?.filter(
        (item, index) => index !== this.state.segments?.indexOf(item)
      ) || [];
    const ignoreSegmentDuplicates =
      this.state.ignoreSegments?.filter(
        (item, index) => index !== this.state.ignoreSegments?.indexOf(item)
      ) || [];
    const sendToContent = (
      <div style={{ fontSize: '12px', padding: '5px' }}>
        {
          'You can choose to send the campaign to users based on the following conditions:'
        }
        <br /> <br />
        <span style={{ fontWeight: 'bold' }}>{'ANY condition'}</span>
        {
          ': Send the campaign to users who are in one or more segments i.e Segment A ⋃ Segment B ⋃ Segment C'
        }
        <br /> <br />
        <span style={{ fontWeight: 'bold' }}>{'ALL condition'}</span>
        {
          ': Send the campaign to only those users who are in all the segments i.e Segment A ⋂ Segment B ⋂ Segment C'
        }
      </div>
    );
    const excludeUsersContent = (
      <div style={{ fontSize: '12px', padding: '5px' }}>
        {
          'You can choose to exclude certain users from receiving this campaign based on the following conditions:'
        }
        <br /> <br />
        <span style={{ fontWeight: 'bold' }}>{'ANY condition'}</span>
        {
          ": Don't send the campaign to users who are in one or more segments i.e Segment A ⋃ Segment B ⋃ Segment C"
        }
        <br /> <br />
        <span style={{ fontWeight: 'bold' }}>{'ALL condition'}</span>
        {
          ": Don't send the campaign to only those users who are in all the segments i.e Segment A ⋂ Segment B ⋂ Segment C"
        }
      </div>
    );
    return (
      <div
        id="communication-body"
        style={{ backgroundColor: 'white', marginBottom: 40 }}
      >
        <div style={{ padding: '30px 30px 90px 30px' }}>
          <div
            id="input-communication-name"
            data-cy="communication-name"
            style={styles.editContainer}
          >
            <div
              style={{
                ...styles.editLabel,
                marginLeft: -5,
                marginTop: -25,
              }}
            >
              COMMUNICATION NAME
            </div>
            <div
              style={{
                ...styles.editFieldContainer,
                marginLeft: 20,
              }}
            >
              <Form.Item
                validateStatus={this.formHelpCommunication() ? 'error' : null}
                help={this.formHelpCommunication()}
                style={{ width: '100%' }}
              >
                <Input
                  id={
                    isEmpty(this.props.errorCreate) ||
                    isEmpty(this.props.errorCreateCommunication) ||
                    isEmpty(this.props.errorUpdateAudienceSubmit)
                      ? null
                      : 'error'
                  }
                  disabled={this.props.readOnly || this.isEditDisable()}
                  value={this.state.name}
                  onChange={(e) =>
                    this.handleChangeCommunicationName(e.target.value)
                  }
                  size="large"
                  style={styles.editField}
                />
              </Form.Item>
            </div>
          </div>
          <div style={styles.editContainer} hidden={this.isParent}>
            <div style={{ ...styles.editLabel, marginTop: -45 }}>TAGS</div>
            <div
              style={{
                ...styles.editFieldContainer,
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: -10,
              }}
            >
              <Form.Item style={{ width: '100%' }}>
                <TagInput
                  loading={this.props.loadingListTags}
                  containerStyle={{
                    ...styles.editFieldContainer,
                    marginLeft: 20,
                  }}
                  disabled={this.props.readOnly || this.isEditDisable()}
                  style={{ width: 600 }}
                  tags={this.state.tags}
                  onChange={(value) => this.setState({ tags: value })}
                  listTags={this.props.listTags}
                  description={
                    'This field is optional. Tags help you analyze communication results for a group of communications that belong to a tag.'
                  }
                />
              </Form.Item>
            </div>
          </div>
          {this.channel !== 'IN_APP' &&
            this.channel !== 'ON_SITE' &&
            !this.props.journey &&
            !this.communicationHasJourneyType() && (
              <div
                style={{
                  ...styles.editContainer,
                  marginTop: 20,
                }}
              >
                <div style={{ ...styles.editLabel }}>COMMUNICATION TYPE</div>
                <div
                  style={{
                    ...styles.editFieldContainer,
                    marginLeft: 20,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {renderCampaignType(this.state.type)}
                  {!this.isEditDisable() && !this.communicationId && (
                    <Tooltip
                      placement={'top'}
                      title={'Change Communication Type'}
                    >
                      <EditOutlined
                        id="int-communication-type"
                        data-cy="communication-type"
                        style={{
                          cursor: 'pointer',
                          color: '#bbbcc1',
                          marginLeft: 10,
                          fontSize: '24px',
                          padding: 4,
                          lineHeight: '28px',
                        }}
                        onClick={() =>
                          this.setState({
                            addPushModalVisible: true,
                          })
                        }
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
            )}
          {this.state.type !== 'TRANSACTIONAL' && (
            <React.Fragment>
              {this.channel === 'PUSH' && (
                <React.Fragment>
                  <div
                    style={{
                      ...styles.editContainer,
                      marginTop: 20,
                      marginBottom: 30,
                      alignItems: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        ...styles.editLabel,
                        position: 'relative',
                        top: 15,
                      }}
                    >
                      TARGET DEVICE
                    </div>
                    <div
                      style={{
                        ...styles.editFieldContainer,
                        marginLeft: 20,
                      }}
                    >
                      <Radio.Group
                        data-cy="communication-target-device"
                        id="int-communication-target-device"
                        disabled={this.props.readOnly || this.isEditDisable()}
                        value={
                          this.state.mobileDevice
                            ? this.state.mobileDevice
                            : undefined
                        }
                        onChange={(e) =>
                          this.handleChangeDeviceType(e.target.value)
                        }
                      >
                        <Radio style={styles.radioStyle} value={undefined}>
                          All
                        </Radio>
                        <Radio style={styles.radioStyle} value={'ANDROID'}>
                          Android Only
                        </Radio>
                        <Radio style={styles.radioStyle} value={'IOS'}>
                          iOS Only
                        </Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  {this.state.mobileDevice !== 'IOS' && (
                    <div
                      style={{
                        ...styles.editContainer,
                        marginTop: 20,
                        alignItems: 'flex-start',
                      }}
                    >
                      <div
                        style={{
                          ...styles.editLabel,
                          position: 'relative',
                          top: 10,
                        }}
                      >
                        TARGET ANDROID APPS
                      </div>
                      <div
                        style={{
                          ...styles.editFieldContainer,
                          marginLeft: 20,
                        }}
                      >
                        <Select
                          data-cy="communication-android-apps"
                          loading={this.props.loadingPushChannels}
                          id="int-communication-target-android"
                          mode="multiple"
                          disabled={this.isEditDisable() || this.props.readOnly}
                          value={
                            this.props.loadingPushChannels
                              ? null
                              : this.state.androidPushChannels
                          }
                          showSearch={false}
                          filterOption={false}
                          style={{ width: 600 }}
                          size="large"
                          onChange={this.onChangeAndroidPushChannels}
                        >
                          {this.props.loadingPushChannels
                            ? null
                            : filter(
                                this.props.pushChannels,
                                (o) => o.mobileDevice === 'ANDROID'
                              ).map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                  {item.packageName}
                                </Select.Option>
                              ))}
                        </Select>
                      </div>
                    </div>
                  )}
                  {this.state.mobileDevice !== 'ANDROID' && (
                    <div
                      style={{
                        ...styles.editContainer,
                        marginTop: 20,
                        alignItems: 'flex-start',
                      }}
                    >
                      <div
                        data-cy="communication-ios-apps_title"
                        style={{
                          ...styles.editLabel,
                          position: 'relative',
                          top: 10,
                        }}
                      >
                        TARGET IOS APPS
                      </div>
                      <div
                        style={{
                          ...styles.editFieldContainer,
                          marginLeft: 20,
                        }}
                      >
                        <Select
                          data-cy="communication-ios-apps"
                          loading={this.props.loadingPushChannels}
                          id="int-communication-target-ios"
                          mode="multiple"
                          disabled={this.isEditDisable() || this.props.readOnly}
                          value={
                            this.props.loadingPushChannels
                              ? null
                              : this.state.iosPushChannels
                          }
                          showSearch={false}
                          filterOption={false}
                          style={{ width: 600 }}
                          size="large"
                          onChange={this.onChangeIosPushChannels}
                        >
                          {this.props.loadingPushChannels
                            ? null
                            : filter(
                                this.props.pushChannels,
                                (o) => o.mobileDevice === 'IOS'
                              ).map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                  {item.packageName}
                                </Select.Option>
                              ))}
                        </Select>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
              {this.channel !== 'CUSTOM' &&
                this.channel !== 'IN_APP' &&
                this.channel !== 'ON_SITE' && (
                  <div
                    style={{
                      ...styles.editContainer,
                      marginTop: 20,
                      alignItems: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        ...styles.editLabel,
                        position: 'relative',
                        top: 5,
                      }}
                    >
                      CONTENT TYPE
                    </div>
                    <div
                      style={{
                        ...styles.editFieldContainer,
                        marginLeft: 20,
                      }}
                    >
                      <Radio.Group
                        id="int-communication-content-type"
                        data-cy="communication-content-type"
                        disabled={this.props.readOnly || this.isEditDisable()}
                        onChange={(e) =>
                          this.setState({
                            transactional: e.target.value,
                          })
                        }
                        value={this.state.transactional}
                      >
                        <Radio
                          style={{
                            ...styles.radioStyle,
                            marginTop: -10,
                          }}
                          value={false}
                        >
                          Promotional (send only to subscribed users in
                          Audience)
                        </Radio>
                        <Radio style={styles.radioStyle} value={true}>
                          Transactional (send to all users in Audience including
                          those who have unsubscribed)
                        </Radio>
                      </Radio.Group>
                    </div>
                  </div>
                )}
              {this.isJourney() &&
                this.props.currentUser &&
                findProductThrottlingEnabled(
                  this.props.currentUser,
                  activeProductId
                ) && (
                  <div
                    style={{
                      ...styles.editContainer,
                      marginTop: 20,
                      alignItems: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        ...styles.editLabel,
                        position: 'relative',
                        top: 2,
                      }}
                    >
                      THROTTLING
                    </div>
                    <div
                      style={{
                        ...styles.editFieldContainer,
                        marginLeft: 20,
                      }}
                    >
                      <Radio.Group
                        disabled={this.isEditDisable()}
                        value={this.state.throttlingType}
                        onChange={(e) =>
                          this.handleChangeThrottlingType(e.target.value)
                        }
                      >
                        <Radio style={styles.radioStyle} value={'do_not'}>
                          Do not throttle campaigns. Send all messages
                          immediately
                        </Radio>
                        <Radio
                          disabled={
                            isNil(this.state.defaultDayThrottling) &&
                            isNil(this.state.defaultSecondThrottling)
                          }
                          style={styles.radioStyle}
                          value={'limits'}
                        >
                          Throttle campaigns as per channel throttling limits (
                          {this.getDefaultThrottling()})
                        </Radio>
                      </Radio.Group>
                    </div>
                  </div>
                )}
              {!this.props.journey && !this.communicationHasJourneyType() && (
                <div
                  style={{
                    ...styles.editContainer,
                    marginTop: 20,
                    alignItems: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      ...styles.editLabel,
                      position: 'relative',
                      top: 15,
                    }}
                  >
                    AUDIENCE TYPE
                  </div>
                  <div
                    style={{
                      ...styles.editFieldContainer,
                      marginLeft: 20,
                    }}
                  >
                    <Radio.Group
                      id="int-communication-audience-type"
                      data-cy="communication-audience-type"
                      disabled={this.props.readOnly || this.isEditDisable()}
                      onChange={(e) => {
                        const type = e.target.value;
                        const newState = {
                          audienceType: type,
                        };
                        if (type === 'single') {
                          newState.ignoreSegments = [];
                          newState.segments = this.state?.segments?.length
                            ? [this.state.segments[0]]
                            : [];
                        }
                        this.setState(newState);
                      }}
                      value={this.state.audienceType}
                    >
                      <Radio style={styles.radioStyle} value={'single'}>
                        Send to users in single segment
                      </Radio>
                      <Radio style={styles.radioStyle} value={'multiple'}>
                        Send to users in multiple segments and/or Don't send to
                        users in certain segments
                      </Radio>
                    </Radio.Group>
                  </div>
                </div>
              )}
              {!this.props.journey && !this.communicationHasJourneyType() && (
                <div
                  style={{
                    ...styles.editContainer,
                    marginTop: 30,
                    marginLeft: 5,
                    alignItems: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      ...styles.editLabel,
                      position: 'relative',
                    }}
                  >
                    {'SEND TO'}
                    {this.state.audienceType !== 'single' && (
                      <Tooltip placement={'bottomLeft'} title={sendToContent}>
                        <InfoCircleOutlined
                          style={{
                            cursor: 'pointer',
                            color: '#bbbcc1',
                            fontSize: '12px',
                            paddingLeft: '4px',
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                  <div
                    id="int-communication-audience-segment-wrap"
                    data-cy="communication-audience-segment-wrap"
                    style={{
                      ...styles.editFieldContainer,
                      marginLeft: 20,
                    }}
                  >
                    {this.state.audienceType === 'single' ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Select
                            loading={this.props.loadingSegment}
                            id="int-communication-audience-segment-single"
                            data-cy="int-communication-audience-segment-single"
                            disabled={this.isEditDisable()}
                            filterOption={(input, option) =>
                              option.props.children
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            style={{
                              ...styles.editField,
                              fontSize: 13,
                              color: '#333',
                            }}
                            dropdownMenuStyle={{
                              maxHeight: 150,
                            }}
                            size="large"
                            value={
                              this.state.segments.length > 0
                                ? this.state.segments[0]
                                : null
                            }
                            onChange={(id) => {
                              if (id) {
                                this.props.productSegmentReportViewFetch(id);
                              }
                              if (id === null || isNil(id)) {
                                this.setState(
                                  {
                                    segments: [],
                                  },
                                  () => {
                                    if (
                                      !hasAnyAuthority(
                                        AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_CREATE
                                      )
                                    ) {
                                      this.props.communicationAudienceSegmentReportFetch(
                                        {
                                          segments: this.state.segments,
                                          ignoreSegments:
                                            this.state.ignoreSegments,
                                          segmentsConjunction:
                                            this.state.segmentsConjunction,
                                          ignoreSegmentsConjunction:
                                            this.state
                                              .ignoreSegmentsConjunction,
                                        }
                                      );
                                    }
                                    this.forceUpdate();
                                  }
                                );
                              } else {
                                this.setState(
                                  {
                                    segments: [id],
                                  },
                                  () => {
                                    if (
                                      !hasAnyAuthority(
                                        AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_CREATE
                                      )
                                    ) {
                                      this.props.communicationAudienceSegmentReportFetch(
                                        {
                                          segments: this.state.segments,
                                          ignoreSegments:
                                            this.state.ignoreSegments,
                                          segmentsConjunction:
                                            this.state.segmentsConjunction,
                                          ignoreSegmentsConjunction:
                                            this.state
                                              .ignoreSegmentsConjunction,
                                        }
                                      );
                                    }
                                    this.forceUpdate();
                                  }
                                );
                              }
                            }}
                          >
                            {this.props.loadingSegment
                              ? null
                              : this.renderSegmentsItems(true)}
                          </Select>
                          {this.renderArchiveHint(this.state.segments[0])}
                        </div>
                        {this.renderSegmentControl(
                          this.state.segments.length > 0
                            ? this.state.segments[0]
                            : null
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <div>
                          <Select
                            id="int-communication-audience-segment-conjunction"
                            data-cy="communication-audience-segment-conjunction"
                            onChange={(segmentsConjunction) =>
                              this.setState({
                                segmentsConjunction,
                              })
                            }
                            disabled={this.isEditDisable()}
                            style={{
                              ...styles.editField,
                              fontSize: 13,
                              color: '#333',
                              width: 260,
                              marginRight: 15,
                            }}
                            size="large"
                            defaultValue={this.state.segmentsConjunction}
                            value={this.state.segmentsConjunction}
                          >
                            <Select.Option value={'OR'}>
                              Users in ANY of these segments
                            </Select.Option>
                            <Select.Option value={'AND'}>
                              Users in ALL of these segments
                            </Select.Option>
                          </Select>
                        </div>
                        <div>
                          {this.state.segments.map(
                            (segmentItem, segmentIndex) => (
                              <div
                                key={segmentIndex}
                                style={
                                  segmentIndex !== 0
                                    ? {
                                        paddingTop: 10,
                                        display: 'flex',
                                        flexDirection: 'row',
                                      }
                                    : {
                                        paddingTop: 0,
                                        display: 'flex',
                                        flexDirection: 'row',
                                      }
                                }
                              >
                                <div style={{ marginBottom: 10 }}>
                                  <Form.Item
                                    validateStatus={
                                      this.state.emptySegment[segmentIndex] ||
                                      (segmentDuplicates.includes(
                                        segmentItem
                                      ) &&
                                        !segmentDuplicates.includes(null))
                                        ? 'error'
                                        : null
                                    }
                                    help={
                                      this.state.emptySegment[segmentIndex]
                                        ? 'Pick a segment please'
                                        : !segmentDuplicates.includes(null) &&
                                          segmentDuplicates.includes(
                                            segmentItem
                                          )
                                        ? 'Identical items'
                                        : null
                                    }
                                    style={{
                                      marginBottom: 0,
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                      }}
                                    >
                                      <Select
                                        id={
                                          this.state.emptySegment[
                                            segmentIndex
                                          ] ||
                                          (!segmentDuplicates.includes(null) &&
                                            segmentDuplicates.includes(
                                              segmentItem
                                            ))
                                            ? 'error'
                                            : null
                                        }
                                        data-cy={`communication-audience-segment__${segmentIndex}`}
                                        loading={this.props.loadingSegment}
                                        disabled={this.isEditDisable()}
                                        key={segmentIndex}
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toString()
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                        showSearch
                                        dropdownMenuStyle={{
                                          maxHeight: 150,
                                        }}
                                        style={{
                                          ...styles.editField,
                                          fontSize: 13,
                                          color: '#333',
                                        }}
                                        size="large"
                                        value={
                                          this.state.segments[segmentIndex]
                                        }
                                        onChange={(segment) => {
                                          if (segment) {
                                            this.props.productSegmentReportViewFetch(
                                              segment
                                            );
                                          }
                                          this.setMultipleSegment(
                                            segmentIndex,
                                            segment
                                          );
                                        }}
                                      >
                                        {this.props.loadingSegment
                                          ? null
                                          : this.renderSegmentsItems(false)}
                                      </Select>
                                    </div>
                                  </Form.Item>
                                  {this.renderArchiveHint(
                                    this.state.segments[segmentIndex],
                                    segmentIndex
                                  )}
                                </div>
                                {!this.isEditDisable() && (
                                  <DeleteOutlined
                                    id="int-communication-audience-segment-delete"
                                    onClick={() => {
                                      this.handleRemoveSegmentItem(
                                        segmentIndex
                                      );
                                    }}
                                    style={{
                                      fontSize: 24,
                                      color: 'rgb(187, 188, 193)',
                                      margin: '15px 15px',
                                      position: 'relative',
                                      bottom: 7,
                                    }}
                                  />
                                )}
                                {this.renderSegmentControl(
                                  this.state.segments[segmentIndex],
                                  segmentIndex
                                )}
                              </div>
                            )
                          )}
                          {!this.isEditDisable() && (
                            <div
                              style={{
                                paddingLeft: 10,
                              }}
                            >
                              <Button
                                id="int-communication-audience-segment-add-list"
                                data-cy="communication-audience-segment-add-list"
                                type="primary"
                                shape="round"
                                size={'large'}
                                ghost
                                style={{
                                  ...styles.addNewSlotButton,
                                  marginTop: '10px',
                                }}
                                onClick={() => this.addSegmentList()}
                              >
                                Add Segment
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {this.state.audienceType !== 'single' && (
                <div
                  style={{
                    ...styles.editContainer,
                    marginTop: 20,
                    alignItems: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      ...styles.editLabel,
                      position: 'relative',
                      top: 5,
                      marginLeft: 10,
                    }}
                  >
                    EXCLUDE USERS
                    <Tooltip placement={'topLeft'} title={excludeUsersContent}>
                      <InfoCircleOutlined
                        style={{
                          cursor: 'pointer',
                          color: '#bbbcc1',
                          fontSize: '12px',
                          paddingLeft: '4px',
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      ...styles.editFieldContainer,
                      marginLeft: 20,
                    }}
                  >
                    {this.state.addCriteria ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <div>
                          <Select
                            id="int-communication-audience-ignore-segment-conjunction"
                            data-cy="communication-audience-ignore-segment-conjunction"
                            disabled={this.isEditDisable()}
                            onChange={(ignoreSegmentsConjunction) =>
                              this.setState({
                                ignoreSegmentsConjunction,
                              })
                            }
                            style={{
                              ...styles.editField,
                              fontSize: 13,
                              color: '#333',
                              width: 260,
                              marginRight: 15,
                            }}
                            size="large"
                            defaultValue={this.state.ignoreSegmentsConjunction}
                            value={this.state.ignoreSegmentsConjunction}
                          >
                            <Select.Option value={'OR'}>
                              Users in ANY of these segments
                            </Select.Option>
                            <Select.Option value={'AND'}>
                              Users in ALL of these segments
                            </Select.Option>
                          </Select>
                        </div>
                        <div>
                          {this.state.ignoreSegments.map(
                            (segmentItem, segmentIndex) => (
                              <div
                                key={segmentIndex}
                                style={
                                  segmentIndex !== 0
                                    ? {
                                        paddingTop: 10,
                                        display: 'flex',
                                        flexDirection: 'row',
                                      }
                                    : {
                                        paddingTop: 0,
                                        display: 'flex',
                                        flexDirection: 'row',
                                      }
                                }
                              >
                                <Form.Item
                                  validateStatus={
                                    this.state.emptyIgnoreSegment[
                                      segmentIndex
                                    ] ||
                                    (!ignoreSegmentDuplicates.includes(null) &&
                                      ignoreSegmentDuplicates.includes(
                                        segmentItem
                                      ))
                                      ? 'error'
                                      : null
                                  }
                                  help={
                                    this.state.emptyIgnoreSegment[segmentIndex]
                                      ? 'Pick a segment please'
                                      : !ignoreSegmentDuplicates.includes(
                                          null
                                        ) &&
                                        ignoreSegmentDuplicates.includes(
                                          segmentItem
                                        )
                                      ? 'duplicate items'
                                      : null
                                  }
                                  id={
                                    this.state.emptyIgnoreSegment[
                                      segmentIndex
                                    ] ||
                                    (!ignoreSegmentDuplicates.includes(null) &&
                                      ignoreSegmentDuplicates.includes(
                                        segmentItem
                                      ))
                                      ? 'error'
                                      : null
                                  }
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Select
                                      loading={this.props.loadingSegment}
                                      id="int-communication-audience-ignore-segment"
                                      data-cy={`communication-audience-ignore-segment__${segmentIndex}`}
                                      key={segmentIndex}
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toString()
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      showSearch
                                      disabled={this.isEditDisable()}
                                      dropdownMenuStyle={{
                                        maxHeight: 150,
                                      }}
                                      style={{
                                        ...styles.editField,
                                        fontSize: 13,
                                        color: '#333',
                                      }}
                                      size="large"
                                      value={
                                        this.state.ignoreSegments[segmentIndex]
                                      }
                                      onChange={(segment) => {
                                        this.setMultipleIgnoreSegment(
                                          segmentIndex,
                                          segment
                                        );
                                      }}
                                    >
                                      {this.props.loadingSegment
                                        ? null
                                        : this.renderSegmentsItems(false)}
                                    </Select>
                                  </div>
                                </Form.Item>
                                {!this.isEditDisable() && (
                                  <DeleteOutlined
                                    id="int-communication-audience-ignore-segment-delete"
                                    onClick={() => {
                                      this.handleRemoveIgnoreSegmentItem(
                                        segmentIndex
                                      );
                                    }}
                                    style={{
                                      fontSize: 24,
                                      color: 'rgb(187, 188, 193)',
                                      margin: '15px 15px',
                                      position: 'relative',
                                      bottom: 7,
                                    }}
                                  />
                                )}
                                {this.renderSegmentControl(
                                  this.state.ignoreSegments[segmentIndex],
                                  segmentIndex
                                )}
                              </div>
                            )
                          )}
                          {!this.isEditDisable() && (
                            <div
                              style={{
                                paddingLeft: 10,
                              }}
                            >
                              <Button
                                id="int-communication-audience-ignore-segment-add-list"
                                data-cy="communication-audience-ignore-segment-add-list"
                                type="primary"
                                shape="round"
                                size={'large'}
                                ghost
                                style={{
                                  ...styles.addNewSlotButton,
                                  marginTop: '10px',
                                }}
                                onClick={() => this.addIgnoreSegmentList()}
                              >
                                Add Segment
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          alignSelf: 'flex-start',
                          position: 'relative',
                          top: -3,
                        }}
                      >
                        <Button
                          id="int-communication-audience-exclude-criteria"
                          data-cy="communication-audience-exclude-criteria"
                          disabled={this.isEditDisable()}
                          type="link"
                          onClick={() => this.handleClickAddExcludeCriteria()}
                          style={{
                            fontSize: 12,
                            fontWeight: 600,
                          }}
                        >
                          ADD CRITERIA
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {!this.props.journey && !this.communicationHasJourneyType() && (
                <div
                  style={{
                    ...styles.editContainer,
                    marginTop: 20,
                    alignItems: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      ...styles.editLabel,
                      position: 'relative',
                      top: 15,
                    }}
                  ></div>
                  <div
                    style={{
                      ...styles.editFieldContainer,
                      marginLeft: 20,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: 'white',
                        display: 'flex',
                        flexDirection: 'row',
                        width: 630,
                        justifyContent: 'space-between',
                        borderRadius: vars.borderRadius,
                        alignItems: 'center',
                        paddingLeft: 30,
                        paddingTop: 30,
                        paddingBottom: 30,
                        border: '1px solid #e2e2e2',
                        boxShadow: 'inset 0 0 6px 0 #e7e8e9',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <div
                          style={{
                            color: 'rgba(61,64,78,.55)',
                            fontSize: 15,
                            marginRight: 40,
                          }}
                        >
                          Total Users: <br />
                          {this.props.segmentReport &&
                            numberWithCommas(
                              this.props.segmentReport.unknownUsersCount +
                                this.props.segmentReport.knownUsersCount
                            )}
                        </div>
                        <div
                          style={{
                            color: 'rgba(61,64,78,.55)',
                            fontSize: 15,
                            marginRight: 40,
                          }}
                        >
                          Known Users: <br />
                          {this.props.segmentReport &&
                            numberWithCommas(
                              this.props.segmentReport.knownUsersCount
                            )}
                        </div>
                        <div
                          style={{
                            color: 'rgba(61,64,78,.55)',
                            fontSize: 15,
                          }}
                        >
                          {this.renderReachabilityType()}
                        </div>
                      </div>{' '}
                      <Button
                        type="primary"
                        shape="circle"
                        size={'middle'}
                        style={{
                          marginLeft: 30,
                          marginRight: 10,
                          marginTop: -30,
                        }}
                        onClick={() => {
                          this.props.communicationDraftSaveDiscard();
                          if (this.state.emptySegment.indexOf(null) > -1) {
                            this.changeEmptySegmentsStatus('emptySegment');
                          }
                          if (
                            this.state.emptyIgnoreSegment.indexOf(null) > -1
                          ) {
                            this.changeEmptySegmentsStatus(
                              'emptyIgnoreSegment'
                            );
                          }
                          const sendRequest =
                            this.state.segments.indexOf(null) === -1 &&
                            this.state.ignoreSegments.indexOf(null) === -1;
                          if (sendRequest) {
                            if (
                              !hasAnyAuthority(
                                AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_CREATE
                              )
                            ) {
                              this.props.communicationAudienceSegmentReportFetch(
                                {
                                  segments: this.state.segments,
                                  ignoreSegments: this.state.ignoreSegments,
                                  segmentsConjunction:
                                    this.state.segmentsConjunction,
                                  ignoreSegmentsConjunction:
                                    this.state.ignoreSegmentsConjunction,
                                }
                              );
                            }
                          }
                        }}
                      >
                        {' '}
                        <i
                          className="fl-sync fl-light"
                          style={{ fontSize: 25 }}
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {this.renderRemoveDuplicate()}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };
  changeEmptySegmentsStatus = (input) => {
    const updateEmptySegments = this.state[input];
    for (var i = 0; i <= updateEmptySegments.length; i++) {
      if (updateEmptySegments[i] === null) {
        updateEmptySegments[i] = true;
      }
      this.setState({ [input]: updateEmptySegments }, () => {});
    }
  };

  getCurrentAudience() {
    return {
      channel: this.channel,
      ignoreSegments:
        isEmpty(this.state.ignoreSegments) || isNil(this.state.ignoreSegments)
          ? []
          : this.state.ignoreSegments,
      ignoreSegmentsConjunction: this.state.ignoreSegmentsConjunction,
      mobileDevice: this.state.mobileDevice
        ? this.state.mobileDevice
        : undefined,
      name: this.state.name,
      removeDuplicates: this.state.removeDuplicates,
      pushChannels: this.concatPushChannels() || [],
      segments: this.state.segments,
      segmentsConjunction: this.state.segmentsConjunction,
      transactional: this.state.transactional,
      type: this.props.journey || this.state.type,
      tags:
        this.state.tags &&
        !isNil(this.state.tags) &&
        Array.isArray(this.state.tags) &&
        this.state.tags.length > 0
          ? this.state.tags
          : [],
    };
  }

  handleClickNext = () => {
    const segmentDuplicates = this.state.segments.filter(
      (item, index) => index !== this.state.segments.indexOf(item)
    );
    const ignoreSegmentsduplicates = this.state.ignoreSegments.filter(
      (item, index) => index !== this.state.ignoreSegments.indexOf(item)
    );
    this.props.communicationDraftSaveDiscard();
    if (this.state.emptySegment.indexOf(null) > -1) {
      this.changeEmptySegmentsStatus('emptySegment');
    }
    if (this.state.emptyIgnoreSegment.indexOf(null) > -1) {
      this.changeEmptySegmentsStatus('emptyIgnoreSegment');
    }
    if (
      !this.props.journey &&
      this.state.segments.length <= 0 &&
      this.state.runCampaignOk === false
    ) {
      if (
        this.state.type !== 'TRANSACTIONAL' &&
        !this.communicationHasJourneyType()
      ) {
        this.setState({ runCampaignWarningModal: true }, () => {});
        return;
      }
    }

    const sendRequest =
      this.state.segments.indexOf(null) === -1 &&
      this.state.ignoreSegments.indexOf(null) === -1 &&
      isEmpty(segmentDuplicates) &&
      isEmpty(ignoreSegmentsduplicates);
    if (this.communicationId || this.communicationHasJourneyType()) {
      if (sendRequest) {
        if (this.isJourney) {
          this.props.communicationUpdateSubmitAudienceFetch(
            this.communicationId,
            {
              ...this.props.currentAudience,
              channel: this.channel,
              ignoreSegments:
                isEmpty(this.state.ignoreSegments) ||
                isNil(this.state.ignoreSegments)
                  ? null
                  : this.state.ignoreSegments,
              ignoreSegmentsConjunction: this.state.ignoreSegmentsConjunction,
              mobileDevice: this.state.mobileDevice
                ? this.state.mobileDevice
                : undefined,
              name: this.state.name,
              removeDuplicates: this.state.removeDuplicates,
              pushChannels: this.concatPushChannels(),
              segments: this.state.segments,
              segmentsConjunction: this.state.segmentsConjunction,
              transactional: this.state.transactional,
              type: this.props.journey || this.state.type,
              followThrottling: this.state.followThrottling,
              tags:
                this.state.tags &&
                !isNil(this.state.tags) &&
                Array.isArray(this.state.tags) &&
                this.state.tags.length > 0
                  ? this.state.tags
                  : [],
            },
            this.props.journey,
            this.communicationHasJourneyType()
          );
        } else {
          this.props.communicationUpdateSubmitAudienceFetch(
            this.communicationId,
            {
              ...this.props.currentAudience,
              channel: this.channel,
              ignoreSegments:
                isEmpty(this.state.ignoreSegments) ||
                isNil(this.state.ignoreSegments)
                  ? null
                  : this.state.ignoreSegments,
              ignoreSegmentsConjunction: this.state.ignoreSegmentsConjunction,
              mobileDevice: this.state.mobileDevice
                ? this.state.mobileDevice
                : undefined,
              name: this.state.name,
              removeDuplicates: this.state.removeDuplicates,
              pushChannels: this.concatPushChannels(),
              segments: this.state.segments,
              segmentsConjunction: this.state.segmentsConjunction,
              transactional: this.state.transactional,
              type: this.props.journey || this.state.type,
              tags:
                this.state.tags &&
                !isNil(this.state.tags) &&
                Array.isArray(this.state.tags) &&
                this.state.tags.length > 0
                  ? this.state.tags
                  : [],
            },
            this.props.journey,
            this.communicationHasJourneyType()
          );
        }
      }
    } else {
      if (this.isJourney()) {
        if (sendRequest) {
          this.props.communicationCreateFetch(
            {
              //todo
              tags:
                this.state.tags &&
                !isNil(this.state.tags) &&
                Array.isArray(this.state.tags) &&
                this.state.tags.length > 0
                  ? this.state.tags
                  : [],
              channel: this.channel,
              ignoreSegments:
                isEmpty(this.state.ignoreSegments) ||
                isNil(this.state.ignoreSegments)
                  ? null
                  : this.state.ignoreSegments,
              ignoreSegmentsConjunction: this.state.ignoreSegmentsConjunction,
              mobileDevice: this.state.mobileDevice
                ? this.state.mobileDevice
                : null,
              name: this.state.name,
              removeDuplicates: this.state.removeDuplicates,
              pushChannels: this.concatPushChannels(),
              segments: this.state.segments,
              segmentsConjunction: this.state.segmentsConjunction,
              transactional: this.state.transactional,
              type: this.props.journey || this.state.type,
              followThrottling: this.state.followThrottling,
            },
            null,
            this.props.journey
          );
        }
      } else if (this.props.journey) {
        if (sendRequest) {
          this.props.communicationCreateFetch(
            {
              //todo
              tags:
                this.state.tags &&
                !isNil(this.state.tags) &&
                Array.isArray(this.state.tags) &&
                this.state.tags.length > 0
                  ? this.state.tags
                  : [],
              channel: this.channel,
              ignoreSegments:
                isEmpty(this.state.ignoreSegments) ||
                isNil(this.state.ignoreSegments)
                  ? null
                  : this.state.ignoreSegments,
              ignoreSegmentsConjunction: this.state.ignoreSegmentsConjunction,
              mobileDevice: this.state.mobileDevice
                ? this.state.mobileDevice
                : null,
              name: this.state.name,
              removeDuplicates: this.state.removeDuplicates,
              pushChannels: this.concatPushChannels(),
              segments: this.state.segments,
              segmentsConjunction: this.state.segmentsConjunction,
              transactional: this.state.transactional,
              type: this.props.journey || this.state.type,
            },
            null,
            this.props.journey
          );
        }
      } else {
        if (
          this.state.communicationNameSelected ===
          CommunicationNameStatus.DEFAULT
        ) {
          this.setState(
            {
              communicationNameSelected: CommunicationNameStatus.NOT_SELECTED,
            },
            () => {}
          );
        }
        if (this.state.emptySegment.indexOf(null) > -1) {
          this.changeEmptySegmentsStatus('emptySegment');
        }
        if (this.state.emptyIgnoreSegment.indexOf(null) > -1) {
          this.changeEmptySegmentsStatus('emptyIgnoreSegment');
        }
        if (
          sendRequest &&
          this.state.communicationNameSelected ===
            CommunicationNameStatus.SELECTED
        ) {
          this.props.communicationAudienceCreateFetch({
            //tod
            tags:
              this.state.tags &&
              !isNil(this.state.tags) &&
              Array.isArray(this.state.tags) &&
              this.state.tags.length > 0
                ? this.state.tags
                : [],
            channel: this.channel,
            ignoreSegments:
              isEmpty(this.state.ignoreSegments) ||
              isNil(this.state.ignoreSegments)
                ? null
                : this.state.ignoreSegments,
            ignoreSegmentsConjunction: this.state.ignoreSegmentsConjunction,
            mobileDevice: this.state.mobileDevice
              ? this.state.mobileDevice
              : null,
            name: this.state.name,
            removeDuplicates: this.state.removeDuplicates,
            pushChannels: this.concatPushChannels(),
            segments: this.state.segments,
            segmentsConjunction: this.state.segmentsConjunction,
            transactional: this.state.transactional,
            type: this.props.journey || this.state.type,
          });
        }
      }
    }
  };
  renderModal = () => {
    return (
      <Modal
        title="Run Campaign Warning"
        visible={this.state.runCampaignWarningModal}
        onOk={() => {
          this.setState({ runCampaignOk: true });
          if (this.state.type !== 'TRANSACTIONAL') {
            this.setState({ runCampaignWarningModal: false }, () =>
              this.handleClickNext()
            );
          }
        }}
        onCancel={() => {
          if (this.state.type !== 'TRANSACTIONAL') {
            this.setState({ runCampaignWarningModal: false });
          }
        }}
      >
        <p>
          Are you sure to run this campaign for all {this.renderTotalUsers()}{' '}
          users?
        </p>
      </Modal>
    );
  };
  isEdited = () => {
    const audienceState = this.getCurrentAudience();

    if (this.props.currentAudience.length < audienceState.length) return true;
    let sourceKeys = [
      'type',
      'channel',
      'name',
      'mobileDevice',
      'tags',
      'transactional',
      'pushChannels',
      'segmentsConjunction',
      'segments',
      'ignoreSegmentsConjunction',
      'ignoreSegments',
      'removeDuplicates',
    ];

    const res = sourceKeys.some((key) => {
      const source = this.props.currentAudience[key];
      const dest = audienceState[key];
      return isArray(source) || isObject(source)
        ? !isEqual(source, dest) && source !== undefined && source !== null
        : source != dest && source !== undefined && source !== null;
    });

    return res;
  };
  renderFooter = () => {
    let steps = null;
    if (this.props.journey) {
      steps = this.props.journeySteps;
    }
    if (this.communicationHasJourneyType()) {
      steps = journeySteps;
    }
    if (this.state.type === 'TRANSACTIONAL') {
      steps = transactionalSteps;
    }

    return (
      <React.Fragment>
        <CommunicationFooterComponent
          step={'audience'}
          isEdited={this.isEdited}
          loadingNext={
            this.props.loadingCreate || this.props.loadingUpdateAudienceSubmit
          }
          steps={steps}
          journey={this.props.journey}
          readOnly={this.props.readOnly || this.isEditDisable()}
          handleClickNext={() => this.handleClickNext()}
        />
        {!this.props.journey && this.renderModal()}
      </React.Fragment>
    );
  };
  render() {
    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    return (
      <div
        style={
          this.props.journey
            ? { padding: '0 30px' }
            : { padding: 30, position: 'relative' }
        }
      >
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: 5,
            boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
          }}
        ></div>
        {this.props.loadingUpdateAudience ? null : this.renderSteps()}
        {this.props.loadingUpdateAudience ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              padding: 50,
              backgroundColor: 'white',
            }}
          >
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <React.Fragment>
            {this.renderEditor()}
            {this.renderAddPushModal()}
            {this.renderFooter()}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default CommunicationAudienceComponent;
