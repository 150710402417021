import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import ProductSettingAuditLogContainer from './container/ProductSettingAuditLogContainer';
import ProductSettingDndContainer from './container/ProductSettingDndContainer';
import ProductSettingCustomDomainContainer from './container/ProductSettingCustomDomainContainer';
import ProductSettingFcContainer from './container/ProductSettingFcContainer';
import ProductSettingGeneralContainer from './container/ProductSettingGeneralContainer';
import ProductSettingScheduledReportContainer from './container/ProductSettingScheduledReportContainer';
import ProductSettingThrottlingContainer from '@Modules/setting/productSetting/component/ProductSettingThrottlingComponent';
import ProductSettingFonts from './component/ProductSettingFonts';
import {
  authorizedSideMenuSettingCustomDomain,
  authorizedSideMenuSettingDnd,
  authorizedSideMenuSettingFc,
  authorizedSideMenuSettingGeneral,
} from '@Utils/AuthorityProvider';
import {
  featureAccessCheckSettingCustomDomainTab,
  featureAccessCheckSettingDNDTab,
  featureAccessCheckSettingFCTab,
  featureAccessCheckSettingGeneralTab,
} from '@Utils/AccessManagement';

function ProductSegment() {
  const match = useRouteMatch();

  const showGeneral =
    authorizedSideMenuSettingGeneral() && featureAccessCheckSettingGeneralTab();

  const showDnd =
    authorizedSideMenuSettingDnd() && featureAccessCheckSettingDNDTab();

  const showFC =
    authorizedSideMenuSettingFc() && featureAccessCheckSettingFCTab();

  const showCustomDomain =
    authorizedSideMenuSettingCustomDomain() &&
    featureAccessCheckSettingCustomDomainTab();

  return (
    <Switch>
      <Route exact path={`${match.path}audit`}>
        <ProductSettingAuditLogContainer />
      </Route>
      {showDnd && (
        <Route exact path={`${match.path}dnd`}>
          <ProductSettingDndContainer />
        </Route>
      )}
      {showCustomDomain && (
        <Route exact path={`${match.path}custom-domain`}>
          <ProductSettingCustomDomainContainer />
        </Route>
      )}
      {showFC && (
        <Route exact path={`${match.path}fc`}>
          <ProductSettingFcContainer />
        </Route>
      )}
      {showGeneral && (
        <Route exact path={`${match.path}general`}>
          <ProductSettingGeneralContainer />
        </Route>
      )}
      <Route exact path={`${match.path}report`}>
        <ProductSettingScheduledReportContainer />
      </Route>
      <Route exact path={`${match.path}throttling`}>
        <ProductSettingThrottlingContainer />
      </Route>
      <Route exact path={`${match.path}fonts`}>
        <ProductSettingFonts />
      </Route>
    </Switch>
  );
}

export default ProductSegment;
