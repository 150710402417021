import { productSegmentRecommendEventAttributesFetch } from '@Modules/insight/productSegment/ProductSegmentActions';
import ModalEventFilterSelector from '@Modules/analytics/funnel/component/ModalEventFilterSelector';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getActiveProductDetails } from '@Utils/AuthorityProvider';
import { Badge, Button, Card, Form, Input, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loading from '../components/Loading';
import {
  cohortListEventsFetch,
  cohortListAttributesFetch,
  cohortCreate,
  cohortView,
  cohortEdit,
} from '@Redux/slices/analytics/cohortSlice';
import './cohortCreate.less';

const { Option, OptGroup } = Select;

export default function CohortCreate() {
  const { productId, cohortId } = useParams();
  let { location } = useHistory();
  const dispatch = useDispatch();
  const history = useHistory();

  const listEvents = useSelector((state) => state.analytics.cohort.listEvents);
  const loading = useSelector((state) => state.analytics.cohort.loading);
  const recommendEventAttributes = useSelector(
    (state) => state.insight.productSegment.recommendEventAttributes
  );
  const listAttributes = useSelector(
    (state) => state.analytics.cohort.listAttributes
  );
  const loadingEvent = useSelector(
    (state) => state.analytics.cohort.loadingEvent
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [currentCohort, setCurrentCohort] = useState({
    name: '',
    firstEventFilter: {},
    returnEventFilter: {},
    splitBy: '',
  });

  const deepInIntegration = useMemo(() => {
    return (getActiveProductDetails(location.pathname) || {})
      ?.deepInIntegration;
  }, []);

  useEffect(() => {
    dispatch(cohortListEventsFetch());
    dispatch(cohortListAttributesFetch());
    if (cohortId) {
      dispatch(cohortView({ id: cohortId, cb: setCurrentCohort }));
    }
  }, []);

  useEffect(() => {
    let temp =
      currentCohort?.name !== '' &&
      Object.keys(currentCohort?.firstEventFilter).length &&
      Object.keys(currentCohort?.returnEventFilter).length;
    setIsFormValid(temp);
  }, [currentCohort]);

  const onFinish = () => {
    if (!cohortId) {
      dispatch(cohortCreate({ body: currentCohort, cb: goPrevPage }));
    } else {
      dispatch(
        cohortEdit({ id: cohortId, body: currentCohort, cb: goPrevPage })
      );
    }
  };

  const onChangeEvents = (value, fieldName) => {
    const tempCurrentCohort = Object.assign({}, currentCohort);
    tempCurrentCohort[fieldName]['event'] = value;
    tempCurrentCohort[fieldName]['attributeFilters'] = [];
    tempCurrentCohort[fieldName]['conjunction'] = 'AND';
    setCurrentCohort(tempCurrentCohort);
  };

  const onChangeAttributes = (value) => {
    const tempCurrentCohort = Object.assign({}, currentCohort);
    tempCurrentCohort['splitBy'] = value;
    setCurrentCohort(tempCurrentCohort);
  };

  const onChangeInput = (e) => {
    const tempCurrentCohort = Object.assign({}, currentCohort);
    tempCurrentCohort['name'] = e.target.value;
    setCurrentCohort(tempCurrentCohort);
  };

  const onEventModalChange = (changedPredicate, fieldName) => {
    const tempCurrentCohort = Object.assign({}, currentCohort);
    tempCurrentCohort[fieldName].conjunction = changedPredicate.conjunction;
    tempCurrentCohort[fieldName].attributeFilters =
      changedPredicate.eventPredicates;
    setCurrentCohort(tempCurrentCohort);
    setIsModalVisible(false);
  };

  const renderEventSelector = (fieldName) => {
    return (
      <div className="funnel_evects">
        <Select
          showSearch
          style={{ width: '100%' }}
          value={currentCohort?.[fieldName]?.event}
          placeholder={'Select an Event'}
          onChange={(value) => onChangeEvents(value, fieldName)}
          required
          optionFilterProp="children"
        >
          <OptGroup label="SYSTEM">
            {renderOptions('system', listEvents)}
          </OptGroup>
          <OptGroup label="CUSTOM">
            {renderOptions('custom', listEvents)}
          </OptGroup>
        </Select>
        <Badge
          count={
            currentCohort?.[fieldName]?.['attributeFilters'] &&
            currentCohort?.[fieldName]?.['attributeFilters']?.length
          }
          showZero={false}
        >
          <div
            onClick={() => {
              if (currentCohort[fieldName]?.['event']) {
                setIsModalVisible(currentCohort[fieldName]?.['event']);
              }
            }}
            className="fl-filter fl-dark pointer"
            style={{ fontSize: 28 }}
          ></div>
        </Badge>
        <ModalEventFilterSelector
          currentPredicate={currentCohort?.[fieldName]}
          isModalVisible={
            currentCohort?.[fieldName]?.['event'] === isModalVisible
          }
          events={listEvents}
          onCancelHandler={() => setIsModalVisible(false)}
          onOkHandler={(changedPredicate) =>
            onEventModalChange(changedPredicate, fieldName)
          }
          handleChangePredicate={() => {}}
          fetchRecommendEventAttributes={
            productSegmentRecommendEventAttributesFetch
          }
          recommendEventAttributes={recommendEventAttributes}
          deepInIntegration={deepInIntegration}
        />
      </div>
    );
  };

  const renderOptions = (eventType, data = []) => {
    let systemItems = [];
    let customItems = [];
    if (data.length) {
      data.forEach((item) => {
        if (item.system) {
          systemItems.push(
            <Option key={item.id} value={item.id}>
              {item.title}
            </Option>
          );
        } else {
          customItems.push(
            <Option key={item.id} value={item.id}>
              {item.title}
            </Option>
          );
        }
      });
      if (eventType === 'system') {
        return systemItems;
      } else if (eventType === 'custom') {
        return customItems;
      }
    }
  };

  const renderAttributesSelector = () => {
    return (
      <div className="funnel_evects">
        <Select
          showSearch
          style={{ width: '100%' }}
          value={currentCohort?.splitBy}
          onChange={(value) => onChangeAttributes(value)}
          required
          optionFilterProp="children"
        >
          <OptGroup label="SYSTEM">
            {renderOptions('system', listAttributes)}
          </OptGroup>
          <OptGroup label="CUSTOM">
            {renderOptions('custom', listAttributes)}
          </OptGroup>
        </Select>
      </div>
    );
  };

  const goPrevPage = () => {
    history.push(`/product/${productId}/cohorts/list`);
  };

  return (
    <Card styles={{ padding: 20 }}>
      {loading || loadingEvent ? (
        <Loading />
      ) : (
        <Form
          name="cohort"
          onFinish={onFinish}
          initialValues={{
            cohortName: currentCohort?.name,
          }}
          autoComplete="off"
        >
          <div className="funnel-name">
            <div className="funnel-name_title">COHORT NAME</div>
            <div className="funnel-name_input">
              <Form.Item
                name="cohortName"
                rules={[
                  {
                    required: true,
                    message: 'this field is required',
                  },
                ]}
              >
                <Input
                  value={currentCohort?.name}
                  onChange={(e) => onChangeInput(e)}
                />
              </Form.Item>
            </div>
          </div>
          <div className="funnel-step">
            <div className="funnel-step_title">FIRST EVENT</div>
            <div className="funnel-step_input">
              {renderEventSelector('firstEventFilter')}
            </div>
          </div>
          <div className="funnel-step">
            <div className="funnel-step_title">RETURN EVENT</div>
            <div className="funnel-step_input">
              {renderEventSelector('returnEventFilter')}
            </div>
          </div>
          <div className="funnel-step">
            <div className="funnel-step_title">SPLIT BY</div>
            <div className="funnel-step_input">
              {renderAttributesSelector()}
            </div>
          </div>
          <div className="dnd-footer">
            <Button
              type="text"
              shape="round"
              size={'large'}
              htmlType="reset"
              onClick={goPrevPage}
            >
              CANCEL
            </Button>

            <Button
              loading={loading}
              type="primary"
              shape="round"
              size={'large'}
              htmlType="submit"
              disabled={!isFormValid}
            >
              SAVE
            </Button>
          </div>
        </Form>
      )}
    </Card>
  );
}
