import {
  FUNNEL_LIST_EVENTS_FETCH,
  FUNNEL_LIST_EVENTS_FETCH_SUCCESS,
  FUNNEL_LIST_EVENTS_FETCH_FAIL,
  FUNNEL_EXECUTE,
  FUNNEL_EXECUTE_SUCCESS,
  FUNNEL_EXECUTE_FAIL,
  FUNNEL_EXECUTE_PARAMS_CHANGED,
  FUNNEL_VIEW_EXECUTION,
  FUNNEL_VIEW_EXECUTION_SUCCESS,
  FUNNEL_VIEW_EXECUTION_FAIL,
  FUNNEL_VIEW_FETCH,
  FUNNEL_VIEW_FETCH_SUCCESS,
  FUNNEL_VIEW_FETCH_FAIL,
  FUNNEL_LIST_FETCH,
  FUNNEL_LIST_FETCHED,
  FUNNEL_LIST_FETCH_FAIL,
  FUNNEL_LIST_PARAMS_CHANGED,
  FUNNEL_STOP_EXECUTION,
  FUNNEL_STOP_EXECUTION_SUCCESS,
  FUNNEL_STOP_EXECUTION_FAIL,
  FUNNEL_CREATE_UPDATE_FETCH,
  FUNNEL_CREATE_UPDATE_FETCH_SUCCESS,
  FUNNEL_CREATE_UPDATE_FETCH_FAIL,
} from './FunnelConstants';

const now = new Date();

const initialState = {
  createUpdate: {
    fetching: false,
    errors: [],
    content: {
      name: null,
      steps: [
        {
          conjunction: 'AND',
          event: null,
          type: 'BEHAVIOUR',
          predicates: [],
        },
      ],
    },
  },
  list: {
    fetching: true,
    page: {},
    pageRequest: {
      direction: 'DESC',
      name: null,
      order: 'id',
      page: 0,
    },
  },
  view: {
    fetching: false,
    content: {
      id: null,
      name: null,
      steps: [],
    },
    executionParams: {
      funnelId: null,
      endDate: now.toISOString().split('T')[0],
      startDate: new Date(now.setDate(now.getDate() - 3))
        .toISOString()
        .split('T')[0],
      completionTime: 86400,
    },
    executing: false,
    stopping: false,
    executionId: null,
    executionResult: {
      id: null,
      startDate: null,
      endDate: null,
      completionTime: null,
      status: null,
      totalUsersCount: null,
      processedUsersCount: null,
      remainingTimeSeconds: null,
      averageConversionTimeSeconds: null,
      steps: [],
      transitions: [],
    },
  },
  events: {
    fetching: false,
    content: [],
    errors: [],
  },
};

export default function funnelState(state = initialState, action) {
  switch (action.type) {
    case FUNNEL_CREATE_UPDATE_FETCH:
      return {
        ...state,
        createUpdate: {
          ...state.createUpdate,
          fetching: true,
        },
      };
    case FUNNEL_CREATE_UPDATE_FETCH_SUCCESS:
      return {
        ...state,
        createUpdate: {
          ...state.createUpdate,
          fetching: false,
          content: action.payload,
        },
      };
    case FUNNEL_CREATE_UPDATE_FETCH_FAIL:
      return {
        ...state,
        createUpdate: {
          ...state.createUpdate,
          fetching: false,
          errors: action.payload.errors,
        },
      };

    case FUNNEL_LIST_EVENTS_FETCH:
      return {
        ...state,
        events: {
          ...state.events,
          fetching: true,
        },
      };
    case FUNNEL_LIST_EVENTS_FETCH_SUCCESS:
      return {
        ...state,
        events: {
          ...state.events,
          fetching: false,
          content: action.payload,
        },
      };
    case FUNNEL_LIST_EVENTS_FETCH_FAIL:
      return {
        ...state,
        events: {
          ...state.events,
          fetching: false,
          errors: action.payload,
        },
      };

    case FUNNEL_EXECUTE:
      return {
        ...state,
        view: {
          ...state.view,
          executing: true,
          executionId: null,
        },
      };
    case FUNNEL_EXECUTE_SUCCESS:
      return {
        ...state,
        view: {
          ...state.view,
          executing: false,
          executionId: action.payload,
          executionResult: {
            id: null,
            startDate: null,
            endDate: null,
            completionTime: null,
            status: null,
            totalUsersCount: null,
            processedUsersCount: null,
            remainingTimeSeconds: null,
            averageConversionTimeSeconds: null,
            steps: [],
            transitions: [],
          },
        },
      };
    case FUNNEL_EXECUTE_FAIL:
      return {
        ...state,
        view: {
          executing: false,
          ...state.view,
        },
      };
    case FUNNEL_EXECUTE_PARAMS_CHANGED:
      return {
        ...state,
        view: {
          ...state.view,
          executionParams: {
            ...state.view.executionParams,
            ...action.payload,
          },
        },
      };

    case FUNNEL_VIEW_EXECUTION:
      return {
        ...state,
        view: {
          ...state.view,
        },
      };
    case FUNNEL_VIEW_EXECUTION_SUCCESS:
      return {
        ...state,
        view: {
          ...state.view,
          executing: action.payload.status === 'RUNNING',
          stopping: state.view.stopping && action.payload.status === 'RUNNING',
          executionResult: action.payload,
        },
      };
    case FUNNEL_VIEW_EXECUTION_FAIL:
      return {
        ...state,
        view: {
          ...state.view,
        },
      };

    case FUNNEL_STOP_EXECUTION:
      return {
        ...state,
        view: {
          ...state.view,
          stopping: true,
        },
      };
    case FUNNEL_STOP_EXECUTION_SUCCESS:
      return {
        ...state,
        view: {
          stopping: false,
          ...state.view,
        },
      };
    case FUNNEL_STOP_EXECUTION_FAIL:
      return {
        ...state,
        view: {
          stopping: false,
          ...state.view,
        },
      };

    case FUNNEL_VIEW_FETCH:
      return {
        ...initialState,
        view: {
          ...initialState.view,
          fetching: true,
        },
      };
    case FUNNEL_VIEW_FETCH_SUCCESS:
      return {
        ...state,
        view: {
          ...state.view,
          fetching: false,
          content: action.payload,
          executionParams: {
            ...state.view.executionParams,
          },
        },
      };
    case FUNNEL_VIEW_FETCH_FAIL:
      return {
        ...state,
        view: {
          ...state.view,
          fetching: false,
        },
      };

    case FUNNEL_LIST_FETCH:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: true,
          page: [],
        },
      };
    case FUNNEL_LIST_FETCHED:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: false,
          page: action.payload,
        },
      };
    case FUNNEL_LIST_FETCH_FAIL:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: false,
          page: [],
        },
      };
    case FUNNEL_LIST_PARAMS_CHANGED:
      return {
        ...state,
        list: {
          ...state.list,
          pageRequest: {
            ...state.list.pageRequest,
            ...action.payload,
          },
        },
      };

    default:
      return state;
  }
}
