import { Button, Card, Form, Input, InputNumber, Select } from 'antd';
import Loading from '../../../analytics/cohort/components/Loading';
import { numberCommaSeparator, toEnDigit } from '../helper';
import EventSelector from '../components/EventSelector';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import {
  alertListTeamMembers,
  alertListEventsFetch,
  alertView,
  alertCreate,
  alertEdit,
} from '@Redux/slices/data/alertSlice';
import {
  AlertCheckFrequency,
  AlertOccurrenceCondition,
  AlertTriggerThresholdOperator,
} from '../alertConstants';
import './createAlert.less';

const CreateAlert = () => {
  const [isFormEnabled, setIsFormEnabled] = useState(false);
  const { productId, alertId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const listUsers = useSelector((state) => state.dataModule.alerts.listUsers);
  const loading = useSelector((state) => state.dataModule.alerts.loading);
  const loadingEvent = useSelector(
    (state) => state.dataModule.alerts.loadingEvent
  );

  const [currentAlert, setCurrentAlert] = useState({
    name: '',
    description: '',
    enabled: true,
    occurrenceCondition: '',
    event: {},
    checkFrequency: '',
    triggerThresholdOperator: AlertTriggerThresholdOperator[0].value,
    threshold: '',
    users: [],
  });

  useEffect(() => {
    dispatch(alertListTeamMembers({}));
    dispatch(alertListEventsFetch());
    if (alertId) {
      dispatch(alertView({ id: alertId, cb: setCurrentAlert }));
    }
  }, []);

  useEffect(() => {
    setIsFormEnabled(alertId && currentAlert?.enabled);
  }, [currentAlert, listUsers]);

  const onFinish = () => {
    if (!alertId) {
      dispatch(alertCreate({ body: currentAlert, cb: goPrevPage }));
    } else {
      dispatch(alertEdit({ id: alertId, body: currentAlert, cb: goPrevPage }));
    }
  };

  const onChangeInput = (e) => {
    setCurrentAlert((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onChangeSelect = (name, value) => {
    setCurrentAlert((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const goPrevPage = () => {
    history.push(`/product/${productId}/alerts/list`);
  };

  return (
    <Card>
      {loadingEvent ? (
        <Loading />
      ) : (
        <Form
          disabled={isFormEnabled}
          name="Alert"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{
            name: currentAlert?.name,
            description: currentAlert?.description,
            enabled: currentAlert?.enabled,
            occurrenceCondition: currentAlert?.occurrenceCondition,
            event: currentAlert?.event,
            checkFrequency: currentAlert?.checkFrequency,
            triggerThresholdOperator: currentAlert?.triggerThresholdOperator,
            threshold: currentAlert?.threshold,
            users: currentAlert?.users,
          }}
        >
          <div className="alert-step-wrapper">
            <div className="alert-input_title">ALERT NAME</div>
            <div className="alert-step_input">
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                <Input
                  name="name"
                  value={currentAlert?.name}
                  onChange={(e) => onChangeInput(e)}
                />
              </Form.Item>
            </div>
          </div>
          <div className="alert-step-wrapper">
            <div className="alert-input_title">Description</div>
            <div className="alert-step_input">
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                <Input.TextArea
                  name="description"
                  value={currentAlert?.description}
                  onChange={(e) => onChangeInput(e)}
                />
              </Form.Item>
            </div>
          </div>
          <div className="alert-step-wrapper">
            <div className="alert-input_title">Metrics</div>
            <div className="alert-metric-step_input">
              <Form.Item
                name="occurrenceCondition"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                <Select
                  style={{ width: '100%' }}
                  value={currentAlert?.occurrenceCondition}
                  onChange={(value) =>
                    onChangeSelect('occurrenceCondition', value)
                  }
                  required
                  optionFilterProp="children"
                  placeholder="Select"
                >
                  {AlertOccurrenceCondition?.map((condition) => (
                    <Select.Option
                      value={condition.value}
                      key={condition.value}
                    >
                      {condition.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="alert-metric-input_title">Of</div>
            <div className="alert-metric-step_input">
              <EventSelector
                setCurrentAlert={setCurrentAlert}
                currentAlert={currentAlert}
                fieldName="event"
              />
            </div>
          </div>
          <div className="alert-step-wrapper">
            <div className="alert-input_title">Check metric every</div>
            <div className="alert-time-step_input">
              <Form.Item
                name="checkFrequency"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                <Select
                  style={{ width: '100%' }}
                  value={currentAlert?.checkFrequency}
                  onChange={(value) => onChangeSelect('checkFrequency', value)}
                  required
                  placeholder="Select"
                >
                  {AlertCheckFrequency?.map((timeFrame) => (
                    <Select.Option
                      value={timeFrame.value}
                      key={timeFrame.value}
                    >
                      {timeFrame.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="alert-time-input_title">
              And send alert if current day’s value is
            </div>
            <div className="alert-time-step_input">
              <Form.Item name="triggerThresholdOperator">
                <Select
                  name="triggerThresholdOperator"
                  style={{ width: '100%' }}
                  value={currentAlert?.triggerThresholdOperator}
                  defaultValue={currentAlert?.triggerThresholdOperator}
                  disabled
                  placeholder="Select"
                >
                  {AlertTriggerThresholdOperator?.map((operator) => (
                    <Select.Option value={operator.value} key={operator.value}>
                      {operator.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="alert-count-step_input" id="threshold">
              <Form.Item
                name="threshold"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                <InputNumber
                  className="custom-input-number"
                  name="threshold"
                  value={currentAlert?.threshold}
                  onChange={(value) => onChangeSelect('threshold', value)}
                  formatter={numberCommaSeparator}
                  parser={(value) => {
                    return toEnDigit(value).replaceAll(',', '');
                  }}
                  min={0}
                  placeholder="Count"
                  style={{ borderRadius: '8px' }}
                />
              </Form.Item>
            </div>
          </div>
          <div className="alert-step-wrapper">
            <div className="alert-input_title">Notify</div>
            <div className="alert-step_input">
              <Form.Item
                name="users"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                <Select
                  showArrow
                  mode="multiple"
                  value={currentAlert?.users}
                  onChange={(value) => onChangeSelect('users', value)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {listUsers?.map((user) => (
                    <Select.Option value={user.id} key={user.id}>
                      {user?.user?.email}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className="dnd-footer">
            <Button
              type="text"
              shape="round"
              size={'large'}
              htmlType="reset"
              onClick={goPrevPage}
            >
              CANCEL
            </Button>

            <Button
              loading={loading}
              type="primary"
              shape="round"
              size={'large'}
              htmlType="submit"
            >
              SAVE
            </Button>
          </div>
        </Form>
      )}
    </Card>
  );
};

export default CreateAlert;
