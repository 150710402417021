import React, { memo, Suspense, useEffect, useRef } from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';
import { Layout } from 'antd';
import Loading from '@Component/utils/Loading';
import HomeContainer from '../../modules/home/container/HomeContainer';
import Download from '../../modules/layout/Download';

import UserRoute from '@Modules/track/users';

import OverviewContainer from '@Modules/engage/overview/container/OverviewContainer';
import Communication from '@Modules/engage/communication';
import CommunicationList from '@Modules/engage/communication/communicationList';
import Channels from '@Modules/channel/channels';
import JourneyRoute from '@Modules/engage/journey/journeyRoute';
import JourneysRoute from '@Modules/engage/journey/journeysRoute';
import RelayRoute from '@Modules/engage/journey/relayRoute';
import RelaysRoute from '@Modules/engage/journey/relaysRoute';
import ProductSegment from '@Modules/insight/productSegment';
import Funnel from '@Modules/analytics/funnel';
import Cohort from '@Modules/analytics/cohort';
import Uninstall from '@Modules/analytics/uninstalls';

import DataManagement from '@Modules/data/DataManagement';
import Alerts from '@Modules/data/alerts';
import UploadData from '@Modules/data/uploadData';
import Profile from '@Modules/account/profile';
import BackOffice from '@Modules/account/back-office';

import ProductBilling from '@Modules/setting/billing';
import ProductSetting from '@Modules/setting/productSetting';

import WebhookViewContainer from '@Modules/integration/webhook/container/WebhookViewContainer';
import AuditLogList from '@Modules/setting/auditLog/components/AuditLogList';
import TeamMembers from '@Modules/integration/teamMembers';
import teamMemberInvitationContainer from '@Modules/integration/teamMembers/container/teamMemberInvitationContainer';
import EventAnalyticsContainer from '@Modules/track/events/container/EventAnalyticsContainer';
import ProductSdkAndroidViewContainer from '@Modules/integration/productSdk/container/ProductSdkViewContainer';
import ProductRestApiViewContainer from '@Modules/integration/productRestApi/container/ProductRestApiViewContainer';

import FileStorage from '@Modules/admin/fileStorage';
import ReleaseNote from '@Modules/admin/releaseNote';
import Role from '@Modules/admin/role';

import User from '@Modules/admin/user';
import AdminProduct from '@Modules/admin/adminProduct';
import AdminCreateProduct from '@Modules/admin/createProduct';
import AdminDashboard from '@Modules/admin/dashboard';
import ContractTemplate from '@Modules/admin/contractTemplate';
import Configuration from '@Modules/admin/configuration';
import GatewayResponse from '@Modules/setting/billing/components/payment/GatewayResponse';
import ApiTokenViewContainer from '@Modules/integration/productRestApiToken/container/ApiTokenViewContainer';
import ThirdParty from '@Modules/admin/thirdParty';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const { Content } = Layout;

const PageRoutes = ({ isUserLogin }) => {
  const location = useLocation();

  const ref = useRef(null);

  useEffect(() => {
    ref.current = location.pathname;
  }, []);

  useEffect(() => {
    if (isUserLogin) sessionStorage.removeItem('time');
  }, [isUserLogin]);

  const PageContents = (
    <Content>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route
            exact
            path="/"
            render={() => <HomeContainer returnUrl={ref.current} />}
          />
          <Route
            exact
            path="/register"
            component={teamMemberInvitationContainer}
          />
          {isUserLogin && (
            <>
              <Route
                exact
                path="/billing/payment-result"
                render={() => <GatewayResponse />}
              />
              <Route path="/account/profile/" component={Profile} />
              <Route path="/back-office/" component={BackOffice} />
              <Route path="/base/configuration/" component={Configuration} />
              <Route path="/account/role/" component={Role} />
              <Route path="/account/user/" component={User} />
              <Route path="/admin/adminProduct/" component={AdminProduct} />
              <Route path="/admin/third-party/" component={ThirdParty} />
              <Route
                path="/admin/create-product/"
                component={AdminCreateProduct}
              />
              <Route
                path="/admin/management-dashboard/"
                component={AdminDashboard}
              />
              <Route
                path="/admin/contract-template/"
                component={ContractTemplate}
              />
              <Route path="/account/releaseNote/" component={ReleaseNote} />
              <Route path="/account/file-storage/" component={FileStorage} />
              <Route
                path="/product/:productId/segments/:type/"
                component={ProductSegment}
              />
              <Route
                path="/product/:productId/channels/"
                component={Channels}
              />
              <Route
                path="/product/:productId/settings/"
                component={ProductSetting}
              />
              <Route
                exact
                path="/product/:productId/event-analytics"
                component={EventAnalyticsContainer}
              />
              <Route path="/product/:productId/users/" component={UserRoute} />
              <Route
                exact
                path="/product/:productId/rest-api-key/view"
                component={ProductRestApiViewContainer}
              />
              <Route
                exact
                path="/product/:productId/rest-api-token/view"
                component={ApiTokenViewContainer}
              />
              <Route
                exact
                path="/product/:productId/sdk/view"
                component={ProductSdkAndroidViewContainer}
              />
              <Route
                exact
                path="/product/:productId/webhooks/view"
                component={WebhookViewContainer}
              />
              <Route
                exact
                path="/product/:productId/audit-log"
                component={AuditLogList}
              />
              <Route path="/product/:productId/team/" component={TeamMembers} />
              <Route
                path="/product/:productId/:type/campaign-list/"
                component={CommunicationList}
              />
              <Route
                path="/product/:productId/:type/campaigns/"
                component={Communication}
              />
              <Route
                path="/product/:productId/journey/"
                component={JourneyRoute}
              />
              <Route
                path="/product/:productId/journeys/"
                component={JourneysRoute}
              />
              <Route path="/product/:productId/relay/" component={RelayRoute} />
              <Route
                path="/product/:productId/relays/"
                component={RelaysRoute}
              />
              <Route
                exact
                path="/product/:productId/engagement/overview/all"
                component={OverviewContainer}
              />
              <Route
                path="/product/:productId/uninstalls/"
                component={Uninstall}
              />
              <Route path="/product/:productId/funnels/" component={Funnel} />
              <Route path="/product/:productId/cohorts/" component={Cohort} />
              <Route
                path="/product/:productId/billing"
                component={ProductBilling}
              />
              <Route
                path="/product/:productId/data-management/"
                component={DataManagement}
              />
              <Route
                path="/product/:productId/upload-data/"
                component={UploadData}
              />
              <Route path="/product/:productId/alerts/" component={Alerts} />
              <Route path="/download/:id" component={Download} />
            </>
          )}
        </Switch>
      </Suspense>
    </Content>
  );

  return [PageContents];
};

export default memo(PageRoutes);
