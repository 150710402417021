import { HTMLBuilder } from '../builder';
import { copyIcon } from '../icons';

const defaultClasses = `
.container--default {
display:flex;
flex-direction:column;
align-items:center;
justify-content:space-between;
flex:1;
padding:8px;
width:280px;
background-color:#f2f2f2;
border-radius:4px;
box-shadow: 0 2px 8px 0 #0000001F;
overflow:hidden;
}
.coupon--wrapper-default {
display:flex;
align-items:center;
justify-content:space-between;
border-radius:4px;
width:calc(100% - 16px);
padding:8px;
}
`;

function coupon(variation, customFonts) {
  const builder = new HTMLBuilder(defaultClasses, customFonts);

  const title = builder.text({
    text: variation.subject,
    fontSize: variation.theme.title.font_size,
    fontFamily: variation.theme.title.font_family,
    color: variation.theme.title.color,
    marginBottom: 8,
  });

  const body = builder.text({
    text: variation.message,
    fontSize: variation.theme.message.font_size,
    fontFamily: variation.theme.message.font_family,
    color: variation.theme.message.color,
    marginBottom: 8,
  });

  const couponCode = builder.text({
    text: variation.couponCode,
    fontSize: variation.theme.coupon.font_size,
    fontFamily: variation.theme.coupon.font_family,
    color: variation.theme.coupon.color,
  });

  const copyBtn = builder.block({
    width: 20,
    height: 20,
    background: 'rgb(29,136,246)',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    innerHTML: copyIcon,
  });

  const couponBox = builder.wrapStyled([couponCode, copyBtn], {
    className: 'coupon--wrapper-default',
    backgroundColor: variation.theme.cta_background_coupon,
  });

  builder.setBackground(variation.image);

  builder.injectChildren(title, body, couponBox);

  return builder.build();
}

export { coupon };
