import { HTMLBuilder } from '../builder';

const defaultClasses = `
.container--default {
display:flex;
flex-direction:column;
flex:1;
align-items:center;
justify-content:space-between;
padding:16px;
width:300px;
min-height:380px;
background-color:#ffffff;
border-radius:4px;
box-shadow: 0 2px 8px 0 #0000001F;
overflow:hidden;
}
.text--default {
padding-block:8px;
}
`;

function modal(variation, customFonts) {
  const builder = new HTMLBuilder(defaultClasses, customFonts);

  const actions = [];

  const title = builder.text({
    text: variation.subject,
    fontSize: variation.theme.title.font_size,
    fontFamily: variation.theme.title.font_family,
    color: variation.theme.title.color,
    hasDefaultClassName: true,
  });

  const body = builder.text({
    text: variation.message,
    fontSize: variation.theme.message.font_size,
    fontFamily: variation.theme.message.font_family,
    color: variation.theme.message.color,
    hasDefaultClassName: true,
  });

  const image = builder.image({
    src: variation.image,
    width: '100%',
    minHeight: '300px',
    backgroundColor: '#f2f2f2',
    objectFilt: 'cover',
    objectPosition: 'center',
  });

  if (Array.isArray(variation.theme.actions)) {
    const [action] = variation.theme.actions;

    if (action && variation.androidButton1Label)
      actions.push(
        builder.action({
          text: variation.androidButton1Label,
          action: variation.androidButton1OnClickAction,
          backgroundColor: action.bg_color,
          fontSize: action.style.font_size,
          fontFamily: action.style.font_family,
          color: action.style.color,
        })
      );
  }

  builder.injectChildren(title, image, body, ...actions);

  return builder.build();
}

export { modal };
