import {
  modal,
  card,
  image,
  coupon,
  banner,
  text,
  bannerRtl,
} from './templates';

function createHTML(variation, channel, customFonts) {
  if (channel === 'IN_APP') {
    switch (variation.inAppLayoutType) {
      case 'MODAL':
        return modal(variation, customFonts);
      case 'CARD':
        return card(variation, customFonts);
      case 'COUPON':
        return coupon(variation, customFonts);
      case 'BANNER':
        return banner(variation, customFonts);
      case 'BANNER_RTL':
        return bannerRtl(variation, customFonts);
      case 'IMAGE_ONLY':
        return image(variation);
      default:
        return '';
    }
  }

  if (channel === 'ON_SITE') {
    switch (variation.onSiteLayoutType) {
      case 'COUPON':
        return coupon(variation, customFonts);
      case 'TEXT':
        return text(variation, customFonts);
      case 'IMAGE':
        return image(variation);
      default:
        return '';
    }
  }
}

function injectHTML(variations, channel, customFonts) {
  if (!Array.isArray(variations)) return null;

  return variations.map((variation) => ({
    ...variation,
    htmlBody: createHTML(variation, channel, customFonts),
  }));
}

export { injectHTML };
