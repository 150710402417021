import { isNil } from 'lodash';
import store from '../redux/store';
import {
  featureAccessCheckSideMenuAnalyticsSection,
  featureAccessCheckSideMenuAuditLog,
  featureAccessCheckSideMenuChannel,
  featureAccessCheckSideMenuEngageInApp,
  featureAccessCheckSideMenuEngageJourneys,
  featureAccessCheckSideMenuEngageOnSite,
  featureAccessCheckSideMenuEngageOverview,
  featureAccessCheckSideMenuEngageRelays,
  featureAccessCheckSideMenuEngageSection,
  featureAccessCheckSideMenuRestApiKey,
  featureAccessCheckSideMenuRestApiToken,
  featureAccessCheckSideMenuSDK,
  featureAccessCheckSideMenuTeam,
  featureAccessCheckSideMenuUsersOption,
  featureAccessCheckSideMenuWebhook,
} from './AccessManagement';

export const AuthorityProvider = {
  ROLE_ADMIN_DOCUMENTATION_VIEW: 'ROLE_ADMIN_DOCUMENTATION_VIEW',
  ROLE_ADMIN_CONFIGURATION_VIEW: 'ROLE_ADMIN_CONFIGURATION_VIEW',
  ROLE_ADMIN_CONFIGURATION_UPDATE: 'ROLE_ADMIN_CONFIGURATION_UPDATE',
  ROLE_ADMIN_USER_CREATE: 'ROLE_ADMIN_USER_CREATE',
  ROLE_ADMIN_USER_VIEW: 'ROLE_ADMIN_USER_VIEW',
  ROLE_ADMIN_USER_LIST: 'ROLE_ADMIN_USER_LIST',
  ROLE_ADMIN_USER_UPDATE: 'ROLE_ADMIN_USER_UPDATE',
  ROLE_ADMIN_USER_CHANGE_PASSWORD: 'ROLE_ADMIN_USER_CHANGE_PASSWORD',
  ROLE_ADMIN_ROLE_CREATE: 'ROLE_ADMIN_ROLE_CREATE',
  ROLE_ADMIN_ROLE_VIEW: 'ROLE_ADMIN_ROLE_VIEW',
  ROLE_ADMIN_ROLE_LIST: 'ROLE_ADMIN_ROLE_LIST',
  ROLE_ADMIN_ROLE_UPDATE: 'ROLE_ADMIN_ROLE_UPDATE',
  ROLE_ADMIN_ROLE_DELETE: 'ROLE_ADMIN_ROLE_DELETE',
  ROLE_ADMIN_FILE_STORAGE_CREATE: 'ROLE_ADMIN_FILE_STORAGE_CREATE',
  ROLE_ADMIN_FILE_STORAGE_VIEW: 'ROLE_ADMIN_FILE_STORAGE_VIEW',
  ROLE_ADMIN_FILE_STORAGE_LIST: 'ROLE_ADMIN_FILE_STORAGE_LIST',
  ROLE_ADMIN_FILE_STORAGE_UPDATE: 'ROLE_ADMIN_FILE_STORAGE_UPDATE',
  ROLE_ADMIN_FILE_STORAGE_DELETE: 'ROLE_ADMIN_FILE_STORAGE_DELETE',
  ROLE_ADMIN_PRODUCT_CREATE: 'ROLE_ADMIN_PRODUCT_CREATE',
  ROLE_ADMIN_PRODUCT_VIEW: 'ROLE_ADMIN_PRODUCT_VIEW',
  ROLE_ADMIN_PRODUCT_LIST: 'ROLE_ADMIN_PRODUCT_LIST',
  ROLE_ADMIN_PRODUCT_UPDATE: 'ROLE_ADMIN_PRODUCT_UPDATE',
  ROLE_ADMIN_PRODUCT_MIGRATE_TO_DEMO: 'ROLE_ADMIN_PRODUCT_MIGRATE_TO_DEMO',
  ROLE_ADMIN_PRODUCT_DELETE: 'ROLE_ADMIN_PRODUCT_DELETE',
  ROLE_ADMIN_PRODUCT_ENGAGEMENT_EXPORT: 'ROLE_ADMIN_PRODUCT_ENGAGEMENT_EXPORT',
  ROLE_ADMIN_PRODUCT_CSV_EXPORT_LOG: 'ROLE_ADMIN_PRODUCT_CSV_EXPORT_LOG',
  ROLE_ADMIN_PRODUCT_RELEASE_NOTES: 'ROLE_ADMIN_PRODUCT_RELEASE_NOTES',
  ROLE_ADMIN_MIGRATION: 'ROLE_ADMIN_MIGRATION',
  ROLE_ADMIN_REPORTS: 'ROLE_ADMIN_REPORTS',
  ROLE_PRODUCT_PII: 'ROLE_PRODUCT_PII',
  ROLE_PRODUCT_USER_OVERVIEW: 'ROLE_PRODUCT_USER_OVERVIEW',
  ROLE_PRODUCT_USER_ANALYSE: 'ROLE_PRODUCT_USER_ANALYSE',
  ROLE_PRODUCT_USER_LIST: 'ROLE_PRODUCT_USER_LIST',
  ROLE_PRODUCT_USER_VIEW: 'ROLE_PRODUCT_USER_VIEW',
  ROLE_PRODUCT_UPLOAD_CSV_USER: 'ROLE_PRODUCT_UPLOAD_CSV_USER',
  ROLE_PRODUCT_SEGMENT_CREATE: 'ROLE_PRODUCT_SEGMENT_CREATE',
  ROLE_PRODUCT_SEGMENT_REPORT: 'ROLE_PRODUCT_SEGMENT_REPORT',
  ROLE_PRODUCT_SEGMENT_VIEW: 'ROLE_PRODUCT_SEGMENT_VIEW',
  ROLE_PRODUCT_SEGMENT_LIST: 'ROLE_PRODUCT_SEGMENT_LIST',
  ROLE_PRODUCT_SEGMENT_UPDATE: 'ROLE_PRODUCT_SEGMENT_UPDATE',
  ROLE_PRODUCT_SEGMENT_DELETE: 'ROLE_PRODUCT_SEGMENT_DELETE',
  ROLE_PRODUCT_EVENTS_OVERVIEW: 'ROLE_PRODUCT_EVENTS_OVERVIEW',
  ROLE_PRODUCT_UPLOAD_CSV_EVENT: 'ROLE_PRODUCT_UPLOAD_CSV_EVENT',
  ROLE_PRODUCT_FUNNEL_CREATE: 'ROLE_PRODUCT_FUNNEL_CREATE',
  ROLE_PRODUCT_FUNNEL_VIEW: 'ROLE_PRODUCT_FUNNEL_VIEW',
  ROLE_PRODUCT_FUNNEL_LIST: 'ROLE_PRODUCT_FUNNEL_LIST',
  ROLE_PRODUCT_FUNNEL_UPDATE: 'ROLE_PRODUCT_FUNNEL_UPDATE',
  ROLE_PRODUCT_FUNNEL_DELETE: 'ROLE_PRODUCT_FUNNEL_DELETE',
  ROLE_PRODUCT_FUNNEL_EXECUTE: 'ROLE_PRODUCT_FUNNEL_EXECUTE',
  ROLE_APP_UNINSTALL_OVERVIEW: 'ROLE_APP_UNINSTALL_OVERVIEW',
  ROLE_APP_UNINSTALL_ANALYSE: 'ROLE_APP_UNINSTALL_ANALYSE',
  ROLE_APP_UNINSTALL_LIST: 'ROLE_APP_UNINSTALL_LIST',
  ROLE_PRODUCT_EMAIL_TEMPLATE_CREATE: 'ROLE_PRODUCT_EMAIL_TEMPLATE_CREATE',
  ROLE_PRODUCT_EMAIL_TEMPLATE_VIEW: 'ROLE_PRODUCT_EMAIL_TEMPLATE_VIEW',
  ROLE_PRODUCT_EMAIL_TEMPLATE_LIST: 'ROLE_PRODUCT_EMAIL_TEMPLATE_LIST',
  ROLE_PRODUCT_EMAIL_TEMPLATE_UPDATE: 'ROLE_PRODUCT_EMAIL_TEMPLATE_UPDATE',
  ROLE_PRODUCT_EMAIL_TEMPLATE_DELETE: 'ROLE_PRODUCT_EMAIL_TEMPLATE_DELETE',
  ROLE_PRODUCT_ENGAGEMENT_OVERVIEW: 'ROLE_PRODUCT_ENGAGEMENT_OVERVIEW',
  ROLE_PRODUCT_ENGAGEMENT_JOURNEY: 'ROLE_PRODUCT_ENGAGEMENT_JOURNEY',
  ROLE_PRODUCT_ENGAGEMENT_RELAY: 'ROLE_PRODUCT_ENGAGEMENT_RELAY',
  ROLE_PRODUCT_ENGAGEMENT_EMAIL: 'ROLE_PRODUCT_ENGAGEMENT_EMAIL',
  ROLE_PRODUCT_ENGAGEMENT_PUSH: 'ROLE_PRODUCT_ENGAGEMENT_PUSH',
  ROLE_PRODUCT_ENGAGEMENT_WEB_PUSH: 'ROLE_PRODUCT_ENGAGEMENT_WEB_PUSH',
  ROLE_PRODUCT_ENGAGEMENT_SMS: 'ROLE_PRODUCT_ENGAGEMENT_SMS',
  ROLE_PRODUCT_ENGAGEMENT_CUSTOM: 'ROLE_PRODUCT_ENGAGEMENT_CUSTOM',
  ROLE_PRODUCT_ENGAGEMENT_IN_APP: 'ROLE_PRODUCT_ENGAGEMENT_IN_APP',
  ROLE_PRODUCT_ENGAGEMENT_ON_SITE: 'ROLE_PRODUCT_ENGAGEMENT_ON_SITE',
  ROLE_PRODUCT_ENGAGEMENT_CREATE: 'ROLE_PRODUCT_ENGAGEMENT_CREATE',
  ROLE_PRODUCT_ENGAGEMENT_VIEW: 'ROLE_PRODUCT_ENGAGEMENT_VIEW',
  ROLE_PRODUCT_ENGAGEMENT_LIST: 'ROLE_PRODUCT_ENGAGEMENT_LIST',
  ROLE_PRODUCT_ENGAGEMENT_UPDATE: 'ROLE_PRODUCT_ENGAGEMENT_UPDATE',
  ROLE_PRODUCT_ENGAGEMENT_DELETE: 'ROLE_PRODUCT_ENGAGEMENT_DELETE',
  ROLE_PRODUCT_ENGAGEMENT_LAUNCH: 'ROLE_PRODUCT_ENGAGEMENT_LAUNCH',
  ROLE_PRODUCT_ENGAGEMENT_STOP: 'ROLE_PRODUCT_ENGAGEMENT_STOP',
  ROLE_PRODUCT_ENGAGEMENT_PAUSE: 'ROLE_PRODUCT_ENGAGEMENT_PAUSE',
  ROLE_PRODUCT_ENGAGEMENT_EXPORT: 'ROLE_PRODUCT_ENGAGEMENT_EXPORT',
  ROLE_PRODUCT_SEGMENT_EXPORT: 'ROLE_PRODUCT_SEGMENT_EXPORT',
  ROLE_PRODUCT_SYSTEM_ATTRIBUTE_LIST: 'ROLE_PRODUCT_SYSTEM_ATTRIBUTE_LIST',
  ROLE_PRODUCT_SYSTEM_ATTRIBUTE_VIEW: 'ROLE_PRODUCT_SYSTEM_ATTRIBUTE_VIEW',
  ROLE_PRODUCT_SYSTEM_ATTRIBUTE_UPDATE: 'ROLE_PRODUCT_SYSTEM_ATTRIBUTE_UPDATE',
  ROLE_PRODUCT_CUSTOM_ATTRIBUTE_LIST: 'ROLE_PRODUCT_CUSTOM_ATTRIBUTE_LIST',
  ROLE_PRODUCT_CUSTOM_ATTRIBUTE_CREATE: 'ROLE_PRODUCT_CUSTOM_ATTRIBUTE_CREATE',
  ROLE_PRODUCT_CUSTOM_ATTRIBUTE_VIEW: 'ROLE_PRODUCT_CUSTOM_ATTRIBUTE_VIEW',
  ROLE_PRODUCT_CUSTOM_ATTRIBUTE_UPDATE: 'ROLE_PRODUCT_CUSTOM_ATTRIBUTE_UPDATE',
  ROLE_PRODUCT_CUSTOM_ATTRIBUTE_DELETE: 'ROLE_PRODUCT_CUSTOM_ATTRIBUTE_DELETE',
  ROLE_PRODUCT_CUSTOM_EVENT_LIST: 'ROLE_PRODUCT_CUSTOM_EVENT_LIST',
  ROLE_PRODUCT_CUSTOM_EVENT_CREATE: 'ROLE_PRODUCT_CUSTOM_EVENT_CREATE',
  ROLE_PRODUCT_CUSTOM_EVENT_UPDATE: 'ROLE_PRODUCT_CUSTOM_EVENT_UPDATE',
  ROLE_PRODUCT_CUSTOM_EVENT_VIEW: 'ROLE_PRODUCT_CUSTOM_EVENT_VIEW',
  ROLE_PRODUCT_CUSTOM_EVENT_ATTRIBUTE_DELETE:
    'ROLE_PRODUCT_CUSTOM_EVENT_ATTRIBUTE_DELETE',
  ROLE_PRODUCT_CSV_EXPORT_LOG: 'ROLE_PRODUCT_CSV_EXPORT_LOG',
  ROLE_PRODUCT_BUSINESS_EVENT_LIST: 'ROLE_PRODUCT_BUSINESS_EVENT_LIST',
  ROLE_PRODUCT_BUSINESS_EVENT_CREATE: 'ROLE_PRODUCT_BUSINESS_EVENT_CREATE',
  ROLE_PRODUCT_BUSINESS_EVENT_TRIGGER: 'ROLE_PRODUCT_BUSINESS_EVENT_TRIGGER',
  ROLE_PRODUCT_BUSINESS_EVENT_STOP_TRACKING:
    'ROLE_PRODUCT_BUSINESS_EVENT_STOP_TRACKING',
  ROLE_PRODUCT_BUSINESS_EVENT_START_TRACKING:
    'ROLE_PRODUCT_BUSINESS_EVENT_START_TRACKING',
  ROLE_PRODUCT_REVENUE_MAPPING_CREATE: 'ROLE_PRODUCT_REVENUE_MAPPING_CREATE',
  ROLE_PRODUCT_REVENUE_MAPPING_VIEW: 'ROLE_PRODUCT_REVENUE_MAPPING_VIEW',
  ROLE_PRODUCT_REVENUE_MAPPING_LIST: 'ROLE_PRODUCT_REVENUE_MAPPING_LIST',
  ROLE_PRODUCT_REVENUE_MAPPING_UPDATE: 'ROLE_PRODUCT_REVENUE_MAPPING_UPDATE',
  ROLE_PRODUCT_REVENUE_MAPPING_DELETE: 'ROLE_PRODUCT_REVENUE_MAPPING_DELETE',
  ROLE_PRODUCT_SETTINGS_VIEW: 'ROLE_PRODUCT_SETTINGS_VIEW',
  ROLE_PRODUCT_SETTINGS_UPDATE: 'ROLE_PRODUCT_SETTINGS_UPDATE',
  ROLE_PRODUCT_FREQUENCY_CAPPING_VIEW: 'ROLE_PRODUCT_FREQUENCY_CAPPING_VIEW',
  ROLE_PRODUCT_FREQUENCY_CAPPING_UPDATE:
    'ROLE_PRODUCT_FREQUENCY_CAPPING_UPDATE',
  ROLE_PRODUCT_CUSTOM_CAMPAIGN_URL_VIEW:
    'ROLE_PRODUCT_CUSTOM_CAMPAIGN_URL_VIEW',
  ROLE_PRODUCT_CUSTOM_CAMPAIGN_URL_UPDATE:
    'ROLE_PRODUCT_CUSTOM_CAMPAIGN_URL_UPDATE',
  ROLE_PRODUCT_DND_VIEW: 'ROLE_PRODUCT_DND_VIEW',
  ROLE_PRODUCT_DND_UPDATE: 'ROLE_PRODUCT_DND_UPDATE',
  ROLE_PRODUCT_CHANNEL_THROTTLING_VIEW: 'ROLE_PRODUCT_CHANNEL_THROTTLING_VIEW',
  ROLE_PRODUCT_CHANNEL_THROTTLING_UPDATE:
    'ROLE_PRODUCT_CHANNEL_THROTTLING_UPDATE',
  ROLE_PRODUCT_PRODUCT_USER_CREATE: 'ROLE_PRODUCT_PRODUCT_USER_CREATE',
  ROLE_PRODUCT_PRODUCT_USER_VIEW: 'ROLE_PRODUCT_PRODUCT_USER_VIEW',
  ROLE_PRODUCT_PRODUCT_USER_LIST: 'ROLE_PRODUCT_PRODUCT_USER_LIST',
  ROLE_PRODUCT_PRODUCT_USER_UPDATE: 'ROLE_PRODUCT_PRODUCT_USER_UPDATE',
  ROLE_PRODUCT_PRODUCT_USER_DELETE: 'ROLE_PRODUCT_PRODUCT_USER_DELETE',
  ROLE_PRODUCT_CHANNEL_PUSH_CREATE: 'ROLE_PRODUCT_CHANNEL_PUSH_CREATE',
  ROLE_PRODUCT_CHANNEL_PUSH_LIST: 'ROLE_PRODUCT_CHANNEL_PUSH_LIST',
  ROLE_PRODUCT_CHANNEL_PUSH_VIEW: 'ROLE_PRODUCT_CHANNEL_PUSH_VIEW',
  ROLE_PRODUCT_CHANNEL_PUSH_UPDATE: 'ROLE_PRODUCT_CHANNEL_PUSH_UPDATE',
  ROLE_PRODUCT_CHANNEL_PUSH_DELETE: 'ROLE_PRODUCT_CHANNEL_PUSH_DELETE',
  ROLE_PRODUCT_CHANNEL_SMS_CREATE: 'ROLE_PRODUCT_CHANNEL_SMS_CREATE',
  ROLE_PRODUCT_CHANNEL_SMS_VIEW: 'ROLE_PRODUCT_CHANNEL_SMS_VIEW',
  ROLE_PRODUCT_CHANNEL_SMS_LIST: 'ROLE_PRODUCT_CHANNEL_SMS_LIST',
  ROLE_PRODUCT_CHANNEL_SMS_UPDATE: 'ROLE_PRODUCT_CHANNEL_SMS_UPDATE',
  ROLE_PRODUCT_CHANNEL_SMS_DELETE: 'ROLE_PRODUCT_CHANNEL_SMS_DELETE',
  ROLE_PRODUCT_CHANNEL_WEB_PUSH_VIEW: 'ROLE_PRODUCT_CHANNEL_WEB_PUSH_VIEW',
  ROLE_PRODUCT_CHANNEL_WEB_PUSH_UPDATE: 'ROLE_PRODUCT_CHANNEL_WEB_PUSH_UPDATE',
  ROLE_PRODUCT_CHANNEL_EMAIL_CREATE: 'ROLE_PRODUCT_CHANNEL_EMAIL_CREATE',
  ROLE_PRODUCT_CHANNEL_EMAIL_VIEW: 'ROLE_PRODUCT_CHANNEL_EMAIL_VIEW',
  ROLE_PRODUCT_CHANNEL_EMAIL_LIST: 'ROLE_PRODUCT_CHANNEL_EMAIL_LIST',
  ROLE_PRODUCT_CHANNEL_EMAIL_UPDATE: 'ROLE_PRODUCT_CHANNEL_EMAIL_UPDATE',
  ROLE_PRODUCT_CHANNEL_EMAIL_DELETE: 'ROLE_PRODUCT_CHANNEL_EMAIL_DELETE',
  ROLE_PRODUCT_CHANNEL_WHATSAPP_CREATE: 'ROLE_PRODUCT_CHANNEL_WHATSAPP_CREATE',
  ROLE_PRODUCT_CHANNEL_WHATSAPP_VIEW: 'ROLE_PRODUCT_CHANNEL_WHATSAPP_VIEW',
  ROLE_PRODUCT_CHANNEL_WHATSAPP_LIST: 'ROLE_PRODUCT_CHANNEL_WHATSAPP_LIST',
  ROLE_PRODUCT_CHANNEL_WHATSAPP_UPDATE: 'ROLE_PRODUCT_CHANNEL_WHATSAPP_UPDATE',
  ROLE_PRODUCT_CHANNEL_WHATSAPP_DELETE: 'ROLE_PRODUCT_CHANNEL_WHATSAPP_DELETE',
  ROLE_PRODUCT_WHATSAPP_TEMPLATE_CREATE:
    '  ROLE_PRODUCT_WHATSAPP_TEMPLATE_CREATE',
  ROLE_PRODUCT_WHATSAPP_TEMPLATE_VIEW: 'ROLE_PRODUCT_WHATSAPP_TEMPLATE_VIEW',
  ROLE_PRODUCT_WHATSAPP_TEMPLATE_LIST: 'ROLE_PRODUCT_WHATSAPP_TEMPLATE_LIST',
  ROLE_PRODUCT_WHATSAPP_TEMPLATE_UPDATE:
    'ROLE_PRODUCT_WHATSAPP_TEMPLATE_UPDATE',
  ROLE_PRODUCT_WHATSAPP_TEMPLATE_DELETE:
    'ROLE_PRODUCT_WHATSAPP_TEMPLATE_DELETE',
  ROLE_PRODUCT_CHANNEL_CUSTOM_CREATE: 'ROLE_PRODUCT_CHANNEL_CUSTOM_CREATE',
  ROLE_PRODUCT_CHANNEL_CUSTOM_VIEW: 'ROLE_PRODUCT_CHANNEL_CUSTOM_VIEW',
  ROLE_PRODUCT_CHANNEL_CUSTOM_LIST: 'ROLE_PRODUCT_CHANNEL_CUSTOM_LIST',
  ROLE_PRODUCT_CHANNEL_CUSTOM_UPDATE: 'ROLE_PRODUCT_CHANNEL_CUSTOM_UPDATE',
  ROLE_PRODUCT_CHANNEL_CUSTOM_DELETE: 'ROLE_PRODUCT_CHANNEL_CUSTOM_DELETE',
  ROLE_PRODUCT_CHANNEL_REST_API_VIEW: 'ROLE_PRODUCT_CHANNEL_REST_API_VIEW',
  ROLE_PRODUCT_CHANNEL_REST_API_CREATE: 'ROLE_PRODUCT_CHANNEL_REST_API_CREATE',
  ROLE_PRODUCT_CHANNEL_REST_API_REVOKE: 'ROLE_PRODUCT_CHANNEL_REST_API_REVOKE',
  ROLE_PRODUCT_CHANNEL_ANDROID_VIEW: 'ROLE_PRODUCT_CHANNEL_ANDROID_VIEW',
  ROLE_PRODUCT_CHANNEL_ANDROID_REVOKE: 'ROLE_PRODUCT_CHANNEL_ANDROID_REVOKE',
  ROLE_PRODUCT_CHANNEL_IOS_VIEW: 'ROLE_PRODUCT_CHANNEL_IOS_VIEW',
  ROLE_PRODUCT_CHANNEL_IOS_REVOKE: 'ROLE_PRODUCT_CHANNEL_IOS_REVOKE',
  ROLE_PRODUCT_CHANNEL_WEB_VIEW: 'ROLE_PRODUCT_CHANNEL_WEB_VIEW',
  ROLE_PRODUCT_CHANNEL_WEB_REVOKE: 'ROLE_PRODUCT_CHANNEL_WEB_REVOKE',
  ROLE_PRODUCT_WEBHOOK_OVERVIEW: 'ROLE_PRODUCT_WEBHOOK_OVERVIEW',
  ROLE_PRODUCT_WEBHOOK_REVOKE_API_KEY: 'ROLE_PRODUCT_WEBHOOK_REVOKE_API_KEY',
  ROLE_PRODUCT_WEBHOOK_CREATE: 'ROLE_PRODUCT_WEBHOOK_CREATE',
  ROLE_PRODUCT_WEBHOOK_VIEW: 'ROLE_PRODUCT_WEBHOOK_VIEW',
  ROLE_PRODUCT_WEBHOOK_LIST: 'ROLE_PRODUCT_WEBHOOK_LIST',
  ROLE_PRODUCT_WEBHOOK_UPDATE: 'ROLE_PRODUCT_WEBHOOK_UPDATE',
  ROLE_PRODUCT_WEBHOOK_DELETE: 'ROLE_PRODUCT_WEBHOOK_DELETE',
  ROLE_PRODUCT_FILE_PROGRESS_LIST: 'ROLE_PRODUCT_FILE_PROGRESS_LIST',
  ROLE_PRODUCT_FILE_PROGRESS_VIEW: 'ROLE_PRODUCT_FILE_PROGRESS_VIEW',
  // TODO
  ROLE_PRODUCT_THROTTLING_VIEW: 'ROLE_PRODUCT_THROTTLING_VIEW',
  ROLE_PRODUCT_TEAM_MEMBER_LIST: 'ROLE_PRODUCT_TEAM_MEMBER_LIST',
  ROLE_PRODUCT_TEAM_MEMBER_CREATE: 'ROLE_PRODUCT_TEAM_MEMBER_CREATE',
  ROLE_PRODUCT_TEAM_MEMBER_SEND_INVITE: 'ROLE_PRODUCT_TEAM_MEMBER_SEND_INVITE',
  ROLE_PRODUCT_TEAM_MEMBER_VIEW: 'ROLE_PRODUCT_TEAM_MEMBER_VIEW',
  ROLE_PRODUCT_TEAM_MEMBER_UPDATE: 'ROLE_PRODUCT_TEAM_MEMBER_UPDATE',
  ROLE_PRODUCT_TEAM_MEMBER_DELETE: 'ROLE_PRODUCT_TEAM_MEMBER_DELETE',
  ROLE_MONITORING_SLA_GET_METRICS: 'ROLE_MONITORING_SLA_GET_METRICS',
  ROLE_FEATURE_HIERARCHY_VIEW: 'ROLE_FEATURE_HIERARCHY_VIEW',
  ROLE_CONTRACT_TEMPLATE_CREATE: 'ROLE_CONTRACT_TEMPLATE_CREATE',
  ROLE_CONTRACT_TEMPLATE_VIEW: 'ROLE_CONTRACT_TEMPLATE_VIEW',
  ROLE_CONTRACT_TEMPLATE_LIST: 'ROLE_CONTRACT_TEMPLATE_LIST',
  ROLE_CONTRACT_TEMPLATE_UPDATE: 'ROLE_CONTRACT_TEMPLATE_UPDATE',
  ROLE_CONTRACT_TEMPLATE_DELETE: 'ROLE_CONTRACT_TEMPLATE_DELETE',
  ROLE_CONTRACT_TEMPLATE_PUBLISH: 'ROLE_CONTRACT_TEMPLATE_PUBLISH',
  ROLE_CONTRACT_CREATE: 'ROLE_CONTRACT_CREATE',
  ROLE_CONTRACT_VIEW: 'ROLE_CONTRACT_VIEW',
  ROLE_CONTRACT_LIST: 'ROLE_CONTRACT_LIST',
  ROLE_CONTRACT_UPDATE: 'ROLE_CONTRACT_UPDATE',
  ROLE_CONTRACT_DELETE: 'ROLE_CONTRACT_DELETE',
  ROLE_PRODUCT_ENGAGEMENT_WHATSAPP: 'ROLE_PRODUCT_ENGAGEMENT_WHATSAPP',
  ROLE_INVOICE_VIEW: 'ROLE_INVOICE_VIEW',
  ROLE_INVOICE_LIST: 'ROLE_INVOICE_LIST',
  ROLE_ADMIN_INVOICE_LIST: 'ROLE_ADMIN_INVOICE_LIST',
  ROLE_INVOICE_UPDATE: 'ROLE_INVOICE_UPDATE',
  ROLE_INVOICE_REISSUE: 'ROLE_INVOICE_REISSUE',
  ROLE_INVOICE_SET_PAID: 'ROLE_INVOICE_SET_PAID',
  ROLE_INVOICE_MANUAL_CREATE: 'ROLE_INVOICE_MANUAL_CREATE',
  ROLE_INVOICE_MANUAL_ISSUE: 'ROLE_INVOICE_MANUAL_ISSUE',
  ROLE_MANAGEMENT_DASHBOARD_ALL: 'ROLE_MANAGEMENT_DASHBOARD_ALL',
  ROLE_MANAGEMENT_DASHBOARD_USER: 'ROLE_MANAGEMENT_DASHBOARD_USER',
  ROLE_MANAGEMENT_DASHBOARD_EVENT: 'ROLE_MANAGEMENT_DASHBOARD_EVENT',
  ROLE_MANAGEMENT_DASHBOARD_COMMUNICATION:
    'ROLE_MANAGEMENT_DASHBOARD_COMMUNICATION',
  ROLE_MANAGEMENT_DASHBOARD_REVENUE: 'ROLE_MANAGEMENT_DASHBOARD_REVENUE',
  ROLE_MANAGEMENT_DASHBOARD_CHANNEL: 'ROLE_MANAGEMENT_DASHBOARD_CHANNEL',
  ROLE_MANAGEMENT_DASHBOARD_INVOICE: 'ROLE_MANAGEMENT_DASHBOARD_INVOICE',
  ROLE_MANAGEMENT_DASHBOARD_PROJECT: 'ROLE_MANAGEMENT_DASHBOARD_PROJECT',
  ROLE_COHORT_CREATE: 'ROLE_COHORT_CREATE',
  ROLE_COHORT_VIEW: 'ROLE_COHORT_VIEW',
  ROLE_COHORT_LIST: 'ROLE_COHORT_LIST',
  ROLE_COHORT_UPDATE: 'ROLE_COHORT_UPDATE',
  ROLE_COHORT_DELETE: 'ROLE_COHORT_DELETE',
  ROLE_COHORT_EXECUTE: 'ROLE_COHORT_EXECUTE',
  // third-party feature
  ROLE_THIRD_PARTY_PRODUCT_LIST: 'ROLE_THIRD_PARTY_PRODUCT_LIST',
  ROLE_THIRD_PARTY_PRODUCT_UPDATE: 'ROLE_THIRD_PARTY_PRODUCT_UPDATE',
  ROLE_THIRD_PARTY_PRODUCT_UPDATE_ENABLED:
    'ROLE_THIRD_PARTY_PRODUCT_UPDATE_ENABLED',
  ROLE_AUDIT_VIEW: 'ROLE_AUDIT_VIEW',
  ROLE_ALERT_CREATE: 'ROLE_ALERT_CREATE',
  ROLE_ALERT_VIEW: 'ROLE_ALERT_VIEW',
  ROLE_ALERT_LIST: 'ROLE_ALERT_LIST',
  ROLE_ALERT_UPDATE: 'ROLE_ALERT_UPDATE',
  ROLE_ALERT_DELETE: 'ROLE_ALERT_DELETE',
  ROLE_FONT_LIST: 'ROLE_FONT_LIST',
  ROLE_FONT_UPLOAD: 'ROLE_FONT_UPLOAD',
};

export function hasAnyAuthority(...authorities) {
  const currentUser = store.getState().account.auth.currentUser;
  const pathname = store.getState().router.location.pathname;
  const pid = getProductId(pathname);
  if (!currentUser || !currentUser.roles) {
    return false;
  }
  let result = checkAuthorityProduct(currentUser, null, authorities);
  if (pid) {
    result = result || checkAuthorityProduct(currentUser, pid, authorities);
  } else if (currentUser.lastProduct) {
    result =
      result ||
      checkAuthorityProduct(currentUser, currentUser.lastProduct, authorities);
  }
  return result;
}

// eslint-disable-next-line no-unused-vars
export function hasAnyAuthorityDummyData(...authorities) {
  return true;
}

export function hasAnyRootAuthority(...authorities) {
  const currentUser = store.getState().account.auth.currentUser;
  if (!currentUser || !currentUser.roles) {
    return false;
  }
  return checkAuthorityProduct(currentUser, null, authorities);
}

function checkAuthorityProduct(currentUser, pid, authorities) {
  let role = currentUser.roles.find((x) =>
    pid ? x.productId === pid : !x.productId
  );
  if (!role || !role.permissions) {
    return false;
  }

  return authorities
    .map((x) => role.permissions.indexOf(x) >= 0)
    .reduce((a, b) => a || b);
}

export function getProductId(pathname) {
  if (pathname) {
    if (pathname.startsWith('/')) pathname = pathname.slice(1);

    const split = pathname.split('/');

    let pid = null;

    if (
      pathname.includes('/adminProduct/update/') ||
      pathname.includes('back-office/invoice-list/product')
    ) {
      pid = parseInt(split[3]);
      return isNaN(pid) ? null : pid;
    }

    if (split.length > 2) pid = parseInt(split[1]);

    if (!isNil(pid) && !isNaN(pid)) {
      return pid;
    }
  }

  if (!isNil(store.getState().account.auth.activeProduct)) {
    return store.getState().account.auth.activeProduct;
  }

  const currentUser = store.getState().account.auth.currentUser;

  if (currentUser && currentUser?.login) {
    return currentUser?.lastProduct;
  }

  return null;
}

export function getActiveProductDetails(pathname) {
  const pid = getProductId(pathname);
  const currentUser = store.getState().account.auth.currentUser;
  return currentUser?.products?.find((p) => p.id == pid);
}

export function getChannel(pathname) {
  let split = pathname.split('/');
  let result = null;
  let channel = null;
  if (split.length > 3) {
    channel = split[3];
  }

  if (channel === 'push-notifications') {
    result = 'PUSH';
  } else if (channel === 'sms-messages') {
    result = 'SMS';
  } else if (channel === 'emails') {
    result = 'EMAIL';
  } else if (channel === 'web-notifications') {
    result = 'WEB_PUSH';
  } else if (channel === 'custom-channel') {
    result = 'CUSTOM';
  } else if (channel === 'in-app') {
    result = 'IN_APP';
  } else if (channel === 'onSiteChannel') {
    result = 'ON_SITE';
  } else if (channel === 'whatsapp') {
    result = 'WHATSAPP';
  }
  return result;
}

export function getCommunicationId(pathname) {
  let split = pathname.split('/');
  let pid = null;
  if (split.length > 5) {
    pid = parseInt(split[5]);
  }
  if (!pid) {
    return null;
  }
  return pid;
}

export function getJourneyId(pathname) {
  let split = pathname.split('/');
  let pid = null;
  if (split.length > 4) {
    pid = parseInt(split[4]);
  }
  if (!pid) {
    return null;
  }
  return pid;
}

export function getUserId(pathname) {
  let split = pathname.split('/');
  let pid = null;
  if (split.length > 5) {
    pid = String(split[5]);
  }
  if (!pid) {
    return null;
  }
  return pid;
}

// /product/7/journey/750/overview
export function getUserId_(pathname) {
  let split = pathname.split('/');
  let pid = null;
  if (split.length > 2) {
    pid = String(split[2]);
  }
  if (!pid) {
    return null;
  }
  return pid;
}

export function getSegmentId(pathname) {
  let split = pathname.split('/');
  let pid = null;
  if (split.length > 5) {
    pid = String(split[5]);
  }
  if (!pid) {
    return null;
  }
  return pid;
}

export function getCommunicationStep(pathname) {
  let result = null;
  if (pathname.toLowerCase().includes('/audience')) {
    result = 'audience';
  } else if (pathname.toLowerCase().includes('/when')) {
    result = 'when';
  } else if (pathname.toLowerCase().includes('/message')) {
    result = 'message';
  } else if (pathname.toLowerCase().includes('/conversion')) {
    result = 'conversion';
  } else if (pathname.toLowerCase().includes('/preview')) {
    result = 'preview';
  } else if (pathname.toLowerCase().includes('/test')) {
    result = 'test';
  }
  return result;
}

export function getSegmentType(pathname) {
  let result = null;
  if (pathname) {
    if (pathname.toLowerCase().includes('/static/')) {
      result = 'STATIC';
    } else if (pathname.toLowerCase().includes('/live/')) {
      result = 'DYNAMIC';
    }
  }
  return result;
}

export function renderCampaignType(type) {
  if (type === 'ONE_TIME') {
    return 'One-Time';
  } else if (type === 'TRIGGERED') {
    return 'Triggered';
  } else if (type === 'RECURRING') {
    return 'Recurring';
  } else if (type === 'TRANSACTIONAL') {
    return 'Transactional';
  } else if (type === 'JOURNEY') {
    return 'Journey';
  } else if (type === 'RELAY') {
    return 'Relay';
  }
  return '';
}
//Track
export function authorizedUsersOverviewTab() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_USER_OVERVIEW);
}

export function authorizedUsersAnalyzeTab() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_USER_ANALYSE);
}

export function authorizedUsersListTab() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_USER_LIST);
}

export function authorizedSideMenuUsersOption() {
  return (
    authorizedUsersOverviewTab() ||
    authorizedUsersAnalyzeTab() ||
    authorizedUsersListTab()
  );
}

export function authorizedSideMenuEventsOption() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_EVENTS_OVERVIEW);
}

export function authorizedSideMenuTrackSection() {
  return authorizedSideMenuUsersOption() || authorizedSideMenuEventsOption();
}

//Analytics
export function authorizedSideMenuFunnels() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_FUNNEL_LIST);
}

export function authorizedSideMenuCohorts() {
  return hasAnyAuthority(AuthorityProvider.ROLE_COHORT_LIST);
}

export function authorizedSideMenuUninstalls() {
  // ToDo Tbs fn:
  // ROLE_APP_UNINSTALL_OVERVIEW
  // ROLE_APP_UNINSTALL_ANALYSE
  // ROLE_APP_UNINSTALL_LIST
  return hasAnyAuthority(AuthorityProvider.ROLE_APP_UNINSTALL_OVERVIEW);
}

export function authorizedSideMenuLive() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_LIVE_OVERVIEW);
}

export function authorizedSideMenuAnalyticsSection() {
  return (
    authorizedSideMenuFunnels() ||
    authorizedSideMenuCohorts() ||
    authorizedSideMenuUninstalls() ||
    authorizedSideMenuLive()
  );
}

//Engage
export function authorizedSideMenuEngageSection() {
  return (
    authorizedSideMenuEngageOverview() ||
    authorizedSideMenuEngagePush() ||
    authorizedSideMenuEngageSms() ||
    authorizedSideMenuEngageWebPush() ||
    authorizedSideMenuEngageEmail() ||
    authorizedSideMenuEngageCustomChannel() ||
    authorizedSideMenuEngageWhatsapp()
  );
}

export function authorizedSideMenuEngageOverview() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_VIEW);
}

export function authorizedSideMenuEngageWhatsapp() {
  return (
    hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_WHATSAPP) &&
    hasAnyAuthority(
      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_LIST,
      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_VIEW
    )
  );
}
export function authorizedSideMenuEngageOnSite() {
  return (
    hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_ON_SITE) &&
    hasAnyAuthority(
      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_LIST,
      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_VIEW
    )
  );
}
export function authorizedSideMenuEngageinApp() {
  return (
    hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_IN_APP) &&
    hasAnyAuthority(
      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_LIST,
      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_VIEW
    )
  );
}

export function authorizedSideMenuEngageCustomChannel() {
  return (
    hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_CUSTOM) &&
    hasAnyAuthority(
      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_LIST,
      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_VIEW
    )
  );
}
export function authorizedSideMenuEngageEmail() {
  return (
    hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_EMAIL) &&
    hasAnyAuthority(
      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_LIST,
      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_VIEW
    )
  );
}
export function authorizedSideMenuEngageWebPush() {
  return (
    hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_WEB_PUSH) &&
    hasAnyAuthority(
      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_LIST,
      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_VIEW
    )
  );
}
export function authorizedSideMenuEngageSms() {
  return (
    hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_SMS) &&
    hasAnyAuthority(
      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_LIST,
      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_VIEW
    )
  );
}
export function authorizedSideMenuEngagePush() {
  return (
    hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_PUSH) &&
    hasAnyAuthority(
      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_LIST,
      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_VIEW
    )
  );
}

export function authorizedSideMenuEngageRelays() {
  return (
    hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_RELAY) &&
    hasAnyAuthority(
      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_LIST,
      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_VIEW
    )
  );
}

export function authorizedSideMenuEngageJourneys() {
  return (
    hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_JOURNEY) &&
    hasAnyAuthority(
      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_LIST,
      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_VIEW
    )
  );
}

// Segment
export function authorizedSideMenuSegmentSection() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_SEGMENT_LIST);
}

//Settings
export function authorizedSideMenuSettingGeneral() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_SETTINGS_VIEW);
}
export function authorizedSideMenuSettingFc() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_FREQUENCY_CAPPING_VIEW);
}
export function authorizedSideMenuSettingDnd() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_DND_VIEW);
}
export function authorizedSideMenuSettingCustomDomain() {
  return hasAnyAuthority(
    AuthorityProvider.ROLE_PRODUCT_CUSTOM_CAMPAIGN_URL_VIEW
  );
}
export function authorizedSideMenuSetting() {
  return (
    authorizedSideMenuSettingGeneral() ||
    authorizedSideMenuSettingFc() ||
    authorizedSideMenuSettingDnd() ||
    authorizedSideMenuSettingCustomDomain()
  );
}
export function authorizedSideMenuSettingPush() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CHANNEL_PUSH_LIST);
}
export function authorizedSideMenuSettingSms() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CHANNEL_SMS_LIST);
}
export function authorizedSideMenuSettingEmail() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CHANNEL_EMAIL_LIST);
}
export function authorizedSideMenuSettingTemplate() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_EMAIL_TEMPLATE_LIST);
}
export function authorizedSideMenuSettingChannelCustom() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CHANNEL_CUSTOM_LIST);
}
export function authorizedSideMenuSettingWhatsApp() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CHANNEL_WHATSAPP_LIST);
}
export function authorizedSideMenuChannels() {
  return (
    authorizedSideMenuSettingPush() ||
    authorizedSideMenuSettingSms() ||
    authorizedSideMenuSettingEmail() ||
    authorizedSideMenuSettingTemplate() ||
    authorizedSideMenuSettingChannelCustom() ||
    authorizedSideMenuSettingWhatsApp()
  );
}
export function authorizedSideMenuSettingSdk() {
  return hasAnyAuthority(
    AuthorityProvider.ROLE_PRODUCT_CHANNEL_ANDROID_VIEW,
    AuthorityProvider.ROLE_PRODUCT_CHANNEL_IOS_VIEW,
    AuthorityProvider.ROLE_PRODUCT_CHANNEL_WEB_VIEW
  );
}
export function authorizedSideMenuSettingWebhooks() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_WEBHOOK_LIST);
}
export function authorizedSideMenuSettingAuditLog() {
  return hasAnyAuthority(AuthorityProvider.ROLE_AUDIT_VIEW);
}
export function authorizedSideMenuSettingRestApiKey() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CHANNEL_REST_API_VIEW);
}
export function authorizedSideMenuSettingRestApiToken() {
  return hasAnyAuthority(
    AuthorityProvider.ROLE_PRODUCT_CHANNEL_REST_API_CREATE
  );
}
export function authorizedSideMenuSettingTeamMember() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_TEAM_MEMBER_LIST);
}

export function authorizedSideMenuBilling() {
  return hasAnyAuthority(AuthorityProvider.ROLE_MONITORING_SLA_GET_METRICS);
}

export function authorizedSideMenuSettingMenu() {
  return (
    authorizedSideMenuSetting() ||
    authorizedSideMenuChannels() ||
    authorizedSideMenuSettingSdk() ||
    authorizedSideMenuSettingWebhooks() ||
    authorizedSideMenuSettingAuditLog() ||
    authorizedSideMenuSettingRestApiKey() ||
    authorizedSideMenuSettingTeamMember() ||
    authorizedSideMenuBilling()
  );
}

// Data Platform

export function authorizedSideMenuDataPlatformSection() {
  return hasAnyAuthority(
    AuthorityProvider.ROLE_PRODUCT_UPLOAD_CSV_USER,
    AuthorityProvider.ROLE_PRODUCT_UPLOAD_CSV_EVENT,
    AuthorityProvider.ROLE_PRODUCT_SETTINGS_VIEW,
    AuthorityProvider.ROLE_PRODUCT_CUSTOM_ATTRIBUTE_LIST,
    AuthorityProvider.ROLE_PRODUCT_CUSTOM_EVENT_LIST,
    AuthorityProvider.ROLE_PRODUCT_REVENUE_MAPPING_VIEW,
    AuthorityProvider.ROLE_PRODUCT_BUSINESS_EVENT_LIST
  );
}
export function authorizedSideMenuDataPlatformSystemAttributes() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_SETTINGS_VIEW);
}
export function authorizedSideMenuDataPlatformUserAttributes() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CUSTOM_ATTRIBUTE_LIST);
}
export function authorizedSideMenuDataPlatformEventsAttributes() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CUSTOM_EVENT_LIST);
}
export function authorizedSideMenuDataPlatformEventsRevenue() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_REVENUE_MAPPING_VIEW);
}
export function authorizedSideMenuDataPlatformEventBusiness() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_BUSINESS_EVENT_LIST);
}
export function authorizedSideMenuDataPlatformData() {
  return (
    authorizedSideMenuDataPlatformSystemAttributes() ||
    authorizedSideMenuDataPlatformUserAttributes() ||
    authorizedSideMenuDataPlatformEventsAttributes() ||
    authorizedSideMenuDataPlatformEventsRevenue() ||
    authorizedSideMenuDataPlatformEventBusiness()
  );
}
export function authorizedSideMenuDataPlatformUsersUpload() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_UPLOAD_CSV_USER);
}
export function authorizedSideMenuDataPlatformEventUpload() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_UPLOAD_CSV_EVENT);
}
export function authorizedSideMenuDataPlatformUpload() {
  return (
    authorizedSideMenuDataPlatformUsersUpload() ||
    authorizedSideMenuDataPlatformEventUpload()
  );
}
export function authorizedSideMenuDataPlatformHelp() {
  return hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_DOCUMENTATION_VIEW);
}

export function authorizedSideMenuAlert() {
  return hasAnyAuthority(AuthorityProvider.ROLE_ALERT_VIEW);
}

// Adimin

export function authorizedSideMenuAdminSection() {
  return (
    authorizedSideMenuAdminRoleList() ||
    authorizedSideMenuAdminUserList() ||
    authorizedSideMenuAdminReleaseNote() ||
    authorizedSideMenuAdminManageProducts() ||
    authorizedSideMenuAdminFileStorage() ||
    authorizedSideMenuAdminConfiguration() ||
    authorizedSideMenuContractTemplate() ||
    authorizedSideMenuManagementDashboard()
  );
}
export function authorizedSideMenuAdminRoleList() {
  return hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_ROLE_LIST);
}
export function authorizedSideMenuAdminUserList() {
  return hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_USER_LIST);
}
export function authorizedSideMenuAdminReleaseNote() {
  return hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_PRODUCT_RELEASE_NOTES);
}
export function authorizedSideMenuAdminManageProducts() {
  return hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_PRODUCT_LIST);
}
export function authorizedSideMenuThirdParty() {
  return hasAnyAuthority(AuthorityProvider.ROLE_THIRD_PARTY_PRODUCT_LIST);
}
export function authorizedSideMenuContractTemplate() {
  return hasAnyAuthority(AuthorityProvider.ROLE_CONTRACT_TEMPLATE_LIST);
}
export function authorizedSideMenuAdminFileStorage() {
  return hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_FILE_STORAGE_LIST);
}
export function authorizedSideMenuAdminConfiguration() {
  return hasAnyRootAuthority(
    AuthorityProvider.ROLE_ADMIN_CONFIGURATION_UPDATE,
    AuthorityProvider.ROLE_ADMIN_CONFIGURATION_VIEW
  );
}

export function authorizedSideMenuFinancialSection() {
  return hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_INVOICE_LIST);
}

export function authorizedSideMenuManagementDashboard() {
  return (
    hasAnyAuthority(AuthorityProvider.ROLE_MANAGEMENT_DASHBOARD_ALL) &&
    hasAnyAuthority(AuthorityProvider.ROLE_MANAGEMENT_DASHBOARD_USER) &&
    hasAnyAuthority(AuthorityProvider.ROLE_MANAGEMENT_DASHBOARD_EVENT) &&
    hasAnyAuthority(
      AuthorityProvider.ROLE_MANAGEMENT_DASHBOARD_COMMUNICATION
    ) &&
    hasAnyAuthority(AuthorityProvider.ROLE_MANAGEMENT_DASHBOARD_REVENUE) &&
    hasAnyAuthority(AuthorityProvider.ROLE_MANAGEMENT_DASHBOARD_CHANNEL) &&
    hasAnyAuthority(AuthorityProvider.ROLE_MANAGEMENT_DASHBOARD_INVOICE) &&
    hasAnyAuthority(AuthorityProvider.ROLE_MANAGEMENT_DASHBOARD_PROJECT)
  );
}
export function authorizedForLaunchingCampaigns() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_LAUNCH);
}

export function authorizedForDelayedDeliveryCheck() {
  return hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_LAUNCH);
}

export function authorizedForInstantDeliveryCheck() {
  return hasAnyRootAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_LAUNCH);
}

export function authorizedSideMenuAdminCreateProduct() {
  return hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_PRODUCT_CREATE);
}

export function dataAndInsight() {
  return (
    (authorizedSideMenuUsersOption() &&
      featureAccessCheckSideMenuUsersOption()) ||
    (authorizedSideMenuAnalyticsSection() &&
      featureAccessCheckSideMenuAnalyticsSection())
  );
}
export function campaignManager() {
  return (
    (authorizedSideMenuEngageOverview() &&
      featureAccessCheckSideMenuEngageOverview()) ||
    (authorizedSideMenuEngageSection() &&
      featureAccessCheckSideMenuEngageSection()) ||
    (authorizedSideMenuEngageJourneys() &&
      featureAccessCheckSideMenuEngageJourneys()) ||
    (authorizedSideMenuEngageRelays() &&
      featureAccessCheckSideMenuEngageRelays())
  );
}
export function personalization() {
  return (
    (authorizedSideMenuEngageOnSite() &&
      featureAccessCheckSideMenuEngageOnSite()) ||
    (authorizedSideMenuEngageinApp() && featureAccessCheckSideMenuEngageInApp())
  );
}
export function settings() {
  return (
    (authorizedSideMenuChannels() && featureAccessCheckSideMenuChannel()) ||
    (authorizedSideMenuSettingSdk() && featureAccessCheckSideMenuSDK()) ||
    (authorizedSideMenuSettingWebhooks() &&
      featureAccessCheckSideMenuWebhook()) ||
    (authorizedSideMenuSettingAuditLog() &&
      featureAccessCheckSideMenuAuditLog()) ||
    (authorizedSideMenuSettingRestApiKey() &&
      featureAccessCheckSideMenuRestApiKey()) ||
    (authorizedSideMenuSettingRestApiToken() &&
      featureAccessCheckSideMenuRestApiToken()) ||
    (authorizedSideMenuSettingTeamMember() &&
      featureAccessCheckSideMenuTeam()) ||
    authorizedSideMenuBilling()
  );
}
export function backOffice() {
  return (
    authorizedSideMenuFinancialSection() ||
    authorizedSideMenuAdminRoleList() ||
    authorizedSideMenuAdminUserList() ||
    authorizedSideMenuAdminReleaseNote() ||
    authorizedSideMenuAdminManageProducts() ||
    authorizedSideMenuAdminCreateProduct() ||
    authorizedSideMenuContractTemplate() ||
    authorizedSideMenuAdminFileStorage() ||
    authorizedSideMenuAdminConfiguration() ||
    authorizedSideMenuManagementDashboard()
  );
}
