import React, { useState, useEffect, useMemo } from 'react';
import { Form, Select } from 'antd';
import { find as lodashFind } from 'lodash';
import Card from '@Utils/Card';

const styles = {
  editContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
  },
  editFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 6,
    fontSize: 13,
    lineHeight: 1.43,
    color: '#3d404e',
  },

  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    textTransform: 'uppercase',
    flex: 1,
    textAlign: 'right',
    fontWeight: 'bold',
  },
  headTitleContainer: {
    fontSize: 18,
    fontWeight: '500',
    color: '#3d404e',
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    borderBottom: '1px solid',
  },
  dataRowContainer: {
    padding: 30,
  },
};

const TIME_SELECTOR_MAPPER = {
  MINUTE: 1,
  HOUR: 60,
  DAY: 60 * 24,
  WEEK: 60 * 24 * 7,
};

export default function Ttl({ onChangeTtl, errors, ttlValue }) {
  const [displayOptions, setDisplayOptions] = useState(false);
  const [ttlTimeSelector, handleChangeTtlTimeSelector] = useState('MINUTE');

  const errorMessage = useMemo(() => {
    if (Array.isArray(errors)) {
      const foundItem = lodashFind(errors, (item) => item.key === '');

      if (foundItem) {
        return <span>{foundItem.message}</span>;
      }
    }

    return null;
  }, [errors]);

  useEffect(() => {
    if (ttlValue && !displayOptions) {
      const vals = convertTtl(ttlValue);
      handleChangeTtlTimeSelector(vals?.ttlTimeSelector);

      setDisplayOptions(true);
    }
  }, [ttlValue]);

  const convertTtl = (ttlMinutes) => {
    if (ttlMinutes) {
      if (ttlMinutes >= 60 * 24 && ttlMinutes % (60 * 24) === 0) {
        return {
          ttl: Math.round(ttlMinutes / (60 * 24)),
          ttlTimeSelector: 'DAY',
        };
      } else if (ttlMinutes >= 60 && ttlMinutes % 60 === 0) {
        return {
          ttl: Math.round(ttlMinutes / 60),
          ttlTimeSelector: 'HOUR',
        };
      } else {
        return {
          ttl: ttlMinutes,
          ttlTimeSelector: 'MINUTE',
        };
      }
    }
  };

  const TtlDisplayValue =
    (ttlValue || 0) / TIME_SELECTOR_MAPPER[ttlTimeSelector];

  return (
    <div
      style={{
        marginTop: 20,
        paddingBottom: 50,
        backgroundColor: 'white',
        borderRadius: 5,
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
      }}
    >
      <div
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          marginTop: 40,
          backgroundColor: 'white',
        }}
      >
        <Card
          styles={{
            padding: 16,
            borderBottom: 'solid 2px #f4f9ff',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          <div className="d-flex text-title-md">
            <span style={{ fontWeight: 'bold' }}>TTL</span>
          </div>
        </Card>

        <div>
          <Form layout="vertical">
            <div
              style={{
                ...styles.editContainer,
                marginTop: 20,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...styles.editLabel,
                  position: 'relative',
                  top: 9,
                }}
              >
                <label style={{ fontSize: '12px' }}>Time To Live</label>
              </div>
              <div style={{ ...styles.editFieldContainer, marginLeft: 20 }}>
                <Form.Item className="flex-align-center">
                  <input
                    value={TtlDisplayValue || ''}
                    onChange={(e) => {
                      if (!e.target.value) {
                        onChangeTtl(null);
                        return;
                      }
                      const value = parseInt(e.target.value);
                      if (!isNaN(value)) {
                        onChangeTtl(
                          (value || 0) * TIME_SELECTOR_MAPPER[ttlTimeSelector]
                        );
                      }
                    }}
                    style={{
                      marginRight: 20,
                    }}
                    step={1}
                    className="input-number"
                  />
                  <Select
                    id="int-communication-when-queue-selector"
                    style={{
                      width: 130,
                      marginRight: 20,
                      top: 2,
                    }}
                    value={ttlTimeSelector}
                    onChange={(e) => {
                      onChangeTtl(TtlDisplayValue * TIME_SELECTOR_MAPPER[e]);
                      handleChangeTtlTimeSelector(e);
                    }}
                  >
                    <Select.Option value={'MINUTE'}>
                      {ttlValue === 1 ? 'Minute' : 'Minutes'}
                    </Select.Option>
                    <Select.Option value={'HOUR'}>
                      {ttlValue === 1 ? 'Hour' : 'Hours'}
                    </Select.Option>
                    <Select.Option value={'DAY'}>
                      {ttlValue === 1 ? 'Day' : 'Days'}
                    </Select.Option>
                  </Select>
                </Form.Item>
                {errorMessage ? (
                  <p
                    className="text-body-sm"
                    style={{ width: 500, color: 'red' }}
                  >
                    {errorMessage}
                  </p>
                ) : (
                  <p className="text-body-sm" style={{ width: 500 }}>
                    Time To Live: The duration for which the message remains
                    valid and will be attempted to be delivered to the user’s
                    device. (Acceptable range=30 minutes-28 days).
                  </p>
                )}
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
