import React, { useEffect } from 'react';
import {
  Form,
  Input,
  Col,
  Row,
  Button,
  Divider,
  Switch,
  Select,
  InputNumber,
  DatePicker,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import NumberBetweenInput from './NumberBetweenInput';
import vars from '@Theme/styles/vars';
import { OPERATORS } from './helpers';
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function ScreenDataMapper({ form }) {
  useEffect(() => {
    form.validateFields();
  }, []);
  return (
    <div className="relay-mapper-area" style={{ marginLeft: 0 }}>
      <Form.Item name="screenDataMapperConjunction" style={{ display: 'none' }}>
        <Input defaultValue={'AND'} />
      </Form.Item>
      <Form.List name="screenDataMapper">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, idx) => (
              <>
                <Row key={field.key} gutter={16}>
                  <Col span="6">
                    <Form.Item
                      {...field}
                      name={[field.name, 'term']}
                      rules={[
                        { required: true, message: 'enter attribute name' },
                      ]}
                    >
                      <Input placeholder="Page Data Attribute" />
                    </Form.Item>
                  </Col>
                  <Form.Item
                    {...field}
                    name={[field.name, 'dataType']}
                    rules={[{ required: true, message: 'select type' }]}
                  >
                    <Select
                      defaultValue="STRING"
                      onChange={() => {
                        const formValues = form.getFieldsValue();
                        formValues.screenDataMapper[idx].value = null;
                        formValues.screenDataMapper[idx].operator = 'EQUAL_TO';
                        form.setFieldsValue(formValues);
                      }}
                    >
                      <Option value={'STRING'}></Option>
                      <Option value={'NUMERIC'}></Option>
                      <Option value={'DATE'}></Option>
                      <Option value={'BOOLEAN'}></Option>
                    </Select>
                  </Form.Item>
                  <Col span="4">
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) => {
                        return (
                          curValues?.screenDataMapper?.length > idx &&
                          curValues?.screenDataMapper[idx] &&
                          prevValues?.screenDataMapper?.length > idx &&
                          prevValues?.screenDataMapper[idx] &&
                          curValues?.screenDataMapper[idx]?.negate !==
                            prevValues?.screenDataMapper[idx]?.negate
                        );
                      }}
                    >
                      {() => {
                        const formValues = form.getFieldsValue();
                        const checked =
                          formValues.screenDataMapper &&
                          formValues.screenDataMapper.length &&
                          formValues.screenDataMapper[idx]?.negate;
                        const dataType =
                          (formValues.screenDataMapper &&
                            formValues?.screenDataMapper.length &&
                            formValues?.screenDataMapper[idx]?.dataType) ||
                          'STRING';
                        return (
                          <Form.Item
                            {...field}
                            name={[field.name, 'operator']}
                            rules={[
                              { required: true, message: 'select operator' },
                            ]}
                            style={{ width: '100%' }}
                          >
                            <Select
                              style={{ width: '100%' }}
                              onChange={(value) => {
                                const formValues = form.getFieldsValue();
                                if (value === 'IS_NOT_EMPTY') {
                                  formValues.screenDataMapper[idx].value = '-';
                                } else {
                                  formValues.screenDataMapper[idx].value =
                                    value === 'ONE_OF' ? [] : null;
                                }
                                form.setFieldsValue(formValues);
                              }}
                              dropdownRender={(menu) => (
                                <div>
                                  {menu}
                                  <Divider style={{ margin: '4px 0' }} />
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginRight: 10,
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: '#1c1c1c',
                                      }}
                                    >
                                      Negate:
                                    </div>
                                    <div>
                                      <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onChange={(value) => {
                                          const formValues =
                                            form.getFieldsValue();

                                          if (
                                            formValues.screenDataMapper[idx]
                                          ) {
                                            formValues.screenDataMapper[
                                              idx
                                            ].negate = value;
                                          } else {
                                            formValues.screenDataMapper[idx] = {
                                              negate: value,
                                            };
                                          }

                                          form.setFieldsValue(formValues);
                                          form.submit();
                                        }}
                                        checked={checked}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            >
                              {OPERATORS.filter((op) => {
                                return op.dataTypes.includes(dataType);
                              }).map((op) => (
                                <Option value={op.name} key={op.name}>
                                  {checked ? op.negateLabel : op.label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                  <Col span="8">
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) => {
                        return (
                          curValues?.screenDataMapper?.length > idx &&
                          curValues?.screenDataMapper[idx] &&
                          prevValues?.screenDataMapper?.length > idx &&
                          prevValues?.screenDataMapper[idx] &&
                          curValues?.screenDataMapper[idx]?.operator !==
                            prevValues?.screenDataMapper[idx]?.operator
                        );
                      }}
                    >
                      {() => {
                        const formValues = form.getFieldsValue();
                        const operator =
                          formValues.screenDataMapper &&
                          formValues.screenDataMapper.length &&
                          formValues.screenDataMapper[idx]?.operator;
                        const dataType =
                          (formValues.screenDataMapper &&
                            formValues.screenDataMapper.length &&
                            formValues.screenDataMapper[idx]?.dataType) ||
                          'STRING';
                        return (
                          <Form.Item
                            {...field}
                            name={[field.name, 'value']}
                            style={{
                              display:
                                operator === 'IS_NOT_EMPTY' ? 'none' : 'block',
                            }}
                            rules={[
                              {
                                required: true,
                                message: 'please enter a value',
                              },
                            ]}
                          >
                            {operator === 'ONE_OF' ? (
                              <Select
                                mode="tags"
                                style={{
                                  width: '100%',
                                }}
                              />
                            ) : operator === 'BETWEEN' ? (
                              dataType === 'NUMERIC' ? (
                                <NumberBetweenInput />
                              ) : (
                                <RangePicker />
                              )
                            ) : dataType === 'NUMERIC' ? (
                              <InputNumber style={{ width: '100%' }} />
                            ) : dataType === 'DATE' &&
                              operator !== 'IS_NOT_EMPTY' ? (
                              <DatePicker />
                            ) : (
                              <Input style={{ width: '100%' }} />
                            )}
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                  {fields.length > 1 ? (
                    <Col span="2">
                      <MinusCircleOutlined
                        style={{ marginTop: '10px' }}
                        onClick={() => remove(field.name)}
                      />
                    </Col>
                  ) : null}
                </Row>
                {idx < fields.length - 1 ? (
                  <Row>
                    <Col span="24">
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) => {
                          return (
                            prevValues.screenDataMapperConjunction !==
                            curValues.screenDataMapperConjunction
                          );
                        }}
                      >
                        {() => {
                          const formValues = form.getFieldsValue();
                          const conjunction =
                            formValues.screenDataMapperConjunction;
                          return (
                            <div
                              style={{ position: 'relative', width: '100%' }}
                            >
                              <div
                                style={{
                                  border: '1px dashed #E9E9E9',
                                  position: 'absolute',
                                  width: '100%',
                                  top: '50%',
                                }}
                              />
                              <Button
                                onClick={() => {
                                  formValues.screenDataMapperConjunction =
                                    conjunction === 'AND' ? 'OR' : 'AND';

                                  form.setFieldsValue(formValues);
                                }}
                                style={{
                                  color: '#FFF',
                                  backgroundColor:
                                    conjunction === 'AND'
                                      ? vars.btnOrOpration
                                      : vars.primaryColor,
                                  opacity: 1,
                                }}
                              >
                                {conjunction}
                              </Button>
                            </div>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                ) : null}
              </>
            ))}

            <Row>
              <Col span="24">
                <Form.Item
                  shouldUpdate={(prevValues, curValues) => {
                    return (
                      prevValues.screenDataMapperConjunction !==
                      curValues.screenDataMapperConjunction
                    );
                  }}
                >
                  {() => {
                    const formValues = form.getFieldsValue();
                    const conjunction = formValues.screenDataMapperConjunction;

                    return (
                      <Form.Item>
                        <div
                          style={{
                            border: '1px dashed #E9E9E9',
                            position: 'absolute',
                            width: '100%',
                            top: '50%',
                          }}
                        />
                        <Button
                          onClick={() => {
                            add({
                              term: null,
                              negate: false,
                              operator: 'EQUAL_TO',
                              dataType: 'STRING',
                              value: null,
                            });
                            setTimeout(() => {
                              form.validateFields();
                            }, 500);
                          }}
                          icon={<PlusOutlined />}
                          style={{
                            border: 'solid 1px',
                            borderColor:
                              conjunction === 'AND'
                                ? vars.btnOrOpration
                                : vars.primaryColor,
                            color:
                              conjunction === 'AND'
                                ? vars.btnOrOpration
                                : vars.primaryColor,
                            backgroundColor: '#FFFFFF',
                            opacity: 1,
                          }}
                        >
                          Add Mapping
                        </Button>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    </div>
  );
}
