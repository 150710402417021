import { HTMLBuilder } from '../builder';

const defaultClasses = `
.container--default {
display:flex;
flex-direction:column;
flex:1;
justify-content:space-between;
width:300px;
min-height:380px;
background-color:#ffffff;
border-radius:4px;
box-shadow: 0 2px 8px 0 #0000001F;
overflow:hidden;
}

.texts--wrapper--default {
display:flex;
gap:8px;
align-items:center;
margin:0 auto 0 8px;
}

.actions--wrapper--default {
margin:0px 8px 8px auto;
display:flex;
gap:8px;
align-items:center;
flex-direction:row-reverse;
}
`;

function card(variation, customFonts) {
  const builder = new HTMLBuilder(defaultClasses, customFonts);

  const actions = [];

  const title = builder.text({
    text: variation.subject,
    fontSize: variation.theme.title.font_size,
    fontFamily: variation.theme.title.font_family,
    color: variation.theme.title.color,
  });

  const body = builder.text({
    text: variation.message,
    fontSize: variation.theme.message.font_size,
    fontFamily: variation.theme.message.font_family,
    color: variation.theme.message.color,
  });

  const image = builder.image({
    src: variation.image,
    width: '100%',
    minHeight: '300px',
    backgroundColor: '#f2f2f2',
    objectFilt: 'cover',
    objectPosition: 'center',
  });

  if (Array.isArray(variation.theme.actions)) {
    const [action1, action2] = variation.theme.actions;

    if (action1 && variation.androidButton1Label)
      actions.push(
        builder.action({
          text: variation.androidButton1Label,
          action: variation.androidButton1OnClickAction,
          backgroundColor: action1.bg_color,
          fontSize: action1.style.font_size,
          fontFamily: action1.style.font_family,
          color: action1.style.color,
        })
      );

    if (action2 && variation.androidButton2Label)
      actions.push(
        builder.action({
          text: variation.androidButton2Label,
          action: variation.androidButton2OnClickAction,
          backgroundColor: action2.bg_color,
          fontSize: action2.style.font_size,
          fontFamily: action2.style.font_family,
          color: action2.style.color,
        })
      );
  }

  const actionsBox = builder.wrap(actions, 'actions--wrapper--default');

  const textBox = builder.wrap([title, body], 'texts--wrapper--default');

  builder.injectChildren(image, textBox, actionsBox);

  return builder.build();
}

export { card };
