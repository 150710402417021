import { HTMLBuilder } from '../builder';

const defaultClasses = `
.container--default {
display:flex;
gap:8px;
padding:8px;
width:300px;
height:80px;
background-color:#ffffff;
border-radius:4px;
box-shadow: 0 2px 8px 0 #0000001F;
overflow:hidden;
}
.texts--wrapper-default {
display:flex;
flex-direction:column;
}

`;

function banner(variation, customFonts) {
  const builder = new HTMLBuilder(defaultClasses, customFonts);

  const title = builder.text({
    text: variation.subject,
    fontSize: variation.theme.title.font_size,
    fontFamily: variation.theme.title.font_family,
    color: variation.theme.title.color,
  });

  const body = builder.text({
    text: variation.message,
    fontSize: variation.theme.message.font_size,
    fontFamily: variation.theme.message.font_family,
    color: variation.theme.message.color,
  });

  const image = builder.image({
    src: variation.image,
    height: '100%',
    backgroundColor: '#f2f2f2',
    objectFilt: 'cover',
    objectPosition: 'center',
  });

  const textBox = builder.wrap([title, body], 'texts--wrapper-default');

  builder.injectChildren(image, textBox);

  return builder.build();
}

export { banner };
