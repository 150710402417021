import { getPossibleSegmentHeaders } from '@Component/utils/CsvExport/CsvExportActions';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Select } from 'antd';
import './style/ExportSegmentModal.less';

const ExportSegmentModal = ({
  isExportModalOpen,
  onCancelModal,
  handleExportSegment,
  selectedColumns,
  setSelectedColumns,
}) => {
  const loadingUpdate = useSelector((state) => state.csv.loadingUpdate);
  const [columns, setColumns] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPossibleSegmentHeaders({ cb: setColumns }));
  }, []);

  return (
    <Modal
      width={600}
      title="Select Segment columns"
      centered
      visible={isExportModalOpen}
      onCancel={onCancelModal}
      footer={[
        <Button key="button" shape="round" onClick={onCancelModal}>
          CANCEL
        </Button>,
        <Button
          loading={loadingUpdate}
          key="submit"
          type="primary"
          shape="round"
          onClick={handleExportSegment}
        >
          Export
        </Button>,
      ]}
    >
      <div className="editContainer">
        <div className="label">Selected Attributes</div>
        <div className="inputWrapper">
          <Select
            showArrow
            mode="multiple"
            value={selectedColumns}
            onChange={(events) => {
              setSelectedColumns(events);
            }}
            style={{ width: 308 }}
            className={'chart-selector'}
            bordered={false}
          >
            {columns?.map((column) => (
              <Select.Option value={column} key={column}>
                {column}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    </Modal>
  );
};

export default ExportSegmentModal;
