import React, { useState } from 'react';
import { THEME_OPTIONS as IN_APP_THEME_OPTIONS } from './helpers';
import mobilePreviewContainer from '@Theme/icons/mobile-preview-container.png';
import { CloseOutlined, CopyOutlined } from '@ant-design/icons';
import { getInlineThemeStyle } from './getInlineThemeStyle';
import vars from '@Theme/styles/vars';

export default function InAppPreview({ variation, previewPage = false }) {
  const type = variation?.inAppLayoutType;
  const classNameModifier = `inAppPreview--${type?.toLocaleLowerCase()}`;
  const [isPreviewAndroid] = useState(true);

  const theme = {};
  IN_APP_THEME_OPTIONS.forEach((item) => {
    theme[item.key] =
      (variation?.theme &&
        item.key in variation?.theme &&
        variation?.theme[item.key]) ||
      item.default;
  });

  const details = (
    <>
      <div
        className="inAppPreview__title"
        style={{
          ...getInlineThemeStyle(variation, 'title'),
        }}
      >
        {variation?.subject}
      </div>
      <div
        className="inAppPreview__desc"
        style={{
          ...getInlineThemeStyle(variation, 'message'),
        }}
      >
        {variation?.message}
      </div>
      {['CARD', 'MODAL'].includes(type) && variation?.androidButton1Label && (
        <div className="inAppPreview__actions">
          <span
            className="inAppPreview__action"
            style={{
              ...getInlineThemeStyle(variation, 'actions', 0),
              background: variation?.theme?.['actions']?.[0]?.bg_color,
            }}
          >
            {variation?.androidButton1Label}
          </span>
          {type === 'CARD' && variation?.androidButton2Label && (
            <span
              className="inAppPreview__action"
              style={{
                ...getInlineThemeStyle(variation, 'actions', 1),
                background: variation?.theme?.['actions']?.[1]?.bg_color,
              }}
            >
              {variation?.androidButton2Label}
            </span>
          )}
        </div>
      )}
      {['COUPON'].includes(type) && variation?.couponCode && (
        <div
          className="inAppPreview__coupon-code"
          style={{
            background: variation?.theme?.cta_background_coupon,
          }}
        >
          <span
            className="inAppPreview__coupon_text"
            style={{
              ...getInlineThemeStyle(variation, 'coupon'),
            }}
          >
            {variation?.couponCode}
          </span>
          <span
            style={{
              background: vars.primaryColor,
              borderRadius: '4px',
              padding: '4px',
              width: '20px',
              height: '20px',
            }}
          >
            <CopyOutlined style={{ fontSize: '12px', color: '#fff' }} />
          </span>
        </div>
      )}
    </>
  );

  return (
    <section
      style={{
        direction: 'ltr',
        padding: 8,
        backgroundColor: 'white',
        height: 'auto',
      }}
      className={`message-container__preview ${
        previewPage ? 'message-container__preview--static' : ''
      } overflow-hidden`}
    >
      {isPreviewAndroid ? (
        <div className="push-notification push-notification--android">
          <img
            src={mobilePreviewContainer}
            alt="preview"
            style={{ maxWidth: '100%' }}
          />
          <div
            className="push-notification__block"
            style={{
              minHeight: 'calc(100% - 85px)',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {(variation.image ||
              variation.subject ||
              variation.message ||
              variation.couponCode) && (
              <div
                className={`inAppPreview ${classNameModifier}`}
                style={{
                  backgroundColor:
                    type !== 'IMAGE_ONLY' ? theme.bg_color : 'transparent',
                  ...(type === 'COUPON' && {
                    borderRadius: `${theme.border_radius}px`,
                    ...(variation.image && {
                      backgroundImage: `url(
                      ${variation.image}
                    )`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                    }),
                  }),
                  boxShadow: '5px 5px 15px #dddddd',
                  maxWidth: '80%',
                }}
              >
                <CloseOutlined className="inAppPreview__close" />
                {variation.image && (
                  <div className="inAppPreview__image">
                    <img src={variation.image} alt={'image'} />
                  </div>
                )}
                {variation.inAppLayoutType === 'BANNER' ||
                variation.inAppLayoutType === 'BANNER_RTL' ? (
                  <div className="inAppPreview__body-wrapper">{details}</div>
                ) : (
                  details
                )}
              </div>
            )}
          </div>
        </div>
      ) : null}
    </section>
  );
}
