/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import lodashFind from 'lodash/find';
import lodashFindIndex from 'lodash/findIndex';
import {
  LoadingOutlined,
  InfoCircleOutlined,
  MoreOutlined,
  AndroidFilled,
  AppleFilled,
  SearchOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import lodashIsEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import {
  isEmpty,
  isObject,
  isArray,
  cloneDeep as lodashCloneDeep,
  isEqual,
} from 'lodash';
import moment from 'moment/moment';
import CommunicationStepsComponent from './CommunicationStepsComponent';
import HtmlToReact from 'html-to-react';
//import { getEmailCustomAttributes } from '../../../../utils/MergeTags';
import {
  getPushLayout,
  getInAppLayout,
  getOnSiteLayout,
} from '../../../../utils/RenderUtils';
import InputSelector from '../../../../component/form/InputSelector';
import { variationLetter } from '../../../../utils/FormUtils';
import { history } from '../../../../redux/store';
import EmailTemplareModal from '../../../channel/emailTemplate/component/EmailTemplateModal';
import {
  AuthorityProvider,
  getChannel,
  getCommunicationId,
  getCommunicationStep,
  hasAnyAuthority,
  getProductId,
} from '../../../../utils/AuthorityProvider';
import pushTextPng from '../../../../theme/icons/text.png';
import pushBannerPng from '../../../../theme/icons/banner.png';
import webBannerPng from '../../../../theme/icons/webpush-banner.png';
import webTextPng from '../../../../theme/icons/webpush-text.png';
import androidPushContainer from '../../../../theme/icons/gpixel-push-min.png';
import iosPush from '../../../../theme/icons/iphone6-push-min.png';
import appleIcon from '../../../../theme/icons/apple-icon-min.png';
import androidIcon from '../../../../theme/icons/android-os-min.png';
import androidTrangleIcon from '../../../../theme/icons/android-triangle.png';
import InAppThemeOptions from './inApp/InAppThemOptions';
import InAppActions from './inApp/InAppActions';
import InAppPreview from './inApp/InAppPreview';

import { MinusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Spin,
  Select,
  Radio,
  Input,
  Switch,
  Tooltip,
  Upload,
  Modal,
  Popconfirm,
  Checkbox,
  Form,
  message,
  Row,
  Col,
  Segmented,
} from 'antd';
import CommunicationFooterComponent from './CommunicationFooterComponent';
import {
  journeySteps,
  transactionalSteps,
} from '../../../../utils/CommunicationSteps';
import { errorHandling, emailRegEx } from '../../../../utils/FormUtils';
import texts from '../../../../utils/texts';
const CustomChannelDefaultItems = [
  'message',
  'subject',
  'image',
  'keyValuePair',
  'icon',
  'badge',
  'attachments',
];
import vars from '@Theme/styles/vars.js';
import OnSitePreview from './inApp/OnSitePreview';
import OnSiteActions from './inApp/OnSiteActions';
import Card from '@Utils/Card';
import ListBase from '@Component/form/ListBase';
import WhatsAppPreview from './whatsApp/WhatsAppPreview';
import inAppNewLayoutBanner from '../../../../theme/images/new-layout.png';
import { InlineThemePicker } from './inlineThemePicker';
import { BorderRadiusInput } from './inlineThemePicker/borderRadiusInput';
import { isParentProduct } from '@Utils/isParentProduct';
import { injectHTML } from './inApp/html-templates';

const styles = {
  actions: {
    backgroundColor: 'lightGray',
    margin: '20px',
  },
  actionInput: {
    width: 165,
    height: 48,
    border: '1px solid #ddd',
    outline: 'none',
    fontSize: 18,
    paddingInline: 16,
    textTransform: 'uppercase',
  },
  editContainer: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
  },
  editFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 6,
    fontSize: 13,
    lineHeight: 1.43,
    color: '#3d404e',
  },
  editField: {
    width: 350,
  },
  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
    flex: 1,
    textAlign: 'left',
    width: '37px',
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    height: 60,
    backgroundColor: '#ffffff',
    boxShadow:
      '0 -2px 3px 0 rgba(61,64,78,.12), inset 0 -1px 0 0 rgba(61,64,78,.1)',
    padding: '10px 30px',
    transition: 'all .3s ease-in-out',
    left: 247,
    width: 'calc(100% - 247px)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: '5',
  },
  previewButton: {
    alignSelf: 'flex-end',
    position: 'relative',
  },
  headTitleContainer: {
    fontSize: 18,
    fontWeight: '500',
    color: '#3d404e',
    display: 'flex',
    flexDirection: 'row',
  },
  infoBoxHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 30px',
    borderBottom: '1px solid #e7e8e9',
    backgroundColor: '#fdfdfd',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dataRowContainer: {
    padding: 30,
  },
  dataRowFirst: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dataRow: {
    paddingTop: 20,
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #BFBFBF4D',
  },
  methodsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  methodTitle: {
    fontWeight: '600',
    color: '#3d404e',
    lineHeight: '1.43',
    fontSize: 13,
  },
  methodContainer: {
    border: '1px solid #e7e8e9',
    borderRadius: '5px',
    padding: '20px',
    height: 460,
    width: 247,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  addNewSlotButton: {
    position: 'relative',
    left: -10,
    top: -10,
  },
  renderEditor: {
    background: 'none',
    paddingTop: 40,
    marginBottom: 60,
    direction: 'ltr',
  },
  renderCustomChannel: {
    paddingLeft: 0,
    paddingRight: 0,
    border: '1px solid #F1F1F3',
    backgroundColor: 'white',
  },
  formItem: {
    background: 'none',
    paddingTop: 40,
  },
  renderCustomChannelContent: {
    marginTop: '10px',
    padding: '0px',
  },
};

////todo/////fileFormatChecker implementation for checking image url format///////////////
class CommunicationMessageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.step = getCommunicationStep(this.props.pathname);
    this.isParent = isParentProduct(this.props.currentUser, location);
    this.channel = getChannel(this.props.pathname);
    if (props.journey) {
      this.communicationId =
        props.communicationId ||
        (props.communicationData && props.communicationData.id);
      // TODO fix this shit:
      this.channel = props.channel || 'SMS';
    } else {
      this.communicationId = getCommunicationId(this.props.pathname);
    }
    this.emptyVariation = {
      smsChannel: undefined,
      replyTo: undefined,
      ratingTitle: undefined,
      ratingTextColor: undefined,
      ratingDescription: undefined,
      ratingBackgroundImage: undefined,
      ratingBackgroundColor: undefined,
      pushType: 'TEXT',
      layoutType: 'MODAL',
      inAppLayoutType: 'MODAL',
      onSiteLayoutType: 'TEXT',
      theme: {},
      percentage: undefined,
      message: '',
      subject: '',
      label: '',
      buttonText: '',
      buttonOnsiteAction: '',
      keyValuePair: [],
      json: undefined,
      iosRatingSubmitText: undefined,
      iosOnClickAction: undefined,
      iosButtonsType: undefined,
      iosButton1OnClickAction: undefined,
      image: undefined,
      fromName: undefined,
      fromEmail: undefined,
      emailChannel: undefined,
      carouselType: undefined,
      carouselImages: [],
      cc: undefined,
      bcc: undefined,
      attachments: [],
      androidRatingSubmitText: undefined,
      androidOnClickAction: undefined,
      customChannel: undefined,

      showIosPushButtons: false,
      showIosAdvance: false,
      iosPushButtonType: undefined,
      pushPictureUrl: null,
      pushPicture: null,
      emailAttachment: null,
      emailAttachmentUrl: null,

      showAndroidPushButtons: false,
      showAndroidAdvance: false,

      showWebPushAdvance: false,

      showKeyValue: false,

      androidButtons: [],
      iosButtons: [],

      showReplyTo: false,
      showCc: false,
      showBcc: false,
      selectedTemplateJson: '',
      selectedTemplateHtml: '',
      emailHtml: null,
      uploadFileList: [],
      checkedDefaultLogo: false,
    };
    this.state = {
      showVariationModal: [],
      activeVariationIndex: 0,
      variations: [lodashCloneDeep(this.emptyVariation)],
      isPreviewAndroid: true,
      selectedEmailTemplate: null,
      templateModalVisible: false,
      editCurrentTemplateVisible: false,
      canEditSelectedTemplate: false,
      showEmojiPicker: false,
      showAttributePicker: false,
      emailBodyType: 'raw', // drag
      uploadingAttachmentIndex: -1,
      uploadingVariationIndex: -1,
      uploadingImageVariationIndex: -1,
      emailPreviewVisible: false,
      uploadingWebPushIcon: false,
      uploadingWebPushImage: false,
      uploadingWebPushBadge: false,
      webPushPreviewType: 'chrome_windows_10',
      imageUrlFormatError: true,
      invalidImageUrlError: null,
      fromEmailValidationError: false,
      emailErrors: {
        replyToEmailError: false,
        ccEmailError: false,
        bccEmailError: false,
      },
    };
    this.iosPushButtonType = {
      YES_NO_OPEN_DISMISS: 'YES_NO_OPEN_DISMISS',
      YES_NO_DISMISS: 'YES_NO_DISMISS',
      ACCEPT_DECLINE_OPEN_DISMISS: 'ACCEPT_DECLINE_OPEN_DISMISS',
      ACCEPT_DECLINE_DISMISS: 'ACCEPT_DECLINE_DISMISS',
      SHOP_NOW: 'SHOP_NOW',
      BUY_NOW: 'BUY_NOW',
      DOWNLOAD: 'DOWNLOAD',
    };

    this.formRef = React.createRef();
  }

  handleClickOutside(event) {
    if (this.ref && !this.ref?.current?.contains(event?.target)) {
      const showVariationModal = this.state.showVariationModal;
      let newArr = showVariationModal.map((item) =>
        item === true ? false : false
      );
      this.setState({ showVariationModal: newArr });
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.props.communicationUpdateMessageFetch(this.communicationId);
    this.props.communicationUpdateAudienceFetch(this.communicationId);
    this.props.communicationListEmailChannelsFetch();
    if (this.channel === 'WHATSAPP') {
      this.props.whatsappChannelTemplateListFetch({
        ...this.props.whatsAppTemplatePageRequest,
        name: null,
        page: 0,
      });
    }
    this.props.communicationListPushChannelsFetch();
    this.props.communicationListSmsChannelsFetch();
    this.props.communicationListCustomChannelsFetch();
    this.props.productSegmentListAttributesFetch();
    this.props.productSegmentListEventsFetch();
    this.props.journeyListBusinessEventsFetch();
    if (hasAnyAuthority(AuthorityProvider.ROLE_FONT_LIST)) {
      this.props.fontsListFetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.loadingUpdateMessage &&
      prevProps.loadingUpdateMessage &&
      this.props.currentMessage
    ) {
      if (
        this.props.currentMessage.variations &&
        this.props.currentMessage.variations.length > 0
      ) {
        this.props.currentMessage.variations.map((variation, index) => {
          if (variation.whatsAppTemplate) {
            this.props.whatsappChannelTemplateViewFetch(
              variation.whatsAppTemplate,
              index
            );
          }
        });

        if (this.channel === 'EMAIL') {
          this.prepareEmailVariations(this.props.currentMessage.variations);
          return;
        }
        const variations = [];
        const showVariationModal = [];
        this.props.currentMessage.variations.forEach((item, i) => {
          if (this.channel === 'PUSH' || this.channel === 'WEB_PUSH') {
            let showAndroidAdvance = false;
            let showWebPushAdvance = false;
            let showAndroidPushButtons = false;
            let showKeyValue = false;
            if (
              !isNil(item.androidButton1Label) ||
              !isNil(item.androidButton1OnClickAction) ||
              !isNil(item.androidButton2Label) ||
              !isNil(item.androidButton2OnClickAction)
            ) {
              showAndroidPushButtons = true;
            }
            if (!isNil(item.androidOnClickAction) || showAndroidPushButtons) {
              showAndroidAdvance = true;
            }
            if (item.autoHide === false) {
              showWebPushAdvance = true;
            }
            if (
              !isNil(item.keyValuePair) &&
              !lodashIsEmpty(item.keyValuePair) &&
              item.keyValuePair.length > 0
            ) {
              showKeyValue = true;
            }
            let androidButtons = [];
            if (
              !isNil(item.androidButton1Label) ||
              !isNil(item.androidButton1OnClickAction)
            ) {
              androidButtons.push({
                label: item.androidButton1Label,
                onClick: item.androidButton1OnClickAction,
              });
            }
            if (
              !isNil(item.androidButton2Label) ||
              !isNil(item.androidButton2OnClickAction)
            ) {
              androidButtons.push({
                label: item.androidButton2Label,
                onClick: item.androidButton2OnClickAction,
              });
            }
            variations.push({
              ...lodashCloneDeep(this.emptyVariation),
              ...item,
              showAndroidPushButtons,
              showAndroidAdvance,
              showWebPushAdvance,
              androidButtons,
              showKeyValue,
            });
            if (this.channel === 'PUSH') {
              this.formRef.current.setFieldsValue({
                [`variations[${i}].subject`]: item?.subject,
                [`variations[${i}].message`]: item?.message,
              });
            }
          } else if (this.channel === 'CUSTOM') {
            // this.formRef.current.
            let showKeyValue = false;
            if (
              !isNil(item.keyValuePair) &&
              !lodashIsEmpty(item.keyValuePair) &&
              item.keyValuePair.length > 0
            ) {
              showKeyValue = true;
            }
            variations.push({
              ...lodashCloneDeep(this.emptyVariation),
              ...item,
              showKeyValue,
            });
            this.formRef.current.setFieldsValue({
              [`variations[${i}].customChannel`]: item?.customChannel,
              [`variations[${i}].subject`]: item?.subject,
              [`variations[${i}].message`]: item?.message,
            });
          } else if (this.channel === 'IN_APP' || this.channel === 'ON_SITE') {
            variations.push({
              ...lodashCloneDeep(this.emptyVariation),
              ...item,
            });
            this.formRef.current.setFieldsValue({
              [`variations[${i}].subject`]: item?.subject,
              [`variations[${i}].message`]: item?.message,
            });
          } else {
            variations.push({
              ...lodashCloneDeep(this.emptyVariation),
              ...item,
            });
          }
        });
        variations.forEach(() => showVariationModal.push(false));
        this.setState({ variations, showVariationModal });
      }
    } else if (
      !this.props.loadingUploadFile &&
      prevProps.loadingUploadFile &&
      this.props.uploadedFile &&
      this.props.uploadedFile.path
    ) {
      if (this.channel === 'EMAIL') {
        const clonedAttachments =
          this.state.variations[this.state.uploadingVariationIndex] &&
          this.state.variations[this.state.uploadingVariationIndex]
            .attachments &&
          Array.isArray(
            this.state.variations[this.state.uploadingVariationIndex]
              .attachments
          )
            ? lodashCloneDeep(
                this.state.variations[this.state.uploadingVariationIndex]
                  .attachments
              )
            : [];
        clonedAttachments[this.state.uploadingAttachmentIndex] = {
          ...clonedAttachments[this.state.uploadingAttachmentIndex],
          file: this.props.uploadedFile.path,
          ...this.props.uploadedFile,
        };
        this.handleChangeVariations(
          this.state.uploadingVariationIndex,
          'attachments',
          clonedAttachments
        );
        this.setState(
          { uploadingAttachmentIndex: -1, uploadingVariationIndex: -1 },
          () => {
            this.props.communicationUploadFileReset();
          }
        );
      } else if (this.channel === 'PUSH') {
        this.handleChangeVariations(
          this.state.uploadingImageVariationIndex,
          'image',
          this.props.uploadedFile.path
        );
        this.setState({ uploadingImageVariationIndex: -1 }, () => {
          this.props.communicationUploadFileReset();
        });
      } else if (this.channel === 'WEB_PUSH') {
        let name = null;
        if (this.state.uploadingWebPushIcon) {
          name = 'icon';
        } else if (this.state.uploadingWebPushImage) {
          name = 'image';
        } else if (this.state.uploadingWebPushBadge) {
          name = 'badge';
        }
        if (!isNil(name)) {
          this.handleChangeVariations(
            this.state.uploadingImageVariationIndex,
            name,
            this.props.uploadedFile.path
          );
          this.setState({ uploadingImageVariationIndex: -1 }, () => {
            this.props.communicationUploadFileReset();
          });
        }
      } else if (this.channel === 'CUSTOM') {
        let name = null;
        if (this.state.uploadingWebPushIcon) {
          name = 'icon';
        } else if (this.state.uploadingWebPushImage) {
          name = 'image';
        } else if (this.state.uploadingWebPushBadge) {
          name = 'badge';
        }
        if (!isNil(name)) {
          this.handleChangeVariations(
            this.state.uploadingImageVariationIndex,
            name,
            this.props.uploadedFile.path
          );
          this.setState({ uploadingImageVariationIndex: -1 }, () => {
            this.props.communicationUploadFileReset();
          });
          this.clearLoading(name);
        } else if (this.state.uploadingAttachmentIndex >= 0) {
          const clonedAttachments =
            this.state.variations[this.state.uploadingVariationIndex] &&
            this.state.variations[this.state.uploadingVariationIndex]
              .attachments &&
            Array.isArray(
              this.state.variations[this.state.uploadingVariationIndex]
                .attachments
            )
              ? lodashCloneDeep(
                  this.state.variations[this.state.uploadingVariationIndex]
                    .attachments
                )
              : [];
          clonedAttachments[this.state.uploadingAttachmentIndex] = {
            ...clonedAttachments[this.state.uploadingAttachmentIndex],
            file: this.props.uploadedFile.path,
            ...this.props.uploadedFile,
          };
          this.handleChangeVariations(
            this.state.uploadingVariationIndex,
            'attachments',
            clonedAttachments
          );
          this.setState(
            { uploadingAttachmentIndex: -1, uploadingVariationIndex: -1 },
            () => {
              this.props.communicationUploadFileReset();
            }
          );
        }
      } else if (this.channel === 'IN_APP' || this.channel === 'ON_SITE') {
        this.handleChangeVariations(
          this.state.uploadingImageVariationIndex,
          'image',
          this.props.uploadedFile.path
        );
        this.setState({ uploadingImageVariationIndex: -1 }, () => {
          this.props.communicationUploadFileReset();
        });
      }
    } else if (
      !this.props.loadingUpdateAudience &&
      prevProps.loadingUpdateAudience &&
      this.props.currentAudience
    ) {
      let isPreviewAndroid = true;
      if (this.props.currentAudience.mobileDevice === 'IOS') {
        isPreviewAndroid = false;
      }
      this.setState({ isPreviewAndroid });
    } else if (
      !this.props.loadingViewTemplate &&
      prevProps.loadingViewTemplate &&
      this.props.currentTemplate
    ) {
      //todo
      // fill template and open continue modal
      this.setState({ selectedEmailTemplate: this.props.currentTemplate });
    } else if (
      this.props.journey &&
      prevProps.successUpdateMessageSubmit !==
        this.props.successUpdateMessageSubmit &&
      this.props.successUpdateMessageSubmit
    ) {
      this.props.goToStepOnJourney('conversion');
    } else if (this.props.customChannel !== prevProps.customChannel) {
      let customChannelItems = CustomChannelDefaultItems;
      // const body_ = this.props.customChannel.body;
      // if (body_) {
      //     customChannelItems = CustomChannelDefaultItems.filter(item => body_.search(item) >= 0);
      // }
      this.setState({
        [`selectedCustomChannelItems_${this.state.activeVariationIndex}`]:
          customChannelItems,
      });
    }
  }

  clearLoading(name) {
    if (name === 'icon') {
      this.setState({
        uploadingWebPushIcon: false,
      });
    } else if (name === 'image') {
      this.setState({
        uploadingWebPushImage: false,
      });
    } else if (name === 'badge') {
      this.setState({
        uploadingWebPushIuploadingWebPushBadgecon: false,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    this.props.clearCommunicationMessageError(
      this.props.errorUpdateMessageSubmit.errors
    );

    this.props.resetCurrentMessage({});
  }

  setEmailError = (field, value) => {
    this.setState({
      emailErrors: { ...this.state.emailErrors, [field]: value },
    });
  };

  viewItem(id) {
    if (hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CUSTOM_ATTRIBUTE_VIEW)) {
      history.push(`view/${id}`);
    }
  }

  getNavigation() {
    return [{ name: 'Custom Attribute List', path: '' }];
  }

  communicationHasJourneyType = () => {
    if (
      !this.props.journey &&
      this.props.currentMessage &&
      ['JOURNEY', 'RELAY'].includes(this.props.currentMessage.type)
    ) {
      return true;
    }
    return false;
  };

  addVariation = () => {
    if (this.props.readOnly) {
      return;
    }
    const { variations } = this.state;
    const showVariationModal = this.state.showVariationModal;
    if (variations.length < 5) {
      variations.push(lodashCloneDeep(this.emptyVariation));
      const newArr = showVariationModal.map((item) =>
        item === true ? false : false
      );
      newArr.push(false);
      this.setState({ showVariationModal: newArr });
    }
    this.setState({ variations });
  };

  addEmailAttachment = (index) => {
    const cloned =
      this.state.variations[index] &&
      this.state.variations[index].attachments &&
      Array.isArray(this.state.variations[index].attachments)
        ? lodashCloneDeep(this.state.variations[index].attachments)
        : [];
    cloned.push({ file: null });
    this.handleChangeVariations(index, 'attachments', cloned);
  };

  changeEmailAttachment = (index, attachmentIndex, value) => {
    const cloned = lodashCloneDeep(this.state.variations[index].attachments);
    cloned[attachmentIndex].file = value;
    this.handleChangeVariations(index, 'attachments', cloned);
  };

  removeVariation = (index) => {
    if (this.props.readOnly) {
      return;
    }
    const { variations } = this.state;
    const showVariationModal = this.state.showVariationModal;
    if (variations.length > 1) {
      variations.splice(index, 1);
      showVariationModal.splice(index, 1);
    }
    let myActiveVariationIndex = variations.length - 1;
    if (
      (index === 1 && myActiveVariationIndex === index) ||
      variations.length === 1
    ) {
      myActiveVariationIndex = 0;
    }
    this.setState({
      variations,
      activeVariationIndex: myActiveVariationIndex,
      showVariationModal,
    });
  };

  duplicateVariation = (index) => {
    if (this.props.readOnly) {
      return;
    }
    const { variations } = this.state;
    const showVariationModal = this.state.showVariationModal;
    if (variations.length < 5) {
      let foundVar = variations.filter((element, i) => i === index);
      delete foundVar[0].id;
      variations.push(lodashCloneDeep(foundVar[0]));
      const newArr = showVariationModal.map((item) =>
        item === true ? false : false
      );
      newArr.push(false);
      this.setState({ showVariationModal: newArr });
    }
    this.setState({ variations });
  };

  isEditDisable = () => {
    return (
      !this.props.journey &&
      this.props.currentMessage &&
      this.props.currentMessage.status === 'ENDED'
    );
  };

  getAndroidAdvance = (index) => {
    const result = {};
    if (!this.state.variations[index].showAndroidAdvance) {
      result.androidButton1Label = null;
      result.androidButton1OnClickAction = null;
      result.androidButton2Label = null;
      result.androidButton2OnClickAction = null;
      if (this.channel !== 'WEB_PUSH') {
        result.androidOnClickAction = null;
      }
    } else if (!this.state.variations[index].showAndroidPushButtons) {
      result.androidButton1Label = null;
      result.androidButton1OnClickAction = null;
      result.androidButton2Label = null;
      result.androidButton2OnClickAction = null;
    } else {
      if (this.state.variations[index].androidButtons.length === 1) {
        result.androidButton1Label =
          this.state.variations[index].androidButtons[0].label;
        result.androidButton1OnClickAction =
          this.state.variations[index].androidButtons[0].onClick;
        result.androidButton2Label = null;
        result.androidButton2OnClickAction = null;
      }
      if (this.state.variations[index].androidButtons.length === 2) {
        result.androidButton1Label =
          this.state.variations[index].androidButtons[0].label;
        result.androidButton1OnClickAction =
          this.state.variations[index].androidButtons[0].onClick;
        result.androidButton2Label =
          this.state.variations[index].androidButtons[1].label;
        result.androidButton2OnClickAction =
          this.state.variations[index].androidButtons[1].onClick;
      }
    }
    return result;
  };

  getWebPushButtons = (index) => {
    const result = {};
    if (!this.state.variations[index].showAndroidPushButtons) {
      result.androidButton1Label = null;
      result.androidButton1OnClickAction = null;
      result.androidButton2Label = null;
      result.androidButton2OnClickAction = null;
    } else {
      if (this.state.variations[index].androidButtons.length === 1) {
        result.androidButton1Label =
          this.state.variations[index].androidButtons[0].label;
        result.androidButton1OnClickAction =
          this.state.variations[index].androidButtons[0].onClick;
        result.androidButton2Label = null;
        result.androidButton2OnClickAction = null;
      }
      if (this.state.variations[index].androidButtons.length === 2) {
        result.androidButton1Label =
          this.state.variations[index].androidButtons[0].label;
        result.androidButton1OnClickAction =
          this.state.variations[index].androidButtons[0].onClick;
        result.androidButton2Label =
          this.state.variations[index].androidButtons[1].label;
        result.androidButton2OnClickAction =
          this.state.variations[index].androidButtons[1].onClick;
      }
    }
    return result;
  };

  getIosAdvance = (index) => {
    const result = {};
    if (!this.state.variations[index].showIosAdvance) {
      result.iosButton1OnClickAction = null;
      result.iosButtonsType = null;
      result.iosOnClickAction = null;
    } else {
      if (
        this.state.variations[index].iosButtons.length > 0 &&
        (this.state.variations[index].iosPushButtonType ===
          this.iosPushButtonType.YES_NO_OPEN_DISMISS ||
          this.state.variations[index].iosPushButtonType ===
            this.iosPushButtonType.ACCEPT_DECLINE_OPEN_DISMISS ||
          this.state.variations[index].iosPushButtonType ===
            this.iosPushButtonType.SHOP_NOW ||
          this.state.variations[index].iosPushButtonType ===
            this.iosPushButtonType.BUY_NOW ||
          this.state.variations[index].iosPushButtonType ===
            this.iosPushButtonType.DOWNLOAD)
      ) {
        result.iosButton1OnClickAction =
          this.state.variations[index].iosButtons[0].onClick;
      }
    }
    return result;
  };

  getKeyValue = (index) => {
    let keyValuePair = this.state.variations[index].keyValuePair
      ? lodashCloneDeep(this.state.variations[index].keyValuePair)
      : [];
    if (!this.state.variations[index].showKeyValue) {
      keyValuePair = [];
    }
    return keyValuePair;
  };

  getVariation = () => {
    const result = [];
    const { variations } = this.state;
    variations.forEach((item, index) => {
      const androidAdvance =
        this.channel === 'IN_APP' || this.channel === 'ON_SITE'
          ? {}
          : this.getAndroidAdvance(index);
      const webPushButtons =
        this.channel === 'WEB_PUSH' ? this.getWebPushButtons(index) : null;
      const iosAdvance = this.getIosAdvance(index);
      const keyValuePair = this.getKeyValue(index);
      const cc = item.showCc ? item.cc : null;
      const bcc = item.showBcc ? item.bcc : null;
      const replyTo = item.showReplyTo ? item.replyTo : null;
      let autoHide = item.autoHide;
      if (this.channel === 'WEB_PUSH' && !item.showWebPushAdvance) {
        autoHide = true;
      }

      result.push({
        ...item,
        ...androidAdvance,
        ...iosAdvance,
        ...webPushButtons,
        keyValuePair,
        cc,
        bcc,
        replyTo,
        percentage: this.getVariationPercentage(index, variations.length),
        autoHide,
      });
    });
    return result;
  };

  openEmailPreview = (index) => {
    const parser = new HtmlToReact.Parser();
    const htmlInput =
      this.state.variations[index] && this.state.variations[index].message
        ? this.state.variations[index].message
        : '';
    this.setState({
      emailPreviewVisible: true,
      emailHtml: parser.parse(htmlInput),
    });
  };

  cancelEmailPreview = () => {
    this.setState({ emailPreviewVisible: false });
  };

  confirmDeleteAttachment = (e, index, attachmentIndex) => {
    const cloned = lodashCloneDeep(this.state.variations[index].attachments);
    cloned.splice(attachmentIndex, 1);
    this.handleChangeVariations(index, 'attachments', cloned);
  };

  cancel = (e) => {
    e.stopPropagation();
  };

  checkEmailErrors = (showType, value) => {
    let selectedVariation =
      this.state.variations[this.state.activeVariationIndex];
    if (
      selectedVariation[showType] &&
      (isEmpty(selectedVariation[value]) ||
        !isNil(this.checkValidEmail(selectedVariation[value])))
    ) {
      return true;
    }
    return false;
  };

  getBlobURL = (code, type) => {
    const blob = new Blob([code], { type });
    return URL.createObjectURL(blob);
  };

  prepareEmailVariations = async (variatons) => {
    const preparedVariations = await Promise.all(
      variatons.map((variation) => this.prepareEmailVariation(variation))
    );

    this.setState({ variations: preparedVariations });
  };

  prepareEmailVariation = async (variation) => {
    let showReplyTo = false;
    let showCc = false;
    let showBcc = false;
    if (!isNil(variation.bcc)) {
      showBcc = true;
    }
    if (!isNil(variation.cc)) {
      showCc = true;
    }
    if (!isNil(variation.replyTo)) {
      showReplyTo = true;
    }

    const message = variation.message;
    const el = document.createElement('html');
    el.innerHTML = message;

    const prepareHeaderStyle = el.querySelector('#intkPreheaderStyle');
    if (prepareHeaderStyle) prepareHeaderStyle.remove();

    let preHeaderBody = el.querySelector('#intkPreheaderBody');
    let preheaderText = '';
    if (preHeaderBody) {
      preheaderText = preHeaderBody.innerText;
      preHeaderBody.remove();
    }

    let messageHtml = el.outerHTML;
    const REGEX_ATTR = /{{[a-zA-Z]+\[[^}}]*]}}+/g;
    const populatedAttrs = messageHtml.match(REGEX_ATTR);

    populatedAttrs?.forEach((item) => {
      messageHtml = messageHtml.replace(item, item.replaceAll(' ', ''));
    });

    const alteredMessage = messageHtml;

    return {
      ...lodashCloneDeep(this.emptyVariation),
      ...variation,
      message: preheaderText ? alteredMessage : message,
      preheader: preheaderText,
      showReplyTo,
      showCc,
      showBcc,
    };
  };

  addingPreHeaderToEmailMessage = async (variation) => {
    const message = variation.message;
    const preheader = variation.preheader;
    let messageWithPreheader = message;
    if (preheader) {
      const el = document.createElement('html');
      el.innerHTML = message;

      const preHeaderCSS =
        'font-size: 0px;line-height: 1px;mso-line-height-rule: exactly;display: none;overflow: hidden;visibility: hidden;color: transparent;opacity: 0;height: 0;width: 0;max-width: 0px;max-height: 0px;mso-hide: all;';

      if (!el.querySelector('#intkPreheaderStyle')) {
        const css = `.intkpreheader {${preHeaderCSS}}`,
          elHead = el.head || el.getElementsByTagName('head')[0],
          style = document.createElement('style');

        style.setAttribute('id', 'intkPreheaderStyle');

        elHead.appendChild(style);

        style.type = 'text/css';
        if (style.styleSheet) {
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }
      }

      let preHeaderBody = el.querySelector('#intkPreheaderBody');
      if (preHeaderBody) {
        preHeaderBody.remove();
      }

      const preHeaderText = document.createTextNode(preheader);
      preHeaderBody = document.createElement('div');
      preHeaderBody.setAttribute('id', 'intkPreheaderBody');
      preHeaderBody.setAttribute('style', preHeaderCSS);
      preHeaderBody.setAttribute('class', 'intkpreheader');
      preHeaderBody.appendChild(preHeaderText);

      const elBody = el.body || el.getElementsByTagName('body')[0];

      elBody.prepend(preHeaderBody);
      let messageHtml = el.outerHTML;
      const REGEX_ATTR = /{{[a-zA-Z]+\[[^}}]*]}}+/g;
      const populatedAttrs = messageHtml.match(REGEX_ATTR);
      populatedAttrs?.forEach((item) => {
        messageHtml = messageHtml.replace(item, item.replaceAll(' ', ''));
      });

      messageWithPreheader = messageHtml;
    }

    return {
      ...variation,
      message: messageWithPreheader,
    };
  };

  handleClickNext = async () => {
    if (this.channel === 'PUSH') {
      const getVariation = this.getVariation();
      let isValid = true;
      getVariation.map((item) => {
        if (item.showAndroidPushButtons) {
          item.androidButtons.map((button) => {
            if (!button?.label) {
              isValid = false;
            }
          });
        }
      });

      if (!isValid) {
        return;
      }

      const variations = this.getVariation()?.map((item) => ({
        ...item,
        image: item.pushType === 'TEXT' ? null : item.image,
      }));
      this.props.communicationUpdateSubmitMessageFetch(
        this.communicationId,
        {
          variations,
        },
        this.props.journey
      );
    } else if (this.channel === 'EMAIL') {
      const replyToEmailError = this.checkEmailErrors('showReplyTo', 'replyTo');
      const ccEmailError = this.checkEmailErrors('showCc', 'cc');
      const bccEmailError = this.checkEmailErrors('showBcc', 'bcc');

      if (
        !isNil(
          this.checkValidEmail(
            this.state.variations[this.state.activeVariationIndex].fromEmail
          )
        ) ||
        replyToEmailError ||
        ccEmailError ||
        bccEmailError
      ) {
        this.setState({
          emailErrors: {
            ...this.state.emailErrors,
            replyToEmailError,
            ccEmailError,
            bccEmailError,
          },
        });
        return;
      } else {
        const variations = await Promise.all(
          this.getVariation().map((variation) =>
            this.addingPreHeaderToEmailMessage(variation)
          )
        );

        this.props.communicationUpdateSubmitMessageFetch(
          this.communicationId,
          {
            variations,
          },
          this.props.journey
        );
        if (
          !isEmpty(this.props.errorUpdateMessageSubmit) &&
          Array.isArray(this.props.errorUpdateMessageSubmit.errors) &&
          this.props.errorUpdateMessageSubmit.errors.length > 0
        ) {
          this.setState(
            {
              activeVariationIndex: parseInt(
                this.props.errorUpdateMessageSubmit.errors[0].key.substring(
                  11,
                  12
                )
              ),
            },
            () => {}
          );
        }
      }
    } else if (this.channel === 'SMS') {
      this.props.communicationUpdateSubmitMessageFetch(
        this.communicationId,
        {
          variations: this.getVariation(),
        },
        this.props.journey
      );
    } else if (this.channel === 'WEB_PUSH') {
      const variations = this.getVariation()?.map((item) => ({
        ...item,
        image: item.pushType === 'TEXT' ? null : item.image,
      }));
      let isValid = true;
      variations.map((item) => {
        if (item.showAndroidPushButtons) {
          item.androidButtons.map((button) => {
            if (!button?.label) {
              isValid = false;
            }
          });
        }
      });

      if (!isValid) {
        return;
      }
      this.props.communicationUpdateSubmitMessageFetch(
        this.communicationId,
        {
          variations,
        },
        this.props.journey
      );
    } else if (this.channel === 'CUSTOM') {
      try {
        await this.formRef.current.validateFields();
        this.props.communicationUpdateSubmitMessageFetch(
          this.communicationId,
          {
            variations: this.getVariation(),
          },
          this.props.journey
        );
      } catch (errorInfo) {
        return;
      }
    } else if (this.channel === 'IN_APP') {
      //check form validation
      const inAppVariations = this.getVariation();
      const isValid = inAppVariations.every((item) => {
        switch (item.inAppLayoutType) {
          case 'IMAGE_ONLY':
            return item?.image;
          case 'BANNER':
          case 'BANNER_RTL':
            return item?.message && item?.subject;

          case 'MODAL':
            return (
              item?.message &&
              item?.subject &&
              ((item.androidButton1Label && item.androidButton1OnClickAction) ||
                (!item.androidButton1Label &&
                  !item.androidButton1OnClickAction))
            );

          case 'CARD':
            return (
              item?.message &&
              item?.subject &&
              item?.image &&
              item.androidButton1Label &&
              item.androidButton1OnClickAction &&
              ((item.androidButton2Label && item.androidButton2OnClickAction) ||
                (!item.androidButton2Label &&
                  !item.androidButton2OnClickAction))
            );
          case 'COUPON':
            return item?.subject && item?.message && item?.couponCode;
          default:
            return false;
        }
      });
      if (!isValid) return;
      try {
        await this.formRef.current.validateFields();

        this.props.communicationUpdateSubmitMessageFetch(
          this.communicationId,
          {
            variations: injectHTML(
              inAppVariations,
              this.channel,
              this.props.uploadedFonts
            ),
          },
          this.props.journey
        );
      } catch (errorInfo) {
        return;
      }
    } else if (this.channel === 'ON_SITE') {
      //check form validation
      const inAppVariations = this.getVariation();
      const isValid = inAppVariations.every((item) => {
        switch (item.onSiteLayoutType) {
          case 'IMAGE':
            return item?.image;
          case 'TEXT':
            return (
              item?.subject &&
              ((item.androidButton1Label && item.androidButton1OnClickAction) ||
                (!item.androidButton1Label &&
                  !item.androidButton1OnClickAction))
            );
          case 'COUPON':
            return item?.subject && item?.couponCode;

          default:
            return false;
        }
      });
      if (!isValid) return;
      try {
        await this.formRef.current.validateFields();
        this.props.communicationUpdateSubmitMessageFetch(
          this.communicationId,
          {
            variations: injectHTML(
              inAppVariations,
              this.channel,
              this.props.uploadedFonts
            ),
          },
          this.props.journey
        );
      } catch (errorInfo) {
        return;
      }
    } else if (this.channel === 'WHATSAPP') {
      this.props.communicationUpdateSubmitMessageFetch(
        this.communicationId,
        {
          variations: this.getVariation(),
        },
        this.props.journey
      );
    }
    if (
      !isEmpty(this.props.errorUpdateMessageSubmit) &&
      Array.isArray(this.props.errorUpdateMessageSubmit.errors) &&
      this.props.errorUpdateMessageSubmit.errors.length > 0
    ) {
      this.setState({
        activeVariationIndex: parseInt(
          this.props.errorUpdateMessageSubmit.errors[0].key.substring(11, 12)
        ),
      });
    }
  };

  handleClickBack = () => {
    if (!this.props.journey) {
      history.push('audience');
    } else if (
      this.props.channel === 'IN_APP' ||
      this.props.channel === 'ON_SITE'
    ) {
      this.props.goToStepOnJourney('when');
    } else {
      this.props.goToStepOnJourney('audience');
    }
  };

  addKeyValuePair = (index) => {
    const { variations } = this.state;
    variations[index].keyValuePair.push({ key: '', value: '' });
    this.setState({ variations });
  };

  handleRemoveIosButtons = (index) => {
    const { iosButtons } = this.state;
    iosButtons.splice(index, 1);
    if (iosButtons.length === 0) {
      this.setState({ showIosPushButtons: false, iosButtons });
    } else {
      this.setState({ iosButtons });
    }
  };

  addAndroidButton = (index) => {
    const { variations } = this.state;
    const androidButtons = variations[index].androidButtons.slice(0);
    if (androidButtons.length < 2) {
      androidButtons.push({ label: '', onClick: '' });
      this.handleChangeVariations(index, 'androidButtons', androidButtons);
    }
  };

  addIosButton = () => {
    const { iosButtons } = this.state;
    if (iosButtons.length < 2) {
      iosButtons.push({ label: '', onClick: '' });
      this.setState({ iosButtons });
    }
  };

  handleRemoveKeyValue = (index, keyValueIndex) => {
    const { variations } = this.state;
    if (variations[index].keyValuePair.length === 1 && keyValueIndex === 0) {
      variations[index].keyValuePair = [{ key: '', value: '' }];
      variations[index].showKeyValue = false;
      this.setState({ variations });
    } else {
      variations[index].keyValuePair = variations[index].keyValuePair.filter(
        (_, i) => i !== keyValueIndex
      );
      this.setState({ variations });
    }
  };

  handleChangeKeyValueKey = (index, keyValueIndex, value) => {
    const { variations } = this.state;
    variations[index].keyValuePair = [
      ...this.state.variations[index].keyValuePair.slice(0, keyValueIndex),
      {
        ...this.state.variations[index].keyValuePair[keyValueIndex],
        key: value,
      },
      ...this.state.variations[index].keyValuePair.slice(keyValueIndex + 1),
    ];
    this.setState({ variations });
    this.props.clearCommunicationMessageError(
      this.props.errorUpdateMessageSubmit.errors,
      'variations[' + [index] + '].keyValuePair[' + [keyValueIndex] + '].key'
    );
  };

  handleChangeKeyValueValue = (index, keyValueIndex, value) => {
    const { variations } = this.state;
    variations[index].keyValuePair = [
      ...this.state.variations[index].keyValuePair.slice(0, keyValueIndex),
      {
        ...this.state.variations[index].keyValuePair[keyValueIndex],
        value: value,
      },
      ...this.state.variations[index].keyValuePair.slice(keyValueIndex + 1),
    ];
    this.setState({ variations });
    this.props.clearCommunicationMessageError(
      this.props.errorUpdateMessageSubmit.errors,
      'variations[' + [index] + '].keyValuePair[' + [keyValueIndex] + '].value'
    );
  };

  handleRemoveUploaded = (selector = null) => {
    // todo
    if (!isNil(selector)) {
      if (selector === 'icon') {
        this.setState({
          uploadFileList: [],
          uploadingWebPushIcon: false,
          uploadingWebPushImage: false,
        });
      } else if (selector === 'image') {
        this.setState({ uploadFileList: [], uploadingWebPushImage: false });
      } else if (selector === 'badge') {
        this.setState({ uploadFileList: [], uploadingWebPushBadge: false });
      }
    } else {
      this.setState({ uploadFileList: [] });
    }
    this.setState({ uploadFileList: [] });
    // this.setState({ uploadFileList: [], newAction: { ...this.state.newAction, emailAttachment: null, pushPicture: null } });
    // this.props.notificationTypeUploadReset();
  };

  handleChangeAndroidPushButtons = (index) => {
    const { variations } = this.state;
    if (variations[index].showAndroidPushButtons) {
      variations[index].showAndroidPushButtons = false;
      variations[index].androidButtons = [];
      this.setState({ variations });
    } else if (!variations[index].showAndroidPushButtons) {
      variations[index].showAndroidPushButtons = true;
      variations[index].androidButtons = [{ label: '', onClick: '' }];
      this.setState({ variations });
    }
  };

  handleChangeIosPush = (index) => {
    const { variations } = this.state;
    if (variations[index].showIosPushButtons) {
      variations[index].showIosPushButtons = false;
      variations[index].iosButtons = [];
      this.setState({ variations });
    } else if (!variations[index].showIosPushButtons) {
      variations[index].showIosPushButtons = true;
      variations[index].iosButtons = [];
      this.setState({ variations });
    }
  };

  handleChangeVariations = (index, name, value) => {
    this.setState({
      uploadingWebPushIcon: false,
      uploadingWebPushImage: false,
    });
    const { variations } = this.state;
    variations[index][name] = value;
    this.setState({ variations });
    if (name === 'image') {
      let { imageUrlFormatError } = this.state;
      if (isEmpty(value)) {
        imageUrlFormatError = false;
      } else {
        if (value.includes('.')) {
          const imageUrl = value.split('.').pop();
          const accepted = ['jpg', 'jpeg', 'png', 'gif'];
          const foundIndex = lodashFindIndex(accepted, (item) =>
            imageUrl.includes(item)
          );
          if (foundIndex > -1) {
            imageUrlFormatError = false;
          } else {
            imageUrlFormatError = true;
          }
        } else {
          imageUrlFormatError = true;
        }
      }
      this.setState({ imageUrlFormatError });
    }
    if (
      name === 'smsChannel' ||
      name === 'message' ||
      name === 'emailChannel' ||
      name === 'fromName' ||
      name === 'fromEmail' ||
      name === 'subject' ||
      name === 'image'
    ) {
      this.props.clearCommunicationMessageError(
        this.props.errorUpdateMessageSubmit.errors,
        'variations[' + [index] + '].' + name
      );
    }
    if (name === 'replyTo') {
      this.setEmailError('replyToEmailError', false);
    }
    if (name === 'cc') {
      this.setEmailError('ccEmailError', false);
    }
    if (name === 'bcc') {
      this.setEmailError('bccEmailError', false);
    }
    if (name === 'customChannel') {
      // fetch specific customChannel
      this.props.customChannelUpdateFetch(value);
    }
  };

  checkValidImageUrl = (url) => {
    // var types = ['jpg', 'jpeg', 'png', 'gif'];
    if (isNil(url) || isEmpty(url)) {
      return true;
    }
    // else {
    //   var parts = url.split('.');
    //   var extension = parts[parts.length - 1];
    //   if (types.indexOf(extension) !== -1) {
    //     return true;
    //   }
    // }
  };

  handleChangeIosPushButtonType = (index, iosPushButtonType) => {
    let iosButtons = [];
    if (iosPushButtonType === this.iosPushButtonType.YES_NO_OPEN_DISMISS) {
      iosButtons.push({ label: 'Yes', onClick: '', disable: false });
      iosButtons.push({
        label: 'No',
        onClick: 'Dismisses the notification',
        disable: true,
      });
    } else if (iosPushButtonType === this.iosPushButtonType.YES_NO_DISMISS) {
      iosButtons.push({
        label: 'Yes',
        onClick: 'Dismisses the notification',
        disable: true,
      });
      iosButtons.push({
        label: 'No',
        onClick: 'Dismisses the notification',
        disable: true,
      });
    } else if (
      iosPushButtonType === this.iosPushButtonType.ACCEPT_DECLINE_OPEN_DISMISS
    ) {
      iosButtons.push({ label: 'Accept', onClick: '', disable: false });
      iosButtons.push({
        label: 'Decline',
        onClick: 'Dismisses the notification',
        disable: true,
      });
    } else if (
      iosPushButtonType === this.iosPushButtonType.ACCEPT_DECLINE_DISMISS
    ) {
      iosButtons.push({
        label: 'Accept',
        onClick: 'Dismisses the notification',
        disable: true,
      });
      iosButtons.push({
        label: 'Decline',
        onClick: 'Dismisses the notification',
        disable: true,
      });
    } else if (iosPushButtonType === this.iosPushButtonType.SHOP_NOW) {
      iosButtons.push({ label: 'Shop Now', onClick: '', disable: false });
    } else if (iosPushButtonType === this.iosPushButtonType.BUY_NOW) {
      iosButtons.push({ label: 'Buy Now', onClick: '', disable: false });
    } else if (iosPushButtonType === this.iosPushButtonType.DOWNLOAD) {
      iosButtons.push({ label: 'Download', onClick: '', disable: false });
    }
    const { variations } = this.state;
    variations[index].iosPushButtonType = iosPushButtonType;
    variations[index].iosButtons = iosButtons;
    this.setState({ variations });
  };

  handleSelectUpload = ({ fileList }, index, attachmentIndex) => {
    if (
      Array.isArray(fileList) &&
      fileList.length > 0 &&
      fileList[0].originFileObj
    ) {
      this.setState(
        {
          uploadingAttachmentIndex: attachmentIndex,
          uploadingVariationIndex: index,
        },
        () => {
          this.props.communicationUploadFileFetch(fileList[0].originFileObj);
          this.props.clearCommunicationMessageError(
            this.props.errorUpdateMessageSubmit.errors,
            'variations[' +
              [index] +
              '].attachments[' +
              [attachmentIndex] +
              '].file'
          );
        }
      );
    }
  };

  handleSelectImage = ({ fileList }, index, selector = null) => {
    if (
      Array.isArray(fileList) &&
      fileList.length > 0 &&
      fileList[0].originFileObj
    ) {
      this.setState({ uploadingImageVariationIndex: index }, () => {
        if (!isNil(selector)) {
          if (selector === 'icon') {
            this.setState({ uploadingWebPushIcon: true });
          } else if (selector === 'image') {
            this.setState({ uploadingWebPushImage: true });
          } else if (selector === 'badge') {
            this.setState({ uploadingWebPushBadge: true });
          }
        }
        this.props.communicationUploadFileFetch(fileList[0].originFileObj);
        this.props.clearCommunicationMessageError(
          this.props.errorUpdateMessageSubmit.errors,
          'variations[' + [index] + '].image'
        );
      });
    }
  };

  handleClickActiveVariation = (activeVariationIndex) => {
    this.setState({ activeVariationIndex });
  };

  handleChangeButtonsAttribute = (
    index,
    buttonName,
    buttonIndex,
    name,
    value
  ) => {
    const { variations } = this.state;
    const buttons = variations[index][buttonName].slice(0);
    buttons[buttonIndex][name] = value;
    this.handleChangeVariations(index, buttonName, buttons);
  };

  handleRemoveButtons = (index, buttonName, buttonIndex) => {
    const { variations } = this.state;
    const buttons = variations[index][buttonName].slice(0);
    buttons.splice(buttonIndex, 1);
    variations[index][buttonName] = buttons;
    if (buttons.length === 0 && buttonName === 'androidButtons') {
      variations[index].showAndroidPushButtons = false;
      this.setState({ variations });
    } else {
      this.setState({ variations });
    }
  };

  selectDragAndDropEditor = (index) => {
    if (
      this.state.variations[index].message &&
      this.state.selectedTemplateHtml ===
        this.state.variations[index].message &&
      this.state.selectedTemplateJson
    ) {
      this.setState({
        editCurrentTemplateVisible: true,
        canEditSelectedTemplate: true,
      });
    } else {
      this.props.emailTemplateListAllFetch();
      this.setState({ emailBodyType: 'drag', selectedEmailTemplate: null });
    }
  };

  selectTemplate = (id) => {
    this.props.emailTemplateToggleModal(false, id, true);
    this.setState({ templateModalVisible: true });
    this.props.clearCommunicationMessageError(
      this.props.errorUpdateMessageSubmit.errors,
      'variations[' + [this.state.activeVariationIndex] + '].message'
    );
  };

  showAndroidIosPreview = () => {
    return (
      this.props.currentAudience ||
      isNil(this.props.currentAudience.mobileDevice)
    );
  };

  selectPushType = (index, pushType) => {
    this.setState({ pushLayoutVisible: false }, () => {
      this.handleChangeVariations(index, 'pushType', pushType);
      if (pushType === 'TEXT') {
        this.handleChangeVariations(index, 'image', null);
      }
    });
  };

  selectInAppType = (index, layoutType) => {
    this.setState({ inAppLayoutVisible: false }, () => {
      this.handleChangeVariations(index, 'inAppLayoutType', layoutType);

      switch (layoutType) {
        case 'IMAGE_ONLY':
          this.handleChangeVariations(index, 'theme', {});
          this.handleChangeVariations(index, 'androidButton1Label', null);
          this.handleChangeVariations(
            index,
            'androidButton1OnClickAction',
            null
          );
          this.handleChangeVariations(index, 'androidButton2Label', null);
          this.handleChangeVariations(
            index,
            'androidButton2OnClickAction',
            null
          );
          this.handleChangeVariations(index, 'subject', 'Subject...');
          this.handleChangeVariations(index, 'message', 'Message...');
          break;
        case 'BANNER':
        case 'BANNER_RTL':
          this.handleChangeVariations(index, 'androidButton1Label', null);
          this.handleChangeVariations(
            index,
            'androidButton1OnClickAction',
            null
          );
          this.handleChangeVariations(index, 'androidButton2Label', null);
          this.handleChangeVariations(
            index,
            'androidButton2OnClickAction',
            null
          );
          break;
        case 'MODAL':
          this.handleChangeVariations(index, 'androidButton2Label', null);
          this.handleChangeVariations(
            index,
            'androidButton2OnClickAction',
            null
          );
          break;

        default:
          break;
      }
    });
  };

  selectOnSiteType = (index, onSiteLayoutType) => {
    this.handleChangeVariations(index, 'onSiteLayoutType', onSiteLayoutType);
  };

  cancelEditCurrentTemplate = () => {
    // this.props.emailEntityListAllFetch(this.props.allPageRequest);
    this.setState({
      editCurrentTemplateVisible: false,
      emailBodyType: 'drag',
      selectedEmailTemplate: null,
    });
  };

  continueEditCurrentTemplate = () => {
    if (this.state.selectedEmailTemplate) {
      this.setState({
        editCurrentTemplateVisible: false,
        canEditSelectedTemplate: true,
        templateModalVisible: true,
      });
    } else {
      this.setState({
        editCurrentTemplateVisible: false,
        canEditSelectedTemplate: true,
      });

      this.props.emailTemplateToggleModal(true, undefined, true);
    }
  };

  continueSelectTemplate = () => {
    this.setState({
      templateModalVisible: false,
      canEditSelectedTemplate: false,
    });

    this.props.emailTemplateToggleModal(true, undefined, true);
  };

  cancelSelectTemplateModal = () => {
    this.setState({
      emailBodyType: 'raw',
      templateModalVisible: false,
      canEditSelectedTemplate: false,
      selectedEmailTemplate: null,
    });
  };

  checkValidEmail = (value) => {
    if (!isEmpty(value) && !emailRegEx.test(value.trim())) {
      return 'error';
    }
    return null;
  };

  renderMobileNumber = (variationIndex) => {
    let result = '8758316578';
    const { variations } = this.state;
    if (
      variations[variationIndex] &&
      !isNil(variations[variationIndex].smsChannel)
    ) {
      const foundItem = lodashFind(
        this.props.smsChannels,
        (item) => item.id === variations[variationIndex].smsChannel
      );
      if (foundItem && !isNil(foundItem.name)) {
        result = foundItem.name;
      }
    }
    return result;
  };

  isEdited = () => {
    const variations = this.getVariation();
    if (
      (this.props.currentMessage.variations.length !== 0 &&
        this.props.currentMessage.variations.length < variations.length) ||
      this.props.currentMessage.variations.length > variations.length
    ) {
      return true;
    }

    let sourceKeys = [
      { key: 'id', defaultValue: undefined },
      { key: 'message', defaultValue: '' },
      { key: 'subject', defaultValue: '' },
      { key: 'percentage', defaultValue: '100' },
    ];
    if (this.channel === 'PUSH' || this.channel === 'WEB_PUSH') {
      let sourceKeysPush = [
        { key: 'pushType', defaultValue: 'TEXT' },
        { key: 'image', defaultValue: undefined },
        { key: 'androidOnClickAction', defaultValue: null },
        { key: 'androidButton1Label', defaultValue: null },
        { key: 'androidButton1OnClickAction', defaultValue: null },
        { key: 'androidButton2Label', defaultValue: null },
        { key: 'androidButton2OnClickAction', defaultValue: null },
        { key: 'keyValuePair', defaultValue: [] },
      ];
      let sourceKeysWebPush = [
        { key: 'icon', defaultValue: undefined },
        { key: 'badge', defaultValue: undefined },
        { key: 'autoHide', defaultValue: true },
      ];
      if (this.channel === 'PUSH') {
        sourceKeys = sourceKeys.concat(sourceKeysPush);
      } else if (this.channel === 'WEB_PUSH') {
        sourceKeys = sourceKeys.concat(sourceKeysPush, sourceKeysWebPush);
      }
    } else if (this.channel === 'SMS') {
      sourceKeys = sourceKeys.concat([
        { key: 'smsChannel', defaultValue: null },
      ]);
    } else if (this.channel === 'EMAIL') {
      sourceKeys = sourceKeys.concat([
        { key: 'emailChannel', defaultValue: null },
        { key: 'json', defaultValue: undefined },
        { key: 'fromName', defaultValue: undefined },
        { key: 'fromEmail', defaultValue: undefined },
        { key: 'replyTo', defaultValue: null },
        { key: 'cc', defaultValue: null },
        { key: 'bcc', defaultValue: null },
        { key: 'attachments', defaultValue: [] },
      ]);
    } else if (this.channel === 'CUSTOM') {
      sourceKeys = sourceKeys.concat([
        { key: 'customChannel', defaultValue: null },
        { key: 'icon', defaultValue: undefined },
        { key: 'image', defaultValue: undefined },
        { key: 'keyValuePair', defaultValue: [] },
        { key: 'attachments', defaultValue: [] },
      ]);
    } else if (this.channel === 'IN_APP') {
      sourceKeys = sourceKeys.concat([
        { key: 'inAppLayoutType', defaultValue: 'MODAL' },
        { key: 'theme', defaultValue: {} },
        { key: 'image', defaultValue: undefined },
        { key: 'androidButton1Label', defaultValue: null },
        { key: 'androidButton1OnClickAction', defaultValue: null },
      ]);
    } else if (this.channel === 'ON_SITE') {
      sourceKeys = sourceKeys.concat([
        { key: 'onSiteLayoutType', defaultValue: 'TEXT' },
        { key: 'label', defaultValue: '' },
        { key: 'couponCode', defaultValue: undefined },
        { key: 'htmlBody', defaultValue: undefined },
        { key: 'androidButton1Labe', defaultValue: null },
        { key: 'androidButton1OnClickAction', defaultValue: null },
        { key: 'theme', defaultValue: {} },
        { key: 'image', defaultValue: undefined },
      ]);
    }

    const changedCurrentMessage = this.props.currentMessage.variations.some(
      (sVar, idx) => {
        const res = sourceKeys.some((key) => {
          const src = this.props.currentMessage.variations[idx][key.key];
          const dest = variations[idx][key.key];
          var isChanged = false;
          var cond1 =
            (src === null || src === undefined) &&
            (dest === null || dest === undefined);
          var cond2 =
            (src === null || src === undefined) && dest === key.defaultValue;
          var cond3 = src == dest;
          var cond4 =
            isEqual(src, dest) &&
            (isArray(src) || isObject(src)) &&
            (isArray(dest) || isObject(dest));
          if (cond1 || cond2 || cond3 || cond4) isChanged = false;
          else isChanged = true;
          return isChanged;
        });
        return res;
      }
    );

    if (this.props.currentMessage.variations.length === 0) {
      const result = sourceKeys.some((key) => {
        const src = sourceKeys.filter((t) => t.key === key.key)[0].defaultValue;
        if (variations[0] !== undefined) {
          const dest = variations[0][key.key];
          var cond1 =
            (src === null || src === undefined) &&
            (dest === null || dest === undefined || dest === '' || src === '');
          var cond2 =
            (src === null || src === undefined) && dest === key.defaultValue;
          var cond3 = src == dest;
          var cond4 =
            isEqual(src, dest) &&
            (isArray(dest) || isArray(dest) || isObject(src) || isObject(src));
          var noChanged = cond1 || cond2 || cond3 || cond4;

          return !noChanged;
        }
        return false;
      });
      return result;
    }
    if (this.props.currentMessage.variations.length >= 1) {
      return changedCurrentMessage;
    }
  };

  renderSteps = () => {
    let steps = null;
    if (this.props.journey) {
      steps = this.props.journeySteps;
    }
    if (this.communicationHasJourneyType()) {
      steps = journeySteps;
    }
    if (this.props.currentMessage.type === 'TRANSACTIONAL') {
      steps = transactionalSteps;
    }
    return (
      <CommunicationStepsComponent
        step={'message'}
        isEdited={this.isEdited}
        campaignType={
          this.props.currentMessage && this.props.currentMessage.type
        }
        creationStep={
          this.props.currentMessage && this.props.currentMessage.creationStep
            ? this.props.currentMessage.creationStep
            : null
        }
        steps={steps}
        journey={this.props.journey}
        goToStepOnJourney={this.props.goToStepOnJourney}
        channel={this.channel}
      />
    );
  };

  renderUploader = (index, attachmentIndex) => {
    let showButton = false;
    const attachment =
      this.state.variations[index] &&
      this.state.variations[index].attachments &&
      Array.isArray(this.state.variations[index].attachments) &&
      this.state.variations[index].attachments[attachmentIndex] &&
      this.state.variations[index].attachments[attachmentIndex]
        ? this.state.variations[index].attachments[attachmentIndex]
        : null;
    if (this.channel === 'EMAIL' || this.channel === 'CUSTOM') {
      showButton =
        !!attachment &&
        (isNil(attachment.file) || lodashIsEmpty(attachment.file));
    }
    return (
      <Upload
        disabled={this.props.readOnly || this.isEditDisable()}
        fileList={attachment && attachment.file ? [attachment] : []}
        onChange={(object) =>
          this.handleSelectUpload(object, index, attachmentIndex)
        }
        onRemove={this.handleRemoveUploaded}
        beforeUpload={() => false}
        data-cy="upload-file"
      >
        {showButton && (
          <Button
            type="primary"
            shape="round"
            size={'small'}
            ghost
            disabled={this.props.readOnly || this.isEditDisable()}
            loading={
              this.props.loadingUploadFile &&
              attachmentIndex === this.state.uploadingAttachmentIndex
            }
            style={{
              position: 'relative',
              top: 11,
            }}
          >
            Upload
          </Button>
        )}
      </Upload>
    );
  };

  renderWebPushIconUploader = (selector, index) => {
    const acceptedImageUrlFormat = '.jpeg,.jpg,.png,.gif';
    let showButton = false;
    const image =
      this.state.variations[index] && this.state.variations[index][selector]
        ? this.state.variations[index][selector]
        : null;
    if (this.channel === 'WEB_PUSH' || this.channel === 'CUSTOM') {
      showButton = isNil(image);
    }
    if (showButton) {
      const props = {
        beforeUpload: (file) => {
          const validUrl =
            file.type.includes('png') ||
            file.type.includes('jpg') ||
            file.type.includes('jpeg');
          if (!validUrl) {
            message.error('Invalid Image URL');
          }
          return validUrl || Upload.LIST_IGNORE;
        },
        onChange: (info) => {
          console.log(info.fileList);
        },
      };
      return (
        <Upload
          {...props}
          accept={acceptedImageUrlFormat}
          disabled={this.isEditDisable()}
          fileList={image ? [image] : []}
          id={selector}
          key={selector}
          onChange={(object) => this.handleSelectImage(object, index, selector)}
          onRemove={() => this.handleRemoveUploaded(selector)}
        >
          <Button
            id={selector}
            key={selector}
            disabled={
              this.isEditDisable() ||
              this.state.variations[index]?.checkedDefaultLogo
            }
            type="primary"
            shape="round"
            size={'small'}
            ghost
            loading={
              selector === 'icon'
                ? this.state.uploadingWebPushIcon
                : this.state.uploadingWebPushImage
            }
            style={{
              top: -11,
            }}
          >
            Upload
          </Button>
        </Upload>
      );
    }
    return null;
  };

  renderPushUploader = (selector, index) => {
    const acceptedImageUrlFormat = '.jpeg,.jpg,.png,.gif';
    let showButton = false;
    const image =
      this.state.variations[index] && this.state.variations[index].image
        ? this.state.variations[index].image
        : null;
    if (
      this.channel === 'PUSH' ||
      this.channel === 'CUSTOM' ||
      this.channel === 'IN_APP' ||
      this.channel === 'ON_SITE'
    ) {
      showButton = isNil(image);
    }
    if (showButton) {
      return (
        <Upload
          accept={acceptedImageUrlFormat}
          disabled={this.isEditDisable()}
          fileList={image ? [image] : []}
          onChange={(object) => this.handleSelectImage(object, index, selector)}
          onRemove={this.handleRemoveUploaded}
        >
          <Button
            data-cy="upload-image"
            disabled={this.isEditDisable()}
            type="primary"
            shape="round"
            size={'small'}
            ghost
            loading={
              this.props.loadingUploadFile && this.state.uploadingWebPushImage
            }
            style={{
              // position: 'relative',
              top: -11,
            }}
          >
            Upload
          </Button>
        </Upload>
      );
    }
    return null;
  };

  renderProductTitle = () => {
    let pid = getProductId(this.props.pathname);
    return (
      (this.props?.userProducts &&
        this.props?.userProducts?.find((p) => p.id === pid)?.name) ||
      texts?.BrandName
    );
  };

  renderFooter = () => {
    let steps = null;
    if (this.props.journey) {
      steps = this.props.journeySteps;
    }
    if (this.communicationHasJourneyType()) {
      steps = journeySteps;
    }
    if (this.props.currentMessage.type === 'TRANSACTIONAL') {
      steps = transactionalSteps;
    }
    return (
      <CommunicationFooterComponent
        isEdited={this.isEdited}
        step={'message'}
        loadingNext={this.props.loadingUpdateMessageSubmit}
        steps={steps}
        journey={this.props.journey}
        readOnly={this.props.readOnly || this.isEditDisable()}
        handleClickNext={() => this.handleClickNext()}
        handleClickBack={() => this.handleClickBack()}
      />
    );
  };

  renderTemplateDetectedModal = () => {
    if (this.state.editCurrentTemplateVisible) {
      return (
        <Modal
          width={600}
          title={'HTML is detected for drag & drop parser'}
          centered
          visible={this.state.editCurrentTemplateVisible}
          onOk={() => this.continueEditCurrentTemplate()}
          onCancel={() => this.cancelEditCurrentTemplate()}
          okText={'Edit current data'}
          cancelText={'Start a fresh template'}
        >
          <div>
            <div>
              Would you like to edit current HTML data in drag & drop editor?
              Instead of edit current data, you can start with a fresh template
              that replace content in the email body.
            </div>
          </div>
        </Modal>
      );
    }
    return null;
  };

  renderTemplateModalVisible = () => {
    if (this.state.templateModalVisible) {
      return (
        <Modal
          width={600}
          title={
            this.props.currentTemplate && this.props.currentTemplate.name
              ? 'Select Email Template: ' + this.props.currentTemplate.name
              : 'Select Email Template'
          }
          centered
          visible={this.state.templateModalVisible}
          onOk={() => this.continueSelectTemplate()}
          onCancel={() => this.cancelSelectTemplateModal()}
          okText={'Continue'}
        >
          <div>
            {this.props.loadingViewTemplate ? (
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spin />
              </div>
            ) : (
              <div>
                Are you sure to select this email template? Current body will be
                replaced.
              </div>
            )}
          </div>
        </Modal>
      );
    }
  };

  renderPushModalVisible = (index) => {
    return (
      <Modal
        width={550}
        title={'Select a Layout'}
        centered
        footer={null}
        visible={this.state.pushLayoutVisible}
        onOk={() => this.setState({ pushLayoutVisible: false })}
        onCancel={() => this.setState({ pushLayoutVisible: false })}
      >
        <div style={styles.methodsContainer}>
          <div
            onClick={() => this.selectPushType(index, 'TEXT')}
            className="method-push-container"
            style={styles.methodContainer}
          >
            <div style={styles.methodTitle}>Text</div>
            <div>
              <img
                src={this.channel === 'PUSH' ? pushTextPng : webTextPng}
                style={{ maxWidth: '100%' }}
                alt=""
              />
            </div>
          </div>
          <div
            data-cy="push-layout-banner"
            onClick={() => this.selectPushType(index, 'BANNER')}
            className="method-push-container"
            style={styles.methodContainer}
          >
            <div style={styles.methodTitle}>Banner</div>
            <div>
              <img
                src={this.channel === 'PUSH' ? pushBannerPng : webBannerPng}
                style={{ maxWidth: '100%' }}
                alt=""
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  renderWhatsAppModalVisible = (index) => {
    const columns = [
      {
        title: 'TEMPLATE NAME',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'TEMPLATE TEXT',
        dataIndex: 'bodyText',
        key: 'bodyText',
        render: (x) => (
          <div
            style={{
              display: 'inline-block',
              width: '300px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {x}
          </div>
        ),
        width: 300,
      },
      {
        title: 'TEMPLATE TYPE',
        dataIndex: 'headerType',
        key: 'headerType',
      },
      {
        title: 'WSP',
        dataIndex: ['whatsAppChannel', 'name'],
        key: 'wsp',
      },
    ];

    return (
      <Modal
        width={'70%'}
        title={'Select a Layout'}
        centered
        footer={null}
        visible={this.state.whatsAppLayoutVisible}
        onOk={() => this.setState({ whatsAppLayoutVisible: false })}
        onCancel={() => this.setState({ whatsAppLayoutVisible: false })}
      >
        <ListBase
          page={this.props.whatsAppTemplate}
          columns={columns}
          pageRequest={this.props.whatsAppTemplatePageRequest}
          onChange={this.props.whatsappChannelTemplateListFetch}
          onClick={(row) => {
            this.setState({ whatsAppLayoutVisible: false });
            this.props.whatsappChannelTemplateViewChange(index, row);
            this.handleChangeVariations(index, 'whatsAppTemplate', row.id);
            this.handleChangeVariations(index, 'whatsAppVariables', {});
            this.handleChangeVariations(index, 'dynamicUrlSuffix', '');
          }}
        />
      </Modal>
    );
  };

  renderEmailTemplateEditor = (index) => {
    return (
      <EmailTemplareModal
        onDone={(data) => {
          this.props.emailTemplateListAllFetch();
          const { design, html } = data;
          const { variations } = this.state;
          variations[index]['message'] = html;
          variations[index]['json'] = JSON.stringify(design);
          this.setState({
            selectedEmailTemplate: null,
            emailBodyType: 'raw',
            selectedTemplateHtml: html,
            selectedTemplateJson: JSON.stringify(design),
            variations,
          });
        }}
        onCancel={() => {
          this.setState({
            emailBodyType: 'raw',
          });
        }}
      />
    );
  };

  renderEmailPreviewModal = () => {
    if (this.state.emailPreviewVisible) {
      return (
        <Modal
          title={'Email Preview'}
          width={900}
          bodyStyle={{ height: 450, padding: 0 }}
          centered
          visible={this.state.emailPreviewVisible}
          footer={null}
          onCancel={() => this.cancelEmailPreview()}
          data-cy="preview-modal"
        >
          <div id="emailPreview" style={{ height: 450, overflow: 'scroll' }}>
            {this.state.emailHtml}
          </div>
        </Modal>
      );
    }
  };

  inputGenerator = (type, index) => {
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;
    const unSubLinkText = '<a href="{{unsubscribe}}" >Unsubscribe Here</a>';
    const productId = this.props.match?.params?.productId;
    const currentProduct = this.props?.userProducts?.find(
      (p) => p.id === +productId
    );
    switch (type) {
      case 'subject':
        return (
          <div style={styles.editContainer}>
            <div style={styles.editLabel}>SUBJECT</div>
            <div
              style={{ ...styles.editFieldContainer, flex: 3, marginLeft: 20 }}
            >
              <Form.Item
                validateStatus={
                  this.channel !== 'CUSTOM'
                    ? errorHandling(
                        this.props.errorUpdateMessageSubmit,
                        'variations[' + [index] + '].subject'
                      )
                      ? 'error'
                      : null
                    : undefined
                }
                help={
                  this.channel !== 'CUSTOM'
                    ? errorHandling(
                        this.props.errorUpdateMessageSubmit,
                        'variations[' + [index] + '].subject'
                      )
                    : undefined
                }
                id={
                  errorHandling(
                    this.props.errorUpdateMessageSubmit,
                    'variations[' + [index] + '].subject'
                  )
                    ? 'error'
                    : null
                }
                // name={`variations[${index}].subject`}
                // initialValue={
                //   this.state.variations[index] &&
                //   this.state.variations[index].subject
                //     ? this.state.variations[index].subject
                //     : ''
                // }
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                <InputSelector
                  type="richInline"
                  index={index}
                  id={'variations[' + [index] + '].subject'}
                  dataCy="subject"
                  journeySteps={this.props.journeyStepsForVariable}
                  disabled={this.props.readOnly || this.isEditDisable()}
                  value={
                    this.state.variations[index] &&
                    this.state.variations[index].subject
                      ? this.state.variations[index].subject
                      : ''
                  }
                  onChange={(value) =>
                    this.handleChangeVariations(index, 'subject', value)
                  }
                  attributes={this.props.attributes}
                  events={this.props.events}
                  currentType={this.props.currentMessage.type}
                  currentEvent={this.props.currentMessage.event}
                  businessEvents={this.props.businessEvents}
                  size="middle"
                  style={{ ...styles.editField, width: '90%' }}
                  attributeStyle={{
                    position: 'relative',
                    top: '5px',
                    right: '-483px',
                  }}
                  emojiStyle={{ position: 'relative', top: 1, left: 312 }}
                />
              </Form.Item>
            </div>
          </div>
        );
      case 'preheader':
        return (
          <div style={styles.editContainer}>
            <div style={styles.editLabel}>
              PRE HEADER
              <Tooltip
                placement="bottom"
                title={`Email preheader text is what many mobile, desktop, and web email clients show readers to give them an idea of what’s inside the message before they actually open it.
                  If you input preheader text, we will convert your body to HTML (if it is not already in HTML)
                  `}
              >
                <span style={{ display: 'inline-block', marginLeft: '1em' }}>
                  <InfoCircleOutlined />
                </span>
              </Tooltip>
            </div>
            <div
              style={{ ...styles.editFieldContainer, flex: 3, marginLeft: 20 }}
            >
              <Form.Item
                validateStatus={
                  this.state.variations[index].preheader &&
                  this.state.variations[index].message.indexOf('<html') === -1
                    ? 'warning'
                    : null
                }
                help={
                  this.state.variations[index].preheader &&
                  this.state.variations[index].message.indexOf('<html') === -1
                    ? 'If you input preheader text, we will convert your message body to HTML'
                    : ''
                }
              >
                <InputSelector
                  id={'variations[' + [index] + '].preheader'}
                  dataCy="preheader"
                  journeySteps={this.props.journeyStepsForVariable}
                  disabled={this.props.readOnly || this.isEditDisable()}
                  value={
                    this.state.variations[index] &&
                    this.state.variations[index].preheader
                      ? this.state.variations[index].preheader
                      : ''
                  }
                  onChange={(value) =>
                    this.handleChangeVariations(index, 'preheader', value)
                  }
                  attributes={this.props.attributes}
                  events={this.props.events}
                  currentType={this.props.currentMessage.type}
                  currentEvent={this.props.currentMessage.event}
                  businessEvents={this.props.businessEvents}
                  size="middle"
                  style={{ ...styles.editField, width: '90%' }}
                  attributeStyle={{
                    position: 'relative',
                    top: '5px',
                    right: '-483px',
                  }}
                  emojiStyle={{ position: 'relative', top: 1, left: 312 }}
                />
              </Form.Item>
            </div>
          </div>
        );
      case 'body':
        return (
          <div>
            <div style={{ ...styles.editContainer, marginTop: 20 }}>
              <div style={styles.editLabel}>BODY</div>
              <div
                style={{
                  ...styles.editFieldContainer,
                  marginLeft: 30,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginRight: 59,
                }}
              >
                <Radio.Group
                  disabled={this.props.readOnly || this.isEditDisable()}
                  onChange={(e) => {
                    if (e.target.value === 'drag') {
                      this.selectDragAndDropEditor(index);
                    } else {
                      this.setState({ emailBodyType: e.target.value });
                    }
                  }}
                  value={this.state.emailBodyType}
                >
                  <Radio data-cy="raw-html" value={'raw'}>
                    Raw HTML
                  </Radio>
                  <Radio data-cy="drag-drop-editor" value={'drag'}>
                    Drag & Drop Editor
                  </Radio>
                </Radio.Group>
                <Button
                  type="primary"
                  shape="round"
                  size={'large'}
                  style={styles.previewButton}
                  ghost
                  onClick={() => this.openEmailPreview(index)}
                  data-cy="see-raw-preview"
                >
                  SEE RAW PREVIEW
                </Button>
              </div>
            </div>
            {this.state.emailBodyType === 'raw' && (
              <div style={{ ...styles.editContainer, marginTop: 0 }}>
                <div style={{ ...styles.editLabel }}></div>
                <div
                  style={{
                    ...styles.editFieldContainer,
                    marginLeft: 20,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ flex: 0.7 }}>
                    <Form.Item
                      validateStatus={
                        errorHandling(
                          this.props.errorUpdateMessageSubmit,
                          'variations[' + [index] + '].message'
                        )
                          ? 'error'
                          : null
                      }
                      help={errorHandling(
                        this.props.errorUpdateMessageSubmit,
                        'variations[' + [index] + '].message'
                      )}
                      style={{ ...styles.editField, width: 800 }}
                    >
                      <InputSelector
                        ref={(emailEditor) => (this.emailEditor = emailEditor)}
                        id="email-editor"
                        // id={
                        //   errorHandling(
                        //     this.props.errorUpdateMessageSubmit,
                        //     'variations[' + [index] + '].message'
                        //   )
                        //     ? 'error'
                        //     : null
                        // }
                        dataCy="email-body-text"
                        journeySteps={this.props.journeyStepsForVariable}
                        disabled={this.props.readOnly || this.isEditDisable()}
                        attributeStyle={{
                          position: 'absolute',
                          top: '49px',
                          right: '195px',
                          zIndex: 20,
                        }}
                        attributes={this.props.attributes}
                        events={this.props.events}
                        currentType={this.props.currentMessage.type}
                        currentEvent={this.props.currentMessage.event}
                        businessEvents={this.props.businessEvents}
                        value={
                          this.state.variations[index] &&
                          this.state.variations[index].message
                            ? this.state.variations[index].message
                            : ''
                        }
                        hideEmojiPicker={false}
                        placeholder={''}
                        className={'not-resizable'}
                        textArea={true}
                        onChange={(value) =>
                          this.handleChangeVariations(index, 'message', value)
                        }
                        style={{ ...styles.editField, width: 800, height: 250 }}
                        autoSize={{ maxRows: 25, minRows: 25 }}
                        rows={25}
                        size="large"
                      />
                    </Form.Item>
                    {!this.isEditDisable() && (
                      <Button
                        type="primary"
                        shape="round"
                        size={'large'}
                        style={{ ...styles.previewButton }}
                        ghost
                        onClick={() =>
                          this.emailEditor.appendMessage(unSubLinkText, true)
                        }
                        data-cy="insert-unsubscribe-link"
                      >
                        INSERT UNSUBSCRIBE LINK
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
            {this.state.emailBodyType === 'drag' && (
              <div style={{ ...styles.editContainer, marginTop: 20 }}>
                <div style={{ ...styles.editLabel }}>Select Template</div>
                <div
                  style={{
                    ...styles.editFieldContainer,
                    marginLeft: 20,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {this.props.loadingListAllTemplate ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Spin indicator={inputLoadingSpin} />
                    </div>
                  ) : (
                    <React.Fragment>
                      <Form.Item
                        validateStatus={
                          errorHandling(
                            this.props.errorUpdateMessageSubmit,
                            'variations[' + [index] + '].message'
                          )
                            ? 'error'
                            : null
                        }
                        help={errorHandling(
                          this.props.errorUpdateMessageSubmit,
                          'variations[' + [index] + '].message'
                        )}
                        id={
                          errorHandling(
                            this.props.errorUpdateMessageSubmit,
                            'variations[' + [index] + '].message'
                          )
                            ? 'error'
                            : null
                        }
                      >
                        <Select
                          id={
                            errorHandling(
                              this.props.errorUpdateMessageSubmit,
                              'variations[' + [index] + '].message'
                            )
                              ? 'error'
                              : null
                          }
                          data-cy="select-template"
                          disabled={this.props.readOnly || this.isEditDisable()}
                          showSearch
                          dropdownMenuStyle={{ maxHeight: 150 }}
                          style={styles.editField}
                          size="large"
                          value={
                            this.state.selectedEmailTemplate &&
                            this.state.selectedEmailTemplate.id
                              ? this.state.selectedEmailTemplate.id
                              : null
                          }
                          onChange={(id) => this.selectTemplate(id)}
                          filterOption={(input, option) => {
                            return (
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          <Select.Option value={null}>-</Select.Option>
                          {this.props.listAllTemplate.map((ua) => (
                            <Select.Option value={ua.id} key={ua.id}>
                              {ua.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </React.Fragment>
                  )}
                  <Button
                    style={{ height: 40, margin: '0 0 23px 23px' }}
                    onClick={() => {
                      this.props.emailTemplateChangeCurrent({});
                      this.props.emailTemplateToggleModal(true, null, false);
                    }}
                  >
                    NEW TEMPLATE
                  </Button>
                </div>
              </div>
            )}
          </div>
        );
      case 'attachments':
        return (
          <Card styles={{ marginTop: '10px' }}>
            <div style={styles.infoBoxHeader}>
              <div style={styles.headTitleContainer}>
                <span>Attachments</span>
              </div>
            </div>
            <div style={{ padding: 30, backgroundColor: 'white' }}>
              {this.state.variations[index] &&
                this.state.variations[index].attachments &&
                Array.isArray(this.state.variations[index].attachments) &&
                this.state.variations[index].attachments.map(
                  (attachmentItem, attachmentIndex) => (
                    <div
                      key={attachmentIndex}
                      style={{ ...styles.editContainer }}
                    >
                      <div
                        style={{
                          ...styles.editLabel,
                          position: 'relative',
                          top: -2,
                        }}
                      >
                        {'ATTACHMENT ' + (Number(attachmentIndex) + 1)}
                      </div>
                      <div
                        style={{ ...styles.editFieldContainer, marginLeft: 20 }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <Form.Item
                            validateStatus={
                              errorHandling(
                                this.props.errorUpdateMessageSubmit,
                                'variations[' +
                                  [index] +
                                  '].attachments[' +
                                  [attachmentIndex] +
                                  '].file'
                              )
                                ? 'error'
                                : null
                            }
                            help={errorHandling(
                              this.props.errorUpdateMessageSubmit,
                              'variations[' +
                                [index] +
                                '].attachments[' +
                                [attachmentIndex] +
                                '].file'
                            )}
                            id={
                              errorHandling(
                                this.props.errorUpdateMessageSubmit,
                                'variations[' +
                                  [index] +
                                  '].attachments[' +
                                  [attachmentIndex] +
                                  '].file'
                              )
                                ? 'error'
                                : null
                            }
                            style={{ width: '100%' }}
                          >
                            {' '}
                            <InputSelector
                              type="richInline"
                              index={index}
                              id={
                                'variations[' +
                                [index] +
                                '].attachments[' +
                                [attachmentIndex] +
                                ']'
                              }
                              dataCy="attachment-file"
                              disabled={
                                this.props.readOnly || this.isEditDisable()
                              }
                              attributeStyle={{ top: '30px', right: '210px' }}
                              hideEmojiPicker
                              attributes={this.props.attributes}
                              events={this.props.events}
                              currentType={this.props.currentMessage.type}
                              currentEvent={this.props.currentMessage.event}
                              journeySteps={this.props.journeyStepsForVariable}
                              businessEvents={this.props.businessEvents}
                              placeholder={'https://www.abc.com/document.pdf'}
                              value={
                                attachmentItem && attachmentItem.file
                                  ? attachmentItem.file
                                  : ''
                              }
                              onChange={(value) =>
                                this.changeEmailAttachment(
                                  index,
                                  attachmentIndex,
                                  value
                                )
                              }
                              size="middle"
                              style={{ ...styles.editField, width: 300 }}
                            />
                          </Form.Item>
                          {attachmentItem.file ? (
                            <Button
                              disabled={
                                this.props.readOnly || this.isEditDisable()
                              }
                              type="primary"
                              shape="round"
                              ghost
                              onClick={() =>
                                this.changeEmailAttachment(
                                  index,
                                  attachmentIndex,
                                  null
                                )
                              }
                              size={'small'}
                              style={{
                                position: 'realtive',
                                top: 9,
                                marginLeft: 15,
                              }}
                            >
                              Reset
                            </Button>
                          ) : (
                            <React.Fragment>
                              <span
                                style={{
                                  // alignSelf: 'center',
                                  paddingLeft: 15,
                                  paddingRight: 15,
                                  color: 'rgb(61, 64, 78)',
                                  fontSize: 13,
                                  lineHeight: '18.59px',
                                  marginTop: '15px',
                                }}
                              >
                                or
                              </span>
                              {this.renderUploader(index, attachmentIndex)}
                            </React.Fragment>
                          )}
                          {!this.isEditDisable() && (
                            <div
                              style={{
                                paddingLeft: 20,
                                position: 'relative',
                                top: 12,
                              }}
                            >
                              <Popconfirm
                                title="Are you sure to delete?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={(e) =>
                                  this.confirmDeleteAttachment(
                                    e,
                                    index,
                                    attachmentIndex
                                  )
                                }
                                onCancel={(e) =>
                                  this.cancel(e, index, attachmentIndex)
                                }
                              >
                                <MinusCircleOutlined
                                  style={{ color: '#bbbcc1', fontSize: 20 }}
                                />
                              </Popconfirm>
                            </div>
                          )}
                        </div>
                        <span
                          style={{
                            color: '#94969e',
                            fontSize: '10px',
                            paddingLeft: '5px',
                            paddingTop: '5px',
                            display: 'block',
                          }}
                        >
                          {'Maximum file size: 10 MB'}
                        </span>
                      </div>
                    </div>
                  )
                )}
              <div style={{ ...styles.editContainer }}>
                <div
                  style={{
                    ...styles.editFieldContainer,
                    marginLeft: 15,
                    marginTop: 20,
                    alignItems: 'flex-start',
                  }}
                >
                  <Button
                    disabled={this.props.readOnly || this.isEditDisable()}
                    type="primary"
                    shape="round"
                    size={'large'}
                    ghost
                    style={styles.addNewSlotButton}
                    onClick={() => this.addEmailAttachment(index)}
                    data-cy="add-attachment"
                  >
                    {this.state.variations[index] &&
                    this.state.variations[index].attachments &&
                    Array.isArray(this.state.variations[index].attachments) &&
                    this.state.variations[index].attachments.length > 0
                      ? 'Add ANOTHER ATTACHMENT'
                      : 'ADD ATTACHMENT'}
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        );
      case 'image':
        return (
          <div style={styles.editContainer}>
            <div style={styles.editLabel}>IMAGE</div>
            <div
              style={{
                marginLeft: 20,
                flex: 3,
                fontSize: 13,
                lineHeight: 1.43,
                color: '#3d404e',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Form.Item
                  style={{ margin: 0, width: '100%' }}
                  validateStatus={
                    errorHandling(
                      this.props.errorUpdateMessageSubmit,
                      'variations[' + [index] + '].image'
                    )
                      ? 'error'
                      : this.state.imageUrlFormatError
                      ? 'error'
                      : null
                  }
                  help={
                    !isNil(
                      errorHandling(
                        this.props.errorUpdateMessageSubmit,
                        'variations[' + [index] + '].image'
                      )
                    )
                      ? errorHandling(
                          this.props.errorUpdateMessageSubmit,
                          'variations[' + [index] + '].image'
                        )
                      : this.state.imageUrlFormatError
                      ? 'Incorrect image url'
                      : null
                  }
                  id={
                    errorHandling(
                      this.props.errorUpdateMessageSubmit,
                      'variations[' + [index] + '].image'
                    )
                      ? 'error'
                      : this.state.imageUrlFormatError
                      ? 'error'
                      : null
                  }
                >
                  <InputSelector
                    type="richInline"
                    index={index}
                    dataCy="image-url"
                    id={'variations[' + [index] + '].image'}
                    disabled={this.isEditDisable()}
                    attributeStyle={{ top: '30px', right: '0px' }}
                    attributes={this.props.attributes}
                    events={this.props.events}
                    currentType={this.props.currentMessage.type}
                    currentEvent={this.props.currentMessage.event}
                    journeySteps={this.props.journeyStepsForVariable}
                    businessEvents={this.props.businessEvents}
                    hideEmojiPicker
                    placeholder="https://www.abc.com/image.png"
                    value={
                      this.state.variations[index] &&
                      this.state.variations[index].image
                        ? this.state.variations[index].image
                        : ''
                    }
                    onChange={(value) =>
                      this.handleChangeVariations(index, 'image', value)
                    }
                    size="large"
                    style={{ ...styles.editField, width: '100%' }}
                  />
                </Form.Item>
                {!isNil(this.state.variations[index].image) &&
                this.state.variations[index].image.length > 0 ? (
                  <Button
                    disabled={this.isEditDisable()}
                    type="primary"
                    shape="round"
                    ghost
                    onClick={() =>
                      this.handleChangeVariations(index, 'image', null)
                    }
                    size={'small'}
                    style={{
                      marginLeft: 15,
                    }}
                  >
                    Reset
                  </Button>
                ) : (
                  <React.Fragment>
                    <span style={{ margin: '-27px 10px 0 10px' }}>or</span>
                    {this.renderPushUploader('image', index)}
                  </React.Fragment>
                )}
              </div>
              <div
                style={{
                  color: '#94969e',
                  fontSize: '10px',
                  paddingLeft: '5px',
                  paddingTop: '5px',
                  display: 'block',
                }}
              >
                {
                  'Recommended size: 1080px x 540px. Aspect ratio (width:height): 2:1. Actual rendering depends on screen size. JPG, JPEG, PNG formats only. GIF format also available but for iOS 10+ only. Less than 1 MB in size. URL must begin with HTTPS.'
                }
              </div>
            </div>
          </div>
        );
      case 'icon':
        return (
          <div style={styles.editContainer}>
            <div style={styles.editLabel}>ICON</div>
            <div
              style={{
                marginLeft: 20,
                flex: 3,
                fontSize: 13,
                lineHeight: 1.43,
                color: '#3d404e',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Form.Item
                  style={{ width: '100%' }}
                  validateStatus={
                    this.checkValidImageUrl(this.state.variations[index].icon)
                      ? null
                      : 'warning'
                  }
                  // help={
                  //   this.checkValidImageUrl(this.state.variations[index].icon)
                  //     ? null
                  //     : 'Invalid Image URL'
                  // }
                  disabled={this.state.variations[index].checkedDefaultLogo}
                >
                  <InputSelector
                    type="richInline"
                    index={index}
                    disabled={
                      this.isEditDisable() ||
                      this.state.variations[index]?.checkedDefaultLogo
                    }
                    attributeStyle={{
                      position: 'relative',
                      top: '30px',
                      right: '210px',
                    }}
                    id={'variations[' + [index] + '].icon'}
                    attributes={this.props.attributes}
                    events={this.props.events}
                    currentType={this.props.currentMessage.type}
                    currentEvent={this.props.currentMessage.event}
                    journeySteps={this.props.journeyStepsForVariable}
                    businessEvents={this.props.businessEvents}
                    hideEmojiPicker
                    placeholder="https://www.abc.com/image.png"
                    value={
                      this.state.variations[index] &&
                      this.state.variations[index].icon
                        ? this.state.variations[index].icon
                        : ''
                    }
                    onChange={(value) => {
                      this.handleChangeVariations(index, 'icon', value);
                    }}
                    size="large"
                    style={{ ...styles.editField, width: '100%' }}
                    onChangeButton={
                      this.state.variations[index].checkedDefaultLogo
                    }
                  />
                </Form.Item>
                {this.state.variations[index] &&
                !isNil(this.state.variations[index].icon) &&
                this.state.variations[index].icon.length > 0 ? (
                  <Button
                    disabled={
                      this.isEditDisable() ||
                      this.state.variations[index]?.checkedDefaultLogo
                    }
                    type="primary"
                    shape="round"
                    ghost
                    onClick={() =>
                      this.handleChangeVariations(index, 'icon', null)
                    }
                    size={'small'}
                    style={{
                      position: 'realtive',
                      top: 13,

                      marginLeft: 15,
                    }}
                  >
                    Reset
                  </Button>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ margin: '-27px 10px 0 10px' }}>or</span>
                    {this.renderWebPushIconUploader('icon', index)}
                  </div>
                )}
              </div>

              <div
                style={{
                  color: '#94969e',
                  fontSize: '10px',
                  paddingLeft: '5px',
                  paddingTop: '5px',
                  display: 'block',
                }}
              >
                {
                  'Recommended size: 192px x 192px. JPG, JPEG, PNG formats only. Less than 1 MB in size. URL must begin with HTTPS'
                }
              </div>
              {currentProduct?.defaultPushIcon &&
                !isEmpty(currentProduct?.defaultPushIcon) && (
                  <Checkbox
                    disabled={this.isEditDisable()}
                    style={{
                      fontSize: 12,
                      textTransform: 'capitalize',
                      fontWeight: 'normal',
                    }}
                    checked={this.state.variations[index].checkedDefaultLogo}
                    onChange={(e) => {
                      if (e.target.checked) {
                        this.handleChangeVariations(
                          index,
                          'icon',
                          currentProduct?.defaultPushIcon
                        );
                      }
                      this.handleChangeVariations(
                        index,
                        'checkedDefaultLogo',
                        e.target.checked
                      );
                    }}
                  >
                    Use Default Logo
                  </Checkbox>
                )}
            </div>
          </div>
        );
      case 'message':
        return (
          <div style={styles.editContainer}>
            <div style={styles.editLabel}>MESSAGE</div>
            <div
              style={{ ...styles.editFieldContainer, marginLeft: 20, flex: 3 }}
            >
              <Form.Item
                data-cy="push-message"
                validateStatus={
                  this.channel !== 'CUSTOM'
                    ? errorHandling(
                        this.props.errorUpdateMessageSubmit,
                        'variations[' + [index] + '].message'
                      )
                      ? 'error'
                      : null
                    : undefined
                }
                help={
                  this.channel !== 'CUSTOM'
                    ? errorHandling(
                        this.props.errorUpdateMessageSubmit,
                        'variations[' + [index] + '].message'
                      )
                      ? 'error'
                      : null
                    : undefined
                }
                id={
                  errorHandling(
                    this.props.errorUpdateMessageSubmit,
                    'variations[' + [index] + '].message'
                  )
                    ? 'error'
                    : null
                }
                // name="message"
                // name={`variations[${index}].message`}
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                <InputSelector
                  type="richTextArea"
                  index={index}
                  id={
                    errorHandling(
                      this.props.errorUpdateMessageSubmit,
                      'variations[' + [index] + '].message'
                    )
                      ? 'error'
                      : null
                  }
                  journeySteps={this.props.journeyStepsForVariable}
                  disabled={this.isEditDisable()}
                  textArea
                  attributeStyle={{
                    position: 'relative',
                    top: '-7px',
                    right: '150px',
                  }}
                  className={'not-resizable'}
                  currentType={this.props.currentMessage.type}
                  currentEvent={this.props.currentMessage.event}
                  attributes={this.props.attributes}
                  events={this.props.events}
                  businessEvents={this.props.businessEvents}
                  style={{
                    paddingRight: 58,
                    color: 'rgb(61, 64, 78)',
                    fontFamily: 'monospace',
                    fontSize: 13,
                    fontWeight: 400,
                    width: '100%',
                  }}
                  autoSize={{ maxRows: 3, minRows: 3 }}
                  rows={3}
                  value={
                    this.state.variations[index] &&
                    this.state.variations[index].message
                      ? this.state.variations[index].message
                      : ''
                  }
                  onChange={(value) =>
                    this.handleChangeVariations(index, 'message', value)
                  }
                  size="large"
                />
              </Form.Item>
              <span
                style={{
                  color: '#94969e',
                  fontSize: '10px',
                  paddingLeft: '5px',
                  paddingTop: '5px',
                  display: 'block',
                }}
              >
                {'Recommended maximum length: ' +
                  (this.state.variations[index] &&
                  this.state.variations[index].pushType === 'BANNER'
                    ? 36
                    : 180) +
                  ' characters. Current count:' +
                  (this.state.variations[index] &&
                  this.state.variations[index].message
                    ? this.state.variations[index].message.length
                    : 0)}
              </span>
            </div>
          </div>
        );

      case 'rich-message':
        return (
          <div style={styles.editContainer}>
            <div style={styles.editLabel}>MESSAGE</div>
            <div
              style={{
                ...styles.editFieldContainer,
                marginLeft: 20,
                flex: 3,
                maxWidth: '78%',
              }}
            >
              <Form.Item
                data-cy="push-message"
                validateStatus={
                  this.channel !== 'CUSTOM'
                    ? errorHandling(
                        this.props.errorUpdateMessageSubmit,
                        'variations[' + [index] + '].message'
                      )
                      ? 'error'
                      : null
                    : undefined
                }
                help={
                  this.channel !== 'CUSTOM'
                    ? errorHandling(
                        this.props.errorUpdateMessageSubmit,
                        'variations[' + [index] + '].message'
                      )
                      ? 'error'
                      : null
                    : undefined
                }
                id={
                  errorHandling(
                    this.props.errorUpdateMessageSubmit,
                    'variations[' + [index] + '].message'
                  )
                    ? 'error'
                    : null
                }
                // name="message"
                // name={`variations[${index}].message`}
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                <InputSelector
                  isRich={true}
                  type="rich"
                  index={index}
                  id={
                    errorHandling(
                      this.props.errorUpdateMessageSubmit,
                      'variations[' + [index] + '].message'
                    )
                      ? 'error'
                      : null
                  }
                  journeySteps={this.props.journeyStepsForVariable}
                  disabled={this.isEditDisable()}
                  textArea
                  attributeStyle={{
                    position: 'relative',
                    top: '-7px',
                    right: '150px',
                  }}
                  className={'not-resizable'}
                  attributes={this.props.attributes}
                  events={this.props.events}
                  currentType={this.props.currentMessage.type}
                  currentEvent={this.props.currentMessage.event}
                  businessEvents={this.props.businessEvents}
                  style={{
                    paddingRight: 58,
                    color: 'rgb(61, 64, 78)',
                    fontFamily: 'monospace',
                    fontSize: 13,
                    fontWeight: 400,
                    width: '100%',
                  }}
                  autoSize={{ maxRows: 3, minRows: 3 }}
                  rows={3}
                  value={
                    this.state.variations[index] &&
                    this.state.variations[index].message
                      ? this.state.variations[index].message
                      : ''
                  }
                  onChange={(value) =>
                    this.handleChangeVariations(index, 'message', value)
                  }
                  size="large"
                />
              </Form.Item>
              <span
                style={{
                  color: '#94969e',
                  fontSize: '10px',
                  paddingLeft: '5px',
                  paddingTop: '5px',
                  display: 'block',
                }}
              >
                {'Recommended maximum length: ' +
                  (this.state.variations[index] &&
                  this.state.variations[index].pushType === 'BANNER'
                    ? 36
                    : 180) +
                  ' characters. Current count:' +
                  (this.state.variations[index] &&
                  this.state.variations[index].message
                    ? this.state.variations[index].message
                        .replace(/<[^>]*>?/gm, '')
                        .replaceAll('&nbsp;', ' ').length
                    : 0)}
              </span>
            </div>
          </div>
        );
      default:
        return '';
    }
  };

  renderEmailSection = (index) => {
    if (this.channel === 'EMAIL') {
      return (
        <React.Fragment>
          <Form layout="vertical">
            <div style={styles.formItem}>
              {this.props.currentMessage.type === 'TRANSACTIONAL' && (
                <div
                  style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    border: '1px solid #F1F1F3',
                    backgroundColor: 'white',
                    marginBottom: 40,
                  }}
                >
                  <div
                    style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}
                  >
                    <div style={styles.headTitleContainer}>
                      Personalizing Content in Transactional Campaigns
                    </div>
                  </div>
                  <div style={{ ...styles.dataRowContainer, paddingBottom: 0 }}>
                    You can use any variable to personalize any of the fields in
                    the message below. Eg. To include order_id in the order
                    confirmation message, please specify the order_id variable
                    in the message as <span>{'{{token.order_id }}'}</span> .
                    Please also ensure that your variables are prefixed with the
                    keyword token. Once you launch this campaign, send the token
                    as token: <span>{'{ "order_id" : "abc123" }'}</span> in the{' '}
                    <a href={texts.brandName} target={'blank'}>
                      transactional campaign API call{' '}
                    </a>{' '}
                    for each order confirmation, so that the message gets
                    personalized.
                  </div>
                  <div style={styles.dataRowContainer}>
                    Please refer to our{' '}
                    <a href={texts.campaignApi} target={'blank'}>
                      documentation
                    </a>{' '}
                    for more details on transactional campaigns.
                  </div>
                </div>
              )}
              <Card>
                <div style={styles.infoBoxHeader}>
                  <div style={styles.headTitleContainer}>
                    <span>Service Provider</span>
                  </div>
                </div>
                <div style={{ padding: 30, backgroundColor: 'white' }}>
                  <div style={{ ...styles.editContainer }}>
                    <div style={{ ...styles.editLabel }}>{'ESP'}</div>
                    <div
                      style={{ ...styles.editFieldContainer, marginLeft: 20 }}
                    >
                      <Form.Item
                        validateStatus={
                          errorHandling(
                            this.props.errorUpdateMessageSubmit,
                            'variations[' + [index] + '].emailChannel'
                          )
                            ? 'error'
                            : null
                        }
                        help={errorHandling(
                          this.props.errorUpdateMessageSubmit,
                          'variations[' + [index] + '].emailChannel'
                        )}
                        id={
                          errorHandling(
                            this.props.errorUpdateMessageSubmit,
                            'variations[' + [index] + '].emailChannel'
                          )
                            ? 'error'
                            : null
                        }
                      >
                        <Select
                          id={
                            errorHandling(
                              this.props.errorUpdateMessageSubmit,
                              'variations[' + [index] + '].emailChannel'
                            )
                              ? 'error'
                              : null
                          }
                          data-cy="email-channel"
                          style={styles.editField}
                          loading={this.props.loadingEmailChannels}
                          disabled={this.props.readOnly || this.isEditDisable()}
                          value={this.channelValue(index, 'emailChannel')}
                          dropdownMenuStyle={{ maxHeight: 150 }}
                          size="large"
                          onChange={(value) =>
                            this.handleChangeVariations(
                              index,
                              'emailChannel',
                              value
                            )
                          }
                        >
                          {this.props.loadingEmailChannels
                            ? null
                            : this.renderServiceProviderOptions(
                                this.props.emailChannels
                              )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div style={styles.formItem}>
              <Card>
                <div style={styles.infoBoxHeader}>
                  <div style={styles.headTitleContainer}>
                    <span>From</span>
                  </div>
                </div>
                <div style={{ padding: 30, backgroundColor: 'white' }}>
                  <div style={{ ...styles.editContainer }}>
                    <div style={{ ...styles.editLabel }}>FROM NAME</div>
                    <div
                      style={{ ...styles.editFieldContainer, marginLeft: 20 }}
                    >
                      <Form.Item
                        validateStatus={
                          errorHandling(
                            this.props.errorUpdateMessageSubmit,
                            'variations[' + [index] + '].fromName'
                          )
                            ? 'error'
                            : null
                        }
                        help={errorHandling(
                          this.props.errorUpdateMessageSubmit,
                          'variations[' + [index] + '].fromName'
                        )}
                        id={
                          errorHandling(
                            this.props.errorUpdateMessageSubmit,
                            'variations[' + [index] + '].fromName'
                          )
                            ? 'error'
                            : null
                        }
                      >
                        <InputSelector
                          id={
                            errorHandling(
                              this.props.errorUpdateMessageSubmit,
                              'variations[' + [index] + '].fromName'
                            )
                              ? 'error'
                              : null
                          }
                          type="richInline"
                          index={index}
                          dataCy="from-name"
                          journeySteps={this.props.journeyStepsForVariable}
                          disabled={this.props.readOnly || this.isEditDisable()}
                          attributeStyle={{
                            position: 'relative',
                            top: '-10px',
                            right: '-140px',
                            zIndex: 20,
                          }}
                          attributes={this.props.attributes}
                          events={this.props.events}
                          currentType={this.props.currentMessage.type}
                          currentEvent={this.props.currentMessage.event}
                          businessEvents={this.props.businessEvents}
                          style={styles.editField}
                          hideEmojiPicker
                          placeholder={'Alex Smith'}
                          value={
                            this.state.variations[index] &&
                            this.state.variations[index].fromName
                              ? this.state.variations[index].fromName
                              : ''
                          }
                          onChange={(value) =>
                            this.handleChangeVariations(
                              index,
                              'fromName',
                              value
                            )
                          }
                          size="middle"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div style={{ ...styles.editContainer, marginTop: 20 }}>
                    <div style={{ ...styles.editLabel }}>FROM EMAIL</div>
                    <div
                      style={{ ...styles.editFieldContainer, marginLeft: 20 }}
                    >
                      <Form.Item
                        validateStatus={
                          errorHandling(
                            this.props.errorUpdateMessageSubmit,
                            'variations[' + [index] + '].fromEmail'
                          )
                            ? 'error'
                            : this.checkValidEmail(
                                this.state.variations[index].fromEmail
                              )
                        }
                        help={
                          !isNil(
                            errorHandling(
                              this.props.errorUpdateMessageSubmit,
                              'variations[' + [index] + '].fromEmail'
                            )
                          )
                            ? errorHandling(
                                this.props.errorUpdateMessageSubmit,
                                'variations[' + [index] + '].fromEmail'
                              )
                            : isNil(
                                this.checkValidEmail(
                                  this.state.variations[index].fromEmail
                                )
                              )
                            ? null
                            : 'Invalid Email Address'
                        }
                        id={
                          errorHandling(
                            this.props.errorUpdateMessageSubmit,
                            'variations[' + [index] + '].fromEmail'
                          )
                            ? 'error'
                            : this.checkValidEmail(
                                this.state.variations[index].fromEmail
                              )
                        }
                      >
                        <InputSelector
                          id={'variations[' + [index] + '].fromEmail'}
                          type="richInline"
                          index={index}
                          dataCy="from-email"
                          journeySteps={this.props.journeyStepsForVariable}
                          disabled={this.props.readOnly || this.isEditDisable()}
                          attributeStyle={{
                            position: 'relative',
                            top: '-10px',
                            right: '-140px',
                            zIndex: 20,
                          }}
                          attributes={this.props.attributes}
                          events={this.props.events}
                          currentType={this.props.currentMessage.type}
                          currentEvent={this.props.currentMessage.event}
                          businessEvents={this.props.businessEvents}
                          style={styles.editField}
                          hideEmojiPicker
                          placeholder={'alex.smith@company.com'}
                          value={
                            this.state.variations[index] &&
                            this.state.variations[index].fromEmail
                              ? this.state.variations[index].fromEmail
                              : ''
                          }
                          onChange={(value) =>
                            this.handleChangeVariations(
                              index,
                              'fromEmail',
                              value
                            )
                          }
                          size="middle"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div style={{ ...styles.editContainer, marginTop: 20 }}>
                    <div style={styles.editLabel}>REPLY TO</div>
                    <div
                      style={{
                        display: 'flex',
                        flex: 6,
                        alignItems: 'center',
                        fontSize: 13,
                        lineHeight: 1.43,
                        marginLeft: 20,
                      }}
                    >
                      <Switch
                        checked={
                          this.state.variations[index] &&
                          this.state.variations[index].showReplyTo
                        }
                        disabled={this.props.readOnly || this.isEditDisable()}
                        onChange={(value) =>
                          this.handleChangeVariations(
                            index,
                            'showReplyTo',
                            value
                          )
                        }
                        data-cy="button-email-reply-to"
                      />
                    </div>
                  </div>
                  {this.state.variations[index] &&
                    this.state.variations[index].showReplyTo && (
                      <div style={{ ...styles.editContainer }}>
                        <div style={styles.editLabel}></div>
                        <div
                          style={{
                            flex: 6,
                            alignItems: 'center',
                            fontSize: 13,
                            lineHeight: 1.43,
                            marginLeft: 20,
                          }}
                        >
                          <Form.Item
                            validateStatus={
                              this.state.emailErrors.replyToEmailError
                                ? 'error'
                                : null
                            }
                            help={
                              this.state.emailErrors.replyToEmailError
                                ? 'Invalid Email Address'
                                : null
                            }
                            id={
                              this.state.emailErrors.replyToEmailError
                                ? 'error'
                                : null
                            }
                          >
                            <InputSelector
                              id="email-reply-to"
                              type="richInline"
                              index={index}
                              dataCy="email-reply-to"
                              journeySteps={this.props.journeyStepsForVariable}
                              disabled={
                                this.props.readOnly || this.isEditDisable()
                              }
                              style={styles.editField}
                              attributeStyle={{
                                position: 'relative',
                                top: '10px',
                                right: '210px',
                                zIndex: 20,
                              }}
                              attributes={this.props.attributes}
                              events={this.props.events}
                              currentType={this.props.currentMessage.type}
                              currentEvent={this.props.currentMessage.event}
                              businessEvents={this.props.businessEvents}
                              hideEmojiPicker
                              placeholder={'alex.smith@company.com'}
                              value={
                                this.state.variations[index] &&
                                this.state.variations[index].replyTo
                                  ? this.state.variations[index].replyTo
                                  : ''
                              }
                              onChange={(value) =>
                                this.handleChangeVariations(
                                  index,
                                  'replyTo',
                                  value
                                )
                              }
                              size="middle"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    )}
                  <div style={{ ...styles.editContainer, marginTop: 20 }}>
                    <div style={styles.editLabel}>CC</div>
                    <div
                      style={{
                        flex: 6,
                        alignItems: 'center',
                        fontSize: 13,
                        lineHeight: 1.43,
                        marginLeft: 20,
                      }}
                    >
                      <Switch
                        checked={
                          this.state.variations[index] &&
                          this.state.variations[index].showCc
                        }
                        disabled={this.props.readOnly || this.isEditDisable()}
                        onChange={(value) =>
                          this.handleChangeVariations(index, 'showCc', value)
                        }
                        data-cy="button-email-cc-address"
                      />
                    </div>
                  </div>
                  {this.state.variations[index] &&
                    this.state.variations[index].showCc && (
                      <div style={{ ...styles.editContainer }}>
                        <div style={styles.editLabel}></div>
                        <div
                          style={{
                            flex: 6,
                            alignItems: 'center',
                            fontSize: 13,
                            lineHeight: 1.43,
                            marginLeft: 20,
                          }}
                        >
                          <Form.Item
                            validateStatus={
                              this.state.emailErrors.ccEmailError
                                ? 'error'
                                : null
                            }
                            help={
                              this.state.emailErrors.ccEmailError
                                ? 'Invalid Email Address'
                                : null
                            }
                            id={
                              this.state.emailErrors.ccEmailError
                                ? 'error'
                                : null
                            }
                          >
                            <InputSelector
                              type="richInline"
                              index={index}
                              journeySteps={this.props.journeyStepsForVariable}
                              disabled={
                                this.props.readOnly || this.isEditDisable()
                              }
                              style={styles.editField}
                              attributeStyle={{
                                position: 'relative',
                                top: '10px',
                                right: '210px',
                                zIndex: 20,
                              }}
                              id="email-cc-address"
                              dataCy="email-cc-address"
                              attributes={this.props.attributes}
                              events={this.props.events}
                              currentType={this.props.currentMessage.type}
                              currentEvent={this.props.currentMessage.event}
                              businessEvents={this.props.businessEvents}
                              hideEmojiPicker
                              placeholder={'dave.smith@company.com'}
                              value={
                                this.state.variations[index] &&
                                this.state.variations[index].cc
                                  ? this.state.variations[index].cc
                                  : ''
                              }
                              onChange={(value) =>
                                this.handleChangeVariations(index, 'cc', value)
                              }
                              size="middle"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    )}
                  <div style={{ ...styles.editContainer, marginTop: 20 }}>
                    <div style={styles.editLabel}>BCC</div>
                    <div
                      style={{
                        flex: 6,
                        alignItems: 'center',
                        fontSize: 13,
                        lineHeight: 1.43,
                        marginLeft: 20,
                      }}
                    >
                      <Switch
                        checked={
                          this.state.variations[index] &&
                          this.state.variations[index].showBcc
                        }
                        disabled={this.props.readOnly || this.isEditDisable()}
                        onChange={(value) =>
                          this.handleChangeVariations(index, 'showBcc', value)
                        }
                        data-cy="button-email-bcc-address"
                      />
                    </div>
                  </div>
                  {this.state.variations[index] &&
                    this.state.variations[index].showBcc && (
                      <div style={{ ...styles.editContainer }}>
                        <div style={styles.editLabel}></div>
                        <div
                          style={{
                            flex: 6,
                            alignItems: 'center',
                            fontSize: 13,
                            lineHeight: 1.43,
                            marginLeft: 20,
                          }}
                        >
                          <Form.Item
                            validateStatus={
                              this.state.emailErrors.bccEmailError
                                ? 'error'
                                : null
                            }
                            help={
                              this.state.emailErrors.bccEmailError
                                ? 'Invalid Email Address'
                                : null
                            }
                            id={
                              this.state.emailErrors.bccEmailError
                                ? 'error'
                                : null
                            }
                          >
                            <InputSelector
                              id="email-bcc-address"
                              type="richInline"
                              index={index}
                              dataCy="email-bcc-address"
                              journeySteps={this.props.journeyStepsForVariable}
                              disabled={
                                this.props.readOnly || this.isEditDisable()
                              }
                              style={styles.editField}
                              attributeStyle={{
                                position: 'relative',
                                top: '10px',
                                right: '210px',
                                zIndex: 20,
                              }}
                              attributes={this.props.attributes}
                              events={this.props.events}
                              currentType={this.props.currentMessage.type}
                              currentEvent={this.props.currentMessage.event}
                              businessEvents={this.props.businessEvents}
                              hideEmojiPicker
                              placeholder={'peter.smith@company.com'}
                              value={
                                this.state.variations[index] &&
                                this.state.variations[index].bcc
                                  ? this.state.variations[index].bcc
                                  : ''
                              }
                              onChange={(value) =>
                                this.handleChangeVariations(index, 'bcc', value)
                              }
                              size="middle"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    )}
                </div>
              </Card>
            </div>
            <div style={styles.formItem}>
              <Card>
                <div style={styles.infoBoxHeader}>
                  <div style={styles.headTitleContainer}>
                    <span>Message</span>
                    <div style={{ cursor: 'pointer' }}></div>
                  </div>
                </div>
                <React.Fragment>
                  <div
                    style={{
                      padding: 30,
                      backgroundColor: 'white',
                      width: '100%',
                    }}
                  >
                    {this.inputGenerator('subject', index)}
                    {this.inputGenerator('preheader', index)}
                    {this.inputGenerator('body', index)}
                  </div>
                </React.Fragment>
              </Card>
            </div>
            <div style={styles.formItem}>
              {this.inputGenerator('attachments', index)}
            </div>
            {this.renderEmailPreviewModal()}
            {this.renderTemplateDetectedModal()}
            {this.renderTemplateModalVisible()}
            {this.renderEmailTemplateEditor(index)}
          </Form>
        </React.Fragment>
      );
    }
    return null;
  };

  renderServiceProviderDefaultValue = () => {
    if (
      this.channel === 'SMS' &&
      this.props.smsChannels &&
      this.props.smsChannels.length > 0 &&
      !isNil(this.props.smsChannels[0].id)
    ) {
      return this.props.smsChannels[0].id;
    }
    if (
      this.channel === 'EMAIL' &&
      this.props.emailChannels &&
      this.props.emailChannels.length > 0 &&
      !isNil(this.props.emailChannels[0].id)
    ) {
      return this.props.emailChannels[0].id;
    }
    return undefined;
  };

  renderServiceProviderOptions = (serviceProvider) => {
    const result = [];
    serviceProvider.forEach((item) => {
      result.push(<Select.Option value={item.id}>{item.name}</Select.Option>);
    });
    return result;
  };

  channelValue = (variationIndex, name) => {
    let result;
    const { variations } = this.state;
    if (
      variations[variationIndex] &&
      !isNil(variations[variationIndex][name])
    ) {
      result = variations[variationIndex][name];
    }
    return result;
  };

  renderSMSSectionServiceProviderMessage = (index) => {
    if (this.channel === 'SMS') {
      const reg = /{{[^}]*}}+/g;
      const smsDefaultCount = this.state.variations[index].message.match(
        /[\u0600-\u06FF\s]/
      )
        ? 70
        : 160;
      return (
        <React.Fragment>
          <Form layout="vertical">
            {this.props.currentMessage.type === 'TRANSACTIONAL' && (
              <Card
                styleSheet={{
                  marginBottom: 40,
                }}
              >
                <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
                  <div style={styles.headTitleContainer}>
                    Personalizing Content in Transactional Campaigns
                  </div>
                </div>
                <div style={{ ...styles.dataRowContainer, paddingBottom: 0 }}>
                  You can use any variable to personalize any of the fields in
                  the message below. Eg. To include order_id in the order
                  confirmation message, please specify the order_id variable in
                  the message as <span>{'{{token.order_id }}'}</span> . Please
                  also ensure that your variables are prefixed with the keyword
                  token. Once you launch this campaign, send the token as token:{' '}
                  <span>{'{ "order_id" : "abc123" }'}</span> in the{' '}
                  <a href={texts.campaignApi} target={'blank'}>
                    transactional campaign API call{' '}
                  </a>{' '}
                  for each order confirmation, so that the message gets
                  personalized.
                </div>
                <div style={styles.dataRowContainer}>
                  Please refer to our{' '}
                  <a href={texts.campaignApi} target={'blank'}>
                    documentation
                  </a>{' '}
                  for more details on transactional campaigns.
                </div>
              </Card>
            )}
            {!this.isParent && (
              <Card
                styles={{
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
                  <div style={styles.headTitleContainer}>Service Provider</div>
                </div>
                <div style={styles.dataRowContainer}>
                  <div style={styles.dataRow}>
                    <div style={{ ...styles.editContainer }}>
                      <div style={{ ...styles.editLabel }}>SSP</div>
                      <div
                        style={{
                          ...styles.editFieldContainer,
                          marginLeft: 20,
                          flex: 3,
                        }}
                      >
                        <Form.Item
                          validateStatus={
                            errorHandling(
                              this.props.errorUpdateMessageSubmit,
                              'variations[' + [index] + '].smsChannel'
                            )
                              ? 'error'
                              : null
                          }
                          help={errorHandling(
                            this.props.errorUpdateMessageSubmit,
                            'variations[' + [index] + '].smsChannel'
                          )}
                          id={
                            errorHandling(
                              this.props.errorUpdateMessageSubmit,
                              'variations[' + [index] + '].smsChannel'
                            )
                              ? 'error'
                              : null
                          }
                        >
                          <Select
                            id={
                              errorHandling(
                                this.props.errorUpdateMessageSubmit,
                                'variations[' + [index] + '].smsChannel'
                              )
                                ? 'error'
                                : null
                            }
                            data-cy="sms-channel"
                            loading={this.props.loadingSmsChannels}
                            style={styles.editField}
                            value={this.channelValue(index, 'smsChannel')}
                            dropdownMenuStyle={{ maxHeight: 150 }}
                            size="large"
                            disabled={
                              this.props.readOnly || this.isEditDisable()
                            }
                            onChange={(value) =>
                              this.handleChangeVariations(
                                index,
                                'smsChannel',
                                value
                              )
                            }
                          >
                            {this.props.loadingSmsChannels
                              ? null
                              : this.renderServiceProviderOptions(
                                  this.props.smsChannels || []
                                )}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            )}
            <Card
              styles={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
                <div style={styles.headTitleContainer}>Message</div>
              </div>
              <div style={styles.dataRowContainer}>
                <div style={styles.dataRow}>
                  <div style={styles.editContainer}>
                    <div
                      style={{
                        ...styles.editLabel,
                        position: 'relative',
                        // top: -17,
                      }}
                    >
                      SMS Text
                    </div>
                    <div
                      style={{
                        ...styles.editFieldContainer,
                        marginLeft: 20,
                        flex: 3,
                      }}
                    >
                      <Form.Item
                        validateStatus={
                          errorHandling(
                            this.props.errorUpdateMessageSubmit,
                            'variations[' + [index] + '].message'
                          )
                            ? 'error'
                            : null
                        }
                        help={errorHandling(
                          this.props.errorUpdateMessageSubmit,
                          'variations[' + [index] + '].message'
                        )}
                        id={
                          errorHandling(
                            this.props.errorUpdateMessageSubmit,
                            'variations[' + [index] + '].message'
                          )
                            ? 'error'
                            : null
                        }
                        data-cy="description-text-area"
                      >
                        <InputSelector
                          id={
                            errorHandling('variations[' + [index] + '].message')
                              ? 'error'
                              : null
                          }
                          journeySteps={this.props.journeyStepsForVariable}
                          disabled={this.props.readOnly || this.isEditDisable()}
                          textArea
                          type="richTextArea"
                          currentType={this.props.currentMessage.type}
                          currentEvent={this.props.currentMessage.event}
                          attributeStyle={{
                            position: 'relative',
                            top: '-7px',
                            right: '150px',
                          }}
                          className={'not-resizable'}
                          attributes={this.props.attributes}
                          events={this.props.events}
                          businessEvents={this.props.businessEvents}
                          style={{
                            paddingRight: 58,
                            color: 'rgb(61, 64, 78)',
                            direction: 'ltr',
                            fontFamily: 'monospace',
                            fontSize: 13,
                            fontWeight: 400,
                            width: '100%',
                          }}
                          readOnly={this.props.readOnly}
                          autoSize={{ maxRows: 8, minRows: 3 }}
                          rows={4}
                          value={
                            this.state.variations[index] &&
                            this.state.variations[index].message
                              ? this.state.variations[index].message
                              : ''
                          }
                          onChange={(value) =>
                            this.handleChangeVariations(index, 'message', value)
                          }
                          size="large"
                          index={index}
                        />
                      </Form.Item>
                      <span
                        style={{
                          color: '#94969e',
                          fontSize: '10px',
                          paddingLeft: '5px',
                          paddingTop: '5px',
                          display: 'block',
                        }}
                      >
                        {'Current count:' +
                          (this.state.variations[index] &&
                          this.state.variations[index].message
                            ? this.state.variations[index].message.replace(
                                /{{[^}]*}}+/g,
                                ''
                              ).length
                            : 0)}
                        <br />
                        {process.env.THEME !== 'Usermost' &&
                          'SMS count:' +
                            (this.state.variations[index] &&
                            this.state.variations[index].message
                              ? Math.floor(
                                  this.state.variations[index].message.replace(
                                    reg,
                                    ''
                                  ).length / smsDefaultCount
                                ) + 1
                              : 0)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Form>
        </React.Fragment>
      );
    }
    return null;
  };

  renderCustomChannelContent = (index) => {
    const availableItems =
      this.state[`selectedCustomChannelItems_${index}`] ||
      (this.state.variations[index]
        ? Object.keys(this.state.variations[index])
        : null) ||
      [];

    return (
      <>
        <Card styles={styles.renderCustomChannelContent}>
          <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
            <div style={styles.headTitleContainer}>Properties</div>
          </div>
          <div style={{ padding: 30, backgroundColor: 'white', width: '100%' }}>
            {availableItems.includes('subject') &&
              this.inputGenerator('subject', index)}
            {/* fmb: rich >>>>>>>> */}
            {availableItems.includes('message') &&
              this.inputGenerator('rich-message', index)}
            <br />
            {availableItems.includes('image') &&
              this.inputGenerator('image', index)}
            <br />
            {/* {availableItems.includes('icon') &&  */}
            {this.inputGenerator('icon', index)}
          </div>
        </Card>
        {availableItems.includes('keyValuePair') &&
          this.renderKeyValuePairs(index, 12)}
        {availableItems.includes('attachments') &&
          this.inputGenerator('attachments', index)}
      </>
    );
  };

  renderCustomChannel = (index) => {
    if (this.channel === 'CUSTOM') {
      return (
        <React.Fragment>
          <Form ref={this.formRef} layout="vertical">
            {this.props.currentMessage.type === 'TRANSACTIONAL' && (
              <div
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  border: '1px solid #F1F1F3',
                  backgroundColor: 'white',
                  marginBottom: 40,
                }}
              >
                <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
                  <div style={styles.headTitleContainer}>
                    Personalizing Content in Transactional Campaigns
                  </div>
                </div>
                <div style={{ ...styles.dataRowContainer, paddingBottom: 0 }}>
                  You can use any variable to personalize any of the fields in
                  the message below. Eg. To include order_id in the order
                  confirmation message, please specify the order_id variable in
                  the message as <span>{'{{token.order_id }}'}</span> . Please
                  also ensure that your variables are prefixed with the keyword
                  token. Once you launch this campaign, send the token as token:{' '}
                  <span>{'{ "order_id" : "abc123" }'}</span> in the{' '}
                  <a href={texts.campaignApi} target={'blank'}>
                    transactional campaign API call{' '}
                  </a>{' '}
                  for each order confirmation, so that the message gets
                  personalized.
                </div>
                <div style={styles.dataRowContainer}>
                  Please refer to our{' '}
                  <a href={texts.campaignApi} target={'blank'}>
                    documentation
                  </a>{' '}
                  for more details on transactional campaigns.
                </div>
              </div>
            )}
            <Card styles={styles.renderCustomChannel}>
              <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
                <div style={styles.headTitleContainer}>Channel Provider</div>
              </div>
              <div style={styles.dataRowContainer}>
                <div style={styles.dataRow}>
                  <div style={styles.editContainer}>
                    <div style={{ ...styles.editLabel, flex: 1 }}>
                      Custom Channel
                    </div>
                    <div
                      style={{
                        ...styles.editFieldContainer,
                        marginLeft: 20,
                        flex: 3,
                      }}
                    >
                      <Form.Item
                        // name={`variations[${index}].customChannel`}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          },
                        ]}
                      >
                        <Select
                          loading={this.props.loadingCustomChannels}
                          // id="int-communication-custom-channel"
                          style={styles.editField}
                          value={this.channelValue(index, 'customChannel')}
                          dropdownMenuStyle={{ maxHeight: 150 }}
                          size="large"
                          disabled={this.props.readOnly || this.isEditDisable()}
                          onChange={(value) =>
                            this.handleChangeVariations(
                              index,
                              'customChannel',
                              value
                            )
                          }
                        >
                          {this.props.loadingCustomChannels
                            ? null
                            : this.renderServiceProviderOptions(
                                this.props.customChannels || []
                              )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            {this.renderCustomChannelContent(index)}
          </Form>
        </React.Fragment>
      );
    }
    return null;
  };

  renderSMSSectionMessage = (index) => {
    const mobilePlatformIconStyle = { fontSize: 16, color: '#777' };
    let mobilePlatformOptions = [
      {
        value: 'android',
        icon: <AndroidFilled style={mobilePlatformIconStyle} />,
      },
      {
        value: 'ios',
        icon: <AppleFilled style={mobilePlatformIconStyle} />,
      },
    ];
    if (this.channel === 'SMS') {
      return (
        <section
          style={{ direction: 'ltr', height: '100vh', paddingBottom: 0 }}
          className="message-container__preview overflow-hidden"
        >
          <div className="margin-left-auto message-container__options">
            <div
              className="row justify-content-between"
              style={{ flexDirection: 'row-reverse' }}
            >
              <Segmented
                style={{ borderRadius: 8 }}
                onChange={(val) => {
                  if (val === 'android') {
                    this.setState({ isPreviewAndroid: true });
                  } else {
                    this.setState({ isPreviewAndroid: false });
                  }
                }}
                options={mobilePlatformOptions}
              />
            </div>
          </div>
          <div className="pos-relative w-100 h-100 overflow-hidden">
            <div className="preview">
              {this.state.isPreviewAndroid ? (
                <div className="preview_sms preview_mobile preview_android">
                  <div id="sms-android" className="preview-inner">
                    <div className="preview-device empty"></div>
                    <div className="preview-device mobile mobile_android"></div>
                    <div className="preview-content">
                      <div className="sms-preview-date">
                        {moment().format('H:mm')}
                      </div>
                      <div className="sms-preview-header">
                        <div style={{ textAlign: 'left' }}>
                          {this.renderMobileNumber(index)}
                        </div>
                      </div>
                      <div className="sms-preview-body">
                        <div className="clearfix">
                          <div className="sms-preview-from">8</div>
                          <div
                            className="sms-preview-message"
                            style={{ textAlign: 'left' }}
                          >
                            {this.state.variations[index] &&
                            this.state.variations[index].message
                              ? this.state.variations[index].message
                                  .split('\n')
                                  .map((item, i) => (
                                    <span key={i}>
                                      {item}
                                      <br />
                                    </span>
                                  ))
                              : ''}
                          </div>
                        </div>
                        <br />
                        <div className="sms-preview-footer">
                          {moment().format('H:mm') + ' via SMS'}
                        </div>
                      </div>
                      <div className="sms-preview-msg-textfield">
                        Send an SMS
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="preview_sms preview_mobile preview_ios">
                  <div id="sms-android" className="preview-inner">
                    <div className="preview-device empty"></div>
                    <div className="preview-device mobile mobile_ios"></div>
                    <div className="preview-content">
                      <div className="sms-preview-header row">
                        <div className="col-4">Messages</div>
                        <div className="col-5 text-ellipsis">
                          {this.renderMobileNumber(index)}
                        </div>
                        <div className="col-3">Details</div>
                      </div>
                      <div className="sms-preview-sub-header sms-preview-sub-header_1">
                        Text Message
                      </div>
                      <div className="sms-preview-sub-header sms-preview-sub-header_2">
                        {'Today ' + moment().format('H:mm')}
                      </div>
                      <div className="sms-preview-body">
                        <div className="clearfix">
                          <div className="sms-preview-message">
                            {this.state.variations[index].message
                              ? this.state.variations[index].message
                              : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      );
    }
    return null;
  };

  renderKeyValuePairs = (index) => {
    return (
      <div
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          border: '1px solid #F1F1F3',
          marginTop: 40,
          backgroundColor: 'white',
        }}
      >
        <div style={styles.infoBoxHeader}>
          <div style={styles.headTitleContainer}>
            <span>Key-Value Pairs</span>
            <Switch
              data-cy="key-value-pair-switch"
              disabled={this.isEditDisable()}
              style={{ position: 'relative', top: 5, left: 10 }}
              checked={
                this.state.variations[index] &&
                this.state.variations[index].showKeyValue
              }
              onChange={(value) =>
                this.handleChangeVariations(index, 'showKeyValue', value)
              }
            />
          </div>
        </div>
        {this.state.variations[index] &&
          this.state.variations[index].showKeyValue && (
            <div style={styles.dataRowContainer}>
              {this.state.variations[index] &&
                this.state.variations[index].keyValuePair.map(
                  (item, keyValueIndex) => {
                    const keyCyData = 'push-key' + (keyValueIndex + 1);
                    const valueCyData = 'push-value' + (keyValueIndex + 1);
                    const removeKeyValueCyData =
                      'remove-key-value-pair-' + (keyValueIndex + 1);
                    return (
                      <div key={keyValueIndex} style={styles.dataRowFirst}>
                        <div style={styles.editContainer}>
                          <div style={{ ...styles.editLabel }}>
                            {keyValueIndex === 0 ? 'KEY-VALUE PAIRS' : ''}
                          </div>
                          <div
                            style={{
                              ...styles.editFieldContainer,
                              marginLeft: 20,
                              flex: '6 1 1%',
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <Form.Item
                              validateStatus={
                                errorHandling(
                                  this.props.errorUpdateMessageSubmit,
                                  'variations[' +
                                    [index] +
                                    '].keyValuePair[' +
                                    [keyValueIndex] +
                                    '].key'
                                )
                                  ? 'error'
                                  : null
                              }
                              help={errorHandling(
                                this.props.errorUpdateMessageSubmit,
                                'variations[' +
                                  [index] +
                                  '].keyValuePair[' +
                                  [keyValueIndex] +
                                  '].key'
                              )}
                              id={
                                errorHandling(
                                  this.props.errorUpdateMessageSubmit,
                                  'variations[' +
                                    [index] +
                                    '].keyValuePair[' +
                                    [keyValueIndex] +
                                    '].key'
                                )
                                  ? 'error'
                                  : null
                              }
                            >
                              <Input
                                data-cy={keyCyData}
                                id={
                                  errorHandling(
                                    this.props.errorUpdateMessageSubmit,
                                    'variations[' +
                                      [index] +
                                      '].keyValuePair[' +
                                      [keyValueIndex] +
                                      '].key'
                                  )
                                    ? 'error'
                                    : null
                                }
                                disabled={this.isEditDisable()}
                                value={item && item.key ? item.key : ''}
                                onChange={(e) =>
                                  this.handleChangeKeyValueKey(
                                    index,
                                    keyValueIndex,
                                    e.target.value
                                  )
                                }
                                placeholder="Key"
                                size="large"
                                style={{
                                  ...styles.editField,
                                  width: 150,
                                  height: 50,
                                  color: '#3d404e',
                                  fontSize: 13,
                                }}
                              />
                            </Form.Item>
                            <span style={{ margin: '15px 10px' }}>=</span>
                            <Form.Item
                              validateStatus={
                                errorHandling(
                                  this.props.errorUpdateMessageSubmit,
                                  'variations[' +
                                    [index] +
                                    '].keyValuePair[' +
                                    [keyValueIndex] +
                                    '].value'
                                )
                                  ? 'error'
                                  : null
                              }
                              help={errorHandling(
                                this.props.errorUpdateMessageSubmit,
                                'variations[' +
                                  [index] +
                                  '].keyValuePair[' +
                                  [keyValueIndex] +
                                  '].value'
                              )}
                              id={
                                errorHandling(
                                  this.props.errorUpdateMessageSubmit,
                                  'variations[' +
                                    [index] +
                                    '].keyValuePair[' +
                                    [keyValueIndex] +
                                    '].value'
                                )
                                  ? 'error'
                                  : null
                              }
                              style={{
                                width: 310,
                              }}
                            >
                              <InputSelector
                                type="richInline"
                                index={index}
                                dataCy={valueCyData}
                                disabled={this.isEditDisable()}
                                attributeStyle={{
                                  position: 'relative',
                                  top: '0',
                                  right: '-108px',
                                }}
                                hideEmojiPicker
                                attributes={this.props.attributes}
                                events={this.props.events}
                                currentType={this.props.currentMessage.type}
                                currentEvent={this.props.currentMessage.event}
                                journeySteps={
                                  this.props.journeyStepsForVariable
                                }
                                businessEvents={this.props.businessEvents}
                                style={{ height: 50, width: 310 }}
                                id={'key-value-' + keyValueIndex}
                                className={'key-value-push'}
                                value={item && item.value ? item.value : ''}
                                onChange={(value) =>
                                  this.handleChangeKeyValueValue(
                                    index,
                                    keyValueIndex,
                                    value
                                  )
                                }
                                placeholder="Value"
                                size="large"
                              />
                            </Form.Item>
                            {!this.isEditDisable() && (
                              <i
                                data-cy={removeKeyValueCyData}
                                onClick={() =>
                                  this.handleRemoveKeyValue(
                                    index,
                                    keyValueIndex
                                  )
                                }
                                className="fl-dark fl-delete cursor-pointer"
                                style={{
                                  fontSize: 24,
                                  color: 'rgb(187, 188, 193)',
                                  margin: '15px 10px',
                                }}
                              ></i>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              <div style={styles.dataRowFirst}>
                <div style={styles.editContainer}>
                  <div
                    style={{
                      ...styles.editFieldContainer,
                      marginLeft: 15,
                      flex: 3,
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div style={{ paddingTop: 20 }}>
                      <Button
                        data-cy="add-pair-button"
                        disabled={this.isEditDisable()}
                        type="primary"
                        shape="round"
                        size={'large'}
                        ghost
                        style={styles.addNewSlotButton}
                        onClick={() => this.addKeyValuePair(index)}
                      >
                        Add Pair
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  };

  renderAdvanceWebPush = (index) => {
    return (
      <Card
        styles={{
          marginTop: 40,
        }}
      >
        <div style={styles.infoBoxHeader}>
          <div style={styles.headTitleContainer}>
            <span>Advanced Options</span>
            <Switch
              disabled={this.isEditDisable()}
              checked={
                this.state.variations[index] &&
                this.state.variations[index].showWebPushAdvance
              }
              onChange={(value) =>
                this.handleChangeVariations(index, 'showWebPushAdvance', value)
              }
              style={{ position: 'relative', top: 5, left: 10 }}
            />
          </div>
        </div>
        {this.state.variations[index] &&
          this.state.variations[index].showWebPushAdvance && (
            <div style={styles.dataRowContainer}>
              <div style={styles.dataRowFirst}>
                <div style={styles.editContainer}>
                  <div style={{ ...styles.editLabel, position: 'relative' }}>
                    Auto-Hide
                  </div>
                  <div
                    style={{
                      ...styles.editFieldContainer,
                      marginLeft: 20,
                      flex: 3,
                    }}
                  >
                    <Checkbox
                      checked={
                        !(
                          this.state.variations[index] &&
                          this.state.variations[index].autoHide
                        )
                      }
                      onChange={(e) =>
                        this.handleChangeVariations(
                          index,
                          'autoHide',
                          !e.target.checked
                        )
                      }
                    >
                      <span style={{ fontSize: 12 }}>
                        Do not auto-hide notification. Continue to show until
                        user clicks or dismisses the notification
                      </span>
                    </Checkbox>
                  </div>
                </div>
              </div>
            </div>
          )}
      </Card>
    );
  };

  renderAndroidPushButton = (index) => {
    return (
      <Card
        styles={{
          marginTop: 40,
        }}
      >
        <div style={styles.infoBoxHeader}>
          <div style={styles.headTitleContainer}>
            {this.channel === 'PUSH' && <span>Advanced Options</span>}
            {this.channel === 'WEB_PUSH' && <span>Buttons</span>}
            {this.channel === 'PUSH' && (
              <Switch
                data-cy="push-advanced-options-switch"
                disabled={this.isEditDisable()}
                checked={
                  this.state.variations[index] &&
                  this.state.variations[index].showAndroidAdvance
                }
                onChange={(value) =>
                  this.handleChangeVariations(
                    index,
                    'showAndroidAdvance',
                    value
                  )
                }
                style={{ position: 'relative', top: 5, left: 10 }}
              />
            )}
            {this.channel === 'WEB_PUSH' && (
              <Switch
                disabled={this.isEditDisable()}
                checked={
                  this.state.variations[index] &&
                  this.state.variations[index].showAndroidPushButtons
                }
                onChange={() => this.handleChangeAndroidPushButtons(index)}
                style={{ position: 'relative', top: 5, left: 10 }}
              />
            )}
          </div>
        </div>
        {((this.channel === 'PUSH' &&
          this.state.variations[index] &&
          this.state.variations[index].showAndroidAdvance) ||
          (this.channel === 'WEB_PUSH' &&
            this.state.variations[index] &&
            this.state.variations[index].showAndroidPushButtons)) && (
          <div style={styles.dataRowContainer}>
            {this.channel === 'PUSH' && (
              <div style={styles.dataRowFirst}>
                <div style={styles.editContainer}>
                  <div
                    style={{
                      ...styles.editLabel,
                    }}
                  >
                    ON-CLICK ACTION
                  </div>
                  <div
                    style={{
                      ...styles.editFieldContainer,
                      marginLeft: 20,
                      flex: 3,
                    }}
                  >
                    <InputSelector
                      dataCy="onclick-action-url"
                      type="richInline"
                      index={index}
                      journeySteps={this.props.journeyStepsForVariable}
                      disabled={this.isEditDisable()}
                      attributeStyle={{
                        position: 'relative',
                        top: '-10px',
                        right: '-380px',
                      }}
                      id="deep-link-url"
                      attributes={this.props.attributes}
                      events={this.props.events}
                      currentType={this.props.currentMessage.type}
                      currentEvent={this.props.currentMessage.event}
                      businessEvents={this.props.businessEvents}
                      hideEmojiPicker
                      placeholder="Deeplink or URL"
                      value={
                        this.state.variations[index] &&
                        this.state.variations[index].androidOnClickAction
                          ? this.state.variations[index].androidOnClickAction
                          : ''
                      }
                      onChange={(value) =>
                        this.handleChangeVariations(
                          index,
                          'androidOnClickAction',
                          value
                        )
                      }
                      size="large"
                    />
                    <span
                      style={{
                        color: '#94969e',
                        fontSize: '10px',
                        paddingLeft: '5px',
                        paddingTop: '5px',
                        display: 'block',
                      }}
                    >
                      {
                        'Deeplink can be any URI (eg. myapp://productdetails/item/abc). URL can be HTTP or HTTPS (eg. https://www.abc.com)'
                      }
                    </span>
                  </div>
                </div>
              </div>
            )}
            {this.channel === 'PUSH' && (
              <div style={styles.dataRow}>
                <div style={styles.editContainer}>
                  <div
                    style={{
                      ...styles.editLabel,
                      position: 'relative',
                    }}
                  >
                    Buttons
                  </div>
                  <div style={{ marginLeft: 20, flex: 3 }}>
                    <Switch
                      data-cy="push-buttons-switch"
                      disabled={this.isEditDisable()}
                      checked={
                        this.state.variations[index] &&
                        this.state.variations[index].showAndroidPushButtons
                      }
                      onChange={() =>
                        this.handleChangeAndroidPushButtons(index)
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            {this.state.variations[index] &&
              this.state.variations[index].androidButtons.map(
                (item, androidButtonIndex) => {
                  const buttonIndex = androidButtonIndex + 1;
                  const labelButtonIndex = 'push-label-button-' + buttonIndex;
                  const onClickActionButtonIndex =
                    'push-onclick-action-button-' + buttonIndex;
                  const removeButtonCy =
                    'remove-button-' + (androidButtonIndex + 1);
                  return (
                    <div
                      key={androidButtonIndex}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 20,
                      }}
                    >
                      <div
                        style={{
                          boxShadow: 'inset 0 0 6px 0 #e7e8e9',
                          borderRadius: 5,
                          border: '1px solid #e2e2e2',
                          display: 'flex',
                          flexDirection: 'column',
                          flex: 1,
                        }}
                      >
                        <div
                          style={{
                            fontWeight: 600,
                            opacity: '0.75',
                            marginLeft: 20,
                            marginRight: 20,
                            borderBottom: '1px solid #e7e8e9',
                            paddingTop: 15,
                            paddingBottom: 15,
                          }}
                        >
                          {'Button ' + buttonIndex}
                        </div>
                        <div style={{ margin: 20 }}>
                          <div style={styles.dataRowFirst}>
                            <div style={styles.editContainer}>
                              <div
                                style={{
                                  ...styles.editLabel,
                                  position: 'relative',
                                  top: -10,
                                }}
                              >
                                LABEL
                              </div>
                              <div
                                style={{
                                  ...styles.editFieldContainer,
                                  marginLeft: 20,
                                  flex: 3,
                                }}
                              >
                                <Form.Item
                                  validateStatus={
                                    errorHandling(
                                      this.props.errorUpdateMessageSubmit,
                                      'variations[' +
                                        [index] +
                                        '].androidButton1Label'
                                    ) || lodashIsEmpty(item.label)
                                      ? 'error'
                                      : null
                                  }
                                  id={
                                    errorHandling(
                                      this.props.errorUpdateMessageSubmit,
                                      'variations[' +
                                        [index] +
                                        '].androidButton1Label'
                                    )
                                      ? 'error'
                                      : null
                                  }
                                  help={
                                    errorHandling(
                                      this.props.errorUpdateMessageSubmit,
                                      'variations[' +
                                        [index] +
                                        '].androidButton1Label'
                                    )
                                      ? 'error'
                                      : lodashIsEmpty(item.label)
                                      ? 'required'
                                      : null
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: 'This field is required',
                                    },
                                  ]}
                                >
                                  <InputSelector
                                    dataCy={labelButtonIndex}
                                    type="richInline"
                                    index={index}
                                    disabled={this.isEditDisable()}
                                    readOnly={this.props.readOnly}
                                    attributeStyle={{
                                      position: 'relative',
                                      top: '-10px',
                                      right: '-380px',
                                    }}
                                    id={`deep-link-url-label-${buttonIndex}`}
                                    attributes={this.props.attributes}
                                    events={this.props.events}
                                    currentType={this.props.currentMessage.type}
                                    currentEvent={
                                      this.props.currentMessage.event
                                    }
                                    businessEvents={this.props.businessEvents}
                                    journeySteps={
                                      this.props.journeyStepsForVariable
                                    }
                                    placeholder="Label"
                                    value={
                                      !lodashIsEmpty(item.label)
                                        ? item.label
                                        : ''
                                    }
                                    onChange={(value) =>
                                      this.handleChangeButtonsAttribute(
                                        index,
                                        'androidButtons',
                                        androidButtonIndex,
                                        'label',
                                        value
                                      )
                                    }
                                    size="large"
                                  />
                                </Form.Item>
                                {this.channel === 'PUSH' && (
                                  <span
                                    style={{
                                      color: '#94969e',
                                      fontSize: '10px',
                                      paddingLeft: '5px',
                                      paddingTop: '5px',
                                      display: 'block',
                                    }}
                                  >
                                    {
                                      'Recommended maximum length: 12 characters. Current count: 0'
                                    }
                                  </span>
                                )}
                                {this.channel === 'WEB_PUSH' && (
                                  <span
                                    style={{
                                      color: '#94969e',
                                      fontSize: '10px',
                                      paddingLeft: '5px',
                                      paddingTop: '5px',
                                      display: 'block',
                                    }}
                                  >
                                    {
                                      'Recommended maximum length: 22 characters. Current count: 0'
                                    }
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div style={styles.dataRow}>
                            <div style={styles.editContainer}>
                              <div
                                style={{
                                  ...styles.editLabel,
                                  position: 'relative',
                                  top: -10,
                                }}
                              >
                                ON-CLICK ACTION
                              </div>
                              <div
                                style={{
                                  ...styles.editFieldContainer,
                                  marginLeft: 20,
                                  flex: 3,
                                }}
                              >
                                <InputSelector
                                  dataCy={onClickActionButtonIndex}
                                  type="richInline"
                                  index={index}
                                  disabled={this.isEditDisable()}
                                  readOnly={this.props.readOnly}
                                  attributeStyle={{
                                    position: 'relative',
                                    top: '-10px',
                                    right: '-380px',
                                  }}
                                  id={`deep-link-url-onclick-${buttonIndex}`}
                                  attributes={this.props.attributes}
                                  events={this.props.events}
                                  currentType={this.props.currentMessage.type}
                                  currentEvent={this.props.currentMessage.event}
                                  businessEvents={this.props.businessEvents}
                                  journeySteps={
                                    this.props.journeyStepsForVariable
                                  }
                                  hideEmojiPicker
                                  placeholder="Deeplink or URL"
                                  value={
                                    !lodashIsEmpty(item.onClick)
                                      ? item.onClick
                                      : ''
                                  }
                                  onChange={(value) =>
                                    this.handleChangeButtonsAttribute(
                                      index,
                                      'androidButtons',
                                      androidButtonIndex,
                                      'onClick',
                                      value
                                    )
                                  }
                                  size="large"
                                />
                                <span
                                  style={{
                                    color: '#94969e',
                                    fontSize: '10px',
                                    paddingLeft: '5px',
                                    paddingTop: '5px',
                                    display: 'block',
                                  }}
                                >
                                  {
                                    'Deeplink can be any URI (eg. myapp://productdetails/itemabc). URL can be HTTP or HTTPS (eg. https://www.abc.com)'
                                  }
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {!this.isEditDisable() && !this.props.readOnly && (
                        <div>
                          <MinusCircleOutlined
                            data-cy={removeButtonCy}
                            onClick={() =>
                              this.handleRemoveButtons(
                                index,
                                'androidButtons',
                                androidButtonIndex
                              )
                            }
                            style={{
                              cursor: 'pointer',
                              margin: 10,
                              fontSize: 16,
                              color: '#bbbcc1',
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                }
              )}
            {this.state.variations[index] &&
              this.state.variations[index].androidButtons.length < 2 &&
              this.state.variations[index].showAndroidPushButtons && (
                <div style={styles.dataRowFirst}>
                  <div style={styles.editContainer}>
                    <div style={{ ...styles.editLabel }} />
                    <div
                      style={{
                        ...styles.editFieldContainer,
                        marginLeft: 35,
                        flex: 3,
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <div style={{ paddingTop: 20 }}>
                        <Button
                          data-cy="push-add-button"
                          disabled={this.isEditDisable() || this.props.readOnly}
                          type="primary"
                          shape="round"
                          size={'large'}
                          ghost
                          style={styles.addNewSlotButton}
                          onClick={() => this.addAndroidButton(index)}
                        >
                          Add Button
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        )}
      </Card>
    );
  };

  renderPushType = (index) => {
    if (this.state.variations[index] && this.state.variations[index].pushType) {
      return getPushLayout(this.state.variations[index].pushType);
    }
    return null;
  };

  renderInAppType = (index) => {
    if (
      this.state.variations[index] &&
      this.state.variations[index]?.inAppLayoutType
    ) {
      return getInAppLayout(this.state.variations[index]?.inAppLayoutType);
    }
    return null;
  };

  renderOnSiteType = (index) => {
    if (
      this.state.variations[index] &&
      this.state.variations[index].onSiteLayoutType
    ) {
      return getOnSiteLayout(this.state.variations[index].onSiteLayoutType);
    }
    return null;
  };

  renderSubject = (index) => {
    if (this.state.variations[index] && this.state.variations[index].subject) {
      return this.state.variations[index].subject;
    }
    return null;
  };

  renderLabel = (index) => {
    if (this.state.variations[index] && this.state.variations[index].label) {
      return this.state.variations[index].label;
    }
    return null;
  };

  renderPushSectionLayoutMessage = (index) => {
    if (this.channel === 'PUSH') {
      return (
        <React.Fragment>
          <Form ref={this.formRef} layout="vertical">
            {this.props.currentMessage.type === 'TRANSACTIONAL' && (
              <Card
                styles={{
                  marginBottom: 40,
                }}
              >
                <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
                  <div style={styles.headTitleContainer}>
                    Personalizing Content in Transactional Campaigns
                  </div>
                </div>
                <div style={{ ...styles.dataRowContainer, paddingBottom: 0 }}>
                  You can use any variable to personalize any of the fields in
                  the message below. Eg. To include order_id in the order
                  confirmation message, please specify the order_id variable in
                  the message as <span>{'{{token.order_id }}'}</span> . Please
                  also ensure that your variables are prefixed with the keyword
                  token. Once you launch this campaign, send the token as token:{' '}
                  <span>{'{ "order_id" : "abc123" }'}</span> in the{' '}
                  <a href={texts.campaignApi} target={'blank'}>
                    transactional campaign API call{' '}
                  </a>{' '}
                  for each order confirmation, so that the message gets
                  personalized.
                </div>
                <div style={styles.dataRowContainer}>
                  Please refer to our{' '}
                  <a href={texts.campaignApi} target={'blank'}>
                    documentation
                  </a>{' '}
                  for more details on transactional campaigns.
                </div>
              </Card>
            )}
            <Card>
              <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
                <div style={styles.headTitleContainer}>Layout</div>
              </div>
              <div style={styles.dataRowContainer}>
                <div style={styles.dataRowFirst}>
                  <div style={{ ...styles.editContainer }}>
                    <div style={{ ...styles.editLabel, paddingTop: '5px' }}>
                      LAYOUT
                    </div>
                    <div
                      style={{
                        ...styles.editFieldContainer,
                        flex: 3,
                        marginLeft: 20,
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      {this.renderPushType(index)}
                      {!this.isEditDisable() && (
                        <Tooltip placement={'top'} title={'Change Layout'}>
                          <i
                            data-cy="push-layout-type"
                            onClick={() =>
                              this.setState({ pushLayoutVisible: true })
                            }
                            className="fl-edit"
                            style={{
                              color: '#bbbcc1',
                              marginLeft: 10,
                              border: '1px solid',
                              borderRadius: '50%',
                              fontSize: '28px',
                              lineHeight: '28px',
                            }}
                          ></i>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <Card
              styles={{
                marginTop: 40,
              }}
            >
              <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
                <div style={styles.headTitleContainer}>Basic</div>
              </div>
              <div style={styles.dataRowContainer}>
                <div style={styles.dataRowFirst}>
                  <div style={styles.editContainer}>
                    <div
                      style={{
                        ...styles.editLabel,
                      }}
                    >
                      TITLE
                    </div>
                    <div
                      style={{
                        ...styles.editFieldContainer,
                        marginLeft: 20,
                        flex: 3,
                      }}
                    >
                      <Form.Item
                        data-cy="push_title"
                        validateStatus={
                          errorHandling(
                            this.props.errorUpdateMessageSubmit,
                            'variations[' + [index] + '].subject'
                          )
                            ? 'error'
                            : null
                        }
                        help={errorHandling(
                          this.props.errorUpdateMessageSubmit,
                          'variations[' + [index] + '].subject'
                        )}
                        id={
                          errorHandling(
                            this.props.errorUpdateMessageSubmit,
                            'variations[' + [index] + '].subject'
                          )
                            ? 'error'
                            : null
                        }
                      >
                        <InputSelector
                          type="richInline"
                          index={index}
                          id={
                            errorHandling(
                              this.props.errorUpdateMessageSubmit,
                              'variations[' + [index] + '].subject'
                            )
                              ? 'error'
                              : null
                          }
                          journeySteps={this.props.journeyStepsForVariable}
                          disabled={this.isEditDisable()}
                          attributeStyle={{
                            position: 'relative',
                            top: '-10px',
                            right: '-380px',
                            zIndex: 20,
                          }}
                          attributes={this.props.attributes}
                          events={this.props.events}
                          currentType={this.props.currentMessage.type}
                          currentEvent={this.props.currentMessage.event}
                          businessEvents={this.props.businessEvents}
                          value={this.renderSubject(index)}
                          readOnly={this.props.readOnly}
                          onChange={(value) =>
                            this.handleChangeVariations(index, 'subject', value)
                          }
                        />
                      </Form.Item>
                      <span
                        style={{
                          color: '#94969e',
                          fontSize: '10px',
                          paddingLeft: '5px',
                          paddingTop: '5px',
                          display: 'block',
                        }}
                      >
                        {'Recommended maximum length: 36 characters. Current count:' +
                          (this.state.variations[index] &&
                          this.state.variations[index].subject
                            ? this.state.variations[index].subject.length
                            : 0)}
                      </span>
                    </div>
                  </div>
                </div>
                <div style={styles.dataRow}>
                  {this.inputGenerator('message', index)}
                </div>
              </div>
            </Card>
            {this.state.variations[index] &&
              this.state.variations[index].pushType === 'BANNER' && (
                <Card
                  styles={{
                    marginTop: 40,
                  }}
                >
                  <div
                    style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}
                  >
                    <div style={styles.headTitleContainer}>
                      <span>Image</span>
                    </div>
                  </div>
                  <div style={styles.dataRowContainer}>
                    <div style={styles.dataRowFirst}>
                      {this.inputGenerator('image', index)}
                    </div>
                  </div>
                </Card>
              )}
            {this.showAndroidIosPreview() &&
              this.renderAndroidPushButton(index)}
            {/* {this.showAndroidIosPreview('IOS') && this.renderIosPushButton(index)} */}
            {this.renderKeyValuePairs(index)}
            {this.renderPushModalVisible(index)}
          </Form>
        </React.Fragment>
      );
    }
    return null;
  };

  renderInAppSectionLayoutMessage = (index) => {
    if (this.channel === 'IN_APP') {
      const layoutType = this.state?.variations[index]?.inAppLayoutType;
      return (
        <React.Fragment>
          <Form ref={this.formRef} layout="vertical">
            <Card>
              <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
                <div style={styles.headTitleContainer}>Layout</div>
              </div>
              <div style={styles.dataRowContainer}>
                <div style={styles.dataRowFirst}>
                  <div style={{ ...styles.editContainer, width: '100%' }}>
                    <Row gutter={[16, 16]}>
                      <Col
                        span={8}
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        {this.renderHTMLInAppAndOnSiteLayoutSelectorCard(
                          index,
                          'Modal',
                          'MODAL',
                          layoutType === 'MODAL',
                          () => this.selectInAppType(index, 'MODAL')
                        )}
                      </Col>
                      <Col
                        span={8}
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        {this.renderHTMLInAppAndOnSiteLayoutSelectorCard(
                          index,
                          'Card',
                          'CARD',
                          layoutType === 'CARD',
                          () => this.selectInAppType(index, 'CARD')
                        )}
                      </Col>
                      <Col
                        span={8}
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        {this.renderHTMLInAppAndOnSiteLayoutSelectorCard(
                          index,
                          'Image',
                          'IMAGE_ONLY',
                          layoutType === 'IMAGE_ONLY',
                          () => this.selectInAppType(index, 'IMAGE_ONLY')
                        )}
                      </Col>
                      <Col
                        span={8}
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        {this.renderHTMLInAppAndOnSiteLayoutSelectorCard(
                          index,
                          'LTR Banner',
                          'BANNER',
                          layoutType === 'BANNER',
                          () => this.selectInAppType(index, 'BANNER')
                        )}
                      </Col>
                      <Col
                        span={8}
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        {this.renderHTMLInAppAndOnSiteLayoutSelectorCard(
                          index,
                          'RTL Banner',
                          'BANNER_RTL',
                          layoutType === 'BANNER_RTL',
                          () => this.selectInAppType(index, 'BANNER_RTL')
                        )}
                      </Col>
                      <Col
                        span={8}
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        {this.renderHTMLInAppAndOnSiteLayoutSelectorCard(
                          index,
                          'Coupon',
                          'COUPON',
                          layoutType === 'COUPON',
                          () => this.selectInAppType(index, 'COUPON')
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Card>
            <Card styles={{ marginTop: 40 }}>
              <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
                <div style={styles.headTitleContainer}>Basic</div>
              </div>
              <div style={styles.dataRowContainer}>
                {layoutType !== 'IMAGE_ONLY' && (
                  <>
                    <div style={styles.dataRowFirst}>
                      <div style={styles.editContainer}>
                        <div
                          style={{
                            ...styles.editLabel,
                            position: 'relative',
                            top: -10,
                          }}
                        >
                          TITLE
                        </div>
                        <div
                          style={{
                            ...styles.editFieldContainer,
                            marginLeft: 20,
                            flex: 3,
                          }}
                        >
                          <InlineThemePicker
                            name="title"
                            variation={this.state.variations[index]}
                            customFonts={this.props.uploadedFonts}
                            onChange={(theme) => {
                              this.handleChangeVariations(
                                index,
                                'theme',
                                theme
                              );
                            }}
                          />

                          <Form.Item
                            validateStatus={
                              errorHandling(
                                this.props.errorUpdateMessageSubmit,
                                'variations[' + [index] + '].subject'
                              )
                                ? 'error'
                                : !this.renderSubject(index)
                                ? 'error'
                                : null
                            }
                            help={
                              errorHandling(
                                this.props.errorUpdateMessageSubmit,
                                'variations[' + [index] + '].subject'
                              )
                                ? 'error'
                                : !this.state?.variations[index]?.subject
                                ? 'required'
                                : null
                            }
                            id={
                              errorHandling(
                                this.props.errorUpdateMessageSubmit,
                                'variations[' + [index] + '].subject'
                              )
                                ? 'error'
                                : null
                            }
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <InputSelector
                              type="richInline"
                              style={{ maxWidth: '100%' }}
                              index={index}
                              id={
                                errorHandling(
                                  this.props.errorUpdateMessageSubmit,
                                  'variations[' + [index] + '].subject'
                                )
                                  ? 'error'
                                  : null
                              }
                              journeySteps={this.props.journeyStepsForVariable}
                              disabled={this.isEditDisable()}
                              attributeStyle={{
                                position: 'relative',
                                top: '-10px',
                                right: '-380px',
                                zIndex: 20,
                              }}
                              attributes={this.props.attributes}
                              events={this.props.events}
                              currentType={this.props.currentMessage.type}
                              currentEvent={this.props.currentMessage.event}
                              value={this.renderSubject(index)}
                              channel={this.channel}
                              businessEvents={this.props.businessEvents}
                              readOnly={this.props.readOnly}
                              onChange={(value) =>
                                this.handleChangeVariations(
                                  index,
                                  'subject',
                                  value
                                )
                              }
                            />
                          </Form.Item>
                          {/* <span
                            style={{
                              color: '#94969e',
                              fontSize: '10px',
                              paddingLeft: '5px',
                              paddingTop: '5px',
                              display: 'block',
                            }}
                          >
                            {'Recommended maximum length: 36 characters. Current count:' +
                              (this.state.variations[index] &&
                              this.state.variations[index].subject
                                ? this.state.variations[index].subject.length
                                : 0)}
                          </span> */}
                        </div>
                      </div>
                    </div>

                    <div style={styles.dataRow}>
                      {/* {this.inputGenerator('message', index)} */}
                      <div style={styles.editContainer}>
                        <div style={styles.editLabel}>MESSAGE</div>
                        <div
                          style={{
                            ...styles.editFieldContainer,
                            marginLeft: 20,
                            flex: 3,
                          }}
                        >
                          <InlineThemePicker
                            name="message"
                            variation={this.state.variations[index]}
                            customFonts={this.props.uploadedFonts}
                            onChange={(theme) => {
                              this.handleChangeVariations(
                                index,
                                'theme',
                                theme
                              );
                            }}
                          />

                          <Form.Item
                            validateStatus={
                              errorHandling(
                                this.props.errorUpdateMessageSubmit,
                                'variations[' + [index] + '].message'
                              )
                                ? 'error'
                                : !this.state?.variations[index]?.message
                                ? 'error'
                                : null
                            }
                            help={
                              errorHandling(
                                this.props.errorUpdateMessageSubmit,
                                'variations[' + [index] + '].message'
                              )
                                ? 'error'
                                : !this.state?.variations[index]?.message
                                ? 'required'
                                : null
                            }
                            id={
                              errorHandling(
                                this.props.errorUpdateMessageSubmit,
                                'variations[' + [index] + '].message'
                              )
                                ? 'error'
                                : null
                            }
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <InputSelector
                              type="richTextArea"
                              index={index}
                              id={
                                errorHandling(
                                  this.props.errorUpdateMessageSubmit,
                                  'variations[' + [index] + '].message'
                                )
                                  ? 'error'
                                  : null
                              }
                              journeySteps={this.props.journeyStepsForVariable}
                              disabled={this.isEditDisable()}
                              textArea
                              attributeStyle={{
                                position: 'relative',
                                top: '-7px',
                                right: '150px',
                              }}
                              className={'not-resizable'}
                              attributes={this.props.attributes}
                              events={this.props.events}
                              currentType={this.props.currentMessage.type}
                              currentEvent={this.props.currentMessage.event}
                              channel={this.channel}
                              businessEvents={this.props.businessEvents}
                              style={{
                                paddingRight: 58,
                                color: 'rgb(61, 64, 78)',
                                fontFamily: 'monospace',
                                fontSize: 13,
                                fontWeight: 400,
                                width: '100%',
                              }}
                              autoSize={{ maxRows: 3, minRows: 3 }}
                              rows={3}
                              value={
                                this.state.variations[index] &&
                                this.state.variations[index].message
                                  ? this.state.variations[index].message
                                  : ''
                              }
                              onChange={(value) =>
                                this.handleChangeVariations(
                                  index,
                                  'message',
                                  value
                                )
                              }
                              size="large"
                            />
                          </Form.Item>
                          {/* <span
                            style={{
                              color: '#94969e',
                              fontSize: '10px',
                              paddingLeft: '5px',
                              paddingTop: '5px',
                              display: 'block',
                            }}
                          >
                            {'Recommended maximum length: ' +
                              (this.state.variations[index] &&
                              this.state.variations[index].pushType === 'BANNER'
                                ? 36
                                : 180) +
                              ' characters. Current count:' +
                              (this.state.variations[index] &&
                              this.state.variations[index].message
                                ? this.state.variations[index].message.length
                                : 0)}
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div style={styles.dataRow}>
                  <div style={styles.editContainer}>
                    <div style={styles.editLabel}>IMAGE</div>
                    <div
                      style={{
                        marginLeft: 20,
                        flex: 3,
                        fontSize: 13,
                        lineHeight: 1.43,
                        color: '#3d404e',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Form.Item
                          style={{
                            margin: 0,
                            width: '100%',
                          }}
                          validateStatus={
                            errorHandling(
                              this.props.errorUpdateMessageSubmit,
                              'variations[' + [index] + '].image'
                            )
                              ? 'error'
                              : this.state.imageUrlFormatError
                              ? 'error'
                              : ['IMAGE_ONLY', 'CARD'].includes(
                                  this.state?.variations[index]?.inAppLayoutType
                                ) && !this.state?.variations[index]?.image
                              ? 'error'
                              : null
                          }
                          help={
                            ['IMAGE_ONLY', 'CARD'].includes(
                              this.state?.variations[index]?.inAppLayoutType
                            ) && !this.state?.variations[index]?.image
                              ? 'required'
                              : null
                          }
                          id={
                            errorHandling(
                              this.props.errorUpdateMessageSubmit,
                              'variations[' + [index] + '].image'
                            )
                              ? 'error'
                              : this.state.imageUrlFormatError
                              ? 'error'
                              : null
                          }
                        >
                          <InputSelector
                            type="richInline"
                            index={index}
                            dataCy="image-url"
                            id={'variations[' + [index] + '].image'}
                            disabled={this.isEditDisable()}
                            attributeStyle={{ top: '30px', right: '0px' }}
                            attributes={this.props.attributes}
                            events={this.props.events}
                            currentType={this.props.currentMessage.type}
                            currentEvent={this.props.currentMessage.event}
                            journeySteps={this.props.journeyStepsForVariable}
                            channel={this.channel}
                            businessEvents={this.props.businessEvents}
                            hideEmojiPicker
                            placeholder="https://www.abc.com/image.png"
                            value={
                              this.state.variations[index] &&
                              this.state.variations[index].image
                                ? this.state.variations[index].image
                                : ''
                            }
                            onChange={(value) =>
                              this.handleChangeVariations(index, 'image', value)
                            }
                            size="large"
                            style={{ ...styles.editField, width: '100%' }}
                          />
                        </Form.Item>
                        {!isNil(this.state.variations[index].image) &&
                        this.state.variations[index].image.length > 0 ? (
                          <Button
                            disabled={this.isEditDisable()}
                            type="primary"
                            shape="round"
                            ghost
                            onClick={() =>
                              this.handleChangeVariations(index, 'image', null)
                            }
                            size={'small'}
                            style={{
                              marginLeft: 15,
                            }}
                          >
                            Reset
                          </Button>
                        ) : (
                          <React.Fragment>
                            <span style={{ margin: '-27px 10px 0 10px' }}>
                              or
                            </span>
                            {this.renderPushUploader('image', index)}
                          </React.Fragment>
                        )}
                      </div>
                      {layoutType === 'COUPON' ? (
                        <div
                          style={{
                            color: '#94969e',
                            fontSize: '10px',
                            paddingLeft: '5px',
                            paddingTop: '5px',
                            display: 'block',
                          }}
                        >
                          {
                            'Recommended size: 560 px x 320 px. Aspect ratio (width:height): 1.75:1. Actual rendering depends on screen size. JPG, JPEG, PNG formats only. GIF format also available but for iOS 10+ only. Less than 1 MB in size. URL must begin with HTTP.'
                          }
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Card>

            <InAppActions
              disabled={this.isEditDisable()}
              readOnly={this.props.readOnly}
              variation={this.state.variations[index]}
              index={index}
              handleChange={(prop, value) => {
                this.handleChangeVariations(index, prop, value);
              }}
              journeySteps={this.props.journeyStepsForVariable}
              attributes={this.props.attributes}
              events={this.props.events}
              customFonts={this.props.uploadedFonts}
              businessEvents={this.props.businessEvents}
            />

            {!['IN_APP', 'ON_SITE'].includes(this.channel) && (
              <InAppThemeOptions
                disabled={this.isEditDisable()}
                variation={this.state.variations[index]}
                handleChange={(theme) => {
                  this.handleChangeVariations(index, 'theme', theme);
                }}
                channel={this.channel}
                customFonts={this.props.uploadedFonts}
              />
            )}
            {/*{this.renderInAppModalVisible(index)}*/}
          </Form>
        </React.Fragment>
      );
    }
    return null;
  };

  renderHTMLInAppAndOnSiteLayoutSelectorCard = (
    index,
    title,
    name,
    isSelected,
    onClick
  ) => {
    const baseStyle = {
      padding: 20,
      cursor: 'pointer',
      minHeight: 320,
      maxWidth: 300,
      borderRadius: 15,
      boxSizing: 'border-box',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    };

    const layoutSelectorStyle = {
      ...baseStyle,
      backgroundColor: 'rgb(246, 246, 246)',
      border: '2px solid rgb(246, 246, 246)',
    };

    const layoutSelectorStyleSelected = {
      ...baseStyle,
      backgroundColor: 'rgb(227, 242, 253)',
      borderRadius: 15,
      border: '2px solid rgb(0, 130, 207)',
    };

    const imageStyle = {
      maxWidth: '100%',
      // maxHeight: '200px',
      width: '100%',
      height: 'auto',
      borderRadius: 3,
    };

    const textStyle = {
      color: 'rgb(136, 139, 146)',
      fontSize: 18,
      display: 'flex',
      justifyContent: 'center',
    };

    const containerStyle = {
      backgroundColor: '#fff',
      borderRadius: 8,
      boxShadow: '0px 0px 6px -1px #cecece',
      textAlign: 'center',
      padding: 10,
      overflow: 'hidden',
    };

    const blackButton = (
      <Button
        disabled
        type="text"
        size={'small'}
        style={{
          backgroundColor: '#000',
          color: '#fff',
          borderRadius: 5,
        }}
      >
        Button
      </Button>
    );

    const messageTitle = (
      <div
        style={{
          fontSize: 12,
          fontWeight: 'bold',
        }}
      >
        Message Title
      </div>
    );
    const messageBody = (
      <div
        style={{
          fontSize: 11,
        }}
      >
        Message Body
      </div>
    );
    return (
      <div
        className={styles.content}
        style={isSelected ? layoutSelectorStyleSelected : layoutSelectorStyle}
        onClick={onClick}
      >
        <div
          style={{
            position: 'relative',
          }}
        >
          {(name === 'MODAL' ||
            name === 'IMAGE_ONLY' ||
            name === 'COUPON' ||
            name === 'IMAGE' ||
            name === 'TEXT') && (
            <CloseOutlined
              className="inAppPreview__close"
              style={{
                right: '-10px',
                zIndex: 1,
              }}
            />
          )}

          {name === 'MODAL' && (
            <div style={containerStyle}>
              {messageTitle}
              <div
                style={{
                  margin: '10px 0',
                }}
              >
                <img
                  src={inAppNewLayoutBanner}
                  alt={title}
                  style={imageStyle}
                />
              </div>
              {messageBody}
              <div
                style={{
                  marginTop: 10,
                }}
              >
                {blackButton}
              </div>
            </div>
          )}

          {(name === 'CARD' || name === 'TEXT') && (
            <div
              style={{
                ...containerStyle,
                padding: 0,
              }}
            >
              <div>
                <img
                  src={inAppNewLayoutBanner}
                  alt={title}
                  style={{
                    ...imageStyle,
                    borderRadius: 0,
                    marginTop: '-32px',
                  }}
                />
              </div>

              <div
                style={{
                  textAlign: name === 'CARD' ? 'left' : '',
                  padding: 10,
                }}
              >
                {messageTitle}
                {messageBody}
              </div>

              <div
                style={{
                  textAlign: name === 'CARD' ? 'right' : '',
                  padding: 10,
                }}
              >
                {name === 'CARD' && (
                  <Button
                    disabled
                    type="text"
                    size={'small'}
                    style={{
                      backgroundColor: '#fff',
                      color: '#ddd',
                      borderRadius: 5,
                    }}
                  >
                    Cancel
                  </Button>
                )}

                {blackButton}
              </div>
            </div>
          )}

          {(name === 'IMAGE_ONLY' || name === 'IMAGE' || name === 'COUPON') && (
            <div
              style={{
                ...containerStyle,
                padding: 0,
              }}
            >
              <div
                style={{
                  position: 'relative',
                }}
              >
                <img
                  src={inAppNewLayoutBanner}
                  alt={title}
                  style={{
                    ...imageStyle,
                    borderRadius: 0,
                  }}
                />

                {name === 'COUPON' && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '10%',
                      width: '100%',
                      padding: 10,
                    }}
                  >
                    {messageTitle}
                    {messageBody}

                    <div
                      style={{
                        backgroundColor: '#fff',
                        borderRadius: 3,
                        padding: 5,
                        textAlign: 'right',
                        marginTop: 10,
                      }}
                    >
                      <SearchOutlined style={{ color: '#3188f6' }} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {(name === 'BANNER' || name === 'BANNER_RTL') && (
            <div
              style={{
                ...containerStyle,
                display: 'flex',
                gap: 10,
                direction: name === 'BANNER_RTL' ? 'rtl' : 'ltr',
              }}
            >
              <div>
                <img
                  src={inAppNewLayoutBanner}
                  alt={title}
                  style={{
                    ...imageStyle,
                    width: '70px',
                  }}
                />
              </div>
              <div
                style={{
                  textAlign: name === 'BANNER_RTL' ? 'right' : 'left',
                }}
              >
                {messageTitle}
                {messageBody}
              </div>
            </div>
          )}
        </div>

        <div style={textStyle}>{title}</div>
      </div>
    );
  };

  renderOnSiteSectionLayoutMessage = (index) => {
    if (this.channel === 'ON_SITE') {
      const onSiteLayoutType = this.state?.variations[index]?.onSiteLayoutType;

      const isNotValid =
        (this.state?.variations[index].androidButton1Label &&
          !this.state?.variations[index].androidButton1OnClickAction) ||
        (this.state?.variations[index].androidButton1OnClickAction &&
          !this.state?.variations[index].androidButton1Label);
      return (
        <React.Fragment>
          <Form ref={this.formRef} layout="vertical">
            <Card>
              <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
                <div style={styles.headTitleContainer}>Layout</div>
              </div>
              <div style={styles.dataRowContainer}>
                <div style={styles.dataRowFirst}>
                  <div style={{ ...styles.editContainer }}>
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        {this.renderHTMLInAppAndOnSiteLayoutSelectorCard(
                          index,
                          'Image',
                          'IMAGE',
                          onSiteLayoutType === 'IMAGE',
                          () => this.selectOnSiteType(index, 'IMAGE')
                        )}
                      </Col>
                      <Col span={8}>
                        {this.renderHTMLInAppAndOnSiteLayoutSelectorCard(
                          index,
                          'Text & Link',
                          'TEXT',
                          onSiteLayoutType === 'TEXT',
                          () => this.selectOnSiteType(index, 'TEXT')
                        )}
                      </Col>
                      <Col span={8}>
                        {this.renderHTMLInAppAndOnSiteLayoutSelectorCard(
                          index,
                          'Coupon',
                          'COUPON',
                          onSiteLayoutType === 'COUPON',
                          () => this.selectOnSiteType(index, 'COUPON')
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Card>
            <Card
              styles={{
                marginTop: 40,
              }}
            >
              <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
                <div style={styles.headTitleContainer}>Basic</div>
              </div>
              <div style={styles.dataRowContainer}>
                {onSiteLayoutType !== 'IMAGE' && (
                  <>
                    {onSiteLayoutType !== 'COUPON' && (
                      <div style={styles.dataRowFirst}>
                        <div style={styles.editContainer}>
                          <div
                            style={{
                              ...styles.editLabel,
                              position: 'relative',
                              top: -10,
                            }}
                          >
                            LABEL
                          </div>
                          <div
                            style={{
                              ...styles.editFieldContainer,
                              marginLeft: 20,
                              flex: 3,
                            }}
                          >
                            <InlineThemePicker
                              name="label"
                              variation={this.state.variations[index]}
                              customFonts={this.props.uploadedFonts}
                              onChange={(theme) => {
                                this.handleChangeVariations(
                                  index,
                                  'theme',
                                  theme
                                );
                              }}
                            />

                            <Form.Item
                              validateStatus={
                                errorHandling(
                                  this.props.errorUpdateMessageSubmit,
                                  'variations[' + [index] + '].label'
                                )
                                  ? 'error'
                                  : null
                              }
                              help={
                                errorHandling(
                                  this.props.errorUpdateMessageSubmit,
                                  'variations[' + [index] + '].label'
                                )
                                  ? 'error'
                                  : null
                              }
                              id={
                                errorHandling(
                                  this.props.errorUpdateMessageSubmit,
                                  'variations[' + [index] + '].label'
                                )
                                  ? 'error'
                                  : null
                              }
                            >
                              <InputSelector
                                type="richInline"
                                style={{ maxWidth: '100%' }}
                                index={index}
                                id={
                                  errorHandling(
                                    this.props.errorUpdateMessageSubmit,
                                    'variations[' + [index] + '].label'
                                  )
                                    ? 'error'
                                    : null
                                }
                                journeySteps={
                                  this.props.journeyStepsForVariable
                                }
                                disabled={this.isEditDisable()}
                                attributeStyle={{
                                  position: 'relative',
                                  top: '-10px',
                                  right: '-380px',
                                  zIndex: 20,
                                }}
                                attributes={this.props.attributes}
                                events={this.props.events}
                                currentType={this.props.currentMessage.type}
                                currentEvent={this.props.currentMessage.event}
                                businessEvents={this.props.businessEvents}
                                channel={this.channel}
                                value={
                                  this.state.variations[index] &&
                                  this.state.variations[index].label
                                    ? this.state.variations[index].label
                                    : ''
                                }
                                readOnly={this.props.readOnly}
                                onChange={(value) =>
                                  this.handleChangeVariations(
                                    index,
                                    'label',
                                    value
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    )}

                    <div style={styles.dataRow}>
                      <div style={styles.editContainer}>
                        <div
                          style={{
                            ...styles.editLabel,
                            position: 'relative',
                            top: -10,
                          }}
                        >
                          TITLE
                        </div>
                        <div
                          style={{
                            ...styles.editFieldContainer,
                            marginLeft: 20,
                            flex: 3,
                          }}
                        >
                          <InlineThemePicker
                            name="title"
                            variation={this.state.variations[index]}
                            customFonts={this.props.uploadedFonts}
                            onChange={(theme) => {
                              this.handleChangeVariations(
                                index,
                                'theme',
                                theme
                              );
                            }}
                          />
                          <Form.Item
                            validateStatus={
                              errorHandling(
                                this.props.errorUpdateMessageSubmit,
                                'variations[' + [index] + '].subject'
                              )
                                ? 'error'
                                : !this.state?.variations[index]?.subject
                                ? 'error'
                                : null
                            }
                            help={
                              errorHandling(
                                this.props.errorUpdateMessageSubmit,
                                'variations[' + [index] + '].subject'
                              )
                                ? 'error'
                                : !this.state?.variations[index]?.subject
                                ? 'required'
                                : null
                            }
                            id={
                              errorHandling(
                                this.props.errorUpdateMessageSubmit,
                                'variations[' + [index] + '].subject'
                              )
                                ? 'error'
                                : null
                            }
                          >
                            <InputSelector
                              type="richInline"
                              style={{ maxWidth: '100%' }}
                              index={index}
                              id={
                                errorHandling(
                                  this.props.errorUpdateMessageSubmit,
                                  'variations[' + [index] + '].subject'
                                )
                                  ? 'error'
                                  : null
                              }
                              journeySteps={this.props.journeyStepsForVariable}
                              disabled={this.isEditDisable()}
                              attributeStyle={{
                                position: 'relative',
                                top: '-10px',
                                right: '-380px',
                                zIndex: 20,
                              }}
                              attributes={this.props.attributes}
                              events={this.props.events}
                              currentType={this.props.currentMessage.type}
                              currentEvent={this.props.currentMessage.event}
                              businessEvents={this.props.businessEvents}
                              channel={this.channel}
                              value={this.renderSubject(index)}
                              readOnly={this.props.readOnly}
                              onChange={(value) =>
                                this.handleChangeVariations(
                                  index,
                                  'subject',
                                  value
                                )
                              }
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>

                    <div style={styles.dataRow}>
                      <div style={styles.editContainer}>
                        <div style={styles.editLabel}>MESSAGE</div>
                        <div
                          style={{
                            ...styles.editFieldContainer,
                            marginLeft: 20,
                            flex: 3,
                          }}
                        >
                          <InlineThemePicker
                            name="message"
                            variation={this.state.variations[index]}
                            customFonts={this.props.uploadedFonts}
                            onChange={(theme) => {
                              this.handleChangeVariations(
                                index,
                                'theme',
                                theme
                              );
                            }}
                          />
                          <Form.Item
                            validateStatus={
                              errorHandling(
                                this.props.errorUpdateMessageSubmit,
                                'variations[' + [index] + '].message'
                              )
                                ? 'error'
                                : null
                            }
                            help={
                              errorHandling(
                                this.props.errorUpdateMessageSubmit,
                                'variations[' + [index] + '].message'
                              )
                                ? 'error'
                                : null
                            }
                            id={
                              errorHandling(
                                this.props.errorUpdateMessageSubmit,
                                'variations[' + [index] + '].message'
                              )
                                ? 'error'
                                : null
                            }
                          >
                            <InputSelector
                              type="richTextArea"
                              index={index}
                              id={
                                errorHandling(
                                  this.props.errorUpdateMessageSubmit,
                                  'variations[' + [index] + '].message'
                                )
                                  ? 'error'
                                  : null
                              }
                              journeySteps={this.props.journeyStepsForVariable}
                              disabled={this.isEditDisable()}
                              textArea
                              attributeStyle={{
                                position: 'relative',
                                top: '-7px',
                                right: '150px',
                              }}
                              className={'not-resizable'}
                              attributes={this.props.attributes}
                              events={this.props.events}
                              currentType={this.props.currentMessage.type}
                              currentEvent={this.props.currentMessage.event}
                              channel={this.channel}
                              businessEvents={this.props.businessEvents}
                              style={{
                                paddingRight: 58,
                                color: 'rgb(61, 64, 78)',
                                fontFamily: 'monospace',
                                fontSize: 13,
                                fontWeight: 400,
                                width: '100%',
                              }}
                              autoSize={{ maxRows: 3, minRows: 3 }}
                              rows={3}
                              value={
                                this.state.variations[index] &&
                                this.state.variations[index].message
                                  ? this.state.variations[index].message
                                  : ''
                              }
                              onChange={(value) =>
                                this.handleChangeVariations(
                                  index,
                                  'message',
                                  value
                                )
                              }
                              size="large"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div style={styles.dataRow}>
                      <div style={styles.editContainer}>
                        <div style={styles.editLabel}>IMAGE</div>
                        <div
                          style={{
                            marginLeft: 20,
                            flex: 3,
                            fontSize: 13,
                            lineHeight: 1.43,
                            color: '#3d404e',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Form.Item
                              style={{
                                margin: 0,
                                width: '100%',
                              }}
                              validateStatus={
                                errorHandling(
                                  this.props.errorUpdateMessageSubmit,
                                  'variations[' + [index] + '].image'
                                )
                                  ? 'error'
                                  : this.state.imageUrlFormatError
                                  ? 'error'
                                  : this.state?.variations[index]
                                      .onSiteLayoutType === 'IMAGE' &&
                                    !this.state?.variations[index]?.image
                                  ? 'error'
                                  : null
                              }
                              help={
                                this.state?.variations[index]
                                  .onSiteLayoutType === 'IMAGE' &&
                                !this.state?.variations[index]?.image
                                  ? 'required'
                                  : null
                              }
                              id={
                                errorHandling(
                                  this.props.errorUpdateMessageSubmit,
                                  'variations[' + [index] + '].image'
                                )
                                  ? 'error'
                                  : this.state.imageUrlFormatError
                                  ? 'error'
                                  : null
                              }
                            >
                              <InputSelector
                                type="richInline"
                                index={index}
                                dataCy="image-url"
                                id={'variations[' + [index] + '].image'}
                                disabled={this.isEditDisable()}
                                attributeStyle={{ top: '30px', right: '0px' }}
                                attributes={this.props.attributes}
                                events={this.props.events}
                                currentType={this.props.currentMessage.type}
                                currentEvent={this.props.currentMessage.event}
                                journeySteps={
                                  this.props.journeyStepsForVariable
                                }
                                channel={this.channel}
                                businessEvents={this.props.businessEvents}
                                hideEmojiPicker
                                placeholder="https://www.abc.com/image.png"
                                value={
                                  this.state.variations[index] &&
                                  this.state.variations[index].image
                                    ? this.state.variations[index].image
                                    : ''
                                }
                                onChange={(value) =>
                                  this.handleChangeVariations(
                                    index,
                                    'image',
                                    value
                                  )
                                }
                                size="large"
                                style={{ ...styles.editField, width: '100%' }}
                              />
                            </Form.Item>
                            {!isNil(this.state.variations[index].image) &&
                            this.state.variations[index].image.length > 0 ? (
                              <Button
                                disabled={this.isEditDisable()}
                                type="primary"
                                shape="round"
                                ghost
                                onClick={() =>
                                  this.handleChangeVariations(
                                    index,
                                    'image',
                                    null
                                  )
                                }
                                size={'small'}
                                style={{
                                  marginLeft: 15,
                                }}
                              >
                                Reset
                              </Button>
                            ) : (
                              <React.Fragment>
                                <span style={{ margin: '-27px 10px 0 10px' }}>
                                  or
                                </span>
                                {this.renderPushUploader('image', index)}
                              </React.Fragment>
                            )}
                          </div>
                          {onSiteLayoutType === 'TEXT' ? (
                            <div
                              style={{
                                color: '#94969e',
                                fontSize: '10px',
                                paddingLeft: '5px',
                                paddingTop: '5px',
                                display: 'block',
                              }}
                            >
                              {
                                'Recommended size: 560 px x 100 px. Aspect ratio (width:height): 5.6:1. Actual rendering depends on screen size. JPG, JPEG, PNG formats only. GIF format also available but for iOS 10+ only. Less than 1 MB in size. URL must begin with HTTP.'
                              }
                            </div>
                          ) : onSiteLayoutType === 'COUPON' ? (
                            <div
                              style={{
                                color: '#94969e',
                                fontSize: '10px',
                                paddingLeft: '5px',
                                paddingTop: '5px',
                                display: 'block',
                              }}
                            >
                              {
                                'Recommended size: 560 px x 320 px. Aspect ratio (width:height): 1.75:1. Actual rendering depends on screen size. JPG, JPEG, PNG formats only. GIF format also available but for iOS 10+ only. Less than 1 MB in size. URL must begin with HTTP.'
                              }
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    <div style={styles.dataRow}>
                      <div style={styles.editContainer}>
                        <div style={styles.editLabel}>BORDER RADIUS</div>
                        <BorderRadiusInput
                          variation={this.state.variations[index]}
                          onChange={(theme) =>
                            this.handleChangeVariations(index, 'theme', theme)
                          }
                        />
                      </div>
                    </div>
                    {onSiteLayoutType === 'COUPON' && (
                      <div style={styles.dataRow}>
                        <div style={styles.editContainer}>
                          <div
                            style={{
                              ...styles.editLabel,
                              position: 'relative',
                              top: -10,
                            }}
                          >
                            Coupon Code
                          </div>
                          <div
                            style={{
                              ...styles.editFieldContainer,
                              marginLeft: 20,
                              flex: 3,
                            }}
                          >
                            <InlineThemePicker
                              name="coupon"
                              variation={this.state.variations[index]}
                              customFonts={this.props.uploadedFonts}
                              onChange={(theme) => {
                                this.handleChangeVariations(
                                  index,
                                  'theme',
                                  theme
                                );
                              }}
                            />
                            <Form.Item
                              validateStatus={
                                errorHandling(
                                  this.props.errorUpdateMessageSubmit,
                                  'variations[' + [index] + '].subject'
                                )
                                  ? 'error'
                                  : !this.state?.variations[index]?.couponCode
                                  ? 'error'
                                  : null
                              }
                              help={
                                errorHandling(
                                  this.props.errorUpdateMessageSubmit,
                                  'variations[' + [index] + '].couponCode'
                                )
                                  ? 'error'
                                  : !this.state?.variations[index]?.couponCode
                                  ? 'required'
                                  : null
                              }
                              id={
                                errorHandling(
                                  this.props.errorUpdateMessageSubmit,
                                  'variations[' + [index] + '].couponCode'
                                )
                                  ? 'error'
                                  : null
                              }
                            >
                              <InputSelector
                                type="richInline"
                                style={{ maxWidth: '100%' }}
                                index={index}
                                id={
                                  errorHandling(
                                    this.props.errorUpdateMessageSubmit,
                                    'variations[' + [index] + '].couponCode'
                                  )
                                    ? 'error'
                                    : null
                                }
                                journeySteps={
                                  this.props.journeyStepsForVariable
                                }
                                disabled={this.isEditDisable()}
                                attributeStyle={{
                                  position: 'relative',
                                  top: '-10px',
                                  right: '-380px',
                                  zIndex: 20,
                                }}
                                attributes={this.props.attributes}
                                events={this.props.events}
                                currentType={this.props.currentMessage.type}
                                currentEvent={this.props.currentMessage.event}
                                businessEvents={this.props.businessEvents}
                                channel={this.channel}
                                value={
                                  this.state.variations[index] &&
                                  this.state.variations[index].couponCode
                                    ? this.state.variations[index].couponCode
                                    : ''
                                }
                                readOnly={this.props.readOnly}
                                onChange={(value) =>
                                  this.handleChangeVariations(
                                    index,
                                    'couponCode',
                                    value
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {onSiteLayoutType === 'IMAGE' && (
                  <>
                    <div style={styles.dataRow}>
                      <div style={styles.editContainer}>
                        <div style={styles.editLabel}>IMAGE</div>
                        <div
                          style={{
                            marginLeft: 20,
                            flex: 3,
                            fontSize: 13,
                            lineHeight: 1.43,
                            color: '#3d404e',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Form.Item
                              style={{
                                margin: 0,
                                width: '100%',
                              }}
                              validateStatus={
                                errorHandling(
                                  this.props.errorUpdateMessageSubmit,
                                  'variations[' + [index] + '].image'
                                )
                                  ? 'error'
                                  : this.state.imageUrlFormatError
                                  ? 'error'
                                  : this.state?.variations[index]
                                      .onSiteLayoutType === 'IMAGE' &&
                                    !this.state?.variations[index]?.image
                                  ? 'error'
                                  : null
                              }
                              help={
                                this.state?.variations[index]
                                  .onSiteLayoutType === 'IMAGE' &&
                                !this.state?.variations[index]?.image
                                  ? 'required'
                                  : null
                              }
                              id={
                                errorHandling(
                                  this.props.errorUpdateMessageSubmit,
                                  'variations[' + [index] + '].image'
                                )
                                  ? 'error'
                                  : this.state.imageUrlFormatError
                                  ? 'error'
                                  : null
                              }
                            >
                              <InputSelector
                                type="richInline"
                                index={index}
                                dataCy="image-url"
                                id={'variations[' + [index] + '].image'}
                                disabled={this.isEditDisable()}
                                attributeStyle={{ top: '30px', right: '0px' }}
                                attributes={this.props.attributes}
                                events={this.props.events}
                                currentType={this.props.currentMessage.type}
                                currentEvent={this.props.currentMessage.event}
                                journeySteps={
                                  this.props.journeyStepsForVariable
                                }
                                channel={this.channel}
                                businessEvents={this.props.businessEvents}
                                hideEmojiPicker
                                placeholder="https://www.abc.com/image.png"
                                value={
                                  this.state.variations[index] &&
                                  this.state.variations[index].image
                                    ? this.state.variations[index].image
                                    : ''
                                }
                                onChange={(value) =>
                                  this.handleChangeVariations(
                                    index,
                                    'image',
                                    value
                                  )
                                }
                                size="large"
                                style={{ ...styles.editField, width: '100%' }}
                              />
                            </Form.Item>
                            {!isNil(this.state.variations[index].image) &&
                            this.state.variations[index].image.length > 0 ? (
                              <Button
                                disabled={this.isEditDisable()}
                                type="primary"
                                shape="round"
                                ghost
                                onClick={() =>
                                  this.handleChangeVariations(
                                    index,
                                    'image',
                                    null
                                  )
                                }
                                size={'small'}
                                style={{
                                  marginLeft: 15,
                                }}
                              >
                                Reset
                              </Button>
                            ) : (
                              <React.Fragment>
                                <span style={{ margin: '-27px 10px 0 10px' }}>
                                  or
                                </span>
                                {this.renderPushUploader('image', index)}
                              </React.Fragment>
                            )}
                          </div>
                          <div
                            style={{
                              color: '#94969e',
                              fontSize: '10px',
                              paddingLeft: '5px',
                              paddingTop: '5px',
                              display: 'block',
                            }}
                          >
                            {
                              'Width of the picture is fixed to 560px; height could be any desired. Any picture will be resized to a one with width of 560px. Actual rendering depends on screen size. JPG, JPEG, PNG formats only. GIF format also available but for iOS 10+ only. Less than 1 MB in size. URL must begin with HTTP.'
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={styles.dataRow}>
                      <div style={styles.editContainer}>
                        <span style={styles.editLabel}>BORDER RADIUS</span>
                        <BorderRadiusInput
                          variation={this.state.variations[index]}
                          onChange={(theme) =>
                            this.handleChangeVariations(index, 'theme', theme)
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Card>
            {onSiteLayoutType !== 'COUPON' && (
              <OnSiteActions
                disabled={this.isEditDisable()}
                readOnly={this.props.readOnly}
                variation={this.state.variations[index]}
                handleChange={(prop, value) => {
                  this.handleChangeVariations(index, prop, value);
                }}
                journeySteps={this.props.journeyStepsForVariable}
                attributes={this.props.attributes}
                events={this.props.events}
                businessEvents={this.props.businessEvents}
                customFonts={this.props.uploadedFonts}
                isNotValid={isNotValid}
                index={index}
              />
            )}
            {!['IN_APP', 'ON_SITE'].includes(this.channel) && (
              <InAppThemeOptions
                disabled={this.isEditDisable()}
                variation={this.state.variations[index]}
                handleChange={(theme) => {
                  this.handleChangeVariations(index, 'theme', theme);
                }}
                channel={this.channel}
                customFonts={this.props.uploadedFonts}
              />
            )}
          </Form>
        </React.Fragment>
      );
    }
    return null;
  };

  renderWhatsAppSectionLayoutMessage = (index) => {
    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;

    const regex = /\{\{[0-9]+\}\}+/g;
    const variablesCount =
      this.props.whatsAppTemplateObject[index]?.bodyText.match(regex) || [];
    const isDynamic = this.props.whatsAppTemplateObject[
      index
    ]?.templateButtons?.buttons?.some((item) => item?.url === 'DYNAMIC');
    if (this.channel === 'WHATSAPP') {
      return (
        <>
          <Card>
            <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
              <div style={styles.headTitleContainer}>Message Content</div>
            </div>
            {this.props.loadingWhatsAppTemplateObject ? (
              <div
                style={{
                  backgroundColor: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                  paddingTop: 50,
                  paddingBottom: 50,
                }}
              >
                <Spin indicator={loadingSpin} />
              </div>
            ) : (
              <div style={styles.dataRowContainer}>
                <div style={styles.dataRowFirst}>
                  <div style={{ ...styles.editContainer }}>
                    <div style={{ ...styles.editLabel }}>TEMPLATE NAME</div>
                    <div
                      style={{
                        ...styles.editFieldContainer,
                        flex: 3,
                        marginLeft: 20,
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      {this.props.whatsAppTemplateObject[index]?.name}
                      {!this.isEditDisable() && (
                        <Tooltip placement={'top'} title={'Change Layout'}>
                          <i
                            data-cy="push-layout-type"
                            onClick={() =>
                              this.setState({ whatsAppLayoutVisible: true })
                            }
                            className="fl-edit"
                            style={{
                              color: '#bbbcc1',
                              marginLeft: 10,
                              border: '1px solid',
                              borderRadius: '50%',
                              fontSize: '28px',
                              lineHeight: '28px',
                            }}
                          ></i>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
                {this.props.whatsAppTemplateObject[index]?.bodyText && (
                  <div style={styles.dataRow}>
                    <div style={styles.editContainer}>
                      <div style={{ ...styles.editLabel }}>TEMPLATE TEXT</div>
                      <div
                        style={{
                          ...styles.editFieldContainer,
                          flex: 3,
                          marginLeft: 20,
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          {this.props.whatsAppTemplateObject[index]?.bodyText
                            .split('\n')
                            .map((item, i) => (
                              <span key={i}>
                                {item}
                                <br />
                              </span>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {variablesCount.length > 1 && (
                  <div style={styles.dataRow}>
                    <div style={{ ...styles.editContainer }}>
                      <div style={{ ...styles.editLabel }}>VARIABLES</div>
                      <div
                        style={{
                          flex: 3,
                        }}
                      >
                        {variablesCount.map((variables, i) => {
                          return (
                            <div key={i} style={styles.editContainer}>
                              <div
                                style={{
                                  ...styles.editLabel,
                                }}
                              >
                                {variables}
                              </div>
                              <div
                                style={{
                                  ...styles.editFieldContainer,
                                  marginLeft: 20,
                                  flex: 3,
                                }}
                              >
                                <Form.Item>
                                  <InputSelector
                                    type="richInline"
                                    index={index}
                                    disabled={this.isEditDisable()}
                                    id="description-text-area"
                                    attributes={this.props.attributes}
                                    events={this.props.events}
                                    currentType={this.props.currentMessage.type}
                                    currentEvent={
                                      this.props.currentMessage.event
                                    }
                                    businessEvents={this.props.businessEvents}
                                    journeySteps={
                                      this.props.journeyStepsForVariable
                                    }
                                    value={
                                      this.state.variations[index] &&
                                      this.state.variations[index]
                                        .whatsAppVariables
                                        ? this.state.variations[index]
                                            .whatsAppVariables[variables]
                                        : ''
                                    }
                                    readOnly={this.props.readOnly}
                                    onChange={(value) => {
                                      this.handleChangeVariations(
                                        index,
                                        'whatsAppVariables',
                                        {
                                          ...(this.state.variations[index]
                                            .whatsAppVariables || {}),
                                          [variables]: value,
                                        }
                                      );
                                    }}
                                    hideEmojiPicker={true}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
                {isDynamic && (
                  <div style={styles.dataRow}>
                    <div style={styles.editContainer}>
                      <div style={{ ...styles.editLabel }}>
                        DYNAMIC URL SUFFIX
                      </div>
                      <div
                        style={{
                          ...styles.editFieldContainer,
                          flex: 3,
                          marginLeft: 20,
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Form.Item style={{ width: '100%' }}>
                          <InputSelector
                            type="richInline"
                            index={index}
                            disabled={this.isEditDisable()}
                            id="description-text-area"
                            attributes={this.props.attributes}
                            events={this.props.events}
                            currentType={this.props.currentMessage.type}
                            currentEvent={this.props.currentMessage.event}
                            businessEvents={this.props.businessEvents}
                            journeySteps={this.props.journeyStepsForVariable}
                            value={
                              this.state.variations[index] &&
                              this.state.variations[index].dynamicUrlSuffix
                                ? this.state.variations[index].dynamicUrlSuffix
                                : ''
                            }
                            readOnly={this.props.readOnly}
                            onChange={(value) => {
                              this.handleChangeVariations(
                                index,
                                'dynamicUrlSuffix',
                                value
                              );
                            }}
                            hideEmojiPicker={true}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Card>
          {this.renderWhatsAppModalVisible(index)}
        </>
      );
    }
  };

  renderWhatsAppSectionLayoutPreview = (index) => {
    if (this.channel === 'WHATSAPP') {
      return (
        <WhatsAppPreview
          variation={this.state.variations[index] || {}}
          whatsapp={this.props.whatsAppTemplateObject}
          index={index}
          loading={this.props.loadingWhatsAppTemplateObject}
        />
      );
    }
    return null;
  };

  renderWebPushSectionLayoutMessage = (index) => {
    if (this.channel === 'WEB_PUSH') {
      return (
        <React.Fragment>
          {this.props.currentMessage.type === 'TRANSACTIONAL' && (
            <Card
              styles={{
                marginBottom: 40,
              }}
            >
              <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
                <div style={styles.headTitleContainer}>
                  Personalizing Content in Transactional Campaigns
                </div>
              </div>
              <div style={{ ...styles.dataRowContainer, paddingBottom: 0 }}>
                You can use any variable to personalize any of the fields in the
                message below. Eg. To include order_id in the order confirmation
                message, please specify the order_id variable in the message as{' '}
                <span>{'{{token.order_id }}'}</span> . Please also ensure that
                your variables are prefixed with the keyword token. Once you
                launch this campaign, send the token as token:{' '}
                <span>{'{ "order_id" : "abc123" }'}</span> in the{' '}
                <a href={texts.campaignApi} target={'blank'}>
                  transactional campaign API call{' '}
                </a>{' '}
                for each order confirmation, so that the message gets
                personalized.
              </div>
              <div style={styles.dataRowContainer}>
                Please refer to our{' '}
                <a href={texts.campaignApi}>documentation</a> for more details
                on transactional campaigns.
              </div>
            </Card>
          )}
          <Card>
            <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
              <div style={styles.headTitleContainer}>Layout</div>
            </div>
            <div style={styles.dataRowContainer}>
              <div style={styles.dataRowFirst}>
                <div style={{ ...styles.editContainer }}>
                  <div style={{ ...styles.editLabel }}>LAYOUT</div>
                  <div
                    style={{
                      ...styles.editFieldContainer,
                      flex: 3,
                      marginLeft: 20,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    {this.renderPushType(index)}
                    {!this.isEditDisable() && (
                      <Tooltip placement={'top'} title={'Change Layout'}>
                        <i
                          onClick={() =>
                            this.setState({ pushLayoutVisible: true })
                          }
                          className="fl-edit"
                          style={{
                            color: '#bbbcc1',
                            marginLeft: 10,
                            border: '1px solid',
                            borderRadius: '50%',
                            fontSize: '28px',
                            lineHeight: '28px',
                          }}
                        ></i>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card
            styles={{
              marginTop: 40,
            }}
          >
            <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
              <div style={styles.headTitleContainer}>Basic</div>
            </div>
            <div style={styles.dataRowContainer}>
              <div style={styles.dataRowFirst}>
                <div style={styles.editContainer}>
                  <div
                    style={{
                      ...styles.editLabel,
                      position: 'relative',
                      top: -10,
                    }}
                  >
                    TITLE
                  </div>
                  <div
                    style={{
                      ...styles.editFieldContainer,
                      marginLeft: 20,
                      flex: 3,
                    }}
                  >
                    <InputSelector
                      type="richInline"
                      index={index}
                      disabled={this.isEditDisable()}
                      attributeStyle={{
                        position: 'relative',
                        top: '-10px',
                        right: '-380px',
                        zIndex: 20,
                      }}
                      id="description-text-area"
                      attributes={this.props.attributes}
                      events={this.props.events}
                      currentType={this.props.currentMessage.type}
                      currentEvent={this.props.currentMessage.event}
                      businessEvents={this.props.businessEvents}
                      journeySteps={this.props.journeyStepsForVariable}
                      value={this.renderSubject(index)}
                      readOnly={this.props.readOnly}
                      onChange={(value) =>
                        this.handleChangeVariations(index, 'subject', value)
                      }
                    />
                    <span
                      style={{
                        color: '#94969e',
                        fontSize: '10px',
                        paddingLeft: '5px',
                        paddingTop: '5px',
                        display: 'block',
                      }}
                    >
                      {'Recommended maximum length: 32 characters. Current count:' +
                        (this.state.variations[index] &&
                        this.state.variations[index].subject
                          ? this.state.variations[index].subject.length
                          : 0)}
                    </span>
                  </div>
                </div>
              </div>
              <div style={styles.dataRow}>
                <div style={styles.editContainer}>
                  <div
                    style={{
                      ...styles.editLabel,
                    }}
                  >
                    MESSAGE
                  </div>
                  <div
                    style={{
                      ...styles.editFieldContainer,
                      marginLeft: 20,
                      flex: 3,
                    }}
                  >
                    <InputSelector
                      disabled={this.isEditDisable()}
                      textArea
                      type="richTextArea"
                      index={index}
                      attributeStyle={{
                        position: 'relative',
                        top: '-7px',
                        right: '150px',
                      }}
                      id="message-text-area"
                      className={'not-resizable'}
                      attributes={this.props.attributes}
                      events={this.props.events}
                      currentType={this.props.currentMessage.type}
                      currentEvent={this.props.currentMessage.event}
                      businessEvents={this.props.businessEvents}
                      journeySteps={this.props.journeyStepsForVariable}
                      readOnly={this.props.readOnly}
                      style={{
                        paddingRight: 58,
                        color: 'rgb(61, 64, 78)',
                        fontFamily: 'monospace',
                        fontSize: 13,
                        fontWeight: 400,
                        width: '100%',
                      }}
                      autoSize={{ maxRows: 3, minRows: 3 }}
                      rows={3}
                      value={
                        this.state.variations[index] &&
                        this.state.variations[index].message
                          ? this.state.variations[index].message
                          : ''
                      }
                      onChange={(value) =>
                        this.handleChangeVariations(index, 'message', value)
                      }
                      size="large"
                    />
                    <span
                      style={{
                        color: '#94969e',
                        fontSize: '10px',
                        paddingLeft: '5px',
                        paddingTop: '5px',
                        display: 'block',
                      }}
                    >
                      {'Recommended maximum length: ' +
                        (this.state.variations[index] &&
                        this.state.variations[index].pushType === 'BANNER'
                          ? 42
                          : 160) +
                        ' characters. Current count:' +
                        (this.state.variations[index] &&
                        this.state.variations[index].message
                          ? this.state.variations[index].message.length
                          : 0)}
                    </span>
                  </div>
                </div>
              </div>
              <div style={styles.dataRow}>
                {this.inputGenerator('icon', index)}
              </div>
              <div style={styles.dataRow}>
                <div style={styles.editContainer}>
                  <div
                    style={{
                      ...styles.editLabel,
                      position: 'relative',
                      top: -10,
                    }}
                  >
                    ON-CLICK ACTIONS
                  </div>
                  <div
                    style={{
                      ...styles.editFieldContainer,
                      marginLeft: 20,
                      flex: 3,
                    }}
                  >
                    <InputSelector
                      type="richInline"
                      index={index}
                      journeySteps={this.props.journeyStepsForVariable}
                      disabled={this.isEditDisable()}
                      attributeStyle={{
                        position: 'relative',
                        top: '-10px',
                        right: '-380px',
                        zIndex: 20,
                      }}
                      id="deep-link-url"
                      attributes={this.props.attributes}
                      events={this.props.events}
                      currentType={this.props.currentMessage.type}
                      currentEvent={this.props.currentMessage.event}
                      businessEvents={this.props.businessEvents}
                      hideEmojiPicker
                      placeholder="https://www.abc.com"
                      readOnly={this.props.readOnly}
                      value={
                        this.state.variations[index] &&
                        this.state.variations[index].androidOnClickAction
                          ? this.state.variations[index].androidOnClickAction
                          : ''
                      }
                      onChange={(value) =>
                        this.handleChangeVariations(
                          index,
                          'androidOnClickAction',
                          value
                        )
                      }
                    />
                    <span
                      style={{
                        color: '#94969e',
                        fontSize: '10px',
                        paddingLeft: '5px',
                        paddingTop: '5px',
                        display: 'block',
                      }}
                    >
                      {'URL can be HTTP or HTTPS'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          {this.state.variations[index] &&
            this.state.variations[index].pushType === 'BANNER' && (
              <Card
                styles={{
                  marginTop: 40,
                }}
              >
                <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
                  <div style={styles.headTitleContainer}>
                    <span>Image</span>
                  </div>
                </div>
                <div style={styles.dataRowContainer}>
                  <div style={styles.dataRowFirst}>
                    <div style={styles.editContainer}>
                      <div
                        style={{
                          ...styles.editLabel,
                          position: 'relative',
                          top: -25,
                        }}
                      >
                        IMAGE
                      </div>
                      <div
                        style={{
                          marginLeft: 20,
                          flex: 3,
                          fontSize: 13,
                          lineHeight: 1.43,
                          color: '#3d404e',
                        }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <Form.Item
                            validateStatus={
                              this.checkValidImageUrl(
                                this.state.variations[index].image
                              )
                                ? null
                                : 'warning'
                            }
                            // help={
                            //   this.checkValidImageUrl(
                            //     this.state.variations[index].image
                            //   )
                            //     ? null
                            //     : 'Invalid Image URL'
                            // }
                          >
                            <InputSelector
                              disabled={this.isEditDisable()}
                              attributeStyle={{
                                position: 'relative',
                                top: '30px',
                                right: '210px',
                              }}
                              id="push-picture-url"
                              attributes={this.props.attributes}
                              events={this.props.events}
                              journeySteps={this.props.journeyStepsForVariable}
                              currentType={this.props.currentMessage.type}
                              currentEvent={this.props.currentMessage.event}
                              businessEvents={this.props.businessEvents}
                              hideEmojiPicker
                              placeholder="https://www.abc.com/image.png"
                              value={
                                this.state.variations[index] &&
                                this.state.variations[index].image
                                  ? this.state.variations[index].image
                                  : ''
                              }
                              onChange={(value) =>
                                this.handleChangeVariations(
                                  index,
                                  'image',
                                  value
                                )
                              }
                              size="large"
                              style={{ ...styles.editField, width: '100%' }}
                            />
                          </Form.Item>
                          {!isNil(this.state.variations[index].image) &&
                          this.state.variations[index].image.length > 0 ? (
                            <Button
                              disabled={this.isEditDisable()}
                              type="primary"
                              shape="round"
                              onClick={() =>
                                this.handleChangeVariations(
                                  index,
                                  'image',
                                  null
                                )
                              }
                              ghost
                              size={'small'}
                              style={{
                                marginLeft: 15,
                              }}
                            >
                              Reset
                            </Button>
                          ) : (
                            <React.Fragment>
                              <span style={{ margin: '-27px 10px 0 10px' }}>
                                or
                              </span>
                              {this.renderWebPushIconUploader('image', index)}
                            </React.Fragment>
                          )}
                        </div>
                        <div
                          style={{
                            color: '#94969e',
                            fontSize: '10px',
                            paddingLeft: '5px',
                            paddingTop: '5px',
                            display: 'block',
                          }}
                        >
                          {
                            'Recommended size: 720px x 480px. Minimum size: 360px x 240px. Aspect ratio (width:height): 3:2. Actual rendering depends on screen size. JPG, JPEG, PNG formats only. Less than 1 MB in size. URL must begin with HTTPS.'
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            )}
          {this.renderAndroidPushButton(index)}
          {this.renderAdvanceWebPush(index)}
          {this.renderPushModalVisible(index)}
        </React.Fragment>
      );
    }
    return null;
  };

  renderWebPushPreviewTypeSelector = () => {
    return (
      <Select
        onChange={(webPushPreviewType) => this.setState({ webPushPreviewType })}
        value={this.state.webPushPreviewType}
        style={{ width: 180 }}
        className={'chart-selector'}
        bordered={false}
      >
        <Select.Option value={'chrome_windows_10'}>
          Chrome Windows 10
        </Select.Option>
        <Select.Option value={'chrome_windows'}>Chrome Windows</Select.Option>
        <Select.Option value={'chrome_mac'}>Chrome Mac</Select.Option>
        <Select.Option value={'chrome_android'}>Chrome Android</Select.Option>
        <Select.Option value={'firefox_windows'}>Firefox Windows</Select.Option>
        <Select.Option value={'firefox_mac'}>Firefox Mac</Select.Option>
      </Select>
    );
  };

  renderWebPushSectionPreview = (index) => {
    if (this.channel === 'WEB_PUSH') {
      if (this.state.webPushPreviewType === 'chrome_windows_10') {
        return (
          <section
            style={{ direction: 'ltr', paddingBottom: 0 }}
            className="message-container__preview overflow-hidden"
          >
            <div className="margin-left-auto message-container__options">
              <div
                className="row justify-content-between"
                style={{ flexDirection: 'row-reverse' }}
              >
                {this.renderWebPushPreviewTypeSelector()}
              </div>
            </div>
            <div
              style={{ minHeight: 710 }}
              className="pos-relative w-100 h-100 overflow-hidden"
            >
              <div
                id="webPush-preview"
                className="preview-inner web-push-preview web-push-preview--crud"
              >
                <div className="grid-12 col-12 web-push-preview__body we__win10">
                  {this.state.variations[index] &&
                    this.state.variations[index].pushType === 'BANNER' && (
                      <div className="row">
                        <div className="we__win10__big-image">
                          <div
                            className="bad--win10__preview"
                            style={{
                              backgroundImage: this.state.variations[index]
                                .image
                                ? 'url(' +
                                  this.state.variations[index].image +
                                  ')'
                                : 'url(//d107ygk0jso0v4.cloudfront.net/frontline/images/placeholderImgHorizontal-f6a7793f4c3000d2467c99c87913ea93.svg)',
                            }}
                          ></div>
                        </div>
                      </div>
                    )}
                  <div style={{ display: 'flex', flex: 1 }}>
                    <div className="col col-3 web-push-preview__logo no-padding web-push-preview-flex-center we__win10 we__win10__thumb justify-content-center">
                      <img
                        src={
                          this.state.variations[index] &&
                          this.state.variations[index].icon
                            ? this.state.variations[index].icon
                            : '//d107ygk0jso0v4.cloudfront.net/frontline/images/web-push/placeholder-small-d282371abebcc5dce91979af42768214.png'
                        }
                      />
                    </div>
                    <div
                      className="col col-9 padding-top-x web-push-preview__content we__win10"
                      style={{ flex: 2, display: 'flex' }}
                    >
                      <div className="web-push-preview__title">
                        {lodashIsEmpty(this.state.variations[index].subject)
                          ? 'Title'
                          : this.state.variations[index].subject}
                      </div>
                      <div className="web-push-preview__description we__win10__description">
                        {lodashIsEmpty(this.state.variations[index].message)
                          ? 'Description'
                          : this.state.variations[index].message}
                      </div>
                      <div className="web-push-preview__footer domain__block--windows we__win10__footer ">
                        Google Chrome • localhost
                      </div>
                    </div>
                  </div>
                  {this.state.variations[index] &&
                    this.state.variations[index].showAndroidPushButtons &&
                    this.state.variations[index].androidButtons.length ===
                      2 && (
                      <div className="row text-center">
                        <div className="col-6 no-padding-right margin-bottom-xs">
                          <div className="we__win10__button">
                            {this.state.variations[index].androidButtons[0] &&
                            lodashIsEmpty(
                              this.state.variations[index].androidButtons[0]
                                .label
                            )
                              ? 'Button 1'
                              : this.state.variations[index].androidButtons[0]
                                  .label}
                          </div>
                        </div>
                        <div className="col-6 no-padding-left margin-bottom-xs">
                          <div className="col-12 we__win10__button">
                            {this.state.variations[index].androidButtons[1] &&
                            lodashIsEmpty(
                              this.state.variations[index].androidButtons[1]
                                .label
                            )
                              ? 'Button 2'
                              : this.state.variations[index].androidButtons[1]
                                  .label}
                          </div>
                        </div>
                      </div>
                    )}
                  {this.state.variations[index] &&
                    this.state.variations[index].showAndroidPushButtons &&
                    this.state.variations[index].androidButtons.length ===
                      1 && (
                      <div className="row text-center">
                        <div
                          style={{ visibility: 'hidden' }}
                          className="col-6 no-padding-right margin-bottom-xs"
                        >
                          <div className="we__win10__button">{'Button 1'}</div>
                        </div>
                        <div className="col-6 no-padding-left margin-bottom-xs">
                          <div className="col-12 we__win10__button">
                            {this.state.variations[index].androidButtons[0] &&
                            lodashIsEmpty(
                              this.state.variations[index].androidButtons[0]
                                .label
                            )
                              ? 'Button 1'
                              : this.state.variations[index].androidButtons[0]
                                  .label}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </section>
        );
      }
      if (this.state.webPushPreviewType === 'chrome_windows') {
        return (
          <section
            style={{ direction: 'ltr', paddingBottom: 0 }}
            className="message-container__preview overflow-hidden"
          >
            <div className="margin-left-auto message-container__options">
              <div
                className="row justify-content-between"
                style={{ flexDirection: 'row-reverse' }}
              >
                {this.renderWebPushPreviewTypeSelector()}
              </div>
            </div>
            <div
              style={{ minHeight: 710 }}
              className="pos-relative w-100 h-100 overflow-hidden"
            >
              <div
                id="webPush-preview"
                className="preview-inner web-push-preview web-push-preview--crud"
              >
                <div className="grid-12 col-12 web-push-preview__body ">
                  <div className="row">
                    <div className="col col-3 web-push-preview__logo no-padding web-push-preview-flex-center  we__win10__thumb justify-content-center">
                      <img
                        src={
                          this.state.variations[index].icon
                            ? this.state.variations[index].icon
                            : '//d107ygk0jso0v4.cloudfront.net/frontline/images/web-push/placeholder-small-d282371abebcc5dce91979af42768214.png'
                        }
                      />
                    </div>
                    <div className="col col-9 padding-top-x web-push-preview__content ">
                      <div>
                        <i
                          className="web-push-preview__close fl-close fl-dark"
                          style={{ fontSize: 24 }}
                        ></i>
                        <i
                          className="web-push-preview__close web-push-preview__settings fl-cog fl-dark"
                          style={{ fontSize: 24 }}
                        ></i>
                      </div>
                      <div className="web-push-preview__title">
                        {lodashIsEmpty(this.state.variations[index].subject)
                          ? 'Title'
                          : this.state.variations[index].subject}
                      </div>
                      {this.state.variations[index] &&
                        this.state.variations[index].pushType === 'TEXT' && (
                          <div className="web-push-preview__description">
                            {lodashIsEmpty(this.state.variations[index].message)
                              ? 'Description'
                              : this.state.variations[index].message}
                          </div>
                        )}
                      {this.state.variations[index] &&
                        this.state.variations[index].pushType === 'BANNER' && (
                          <div className="web-push-preview__description win-mi-max-hack">
                            {lodashIsEmpty(this.state.variations[index].message)
                              ? 'Description'
                              : this.state.variations[index].message}
                          </div>
                        )}
                      <div className="web-push-preview__footer domain__block--windows">
                        localhost
                      </div>
                    </div>
                  </div>
                  {this.state.variations[index] &&
                    this.state.variations[index].pushType === 'BANNER' && (
                      <div className="row">
                        <div className="web-push-preview__big-image">
                          <img
                            src={
                              this.state.variations[index].image
                                ? this.state.variations[index].image
                                : '//d107ygk0jso0v4.cloudfront.net/frontline/images/placeholderImgHorizontal-f6a7793f4c3000d2467c99c87913ea93.svg'
                            }
                          />
                        </div>
                      </div>
                    )}
                  {this.state.variations[index] &&
                    this.state.variations[index].showAndroidPushButtons &&
                    this.state.variations[index].androidButtons.length > 0 && (
                      <div className="row border-top">
                        <div className="col-9" style={{ padding: 12 }}>
                          {lodashIsEmpty(
                            this.state.variations[index].androidButtons[0].label
                          )
                            ? 'Button 1'
                            : this.state.variations[index].androidButtons[0]
                                .label}
                        </div>
                      </div>
                    )}
                  {this.state.variations[index] &&
                    this.state.variations[index].showAndroidPushButtons &&
                    this.state.variations[index].androidButtons.length > 1 && (
                      <div className="row border-top">
                        <div className="col-9" style={{ padding: 12 }}>
                          {lodashIsEmpty(
                            this.state.variations[index].androidButtons[1].label
                          )
                            ? 'Button 2'
                            : this.state.variations[index].androidButtons[1]
                                .label}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </section>
        );
      }
      if (this.state.webPushPreviewType === 'chrome_mac') {
        return (
          <section
            style={{ direction: 'ltr', paddingBottom: 0 }}
            className="message-container__preview overflow-hidden"
          >
            <div className="margin-left-auto message-container__options">
              <div
                className="row justify-content-between"
                style={{ flexDirection: 'row-reverse' }}
              >
                {this.renderWebPushPreviewTypeSelector()}
              </div>
            </div>
            <div
              style={{ minHeight: 710 }}
              className="pos-relative w-100 h-100 overflow-hidden"
            >
              <div
                id="webPush-preview"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                className="preview-inner web-push-preview web-push-preview--crud"
              >
                <div className="outer-cover text-left n-preview">
                  <div className="simple-block notification-redirect-url">
                    <div className="placeholder-circle chrome-browser"></div>
                    <div className="text-block">
                      <div className="title-block">
                        {lodashIsEmpty(this.state.variations[index].subject)
                          ? 'Title'
                          : this.state.variations[index].subject}
                      </div>
                      <div className="domain-block">localhost</div>
                      <div className="subtitle-block">
                        {lodashIsEmpty(this.state.variations[index].message)
                          ? 'Description'
                          : this.state.variations[index].message}
                      </div>
                    </div>
                    <div className="logo-block">
                      <img
                        src={
                          this.state.variations[index].icon
                            ? this.state.variations[index].icon
                            : '//d107ygk0jso0v4.cloudfront.net/frontline/images/web-push/placeholder-small-d282371abebcc5dce91979af42768214.png'
                        }
                        className="js-campaign-preview-img"
                      />
                    </div>
                    <div className="cta-block">
                      <div className="cta-button">Close</div>
                      <div className="cta-button cursor-pointer">More</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      } else if (this.state.webPushPreviewType === 'firefox_windows') {
        return (
          <section
            style={{ direction: 'ltr', paddingBottom: 0 }}
            className="message-container__preview overflow-hidden"
          >
            <div className="margin-left-auto message-container__options">
              <div
                className="row justify-content-between"
                style={{ flexDirection: 'row-reverse' }}
              >
                {this.renderWebPushPreviewTypeSelector()}
              </div>
            </div>
            <div
              style={{ minHeight: 710 }}
              id="webPush-preview"
              className="preview-inner web-push-preview web-push-preview--crud"
            >
              <div className="grid-12 col-12 web-push-preview__body ">
                <div
                  className="firefox-font firefox-fweight web-push-preview__title padding-top-x"
                  style={{ maxWidth: 360 }}
                >
                  {lodashIsEmpty(this.state.variations[index].subject)
                    ? 'Title'
                    : this.state.variations[index].subject}
                  <i
                    className="firefox-preview-cog margin-right-x fl-close fl-dark"
                    style={{ top: 3, fontSize: 24 }}
                  ></i>
                </div>
                <div className="row">
                  <div className="col col-3 web-push-preview__logo no-padding web-push-preview-flex-center  we__win10__thumb justify-content-center">
                    <img
                      src={
                        this.state.variations[index].icon
                          ? this.state.variations[index].icon
                          : '//d107ygk0jso0v4.cloudfront.net/frontline/images/web-push/placeholder-small-d282371abebcc5dce91979af42768214.png'
                      }
                    />
                  </div>
                  <div className="col col-9 padding-top-x web-push-preview__content ">
                    <div></div>
                    <div className="web-push-preview__description">
                      {lodashIsEmpty(this.state.variations[index].message)
                        ? 'Description'
                        : this.state.variations[index].message}
                    </div>
                    <div className="web-push-preview__footer domain__block--windows firefox-font">
                      localhost
                      <i
                        style={{ top: 2, fontSize: 24 }}
                        className="firefox-preview-cog fl-cog fl-dark"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      } else if (this.state.webPushPreviewType === 'chrome_android') {
        return (
          <section
            style={{ direction: 'ltr', paddingBottom: 0 }}
            className="message-container__preview overflow-hidden"
          >
            <div className="margin-left-auto message-container__options">
              <div
                className="row justify-content-between"
                style={{ flexDirection: 'row-reverse' }}
              >
                {this.renderWebPushPreviewTypeSelector()}
              </div>
            </div>
            <div
              style={{ minHeight: 710 }}
              className="pos-relative w-100 h-100 overflow-hidden"
            >
              <div
                id="webPush-preview"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                className="preview-inner web-push-preview web-push-preview--crud"
              >
                <div className="outer-cover1 n-preview">
                  <div className="simple-block notification-redirect-url android-preview">
                    <div className="app-block">
                      <span className="browser-icon browser-icon-chrome margin-right-xs">
                        <span className="margin-left-x">
                          <img
                            style={{ maxWidth: '100%', borderStyle: 'none' }}
                            src="https://d107ygk0jso0v4.cloudfront.net/frontline/images/placeholderImgHorizontal-f6a7793f4c3000d2467c99c87913ea93.svg"
                          />
                        </span>
                      </span>
                      <span className="margin-left-x domain__block">
                        <span>Chrome • </span>
                        <span className="domain__block--width">
                          localhost
                        </span>{' '}
                        <span> • 1m</span>
                      </span>
                    </div>
                    <div className="top-block">
                      <div className="content-block">
                        <div className="text-block">
                          <div className="title-block">
                            <span className="notification-title">
                              {lodashIsEmpty(
                                this.state.variations[index].subject
                              )
                                ? 'Title'
                                : this.state.variations[index].subject}
                            </span>
                          </div>
                          <div
                            className={
                              this.state.variations[index] &&
                              this.state.variations[index].pushType === 'BANNER'
                                ? 'subtitle-block'
                                : 'subtitle-block subtitle__override subtitle__override--height'
                            }
                          >
                            <span className="notification-message">
                              {lodashIsEmpty(
                                this.state.variations[index].message
                              )
                                ? 'Description'
                                : this.state.variations[index].message}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="logo-block">
                        <img
                          src={
                            this.state.variations[index] &&
                            this.state.variations[index].icon
                              ? this.state.variations[index].icon
                              : '//d107ygk0jso0v4.cloudfront.net/frontline/images/web-push/placeholder-small-d282371abebcc5dce91979af42768214.png'
                          }
                          className="js-campaign-preview-img"
                        />
                      </div>
                    </div>
                    <div className="bottom-block">
                      {this.state.variations[index] &&
                        this.state.variations[index].pushType === 'BANNER' && (
                          <div className="hero-image-block">
                            <div
                              className="hero-image"
                              style={{
                                backgroundImage:
                                  this.state.variations[index] &&
                                  this.state.variations[index].image
                                    ? 'url(' +
                                      this.state.variations[index].image +
                                      ')'
                                    : 'url(//d107ygk0jso0v4.cloudfront.net/frontline/images/placeholderImgHorizontal-f6a7793f4c3000d2467c99c87913ea93.svg)',
                              }}
                            />
                          </div>
                        )}
                      {this.state.variations[index] &&
                      this.state.variations[index].showAndroidPushButtons &&
                      this.state.variations[index].androidButtons.length > 0 ? (
                        <div className="cta-block">
                          {this.state.variations[index].androidButtons.length >
                            0 && (
                            <div className="cta-button">
                              <div className="cta-button-content">
                                <span href="#">
                                  <span className="cta-text ">
                                    {lodashIsEmpty(
                                      this.state.variations[index]
                                        .androidButtons[0].label
                                    )
                                      ? 'BUTTON 1'
                                      : this.state.variations[index]
                                          .androidButtons[0].label}
                                  </span>
                                </span>
                              </div>
                            </div>
                          )}
                          {this.state.variations[index].androidButtons
                            .length === 2 && (
                            <div className="cta-button">
                              <div className="cta-button-content">
                                <span href="#">
                                  <span className="cta-text ">
                                    {lodashIsEmpty(
                                      this.state.variations[index]
                                        .androidButtons[1].label
                                    )
                                      ? 'BUTTON 2'
                                      : this.state.variations[index]
                                          .androidButtons[1].label}
                                  </span>
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="cta-block">
                          <div className="cta-button">
                            <div className="cta-button-content">
                              <span href="#">
                                <span className="cta-text ">
                                  {'SITE SETTINGS'}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      } else if (this.state.webPushPreviewType === 'firefox_mac') {
        return (
          <section
            style={{ direction: 'ltr', paddingBottom: 0 }}
            className="message-container__preview overflow-hidden"
          >
            <div className="margin-left-auto message-container__options">
              <div
                className="row justify-content-between"
                style={{ flexDirection: 'row-reverse' }}
              >
                {this.renderWebPushPreviewTypeSelector()}
              </div>
            </div>
            <div
              style={{ minHeight: 710 }}
              className="pos-relative w-100 h-100 overflow-hidden"
            >
              <div
                id="webPush-preview"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                className="preview-inner web-push-preview web-push-preview--crud"
              >
                <div className="outer-cover text-left n-preview">
                  <div
                    className="simple-block notification-redirect-url"
                    style={{}}
                  >
                    <div className="placeholder-circle firefox-browser"></div>
                    <div className="text-block">
                      <div className="title-block">
                        {lodashIsEmpty(this.state.variations[index].subject)
                          ? 'Title'
                          : this.state.variations[index].subject}
                      </div>
                      <div className="domain-block">localhost</div>
                      <div className="subtitle-block">
                        {lodashIsEmpty(this.state.variations[index].message)
                          ? 'Description'
                          : this.state.variations[index].message}
                      </div>
                    </div>
                    <div className="logo-block">
                      <img
                        src={
                          this.state.variations[index].icon
                            ? this.state.variations[index].icon
                            : '//d107ygk0jso0v4.cloudfront.net/frontline/images/web-push/placeholder-small-d282371abebcc5dce91979af42768214.png'
                        }
                        className="js-campaign-preview-img"
                      />
                    </div>
                    <div className="cta-block">
                      <div className="cta-button">Close</div>
                      <div className="cta-button cursor-pointer">More</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      }
    }
  };

  implementEllipsis = (buttonLabel, n) => {
    let buttonText = '';
    if (buttonLabel.length > n) {
      buttonText = buttonLabel.substring(0, n) + '...';
    } else {
      buttonText = buttonLabel;
    }
    return buttonText;
  };

  renderPushSectionPreview = (index) => {
    if (this.channel === 'PUSH') {
      const mobilePlatformIconStyle = { fontSize: 16, color: '#777' };
      let mobilePlatformOptions = [];
      if (this.props.currentAudience.mobileDevice !== 'IOS') {
        mobilePlatformOptions.push({
          value: 'android',
          icon: <AndroidFilled style={mobilePlatformIconStyle} />,
        });
      }
      if (this.props.currentAudience.mobileDevice !== 'ANDROID') {
        mobilePlatformOptions.push({
          value: 'ios',
          icon: <AppleFilled style={mobilePlatformIconStyle} />,
        });
      }
      return (
        <section
          style={{ direction: 'ltr', paddingBottom: 0 }}
          className="message-container__preview overflow-hidden"
        >
          <div className="margin-left-auto message-container__options">
            <Segmented
              style={{ borderRadius: 8 }}
              onChange={(val) => {
                if (val === 'android') {
                  this.setState({ isPreviewAndroid: true });
                } else {
                  this.setState({ isPreviewAndroid: false });
                }
              }}
              options={mobilePlatformOptions}
            />
          </div>
          {this.state.isPreviewAndroid ? (
            <div className="pos-relative w-100 h-100 overflow-hidden">
              <div className="message-container__content">
                <div className="push-notification push-notification--android">
                  <img
                    src={androidPushContainer}
                    style={{ maxWidth: '100%' }}
                  />
                  <div
                    className="push-notification__block"
                    style={{
                      ...(this.channel === 'PUSH' && {
                        top: 90,
                        width: 'auto',
                        maxWidth: 300,
                      }),
                    }}
                  >
                    <div
                      className="push-notification__message"
                      style={{ padding: 0 }}
                    >
                      <div style={{ padding: 16 }}>
                        <div className="push-notification__upper-container">
                          <div className="push-notification__section-left">
                            <div className="push-notification__system-info">
                              <img
                                src={androidTrangleIcon}
                                className="push-notification__system-icon"
                              />
                              <span className="push-notification__system-title">
                                {this.renderProductTitle()}
                              </span>
                              <span>&nbsp;&nbsp;</span>
                              <span className="push-notification__system-time">
                                <i className="fl-dark fl-chevron-bottom line-height-0"></i>
                              </span>
                            </div>
                            <div className="push-notification__title">
                              {this.state.variations[index] &&
                                this.state.variations[index].subject}
                            </div>
                            <div
                              className={
                                this.state.variations[index] &&
                                this.state.variations[index].pushType === 'TEXT'
                                  ? 'push-notification__description'
                                  : 'push-notification__description text-ellipsis'
                              }
                            >
                              {this.state.variations[index]
                                ? this.state.variations[index].message
                                : ''}
                            </div>
                          </div>
                          <div className="push-notification__section-right">
                            <img
                              className="push-notification__icon"
                              style={{ maxWidth: '100%' }}
                              src={androidIcon}
                            />
                          </div>
                        </div>
                        {this.state.variations[index] &&
                          this.state.variations[index].image && (
                            <div
                              className="push-notification__banner"
                              style={{
                                backgroundImage:
                                  'url("' +
                                  this.state.variations[index].image +
                                  '")',
                                height: 149,
                                backgroundSize: 'cover',
                              }}
                            ></div>
                          )}
                      </div>
                      {!isEmpty(
                        this.state.variations[index]?.androidButtons
                      ) && (
                        <div
                          className="push-notification__buttons-container"
                          style={{
                            marginTop: 10,
                            display: 'flex',
                            maxHeight: 80,
                          }}
                        >
                          <div
                            className="push-notification__buttons-container-el text-uppercase font-700"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {Array.isArray(
                              this.state.variations[index].androidButtons
                            ) &&
                              this.state.variations[index].androidButtons[0] &&
                              this.implementEllipsis(
                                this.state.variations[index].androidButtons[0]
                                  .label,
                                20
                              )}
                          </div>
                          <div
                            className="push-notification__buttons-container-el text-uppercase font-700"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {Array.isArray(
                              this.state.variations[index].androidButtons
                            ) &&
                              this.state.variations[index].androidButtons[1] &&
                              this.implementEllipsis(
                                this.state.variations[index].androidButtons[1]
                                  .label,
                                20
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="pos-relative w-100 h-100 overflow-hidden">
              <div className="message-container__content">
                <div className="push-notification push-notification--ios">
                  <img src={iosPush} />
                  <div className="push-notification__screen">
                    <div className="push-notification__notification-area">
                      <div
                        className="push-notification__notification-block"
                        style={{ marginTop: -460, height: 150 }}
                      >
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <div className="push-notification__notification-content">
                            <div className="push-notification__system-info align-items-center">
                              <img
                                src={appleIcon}
                                className="push-notification__system-icon"
                              />
                              <span className="push-notification__system-title">
                                {this.renderProductTitle()}
                              </span>
                              <span className="push-notification__system-cross fl-close"></span>
                            </div>
                            {this.state.variations[index] &&
                              this.state.variations[index].image && (
                                <div
                                  className="push-notification__banner"
                                  style={{
                                    backgroundImage:
                                      'url("' +
                                      this.state.variations[index].image +
                                      '")',
                                    height: 149,
                                    backgroundSize: 'cover',
                                  }}
                                ></div>
                              )}
                            <div className="push-notification__text">
                              <p className="push-notification__text--title font-700">
                                {this.state.variations[index]
                                  ? this.state.variations[index].subject
                                  : ''}
                              </p>
                              <div className="push-notification__text--description">
                                {this.state.variations[index]
                                  ? this.state.variations[index].message
                                  : ''}
                              </div>
                            </div>
                          </div>
                        </div>
                        {!isEmpty(
                          this.state.variations[index].androidButtons
                        ) && (
                          <div
                            style={{
                              backgroundColor: '#b9bdc7',
                              display: 'flex',
                              justifyContent: 'center',
                              paddingLeft: 10,
                              paddingRight: 10,
                              alignItems: 'center',
                              flexDirection: 'column',
                              marginTop: 10,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              paddingTop: '20px',
                              paddingBottom: '20px',
                              borderRadius: 15,
                            }}
                          >
                            {Array.isArray(
                              this.state.variations[index].androidButtons
                            ) &&
                              this.state.variations[index].androidButtons
                                .length === 1 && (
                                <div
                                  style={{
                                    display: 'flex',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxHeight: 50,
                                  }}
                                >
                                  {' '}
                                  <span>
                                    {this.state.variations[index]
                                      .androidButtons[0] &&
                                      this.implementEllipsis(
                                        this.state.variations[index]
                                          .androidButtons[0].label,
                                        35
                                      )}
                                  </span>
                                </div>
                              )}
                            {Array.isArray(
                              this.state.variations[index].androidButtons
                            ) &&
                              this.state.variations[index].androidButtons
                                .length === 2 && (
                                <div
                                  style={{
                                    width: '100%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      width: '100%',
                                      borderBottom: 'solid 1px #91949c',
                                      paddingBottom: 20,
                                    }}
                                  >
                                    {this.state.variations[index]
                                      .androidButtons[0] &&
                                      this.implementEllipsis(
                                        this.state.variations[index]
                                          .androidButtons[0].label,
                                        35
                                      )}
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      width: '100%',
                                      paddingTop: 20,
                                    }}
                                  >
                                    {this.state.variations[index]
                                      .androidButtons[1] &&
                                      this.implementEllipsis(
                                        this.state.variations[index]
                                          .androidButtons[1].label,
                                        35
                                      )}
                                  </div>
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      );
    }
    return null;
  };

  renderInAppSectionPreview = (index) => {
    if (this.channel === 'IN_APP') {
      return (
        <InAppPreview
          variation={this.state.variations[index] || {}}
          mobileDevice={this.props.currentAudience.mobileDevice}
        />
      );
    }
    return null;
  };

  renderOnSiteSectionPreview = (index) => {
    if (this.channel === 'ON_SITE') {
      return (
        <OnSitePreview
          variation={this.state.variations[index] || {}}
          mobileDevice={this.props.currentAudience.mobileDevice}
        />
      );
    }
    return null;
  };

  renderDetailsAndPreviewCards = (message, preview) => {
    // let renderSMSSectionServiceProviderMessage1 = this.renderSMSSectionServiceProviderMessage(index);
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
        <div style={{ width: '75%' }}>{message}</div>
        {preview}
      </div>
    );
  };

  renderEditor = (index) => {
    return (
      <div>
        {this.renderEmailSection(index)}
        <div style={styles.renderEditor}>
          {this.renderCustomChannel(index)}
          {this.renderDetailsAndPreviewCards(
            this.renderSMSSectionServiceProviderMessage(index),
            this.renderSMSSectionMessage(index)
          )}
          {this.renderDetailsAndPreviewCards(
            this.renderPushSectionLayoutMessage(index),
            this.renderPushSectionPreview(index)
          )}
          {this.renderDetailsAndPreviewCards(
            this.renderWebPushSectionLayoutMessage(index),
            this.renderWebPushSectionPreview(index)
          )}
          {this.renderDetailsAndPreviewCards(
            this.renderInAppSectionLayoutMessage(index),
            this.renderInAppSectionPreview(index)
          )}
          {this.renderDetailsAndPreviewCards(
            this.renderOnSiteSectionLayoutMessage(index),
            this.renderOnSiteSectionPreview(index)
          )}
          {this.renderDetailsAndPreviewCards(
            this.renderWhatsAppSectionLayoutMessage(index),
            this.renderWhatsAppSectionLayoutPreview(index)
          )}
        </div>
      </div>
    );
  };

  getVariationPercentage = (index, variationCount) => {
    let percentage = null;
    if (
      this.props.currentMessage.variations &&
      Array.isArray(this.props.currentMessage.variations) &&
      this.props.currentMessage.variations.length > 1
    ) {
      percentage =
        this.props.currentMessage.variations.length < variationCount
          ? (100 / variationCount).toFixed()
          : this.props.currentMessage.variations.length > variationCount
          ? (100 / variationCount).toFixed()
          : this.props.currentMessage.variations[index].percentage;
      if (
        (this.props.currentMessage.variations.length < variationCount ||
          this.props.currentMessage.variations.length > variationCount) &&
        variationCount === 3 &&
        index === 2
      ) {
        percentage = '34';
      }
    } else if (
      this.props.currentMessage.variations &&
      Array.isArray(this.props.currentMessage.variations)
    ) {
      percentage = (100 / variationCount).toFixed();
      if (variationCount === 3 && index === 2) {
        percentage = '34';
      }
    }
    return percentage;
  };

  renderVariationBar = () => {
    let isNotStoppedEnded =
      this.props.currentMessage &&
      this.props.currentMessage.status !== 'STOPPED' &&
      this.props.currentMessage.status !== 'ENDED';
    let activeVariationIndex = this.state.activeVariationIndex;
    if (activeVariationIndex > this.state.variations.length - 1) {
      activeVariationIndex = this.state.variations.length - 1;
    }
    return (
      <div style={{ cursor: this.props.readOnly ? 'not-allowed' : null }}>
        <div
          style={{
            borderBottom: '1px solid #e7e8e9',
            width: '100%',
            backgroundColor: '#FFFFFF',
            padding: '0 15px',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          {this.state.variations.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom:
                      activeVariationIndex === index
                        ? '3px solid'
                        : '3px solid',
                    borderColor:
                      activeVariationIndex === index
                        ? vars.primaryColor
                        : '#FFFFFF',
                    width: this.state.variations.length === 1 ? 120 : 160,
                    marginLeft: 20,
                    paddingRight: 5,
                    paddingLeft: 5,
                  }}
                >
                  <div
                    id={'variation' + index}
                    data-cy={'variation' + index}
                    onClick={() => this.handleClickActiveVariation(index)}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span
                      style={{
                        color: '#3d404e',
                        fontWeight: '500',
                        lineHeight: '60px',
                      }}
                    >
                      {'Variation ' + variationLetter[index]}
                      {this.state.variations.length > 1 && (
                        <span
                          style={{
                            background: vars.primaryColor,
                            height: 20,
                            borderRadius: 10,
                            padding: '0 7px',
                            marginLeft: 5,
                            color: 'white',
                          }}
                        >
                          {this.getVariationPercentage(
                            index,
                            this.state.variations.length
                          ) + '%'}
                        </span>
                      )}
                    </span>
                  </div>
                  {isNotStoppedEnded && (
                    <MoreOutlined
                      style={{
                        color: '#BBBCC0',
                        fontSize: 25,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        const showVariationModal =
                          this.state.showVariationModal;
                        const newArr = showVariationModal.map((item) =>
                          item === true ? false : false
                        );
                        newArr[index] = true;
                        this.setState({ showVariationModal: newArr });
                      }}
                    />
                  )}
                </div>
                {index === this.state.variations.length - 1 &&
                  this.state.variations.length < 5 &&
                  isNotStoppedEnded && (
                    <i
                      id={'int-communication-message-variation-add'}
                      data-cy="int-communication-message-variation-add"
                      onClick={() => this.addVariation()}
                      className="fl-add-circle"
                      style={{
                        display: 'flex',
                        alignSelf: 'center',
                        fontSize: '40px',
                        color: vars.primaryColor,
                        marginLeft: 10,
                        cursor: 'pointer',
                      }}
                    ></i>
                  )}
              </React.Fragment>
            );
          })}
        </div>
        {this.renderVariationBarModal()}
      </div>
    );
  };

  renderVariationBarModal = () => {
    let isNotStoppedEnded =
      this.props.currentMessage &&
      this.props.currentMessage.status !== 'STOPPED' &&
      this.props.currentMessage.status !== 'ENDED';
    let index = lodashFindIndex(
      this.state.showVariationModal,
      (o) => o === true
    );
    let deleteAddedVarOnEditMode =
      isNotStoppedEnded &&
      this.props.currentMessage?.variations?.length <
        this.state.variations.length &&
      index > this.props.currentMessage?.variations?.length - 1;
    if (index > -1) {
      return (
        <div
          ref={this.ref}
          id={'int-communication-message-variation-menu-' + index}
          style={{
            position: 'absolute',
            zIndex: 2,
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
            flexDirection: 'column',
            width: 120,
            height:
              this.props.currentMessage?.status === 'DRAFT' ||
              deleteAddedVarOnEditMode
                ? 80
                : 40,
            borderRadius: 10,
            paddingLeft: 20,
            marginTop: -10,
            boxShadow: '1px 2px 9px #CFD2D5',
            marginLeft:
              index === 0 && this.state.showVariationModal.length === 1
                ? 20
                : index === 0 && this.state.showVariationModal.length !== 1
                ? 60
                : index === 1
                ? 240
                : index === 2
                ? 420
                : index === 3
                ? 600
                : 780,
            backgroundColor: 'white',
          }}
        >
          {isNotStoppedEnded && (
            <div
              style={{
                cursor:
                  this.state.showVariationModal.length < 5
                    ? 'pointer'
                    : 'not-allowed',
                marginBottom: 10,
                marginTop:
                  this.props.currentMessage?.status === 'DRAFT' ||
                  deleteAddedVarOnEditMode
                    ? 0
                    : 8,
              }}
              onClick={() => {
                this.duplicateVariation(index);
              }}
            >
              Duplicate
            </div>
          )}
          {this.props.currentMessage?.status === 'DRAFT' ? (
            <div
              onClick={() => {
                this.removeVariation(index);
              }}
              style={{
                cursor:
                  this.state.showVariationModal.length === 1
                    ? 'not-allowed'
                    : 'pointer',
              }}
            >
              Delete
            </div>
          ) : deleteAddedVarOnEditMode ? (
            <div
              onClick={() => {
                this.removeVariation(index);
              }}
              style={{
                cursor:
                  this.state.showVariationModal.length === 1
                    ? 'not-allowed'
                    : 'pointer',
              }}
            >
              Delete
            </div>
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    return (
      <div
        style={{
          position: 'relative',
          paddingRight: 30,
          paddingLeft: 30,
          paddingTop: 30,
          paddingBottom: this.channel === 'SMS' ? 30 : 30,
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: 5,
            boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
          }}
        ></div>
        {this.props.loadingUpdateMessage ? null : this.renderSteps()}
        {this.props.loadingUpdateMessage ? (
          <div
            style={{
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              paddingTop: 50,
              paddingBottom: 50,
            }}
          >
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <React.Fragment>
            {this.renderVariationBar()}
            {this.renderEditor(this.state.activeVariationIndex)}
            {/*<div style={{ backgroundColor: 'green' }}>hello</div>*/}
            {/*<Row>*/}
            {/*  <Col span={16}>*/}
            {/*    {this.renderVariationBar()}*/}
            {/*  </Col>*/}
            {/*  <Col span={8}>*/}
            {/*    {this.renderEditor(this.state.activeVariationIndex)}*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {this.renderFooter()}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default CommunicationMessageComponent;
