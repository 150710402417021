import React from 'react';
import {
  findIndex,
  isNil,
  cloneDeep,
  isEmpty,
  find as lodashFind,
  isArray,
  isObject,
  isEqual,
} from 'lodash';
import { getDelayMinutes } from '../../../../utils/DateUtils';
import moment from 'moment';
import ProductSegmentPredicateComponent from '../../../insight/productSegment/component/ProductSegmentPredicateComponent';
import { history } from '../../../../redux/store';
import {
  Button,
  Select,
  Radio,
  Input,
  DatePicker,
  TimePicker,
  Modal,
  Spin,
  Badge,
  Form,
} from 'antd';
import {
  getChannel,
  getCommunicationId,
  getCommunicationStep,
  getProductId,
} from '../../../../utils/AuthorityProvider';
import { LoadingOutlined } from '@ant-design/icons';
import CommunicationStepsComponent from './CommunicationStepsComponent';
import CommunicationFooterComponent from './CommunicationFooterComponent';
import InAppAdvanceSetting from './inApp/CommunicationInAppAdvance';
import vars from '@Theme/styles/vars';
import { findProductThrottlingEnabled } from '@Utils/hasThrottling';
import TTL from './inApp/TTL';
import Card from '@Utils/Card';

const styles = {
  editContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
  },
  editFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 6,
    fontSize: 13,
    lineHeight: 1.43,
    color: '#3d404e',
  },
  editField: {
    width: 350,
  },
  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
    flex: 1,
    textAlign: 'right',
  },
  radioStyle: {
    display: 'block',
    height: '30px',
    lineHeight: '17px',
    marginBottom: '17px',
  },
  weekDay: {
    display: 'flex',
    borderRadius: 5,
    marginLeft: 4,
    marginRight: 4,
    color: vars.primaryColor,
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid',
    borderColor: vars.primaryColor,
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  selectedWeekDay: {
    display: 'flex',
    borderRadius: 5,
    marginLeft: 4,
    marginRight: 4,
    color: '#FFFFFF',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid',
    borderColor: vars.primaryColor,
    backgroundColor: vars.primaryColor,
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
};
class CommunicationWhenComponent extends React.Component {
  constructor(props) {
    super(props);
    this.step = getCommunicationStep(this.props.pathname);
    this.channel = getChannel(this.props.pathname);
    if (props.journey) {
      this.communicationId =
        props.communicationId ||
        (props.communicationData && props.communicationData.id);
    } else {
      this.communicationId = getCommunicationId(this.props.pathname);
    }
    this.state = {
      inAppAdvanceIsValid: true,
    };
  }

  componentDidMount() {
    this.props.communicationUpdateWhenFetch(this.communicationId);
    this.props.communicationListEventsFetch();
    this.props.communicationUpdateWhenThrottlingFetch();
  }
  componentWillUnmount() {
    this.props.clearCommunicationWhenError(
      this.props.errorUpdateWhenSubmit.errors
    );
  }
  componentDidUpdate(prevProps) {
    let throttlingDefaultSecondValue = null;
    let throttlingDefaultDayValue = null;
    if (
      (prevProps.loadingUpdateThrottlingWhen &&
        !this.props.loadingUpdateThrottlingWhen &&
        this.props.defaultThrottling &&
        Array.isArray(this.props.defaultThrottling.throttlings) &&
        this.props.currentWhen) ||
      (!this.props.loadingUpdateWhen &&
        prevProps.loadingUpdateWhen &&
        this.props.currentWhen &&
        this.props.defaultThrottling &&
        Array.isArray(this.props.defaultThrottling.throttlings))
    ) {
      let followThrottling = null;
      this.props.defaultThrottling.throttlings.forEach((item) => {
        if (this.channel === item.channelType) {
          if (item.interval === 'SECOND') {
            throttlingDefaultSecondValue = item.threshold;
          }
          if (item.interval === 'DAY') {
            throttlingDefaultDayValue = item.threshold;
          }
        }
      });
      if (
        (!isNil(throttlingDefaultDayValue) ||
          !isNil(throttlingDefaultSecondValue)) &&
        typeof this.props.currentWhen.followThrottling === 'undefined' &&
        this.props.currentWhen.crc === 0
      ) {
        followThrottling = true;
      }
      if (
        (!isNil(throttlingDefaultDayValue) ||
          !isNil(throttlingDefaultSecondValue)) &&
        this.props.currentWhen.followThrottling === false &&
        this.props.currentWhen.crc === 0
      ) {
        followThrottling = true;
      } else if (
        typeof this.props.currentWhen.followThrottling === 'undefined' &&
        isNil(throttlingDefaultDayValue) &&
        isNil(throttlingDefaultSecondValue)
      ) {
        followThrottling = false;
      } else {
        followThrottling = this.props.currentWhen.followThrottling;
      }
      this.setState({
        followThrottling: followThrottling,
        throttlingType: followThrottling === true ? 'limits' : 'do_not',
      });
    }
    if (
      prevProps.loadingUpdateThrottlingWhen &&
      !this.props.loadingUpdateThrottlingWhen &&
      this.props.defaultThrottling &&
      Array.isArray(this.props.defaultThrottling.throttlings)
    ) {
      this.props.defaultThrottling.throttlings.forEach((item) => {
        if (this.channel === item.channelType) {
          if (item.interval === 'SECOND') {
            throttlingDefaultSecondValue = item.threshold;
          }
          if (item.interval === 'DAY') {
            throttlingDefaultDayValue = item.threshold;
          }
        }
      });
      this.setState({
        defaultSecondThrottling: throttlingDefaultSecondValue,
        defaultDayThrottling: throttlingDefaultDayValue,
      });
    }
    if (
      !this.props.loadingUpdateWhen &&
      prevProps.loadingUpdateWhen &&
      this.props.currentWhen
    ) {
      const startDate = this.fillStartDate(this.props.currentWhen.startDate);
      const endDate = this.fillEndDate(this.props.currentWhen.endDate);
      const queueing = this.fillQueueing(this.props.currentWhen.queueMinutes);
      const deliveryTime = this.fillDelivery(
        this.props.currentWhen.delayMinutes,
        this.props.currentWhen.type,
        this.props.currentWhen.startDate
      );
      const currentPredicate = this.fillCurrentPredicate(
        this.props.currentWhen.event,
        this.props.currentWhen.eventConjunction,
        this.props.currentWhen.eventPredicates
      );

      this.setState({
        currentPredicate,
        event: this.props.currentWhen.event,
        repeatEveryValue: this.props.currentWhen.repeatEvery,
        repeatType: this.props.currentWhen.repeatType
          ? this.props.currentWhen.repeatType
          : 'DAY',
        repeatTime: this.props.currentWhen.repeatTime,
        repeatDays: this.props.currentWhen.repeatDays,
        eventPredicates: cloneDeep(this.props.currentWhen.eventPredicates),
        eventConjunction: this.props.currentWhen.eventConjunction
          ? cloneDeep(this.props.currentWhen.eventConjunction)
          : 'AND',
        type: this.props.currentWhen.type,
        ...startDate,
        ...endDate,
        ...deliveryTime,
        followFrequencyCapping: !!this.props.currentWhen.followFrequencyCapping,
        followDnd: !!this.props.currentWhen.followDnd,
        ...queueing,
      });

      if (
        this.channel === 'PUSH' ||
        this.props.channel === 'PUSH' ||
        this.props.channel === 'WEB_PUSH' ||
        this.channel === 'WEB_PUSH'
      ) {
        this.setState({
          ttl: this.props.currentWhen?.ttl || 40320,
        });
      }

      if (
        this.channel === 'IN_APP' ||
        this.channel === 'ON_SITE' ||
        this.props.channel === 'IN_APP' ||
        this.props.channel === 'ON_SITE'
      ) {
        const inAppAdvanceValue = {};
        const whenAndWhereConditions =
          this.props.currentWhen?.whenAndWhereConditions;
        Object.keys(
          this.props.currentWhen?.whenAndWhereConditions || {}
        )?.forEach((key) => {
          if (
            key === 'activityNames' &&
            whenAndWhereConditions.activityNames?.terms?.length
          ) {
            const tmpActivityNames = whenAndWhereConditions.activityNames;
            inAppAdvanceValue.setScreenName = true;
            inAppAdvanceValue.screenNameMapperConjunction =
              tmpActivityNames.conjunction;
            inAppAdvanceValue.screenNameMapper = tmpActivityNames.terms.map(
              (term) => ({
                ...term,
                value: ['ONE_OF'].includes(term.operator)
                  ? term?.value?.split(',')
                  : term.value,
              })
            );
          }

          if (
            key === 'activityData' &&
            whenAndWhereConditions.activityData?.terms?.length
          ) {
            const tmpActivityData = whenAndWhereConditions.activityData;
            inAppAdvanceValue.setScreenData = true;
            inAppAdvanceValue.screenDataMapperConjunction =
              tmpActivityData.conjunction;
            inAppAdvanceValue.screenDataMapper = tmpActivityData.terms.map(
              (term) => ({
                ...term,
                value: ['ONE_OF', 'BETWEEN'].includes(term.operator)
                  ? term?.value
                      ?.split(',')
                      .map((item) =>
                        term.dataType === 'DATE' ? moment(item) : item
                      )
                  : term.dataType === 'DATE'
                  ? moment(term.value)
                  : term.value,
              })
            );
          }

          if (
            key === 'timeSpentOnActivity' &&
            whenAndWhereConditions.timeSpentOnActivity
          ) {
            inAppAdvanceValue.setScreenTime = true;
            inAppAdvanceValue.screenTime =
              parseInt(whenAndWhereConditions.timeSpentOnActivity) / 1000;
          }

          if (
            key === 'timeSpentOnApp' &&
            whenAndWhereConditions.timeSpentOnApp
          ) {
            inAppAdvanceValue.setSessionTime = true;
            inAppAdvanceValue.sessionTime =
              parseInt(whenAndWhereConditions.timeSpentOnApp) / 1000;
          }

          if (key === 'activityViews') {
            inAppAdvanceValue.setNumberOfScreens = true;
            inAppAdvanceValue.numberOfScreenMapper = {
              ...whenAndWhereConditions.activityViews,
              value: ['ONE_OF', 'BETWEEN'].includes(
                whenAndWhereConditions.activityViews.operator
              )
                ? whenAndWhereConditions.activityViews?.value?.split(',')
                : whenAndWhereConditions.activityViews.value,
            };
          }

          if (
            key === 'customEvent' &&
            whenAndWhereConditions.customEvent?.terms?.length
          ) {
            const tmpCustomEvents = whenAndWhereConditions.customEvent;
            inAppAdvanceValue.setEvents = true;
            inAppAdvanceValue.eventsMapperConjunction =
              tmpCustomEvents.conjunction;
            inAppAdvanceValue.eventsMapper = tmpCustomEvents.terms;
          }
        });

        this.setState({
          inAppAdvanceValue: inAppAdvanceValue,
          inAppShowCount: this.props.currentWhen?.showLimit,
          inAppAdvanceIsValid: true,
          ttl: this.props.currentWhen?.ttl || 40320,
        });
      }
    }

    if (this.props.journey) {
      if (
        prevProps.loadingUpdateWhenSubmit !==
          this.props.loadingUpdateWhenSubmit &&
        !this.props.loadingUpdateWhenSubmit
      ) {
        this.props.goToStepOnJourney('message');
      }
      // else if (prevProps.currentWhen !== this.props.currentWhen) {
      //   // this.props.goToStepOnJourney('message');
      // }
    }
  }
  state = {
    eventModalVisible: false,

    type: 'ONE_TIME',

    event: null,
    eventPredicates: [],
    eventConjunction: 'AND',

    deliveryType: 'now',
    deliveryValue: null,
    deliveryTimeSelector: null,

    repeatEveryValue: null,
    repeatTime: null,
    repeatType: undefined,
    repeatDateSelector: null,
    repeatDays: [],

    startType: 'now',
    startDateTime: null,

    endType: 'never',
    endDateTime: null,

    followFrequencyCapping: true,

    followDnd: true,

    queueingType: 'do_not',
    queueingValue: '',
    queueingTimeSelector: 'minutes',

    throttlingType: 'do_not',
    followThrottling: false,
    defaultSecondThrottling: null,
    defaultDayThrottling: null,

    selectorModalVisible: false,

    currentPredicate: {
      event: 0,
      eventConjunction: 'AND',
      eventPredicates: [],
      type: 'BEHAVIOUR',
    },
    oneTimeDeliveryTimeError: false,
    eventError: false,
    triggeredStartDateError: false,
    triggeredEndDateError: false,
    recurringStartDateError: false,
    recurringEndDateError: false,
    recurringWeekMonthError: false,
    repeatEveryValueError: false,
  };

  onChangePushChannels = (pushChannels) => {
    this.setState({ pushChannels });
  };

  isEditDisable = () => {
    return (
      !this.props.journey &&
      this.props.currentWhen &&
      this.props.currentWhen.status === 'ENDED'
    );
  };

  fillEndDate = (endDate) => {
    let endType = 'never';
    let endDateTime = null;
    if (endDate) {
      endType = 'till';
      endDateTime = endDate;
    }
    return { endType, endDateTime };
  };

  fillCurrentPredicate = (event, eventConjunction, eventPredicates) => {
    const result = {
      event: cloneDeep(event),
      eventConjunction: eventConjunction ? cloneDeep(eventConjunction) : 'AND',
      eventPredicates: cloneDeep(eventPredicates),
      type: 'BEHAVIOUR',
    };
    return result;
  };

  fillStartDate = (startDate) => {
    let startType = 'now';
    let startDateTime = null;
    if (startDate) {
      startType = 'later';
      startDateTime = startDate;
    }
    return { startType, startDateTime };
  };

  fillDelivery = (delayMinutes, type, startDate) => {
    if (type === 'ONE_TIME' && startDate) {
      return { deliveryType: 'later' };
    } else {
      if (delayMinutes) {
        if (delayMinutes > 0 && delayMinutes < 60) {
          return {
            deliveryType: 'later',
            deliveryValue: String(delayMinutes),
            deliveryTimeSelector: 'MINUTE',
          };
        } else if (delayMinutes >= 60 && delayMinutes < 60 * 24) {
          return {
            deliveryType: 'later',
            deliveryValue: String(Math.round(delayMinutes / 60)),
            deliveryTimeSelector: 'HOUR',
          };
        } else if (delayMinutes >= 60 * 24 && delayMinutes < 60 * 24 * 7) {
          return {
            deliveryType: 'later',
            deliveryValue: String(Math.round(delayMinutes / (60 * 24))),
            deliveryTimeSelector: 'DAY',
          };
        } else if (delayMinutes >= 60 * 24 * 7) {
          return {
            deliveryType: 'later',
            deliveryValue: String(Math.round(delayMinutes / (60 * 24 * 7))),
            deliveryTimeSelector: 'WEEK',
          };
        }
      } else {
        return {
          deliveryType: 'now',
          deliveryTimeSelector: 'MINUTE',
          deliveryValue: '',
        };
      }
    }
  };

  fillQueueing = (queueMinutes) => {
    if (queueMinutes) {
      if (queueMinutes > 0 && queueMinutes < 60) {
        return {
          queueingType: 'do',
          queueingValue: String(queueMinutes),
          queueingTimeSelector: 'MINUTE',
        };
      } else if (queueMinutes >= 60 && queueMinutes < 60 * 24) {
        return {
          queueingType: 'do',
          queueingValue: String(Math.round(queueMinutes / 60)),
          queueingTimeSelector: 'HOUR',
        };
      } else if (queueMinutes >= 60 * 24 && queueMinutes < 60 * 24 * 7) {
        return {
          queueingType: 'do',
          queueingValue: String(Math.round(queueMinutes / (60 * 24))),
          queueingTimeSelector: 'DAY',
        };
      } else if (queueMinutes >= 60 * 24 * 7) {
        return {
          queueingType: 'do',
          queueingValue: String(Math.round(queueMinutes / (60 * 24 * 7))),
          queueingTimeSelector: 'WEEK',
        };
      }
    } else {
      return {
        queueingType: 'do_not',
        queueingTimeSelector: 'MINUTE',
        queueingValue: '',
      };
    }
  };

  isWeekDaySelected = (weekDay) => {
    return (
      this.state.repeatDays &&
      Array.isArray(this.state.repeatDays) &&
      this.state.repeatDays.includes(weekDay)
    );
  };

  toggleDay = (weekDay) => {
    if (!this.isEditDisable()) {
      const { repeatDays } = this.state;
      const foundIndex = findIndex(repeatDays, (o) => o === weekDay);
      if (foundIndex > -1) {
        repeatDays.splice(foundIndex, 1);
      } else {
        repeatDays.push(weekDay);
      }
      this.setState({ repeatDays });
      if (this.state.repeatDays.length > 0) {
        this.setState({ recurringWeekMonthError: false });
      }
    }
  };

  handleChangeQueueingType = (selector, value) => {
    this.setState({ [selector]: value });
    this.props.clearCommunicationWhenError(
      this.props.errorUpdateWhenSubmit.errors,
      'queueMinutes'
    );
  };

  handleChangeThrottlingType = (throttlingType) => {
    this.setState({
      throttlingType,
      followThrottling: throttlingType === 'limits' ? true : false,
    });
  };

  getDefaultThrottling = () => {
    let result = '';
    if (
      isNil(this.state.defaultDayThrottling) &&
      !isNil(this.state.defaultSecondThrottling)
    ) {
      result = `${this.state.defaultSecondThrottling} messages per second`;
    } else if (
      !isNil(this.state.defaultDayThrottling) &&
      isNil(this.state.defaultSecondThrottling)
    ) {
      result = `${this.state.defaultDayThrottling} messages per day`;
    } else if (
      isNil(this.state.defaultDayThrottling) &&
      isNil(this.state.defaultSecondThrottling)
    ) {
      result = 'No setting';
    } else {
      result = `${this.state.defaultDayThrottling} messages per day and ${this.state.defaultSecondThrottling}
        messages per second`;
    }
    return result;
  };
  handleChangeQueueingTimeSelector = (queueingTimeSelector) => {
    this.setState({ queueingTimeSelector });
  };

  setDelayMinute = (delayMinutes) => {
    if (delayMinutes) {
      if (delayMinutes > 0 && delayMinutes < 60) {
        return {
          deliveryTime: 'later',
          delayValue: String(delayMinutes),
          delayDuration: 'MINUTE',
        };
      } else if (delayMinutes >= 60 && delayMinutes < 60 * 24) {
        return {
          deliveryTime: 'later',
          delayValue: String(Math.round(delayMinutes / 60)),
          delayDuration: 'HOUR',
        };
      } else if (delayMinutes >= 60 * 24 && delayMinutes < 60 * 24 * 7) {
        return {
          deliveryTime: 'later',
          delayValue: String(Math.round(delayMinutes / (60 * 24))),
          delayDuration: 'DAY',
        };
      } else if (delayMinutes >= 60 * 24 * 7) {
        return {
          deliveryTime: 'later',
          delayValue: String(Math.round(delayMinutes / (60 * 24 * 7))),
          delayDuration: 'WEEK',
        };
      }
    } else {
      return { deliveryTime: 'soon', delayDuration: 'MINUTE', delayValue: '' };
    }
  };

  handleChangeCampaignType = (type) => {
    this.setState({ type });
    this.props.clearCommunicationWhenError(
      this.props.errorUpdateWhenSubmit.errors
    );
    this.setState({
      oneTimeDeliveryTimeError: false,
      eventError: false,
      triggeredStartDateError: false,
      triggeredEndDateError: false,
      recurringStartDateError: false,
      recurringEndDateError: false,
      recurringWeekMonthError: false,
      repeatEveryValueError: false,
    });
  };

  handleChangeFC = (followFrequencyCapping) => {
    this.setState({ followFrequencyCapping });
  };

  handleChangeDND = (followDnd) => {
    this.setState({ followDnd });
  };

  handleChangeRepeatType = (repeatType) => {
    if (repeatType !== this.state.repeatType) {
      this.setState({ repeatType, repeatDays: [] });
    }
    this.props.CommunicationWhenError(
      this.props.errorUpdateWhenSubmit.errors,
      'repeatType'
    );
    this.setState({ recurringWeekMonthError: false });
  };

  handleChangeRepeatValue = (e) => {
    let repeatValue = e.target.value;

    const re = /^[0-9\b]+$/;
    if (repeatValue === '' || re.test(repeatValue)) {
      this.setState({ repeatEveryValue: repeatValue });
      if (this.state.repeatEveryValueError) {
        this.setState({ repeatEveryValueError: false });
      }
    }
  };

  handleChangeDeliveryTime = (deliveryType) => {
    this.setState({ deliveryType });
    this.setState({ oneTimeDeliveryTimeError: false });
    this.props.clearCommunicationWhenError(
      this.props.errorUpdateWhenSubmit.errors,
      'delayMinutes'
    );
  };
  recurringRepeatTimeOnChange = (value) => {
    this.setState({ repeatTime: value.format('HH:mm:ss') });
    this.props.clearCommunicationWhenError(
      'repeatTime',
      this.props.errorUpdateWhenSubmit.errors
    );
  };
  handleChangeStartTime = (startType) => {
    this.setState({ startType });
    if (this.state.startType !== 'now') {
      this.setState({ triggeredStartDateError: false });
    }
    this.props.clearCommunicationWhenError(
      this.props.errorUpdateWhenSubmit.errors,
      ''
    );
  };

  handleChangeEndTime = (endType) => {
    this.setState({ endType });
    if (this.state.endType !== 'now') {
      this.setState({ triggeredEndDateError: false });
    }
    this.setState({ recurringEndDateError: false });
    this.props.clearCommunicationWhenError(
      this.props.errorUpdateWhenSubmit.errors,
      ''
    );
  };

  handleInAppAdvanceSettings = (values, isValid) => {
    const whenAndWhereConditions = {};
    if (values?.whenOption === 'specific') {
      if (values?.setScreenName) {
        whenAndWhereConditions.activityNames = {
          conjunction: values?.screenNameMapperConjunction || 'OR',
          terms: values?.screenNameMapper
            ?.filter((term) => term?.operator)
            ?.map((term) => ({
              ...term,
              value: isArray(term.value) ? term.value.join(',') : term.value,
              term: 'SCREEN_NAME',
              dataType: 'STRING',
            })),
        };
      }

      if (values?.setScreenData) {
        whenAndWhereConditions.activityData = {
          conjunction: values?.screenDataMapperConjunction || 'OR',
          terms: values?.screenDataMapper
            ?.filter((term) => term?.operator)
            ?.map((term) => ({
              ...term,
              value: isArray(term.value) ? term.value.join(',') : term.value,
            })),
        };
      }

      if (values?.setScreenTime) {
        whenAndWhereConditions.timeSpentOnActivity = values?.screenTime * 1000;
      }

      if (values?.setSessionTime) {
        whenAndWhereConditions.timeSpentOnApp = values?.sessionTime * 1000;
      }

      if (values?.setNumberOfScreens) {
        whenAndWhereConditions.activityViews = {
          ...values?.numberOfScreenMapper,
          value: isArray(values?.numberOfScreenMapper?.value)
            ? values?.numberOfScreenMapper?.value.join(',')
            : values?.numberOfScreenMapper?.value,
          term: 'SCREEN_VIEWS',
          dataType: 'NUMERIC',
        };
      }

      if (values?.setEvents) {
        whenAndWhereConditions.customEvent = {
          conjunction: values?.eventsMapperConjunction || 'OR',
          terms: values?.eventsMapper
            ?.filter((term) => term?.operator)
            ?.map((term) => ({
              ...term,
              term: 'EVENT_NAME',
              // dataType: ?,
            })),
        };
      }
    }

    this.setState({
      inAppAdvanceValue: whenAndWhereConditions,
      inAppShowCount: (values?.hasShowLimit && values?.showLimit) || 2147483647,
      inAppAdvanceIsValid: isValid,
    });
  };
  isEdited = () => {
    const currentWhenState = this.getCurrentWhen();

    if (this.props.currentWhen.length < currentWhenState.length) return true;
    let sourceKeys = [
      { key: 'type', defaultValue: 'ONE_TIME' },
      { key: 'startDate', defaultValue: undefined },
      { key: 'endDate', defaultValue: undefined },
      { key: 'event', defaultValue: null },
      { key: 'eventConjunction', defaultValue: 'AND' },
      { key: 'eventPredicates', defaultValue: [] },
      { key: 'delayMinutes', defaultValue: 0 },
      { key: 'repeatEvery', defaultValue: undefined },
      { key: 'repeatType', defaultValue: 'DAY' },
      { key: 'repeatDays', defaultValue: [] },
      { key: 'repeatTime', defaultValue: null },
      { key: 'followDnd', defaultValue: true },
      { key: 'followFrequencyCapping', defaultValue: true },
      { key: 'queueMinutes', defaultValue: 0 },
      { key: 'showLimit', defaultValue: 2147483647 },
      { key: 'whenAndWhereConditions', defaultValue: {} },
      { key: 'followThrottling', defaultValue: false },
      { key: 'ttl', defaultValue: undefined },
    ];

    const res = sourceKeys.some((key) => {
      const src = this.props.currentWhen[key.key];
      const dest = currentWhenState[key.key];
      var isChanged = false;
      ((src === null || src === undefined) &&
        (dest === null || dest === undefined)) ||
      ((src === null || src === undefined) && dest === key.defaultValue) ||
      src == dest ||
      ((isArray(dest) || isObject(dest)) &&
        (dest == null ||
          dest == undefined ||
          dest.length == undefined ||
          dest.length == 0) &&
        isEqual(src, dest))
        ? (isChanged = false)
        : (isChanged = true);

      return isChanged;
    });
    return res;
  };
  renderSteps = () => {
    let steps = null;
    if (this.props.journey) {
      steps = this.props.journeySteps;
    }
    // if (this.communicationHasJourneyType()) {
    //   steps = journeySteps;
    // }
    return (
      <CommunicationStepsComponent
        step={'when'}
        isEdited={this.isEdited}
        creationStep={
          this.props.currentWhen && this.props.currentWhen.creationStep
            ? this.props.currentWhen.creationStep
            : null
        }
        journey={this.props.journey}
        steps={steps}
        goToStepOnJourney={this.props.goToStepOnJourney}
        campaignType={this.props.currentWhen && this.props.currentWhen.type}
        channel={this.channel}
      />
    );
  };

  handleClickBack = () => {
    if (!this.props.journey) {
      history.push('audience');
    } else if (
      this.props.channel === 'IN_APP' ||
      this.props.channel === 'ON_SITE' ||
      this.props.channel === 'PUSH' ||
      this.props.channel === 'web_PUSH'
    ) {
      this.props.goToStepOnJourney('audience');
    }
  };

  getQueue = () => {
    let result = null;
    if (this.state.queueingType === 'do_not') {
      result = 0;
    } else if (this.state.queueingType === 'do') {
      if (isEmpty(this.state.queueingValue)) {
        result = '';
      } else {
        result = getDelayMinutes(
          this.state.queueingValue,
          this.state.queueingTimeSelector
        );
      }
    }
    return result;
  };

  getStartDate = () => {
    let result = null;
    if (this.state.type === 'ONE_TIME') {
      if (this.state.deliveryType === 'now') {
        result = null;
      } else {
        result = this.state.startDateTime;
      }
    }
    if (this.state.type === 'TRIGGERED') {
      if (this.state.startType === 'now') {
        result = null;
      } else {
        result = this.state.startDateTime;
      }
    }
    if (this.state.type === 'RECURRING') {
      result = this.state.startDateTime;
    }
    return result;
  };

  handleClickNext = () => {
    let {
      recurringStartDateError,
      recurringEndDateError,
      recurringWeekMonthError,
      oneTimeDeliveryTimeError,
      triggeredStartDateError,
      triggeredEndDateError,
      repeatEveryValueError,
    } = this.state;
    if (
      (this.channel === 'IN_APP' ||
        this.channel === 'ON_SITE' ||
        this.props.channel === 'IN_APP' ||
        this.props.channel === 'ON_SITE') &&
      !this.state.inAppAdvanceIsValid
    ) {
      return;
    }
    if (
      this.state.type === 'ONE_TIME' &&
      isNil(this.state.startDateTime) &&
      this.state.deliveryType !== 'now'
    ) {
      oneTimeDeliveryTimeError = true;
    } else if (this.state.type === 'TRIGGERED') {
      if (isNil(this.state.startDateTime) && this.state.startType !== 'now') {
        triggeredStartDateError = true;
      }
      if (isNil(this.state.endDateTime) && this.state.endType !== 'never') {
        triggeredEndDateError = true;
      }
    } else if (this.state.type === 'RECURRING') {
      if (isNil(this.state.startDateTime)) {
        recurringStartDateError = true;
      }
      if (isNil(this.state.endDateTime) && this.state.endType !== 'never') {
        recurringEndDateError = true;
      }
      if (
        this.state.repeatDays.length === 0 &&
        (this.state.repeatType === 'WEEK' || this.state.repeatType === 'MONTH')
      ) {
        recurringWeekMonthError = true;
      }
      if (!this.state.repeatEveryValue) {
        repeatEveryValueError = true;
      }
    }
    const sendRequest =
      recurringStartDateError ||
      recurringEndDateError ||
      recurringWeekMonthError ||
      oneTimeDeliveryTimeError ||
      triggeredStartDateError ||
      triggeredEndDateError ||
      repeatEveryValueError;
    this.setState({
      recurringStartDateError,
      recurringEndDateError,
      recurringWeekMonthError,
      oneTimeDeliveryTimeError,
      triggeredStartDateError,
      triggeredEndDateError,
      repeatEveryValueError,
    });

    const whenAndWhereConditions = {};
    if (
      this.channel === 'IN_APP' ||
      this.channel === 'ON_SITE' ||
      this.props.channel === 'IN_APP' ||
      this.props.channel === 'ON_SITE'
    ) {
      Object.keys(this.state.inAppAdvanceValue).forEach((key) => {
        whenAndWhereConditions[key] = this.state.inAppAdvanceValue[key];
      });
    }
    const result = {
      ...this.props.currentWhen,
      delayMinutes:
        this.state.deliveryType === 'now'
          ? 0
          : getDelayMinutes(
              this.state.deliveryValue,
              this.state.deliveryTimeSelector
            ),
      endDate: this.state.endType === 'never' ? null : this.state.endDateTime,
      event: this.state.event,
      eventConjunction: this.state.eventConjunction,
      eventPredicates: this.state.eventPredicates,
      followDnd: !!this.state.followDnd,
      followFrequencyCapping: !!this.state.followFrequencyCapping,
      queueMinutes: this.getQueue(),
      repeatDays: this.state.repeatDays,
      repeatEvery: this.state.repeatEveryValue,
      repeatTime: this.state.repeatTime,
      repeatType: this.state.repeatType,
      startDate: this.getStartDate(),
      followThrottling: this.state.followThrottling,
      type: this.state.type,
      whenAndWhereConditions: whenAndWhereConditions,
      showLimit: this.state.inAppShowCount || 2147483647,
      ttl: this.state.ttl || 40320,
    };
    if (!sendRequest) {
      this.props.communicationUpdateSubmitWhenFetch(
        this.communicationId,
        result,
        this.props.journey
      );
    }
  };
  getCurrentWhen() {
    if (
      (this.channel === 'IN_APP' ||
        this.channel === 'ON_SITE' ||
        this.props.channel === 'IN_APP' ||
        this.props.channel === 'ON_SITE') &&
      !this.state.inAppAdvanceIsValid
    ) {
      return;
    }

    const whenAndWhereConditions = {};
    if (
      this.channel === 'IN_APP' ||
      this.channel === 'ON_SITE' ||
      this.props.channel === 'IN_APP' ||
      this.props.channel === 'ON_SITE'
    ) {
      Object.keys(this.state.inAppAdvanceValue).forEach((key) => {
        whenAndWhereConditions[key] = this.state.inAppAdvanceValue[key];
      });
    }
    return {
      delayMinutes:
        this.state.deliveryType === 'now'
          ? 0
          : getDelayMinutes(
              this.state.deliveryValue,
              this.state.deliveryTimeSelector
            ),
      endDate: this.state.endType === 'never' ? null : this.state.endDateTime,
      event: this.state.event,
      eventConjunction: this.state.eventConjunction,
      eventPredicates: this.state.eventPredicates,
      followDnd: !!this.state.followDnd,
      followFrequencyCapping: !!this.state.followFrequencyCapping,
      queueMinutes: this.getQueue(),
      repeatDays: this.state.repeatDays,
      repeatEvery: this.state.repeatEveryValue,
      repeatTime: this.state.repeatTime,
      repeatType: this.state.repeatType,
      startDate: this.getStartDate(),
      followThrottling: this.state.followThrottling,
      type: this.state.type,
      whenAndWhereConditions: whenAndWhereConditions,
      showLimit: this.state.inAppShowCount || 2147483647,
      ttl: this.state.ttl,
    };
  }
  renderWeekDays = () => {
    const weekDayNames = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
    const result = [];
    weekDayNames.forEach((item, index) => {
      result.push(
        <div
          id={'int-communication-week-day-' + index}
          data-cy={'int-communication-week-day-' + item}
          onClick={() => this.toggleDay(index)}
          style={
            this.isWeekDaySelected(index)
              ? styles.selectedWeekDay
              : styles.weekDay
          }
        >
          {item}
        </div>
      );
    });
    return result;
  };

  renderMonthDays = (day) => {
    if (day[1] === -1) {
      return (
        <div
          id={'int-communication-month-day-' + day[1]}
          onClick={() => this.toggleDay(day[1])}
          style={
            this.isWeekDaySelected(day[1])
              ? { ...styles.selectedWeekDay, width: 130 }
              : { ...styles.weekDay, width: 130 }
          }
        >
          {day[0]}
        </div>
      );
    } else {
      return (
        <div
          id={'int-communication-month-day-' + day[1]}
          onClick={() => this.toggleDay(day[1])}
          style={
            this.isWeekDaySelected(day[1])
              ? styles.selectedWeekDay
              : styles.weekDay
          }
        >
          {day[0]}
        </div>
      );
    }
  };

  renderMonthWeeks = () => {
    const monthDayNamesChunks = [
      [
        ['1st', 0],
        ['2nd', 1],
        ['3rd', 2],
        ['4th', 3],
        ['5th', 4],
        ['6th', 5],
      ],
      [
        ['7th', 6],
        ['8th', 7],
        ['9th', 8],
        ['10th', 9],
        ['11th', 10],
        ['12th', 11],
      ],
      [
        ['13th', 12],
        ['14th', 13],
        ['15th', 14],
        ['16th', 15],
        ['17th', 16],
        ['18th', 17],
      ],
      [
        ['19th', 18],
        ['20th', 19],
        ['21st', 20],
        ['22nd', 21],
        ['23rd', 22],
        ['24th', 23],
      ],
      [
        ['25th', 24],
        ['26th', 25],
        ['27th', 26],
        ['28th', 27],
        ['29th', 28],
        ['30th', 29],
      ],
      [
        ['31st', 30],
        ['Last Day of Month', -1],
      ],
    ];
    const result = [];
    monthDayNamesChunks.forEach((chunk, chunkIndex) => {
      result.push(
        <div
          style={
            chunkIndex === monthDayNamesChunks.length - 1
              ? { display: 'flex', flexDirection: 'row' }
              : { display: 'flex', flexDirection: 'row', marginBottom: 8 }
          }
        >
          {chunk.map((day) => this.renderMonthDays(day))}
        </div>
      );
    });
    return result;
  };

  renderFooter = () => {
    return (
      <CommunicationFooterComponent
        step={'when'}
        isEdited={this.isEdited}
        readOnly={this.isEditDisable()}
        steps={this.props.journey && this.props.journeySteps}
        loadingNext={this.props.loadingUpdateWhenSubmit}
        handleClickNext={() => this.handleClickNext()}
        handleClickBack={() => this.handleClickBack()}
      />
    );
  };

  renderEventsList = () => {
    let result = [];
    if (Array.isArray(this.props.eventList)) {
      this.props.eventList.forEach((item) => {
        result.push(
          <Select.Option value={item.id}>{item.title}</Select.Option>
        );
      });
    }
    return result;
  };

  renderCustomEventAttributeCount = () => {
    let result = 0;
    if (
      this.state.eventPredicates &&
      Array.isArray(this.state.eventPredicates)
    ) {
      result = this.state.eventPredicates.length;
    }
    return result;
  };

  onOkDeliveryTime = (value) => {
    if (this.state.oneTimeDeliveryTimeError) {
      this.setState({ oneTimeDeliveryTimeError: false });
    }
    this.setState({ startDateTime: value.toISOString() });
  };
  onOkTriggeredStartDate = (value) => {
    if (this.state.triggeredStartDateError) {
      this.setState({ triggeredStartDateError: false });
    }
    this.setState({ startDateTime: value ? value.toISOString() : null });
    this.props.clearCommunicationWhenError(
      this.props.errorUpdateWhenSubmit.errors,
      ''
    );
  };
  onOkTriggeredRecurringEndDate = (value) => {
    if (this.state.triggeredEndDateError) {
      this.setState({ triggeredEndDateError: false });
    }
    if (this.state.recurringEndDateError) {
      this.setState({ recurringEndDateError: false });
    }
    this.setState({ endDateTime: value.toISOString() });
    this.props.clearCommunicationWhenError(
      this.props.errorUpdateWhenSubmit.errors,
      ''
    );
  };
  handleChangeRecurringStartDate = (value) => {
    if (this.state.recurringStartDateError) {
      this.setState({ recurringStartDateError: false });
    }
    const time_ = value
      ? value.startOf('day').format('YYYY-MM-DDTHH:mm:ss')
      : null;
    this.setState({ startDateTime: time_ });
    this.props.clearCommunicationWhenError(
      this.props.errorUpdateWhenSubmit.errors,
      ''
    );
  };
  selectEventOnChange(event) {
    this.setState(
      {
        event,
        currentPredicate: {
          ...this.state.currentPredicate,
          event,
          eventConjunction: 'AND',
          eventPredicates: [],
        },
      },
      () => this.forceUpdate()
    );
    this.props.clearCommunicationWhenError(
      this.props.errorUpdateWhenSubmit.errors,
      'event'
    );
  }
  delayMinutesOnChange(e) {
    this.setState({ deliveryValue: e.target.value });
    this.props.clearCommunicationWhenError(
      this.props.errorUpdateWhenSubmit.errors,
      'delayMinutes'
    );
  }

  showErrorMessage(errorType) {
    if (
      !isEmpty(this.props.errorUpdateWhenSubmit) &&
      Array.isArray(this.props.errorUpdateWhenSubmit.errors)
    ) {
      const foundItem = lodashFind(
        this.props.errorUpdateWhenSubmit.errors,
        (item) => item.key === errorType
      );
      if (foundItem) {
        if (errorType === 'event' || errorType === 'repeatType') {
          return foundItem.message;
        } else if (
          (errorType === 'queueMinutes' && this.state.queueingType === 'do') ||
          errorType === 'delayMinutes'
        ) {
          return <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{foundItem.message}</div>;
        } else if (errorType === 'repeatTime') {
          return (
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {foundItem.message}
            </span>
          );
        } else if (errorType === '') {
          return <span>&nbsp;&nbsp;&nbsp;&nbsp;{foundItem.message}</span>;
        }
      }
    }
    return null;
  }
  validateTriggeredRecurringEndDate() {
    if (!isNil(this.showErrorMessage(''))) return 'error';
    if (this.state.type === 'TRIGGERED') {
      if (this.state.triggeredEndDateError) {
        return 'error';
      }
      return null;
    } else {
      if (this.state.recurringEndDateError) {
        return 'error';
      }
      return null;
    }
  }
  triggeredRecurringStatus() {
    if (!isNil(this.showErrorMessage(''))) return this.showErrorMessage('');
    if (this.state.type === 'TRIGGERED') {
      if (this.state.triggeredEndDateError) {
        return (
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This field is required</span>
        );
      }
      return null;
    } else {
      if (this.state.recurringEndDateError) {
        return (
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This field is required</span>
        );
      }
      return null;
    }
  }
  renderCampaignType = () => {
    if (this.props.journey) return null;
    const productId = getProductId(this.props.pathname);
    const currentProduct = this.props.currentUser?.products?.find(
      (p) => p.id === +productId
    );
    return (
      <Card
        styles={{
          marginTop: 20,
          paddingBottom: 20,
        }}
        data-cy="communication-when"
      >
        <div
          style={{
            padding: 16,
            borderBottom: 'solid 2px #f4f9ff',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          <div style={{ fontSize: 16, color: 'black', fontWeight: '600' }}>
            Communication Type & Schedule
          </div>
        </div>
        <Form layout="vertical">
          <div
            style={{
              ...styles.editContainer,
              marginTop: 20,
              alignItems: 'flex-start',
            }}
          >
            <div style={{ ...styles.editLabel, position: 'relative', top: 2 }}>
              COMMUNICATION TYPE
            </div>
            <div style={{ ...styles.editFieldContainer, marginLeft: 20 }}>
              <Radio.Group
                id="int-communication-when-type"
                data-cy="int-communication-when-type"
                disabled={this.isEditDisable()}
                value={this.state.type}
                onChange={(e) => this.handleChangeCampaignType(e.target.value)}
              >
                <Radio style={styles.radioStyle} value={'ONE_TIME'}>
                  One-Time
                </Radio>
                <Radio
                  style={styles.radioStyle}
                  value={'TRIGGERED'}
                  {...(currentProduct?.sendify && {
                    disabled: true,
                  })}
                >
                  Triggered
                </Radio>
                <Radio
                  style={styles.radioStyle}
                  value={'RECURRING'}
                  {...(currentProduct?.sendify && {
                    disabled: true,
                  })}
                >
                  Recurring
                </Radio>
              </Radio.Group>
            </div>
          </div>

          {this.state.type === 'TRIGGERED' && (
            <div
              style={{
                ...styles.editContainer,
                marginTop: 20,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...styles.editLabel,
                  position: 'relative',
                  top: 5,
                }}
              >
                UPON OCCURRENCE OF
              </div>
              <div style={{ ...styles.editFieldContainer, marginLeft: 20 }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Form.Item
                    validateStatus={
                      isNil(this.showErrorMessage('event')) ? null : 'error'
                    }
                    help={this.showErrorMessage('event')}
                    id={isNil(this.showErrorMessage('event')) ? null : 'error'}
                  >
                    <Select
                      loading={this.props.loadingEvents}
                      id={
                        isNil(this.showErrorMessage('event')) ? null : 'error'
                      }
                      disabled={this.isEditDisable()}
                      showSearch
                      value={this.state.event}
                      style={{ width: 200 }}
                      placeholder="Select Event"
                      onChange={(val) => this.selectEventOnChange(val)}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.props.loadingEvents
                        ? null
                        : this.renderEventsList()}
                    </Select>
                  </Form.Item>
                  {this.props.loadingEvents ? null : (
                    <span
                      id="int-communication-when-custom-attribute"
                      onClick={() =>
                        this.setState({
                          selectorModalVisible: !isNil(this.state.event),
                        })
                      }
                      style={{
                        position: 'relative',
                        top: -5,
                        left: 4,
                        cursor: 'pointer',
                      }}
                    >
                      <Badge
                        count={this.renderCustomEventAttributeCount()}
                        showZero={false}
                      >
                        <i
                          className="fl-filter fl-dark"
                          style={{ fontSize: 28 }}
                        ></i>
                      </Badge>
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}

          {(this.state.type === 'ONE_TIME' ||
            (this.state.type === 'TRIGGERED' && this.state.event)) && (
            <div
              style={{
                ...styles.editContainer,
                marginTop: 20,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{ ...styles.editLabel, position: 'relative', top: 2 }}
              >
                DELIVERY TIME
              </div>
              <div
                style={{
                  ...styles.editFieldContainer,
                  marginLeft: 20,
                  position: 'relative',
                }}
              >
                <Radio.Group
                  id="int-communication-when-delivery-type"
                  data-cy="int-communication-when-delivery-time"
                  disabled={
                    this.isEditDisable() ||
                    (this.props.currentWhen &&
                      this.props.currentWhen.status === 'UPCOMING' &&
                      this.state.type === 'ONE_TIME')
                  }
                  value={this.state.deliveryType}
                  onChange={(e) =>
                    this.handleChangeDeliveryTime(e.target.value)
                  }
                >
                  <Radio style={styles.radioStyle} value={'now'}>
                    Immediately
                  </Radio>
                  {this.state.type === 'ONE_TIME' && (
                    <React.Fragment>
                      <Radio style={styles.radioStyle} value={'later'}>
                        <span>At</span>
                      </Radio>
                      <div style={{ position: 'absolute', top: 44, left: 40 }}>
                        <Form.Item
                          help={
                            this.state.oneTimeDeliveryTimeError &&
                            this.state.deliveryType !== 'now' ? (
                              <span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This
                                field is required
                              </span>
                            ) : null
                          }
                          validateStatus={
                            this.state.oneTimeDeliveryTimeError &&
                            this.state.deliveryType !== 'now'
                              ? 'error'
                              : null
                          }
                        >
                          <DatePicker
                            id={
                              this.state.oneTimeDeliveryTimeError &&
                              this.state.deliveryType !== 'now'
                                ? 'error'
                                : null
                            }
                            placeholder="Select Date & Time"
                            value={
                              this.state.startDateTime
                                ? moment(this.state.startDateTime)
                                : null
                            }
                            disabled={
                              this.state.deliveryType === 'now' ||
                              this.isEditDisable()
                            }
                            showTime={{ format: 'HH:mm' }}
                            style={{ marginLeft: 40 }}
                            onOk={(value) => this.onOkDeliveryTime(value)}
                            onChange={(value) => {
                              this.setState({ startDateTime: value });
                            }}
                          />
                        </Form.Item>
                      </div>
                    </React.Fragment>
                  )}
                  {this.state.type === 'TRIGGERED' && (
                    <Radio style={styles.radioStyle} value={'later'}>
                      <span>Wait for</span>
                    </Radio>
                  )}
                  {this.state.type === 'TRIGGERED' && (
                    <div
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 110,
                        top: 15,
                      }}
                    >
                      <Form.Item
                        validateStatus={
                          isNil(this.showErrorMessage('delayMinutes'))
                            ? null
                            : 'error'
                        }
                        help={this.showErrorMessage('delayMinutes')}
                        id={
                          isNil(this.showErrorMessage('delayMinutes'))
                            ? null
                            : 'error'
                        }
                      >
                        <Input
                          id={
                            isNil(this.showErrorMessage('delayMinutes'))
                              ? null
                              : 'error'
                          }
                          disabled={
                            this.state.deliveryType === 'now' ||
                            this.isEditDisable()
                          }
                          value={this.state.deliveryValue}
                          onChange={(e) => this.delayMinutesOnChange(e)}
                          style={{ width: 80, marginLeft: 20, marginRight: 20 }}
                        />

                        <Select
                          id="int-communication-when-delivery-time-selector"
                          disabled={
                            this.state.deliveryType === 'now' ||
                            this.isEditDisable()
                          }
                          style={{ width: 130, marginRight: 20 }}
                          value={this.state.deliveryTimeSelector}
                          onChange={(deliveryTimeSelector) =>
                            this.setState({ deliveryTimeSelector }, () =>
                              this.forceUpdate()
                            )
                          }
                        >
                          <Select.Option value={'MINUTE'}>
                            Minutes
                          </Select.Option>
                          <Select.Option value={'HOUR'}>Hours</Select.Option>
                          <Select.Option value={'DAY'}>Days</Select.Option>
                          <Select.Option value={'WEEK'}>Weeks</Select.Option>
                        </Select>
                      </Form.Item>
                      <span>and then send</span>
                    </div>
                  )}
                </Radio.Group>
              </div>
            </div>
          )}
          {this.state.type === 'TRIGGERED' && (
            <div
              style={{
                ...styles.editContainer,
                marginTop: 20,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{ ...styles.editLabel, position: 'relative', top: 15 }}
              >
                START DATE
              </div>
              <div
                style={{
                  ...styles.editFieldContainer,
                  position: 'relative',
                  marginLeft: 20,
                }}
              >
                <Radio.Group
                  id="int-communication-when-start-type"
                  disabled={this.isEditDisable()}
                  value={this.state.startType}
                  onChange={(e) => this.handleChangeStartTime(e.target.value)}
                >
                  <Radio style={styles.radioStyle} value={'now'}>
                    Now
                  </Radio>
                  <Radio style={styles.radioStyle} value={'later'}>
                    <span>At</span>
                  </Radio>
                </Radio.Group>
                <div style={{ position: 'absolute', top: 15, left: 60 }}>
                  <Form.Item
                    validateStatus={
                      this.state.triggeredStartDateError ? 'error' : null
                    }
                    help={
                      this.state.triggeredStartDateError ? (
                        <span>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This field is required
                        </span>
                      ) : null
                    }
                  >
                    <DatePicker
                      id={this.state.triggeredStartDateError ? 'error' : null}
                      placeholder="Select Date &amp; Time"
                      value={
                        this.state.startDateTime
                          ? moment(this.state.startDateTime)
                          : null
                      }
                      disabled={
                        this.state.startType === 'now' || this.isEditDisable()
                      }
                      showTime={{ format: 'HH:mm' }}
                      style={{ marginLeft: 20 }}
                      onOk={(value) => {
                        this.onOkTriggeredStartDate(value);
                      }}
                      onChange={(value) => {
                        this.onOkTriggeredStartDate(value);
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          )}
          {this.state.type === 'RECURRING' && (
            <div
              style={{
                ...styles.editContainer,
                marginTop: 20,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{ ...styles.editLabel, position: 'relative', top: 5 }}
              >
                START DATE
              </div>
              <div style={{ ...styles.editFieldContainer }}>
                <div>
                  <Form.Item
                    validateStatus={
                      this.state.recurringStartDateError
                        ? 'error'
                        : !isNil(this.showErrorMessage(''))
                        ? 'error'
                        : null
                    }
                    help={
                      this.state.recurringStartDateError ? (
                        <span>
                          &nbsp;&nbsp;&nbsp;&nbsp;This field is required
                        </span>
                      ) : !isNil(this.showErrorMessage('')) ? (
                        this.showErrorMessage('')
                      ) : null
                    }
                    id={this.state.recurringStartDateError ? 'error' : null}
                  >
                    <DatePicker
                      data-cy="communication-when-recurring-start-date"
                      id={this.state.recurringStartDateError ? 'error' : null}
                      placeholder="Select Date"
                      value={
                        this.state.startDateTime
                          ? moment(this.state.startDateTime)
                          : undefined
                      }
                      disabled={this.isEditDisable()}
                      style={{ width: 200, marginLeft: 20 }}
                      onChange={(value) =>
                        this.handleChangeRecurringStartDate(value)
                      }
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          )}
          {this.state.type === 'RECURRING' && (
            <div
              style={{
                ...styles.editContainer,
                marginTop: 20,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{ ...styles.editLabel, position: 'relative', top: 5 }}
              >
                DELIVERY SCHEDULE
              </div>

              <div style={{ ...styles.editFieldContainer, marginLeft: 20 }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                  }}
                >
                  <span style={{ marginTop: 5 }}>Repeat every</span>
                  <Form.Item
                    validateStatus={
                      this.state.repeatEveryValueError ? 'error' : null
                    }
                    id={this.state.repeatEveryValueError ? 'error' : null}
                  >
                    <Input
                      id={
                        this.state.repeatEveryValueError
                          ? 'error int-communication-when-repeat-every-value'
                          : 'int-communication-when-repeat-every-value'
                      }
                      data-cy="int-communication-when-repeat-every-value"
                      disabled={this.isEditDisable()}
                      value={this.state.repeatEveryValue}
                      onChange={this.handleChangeRepeatValue}
                      style={{ width: 60, marginLeft: 20, marginRight: 20 }}
                    />
                  </Form.Item>
                  <Form.Item
                    validateStatus={
                      isNil(this.showErrorMessage('repeatType'))
                        ? null
                        : 'error'
                    }
                    help={this.showErrorMessage('repeatType')}
                    id={
                      isNil(this.showErrorMessage('repeatType'))
                        ? null
                        : 'error'
                    }
                  >
                    <Select
                      id={
                        isNil(this.showErrorMessage('repeatType'))
                          ? null
                          : 'error'
                      }
                      data-cy="repeat-every-recurring"
                      disabled={this.isEditDisable()}
                      placeholder={'Duration'}
                      style={{ width: 130, marginRight: 20 }}
                      value={this.state.repeatType}
                      onChange={(e) => this.handleChangeRepeatType(e)}
                    >
                      <Select.Option value={'DAY'}>
                        {this.state.repeatEveryValue == 1 ? 'Day' : 'Days'}
                      </Select.Option>
                      <Select.Option value={'WEEK'}>
                        {this.state.repeatEveryValue == 1 ? 'Week' : 'Weeks'}
                      </Select.Option>
                      <Select.Option value={'MONTH'}>
                        {this.state.repeatEveryValue == 1 ? 'Month' : 'Months'}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {this.state.repeatType === 'WEEK' && (
                      <Form.Item
                        validateStatus={
                          this.state.recurringWeekMonthError ? 'error' : null
                        }
                        help={
                          this.state.recurringWeekMonthError ? (
                            <div>&nbsp;&nbsp;&nbsp;Please pick an item</div>
                          ) : null
                        }
                        id={this.state.recurringWeekMonthError ? 'error' : null}
                      >
                        <div
                          id={
                            this.state.recurringWeekMonthError ? 'error' : null
                          }
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: 15,
                          }}
                        >
                          <span style={{ marginRight: 10, marginTop: 5 }}>
                            on
                          </span>
                          {this.renderWeekDays()}
                        </div>
                      </Form.Item>
                    )}
                    {this.state.repeatType === 'MONTH' && (
                      <Form.Item
                        validateStatus={
                          this.state.recurringWeekMonthError ? 'error' : null
                        }
                        help={
                          this.state.recurringWeekMonthError ? (
                            <div>&nbsp;&nbsp;&nbsp;Please pick an item</div>
                          ) : null
                        }
                        id={this.state.recurringWeekMonthError ? 'error' : null}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: 15,
                          }}
                        >
                          <span style={{ marginRight: 10, marginTop: 5 }}>
                            on
                          </span>
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            {this.renderMonthWeeks()}
                          </div>
                        </div>
                      </Form.Item>
                    )}
                    <div>
                      <Form.Item
                        validateStatus={
                          isNil(this.showErrorMessage('repeatTime'))
                            ? null
                            : 'error'
                        }
                        help={this.showErrorMessage('repeatTime')}
                        id={
                          isNil(this.showErrorMessage('repeatTime'))
                            ? null
                            : 'error'
                        }
                      >
                        <span>at</span>
                        <TimePicker
                          id={
                            isNil(this.showErrorMessage('repeatTime'))
                              ? null
                              : 'error'
                          }
                          data-cy="recurring-repeat-time"
                          disabled={this.isEditDisable()}
                          format={'HH:mm'}
                          value={
                            this.state.repeatTime
                              ? moment(this.state.repeatTime, 'HH:mm:ss')
                              : undefined
                          }
                          onChange={(value) =>
                            this.recurringRepeatTimeOnChange(value)
                          }
                          style={{ marginLeft: 20 }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(this.state.type === 'TRIGGERED' ||
            this.state.type === 'RECURRING' ||
            this.state.type === 'ONE_TIME') && (
            <div
              style={{
                ...styles.editContainer,
                marginTop: 20,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{ ...styles.editLabel, position: 'relative', top: 2 }}
              >
                END DATE
              </div>
              <div
                style={{
                  ...styles.editFieldContainer,
                  marginLeft: 20,
                  position: 'relative',
                }}
              >
                <Radio.Group
                  id="int-communication-when-end-type"
                  data-cy="int-communication-when-end-type"
                  disabled={this.isEditDisable()}
                  value={this.state.endType}
                  onChange={(e) => this.handleChangeEndTime(e.target.value)}
                >
                  <Radio style={styles.radioStyle} value={'never'}>
                    Never
                  </Radio>
                  <Radio style={styles.radioStyle} value={'till'}>
                    <span>Till</span>
                  </Radio>
                </Radio.Group>
                <div style={{ position: 'absolute', top: 15, left: 60 }}>
                  <Form.Item
                    validateStatus={this.validateTriggeredRecurringEndDate()}
                    id={this.validateTriggeredRecurringEndDate()}
                  >
                    <DatePicker
                      data-cy="int-communication-when-end-time"
                      id={this.validateTriggeredRecurringEndDate()}
                      placeholder="Select Date & Time"
                      value={
                        this.state.endDateTime
                          ? moment(this.state.endDateTime)
                          : undefined
                      }
                      onChange={(value) => {
                        this.setState({ endDateTime: value });
                      }}
                      disabled={this.state.endType === 'never'}
                      showTime={{ format: 'HH:mm' }}
                      style={{ marginLeft: 20 }}
                      onOk={(value) =>
                        this.onOkTriggeredRecurringEndDate(value)
                      }
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          )}
        </Form>
      </Card>
    );
  };

  onOkEventFilter = () => {
    this.setState({
      event: cloneDeep(this.state.currentPredicate.event),
      eventPredicates: cloneDeep(this.state.currentPredicate.eventPredicates),
      eventConjunction: cloneDeep(this.state.currentPredicate.eventConjunction),
      selectorModalVisible: false,
    });
  };

  onResetEventFilter = () => {
    this.setState({
      currentPredicate: {
        ...this.state.currentPredicate,
        event: cloneDeep(this.state.event),
        eventPredicates: cloneDeep(this.state.eventPredicates),
        eventConjunction: cloneDeep(this.state.eventConjunction),
      },
    });
  };

  renderEventFilterSelector = () => {
    return (
      <Modal
        width={1080}
        title={''}
        centered
        visible={this.state.selectorModalVisible}
        onOk={() => this.setState({ selectorModalVisible: false })}
        onCancel={() => this.setState({ selectorModalVisible: false })}
        footer={[
          <Button key="back" onClick={() => this.onResetEventFilter()}>
            Reset
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => this.onOkEventFilter()}
          >
            Apply
          </Button>,
        ]}
      >
        <div>
          <ProductSegmentPredicateComponent
            type="HAS_DONE_EVENT"
            predicate={{ ...this.state.currentPredicate, type: 'BEHAVIOUR' }}
            events={this.props.eventList}
            isInSegment={false}
            handleChangePredicate={(currentPredicate) =>
              this.setState({ currentPredicate })
            }
            clearSegmentError={() => {
              //TODO Must Handle
            }}
            fetchRecommendEventAttributes={
              this.props.productSegmentRecommendEventAttributesFetch
            }
            recommendEventAttributes={this.props.recommendEventAttributes}
            fetchRecommendDeviceAttributes={
              this.props.productSegmentRecommendDeviceAttributesFetch
            }
            recommendDeviceAttributes={this.props.recommendDeviceAttributes}
            fetchRecommendUserAttributes={
              this.props.productSegmentRecommendUserAttributesFetch
            }
            recommendUserAttributes={this.props.recommendUserAttributes}
          />
        </div>
      </Modal>
    );
  };

  renderAdvanceSettings = () => {
    if (
      this.channel === 'IN_APP' ||
      this.channel === 'ON_SITE' ||
      this.props.channel === 'IN_APP' ||
      this.props.channel === 'ON_SITE'
    ) {
      const data = {
        ...this.state.inAppAdvanceValue,
      };
      if (
        this.state.inAppShowCount &&
        this.state.inAppShowCount !== 2147483647
      ) {
        data.showLimit = this.state.inAppShowCount;
        data.hasShowLimit = true;
      }
      return (
        <>
          <TTL
            ttlValue={this.state.ttl}
            errors={this.props.errorUpdateWhenSubmit?.errors}
            onChangeTtl={(value) => {
              this.setState({ ttl: value });
            }}
          />
          {/* {this.channel === 'IN_APP' ||
            this.channel === 'ON_SITE' ||
            this.props.channel === 'IN_APP' ||
            (this.props.channel === 'ON_SITE' && ( */}
          <InAppAdvanceSetting
            channel={this.props.channel}
            eventList={this.props.eventList}
            onChange={this.handleInAppAdvanceSettings}
            data={data}
          />
          {/* ))} */}
        </>
      );
    }

    const activeProductId = getProductId(this.props.pathname);
    return (
      <>
        {this.channel === 'PUSH' ||
        this.props.channel === 'PUSH' ||
        this.props.channel === 'WEB_PUSH' ||
        this.channel === 'WEB_PUSH' ? (
          <TTL
            ttlValue={this.state.ttl}
            errors={this.props.errorUpdateWhenSubmit?.errors}
            onChangeTtl={(value) => {
              this.setState({ ttl: value });
            }}
          />
        ) : (
          ''
        )}
        {!this.props.journey && (
          <Card
            styles={{
              marginTop: 20,
              paddingBottom: 50,
              marginBottom: 60,
            }}
          >
            <div
              style={{
                padding: 16,
                borderBottom: 'solid 2px #f4f9ff',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              }}
            >
              <div style={{ fontSize: 16, color: 'black', fontWeight: '600' }}>
                Frequency Capping, DND & Queuing
              </div>
            </div>
            <div
              style={{
                ...styles.editContainer,
                marginTop: 20,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{ ...styles.editLabel, position: 'relative', top: 2 }}
              >
                FC
              </div>
              <div style={{ ...styles.editFieldContainer, marginLeft: 20 }}>
                <Radio.Group
                  id="int-communication-when-fc"
                  data-cy="int-communication-when-fc"
                  disabled={this.isEditDisable()}
                  defaultValue={this.state.followFrequencyCapping}
                  value={this.state.followFrequencyCapping}
                  onChange={(e) => this.handleChangeFC(e.target.value)}
                >
                  <Radio style={styles.radioStyle} value={true}>
                    Follow Frequency Capping settings. Do not send messages to a
                    user if limit for that day/week/month is reached for that
                    user
                  </Radio>
                  <Radio style={styles.radioStyle} value={false}>
                    Ignore Frequency Capping settings. Send messages to a user
                    even if limit for that day/week/month is reached for that
                    user
                  </Radio>
                </Radio.Group>
              </div>
            </div>
            <div
              style={{
                ...styles.editContainer,
                marginTop: 20,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{ ...styles.editLabel, position: 'relative', top: 2 }}
              >
                DND
              </div>
              <div style={{ ...styles.editFieldContainer, marginLeft: 20 }}>
                <Radio.Group
                  id="int-communication-when-dnd"
                  data-cy="int-communication-when-dnd"
                  disabled={this.isEditDisable()}
                  defaultValue={this.state.followDnd}
                  value={this.state.followDnd}
                  onChange={(e) => this.handleChangeDND(e.target.value)}
                >
                  <Radio style={styles.radioStyle} value={true}>
                    Follow DND settings. Do not send messages during DND hours
                  </Radio>
                  <Radio style={styles.radioStyle} value={false}>
                    Ignore DND settings. Send messages during DND hours
                  </Radio>
                </Radio.Group>
              </div>
            </div>
            <div
              style={{
                ...styles.editContainer,
                marginTop: 20,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{ ...styles.editLabel, position: 'relative', top: 2 }}
              >
                QUEUEING
              </div>
              <div
                style={{
                  ...styles.editFieldContainer,
                  marginLeft: 20,
                  position: 'relative',
                }}
              >
                <Radio.Group
                  id="int-communication-when-queue"
                  data-cy="int-communication-when-queue"
                  disabled={this.isEditDisable()}
                  value={this.state.queueingType}
                  onChange={(e) =>
                    this.handleChangeQueueingType(
                      'queueingType',
                      e.target.value
                    )
                  }
                >
                  <Radio style={styles.radioStyle} value={'do_not'}>
                    Do not queue messages. Drop the message and do not deliver
                    later if delivery does not happen immediately
                  </Radio>
                  <Radio
                    style={{ ...styles.radioStyle, marginTop: 10 }}
                    value={'do'}
                  >
                    Queue message for up to
                  </Radio>
                </Radio.Group>
                <div style={{ position: 'absolute', top: 45, left: 175 }}>
                  <Form layout="vertical">
                    <Form.Item
                      validateStatus={
                        isNil(this.showErrorMessage('queueMinutes'))
                          ? null
                          : 'error'
                      }
                      help={this.showErrorMessage('queueMinutes')}
                      id={
                        isNil(this.showErrorMessage('queueMinutes'))
                          ? null
                          : 'error'
                      }
                    >
                      <Input
                        id={
                          isNil(this.showErrorMessage('queueMinutes'))
                            ? null
                            : 'error'
                        }
                        disabled={
                          this.state.queueingType === 'do_not' ||
                          this.isEditDisable()
                        }
                        value={this.state.queueingValue}
                        onChange={(e) =>
                          this.handleChangeQueueingType(
                            'queueingValue',
                            e.target.value
                          )
                        }
                        style={{ width: 60, marginLeft: 20, marginRight: 20 }}
                      />
                      <Select
                        id="int-communication-when-queue-selector"
                        disabled={
                          this.state.queueingType === 'do_not' ||
                          this.isEditDisable()
                        }
                        style={{ width: 130, marginRight: 20 }}
                        value={this.state.queueingTimeSelector}
                        onChange={(e) =>
                          this.handleChangeQueueingTimeSelector(e)
                        }
                      >
                        <Select.Option value={'MINUTE'}>
                          {this.state.queueingValue == 1 ? 'Minute' : 'Minutes'}
                        </Select.Option>
                        <Select.Option value={'HOUR'}>
                          {this.state.queueingValue == 1 ? 'Hour' : 'Hours'}
                        </Select.Option>
                        <Select.Option value={'DAY'}>
                          {this.state.queueingValue == 1 ? 'Day' : 'Days'}
                        </Select.Option>
                      </Select>
                      <span style={{ color: 'rgba(0,0,0,0.85)', fontSize: 14 }}>
                        and deliver later if delivery does not happen
                        immediately
                      </span>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
            {this.props.currentUser &&
              findProductThrottlingEnabled(
                this.props.currentUser,
                activeProductId
              ) && (
                <div
                  style={{
                    ...styles.editContainer,
                    marginTop: 20,
                    alignItems: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      ...styles.editLabel,
                      position: 'relative',
                      top: 2,
                    }}
                  >
                    THROTTLING
                  </div>
                  <div
                    style={{
                      ...styles.editFieldContainer,
                      marginLeft: 20,
                    }}
                  >
                    <Radio.Group
                      disabled={this.isEditDisable()}
                      value={this.state.throttlingType}
                      onChange={(e) =>
                        this.handleChangeThrottlingType(e.target.value)
                      }
                    >
                      <Radio style={styles.radioStyle} value={'do_not'}>
                        Do not throttle campaigns. Send all messages immediately
                      </Radio>
                      <Radio
                        disabled={
                          isNil(this.state.defaultDayThrottling) &&
                          isNil(this.state.defaultSecondThrottling)
                        }
                        style={styles.radioStyle}
                        value={'limits'}
                      >
                        Throttle campaigns as per channel throttling limits (
                        {this.getDefaultThrottling()})
                      </Radio>
                    </Radio.Group>
                  </div>
                </div>
              )}
          </Card>
        )}
      </>
    );
  };

  render() {
    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    return (
      <div style={{ padding: 30, position: 'relative' }}>
        {this.renderSteps()}
        {this.props.loadingUpdateWhen ? (
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: 5,
              boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              paddingTop: 50,
              paddingBottom: 50,
            }}
          >
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <React.Fragment>
            {this.renderCampaignType()}
            {this.renderAdvanceSettings()}
            {this.renderEventFilterSelector()}
            {this.renderFooter()}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default CommunicationWhenComponent;
