import React from 'react';
import { useEffect } from 'react';
import '../../productRestApi/style/ProductRestApiViewStyle.less';

import texts from '../../../../utils/texts';
import Card from '@Utils/Card';
import { Button, Modal, message } from 'antd';
const ProductSettingViewComponent = (props) => {
  useEffect(() => {
    props.productRestApiViewFetch();
  }, []);

  const handleCopyClick = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        message.success('Token copied to clipboard');
      },
      (err) => {
        console.error('Unable to copy to clipboard', err);
        message.error('Failed to copy token');
      }
    );
  };

  const handleRevoke = () => {
    Modal.confirm({
      title: 'Confirmation',
      content: 'Are you sure you want to revoke the key?',
      onOk() {
        props.revokeFetch();
      },
    });
  };

  function renderRestApiKeyComponent() {
    return (
      <div>
        <Card
          title="Project Credentials"
          linkHref={texts.restApi}
          linkText="VIEW DOCUMENTATION"
          loading={props.loadingProjectCredentials}
        >
          <>
            <div className="column-content">
              <div className="item">
                <span className="item-title">NAME</span>
                <span className="item-description">
                  {props.currentProductRestApi?.name}
                </span>
              </div>
              <div className="item">
                <span className="item-title">API KEY</span>
                <span
                  className="item-description"
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    handleCopyClick(props.currentProductRestApi?.apiKey)
                  }
                >
                  {props.currentProductRestApi?.apiKey}
                </span>
                <span>
                  <Button
                    type="link"
                    disabled={props.loadingRevoke}
                    onClick={handleRevoke}
                  >
                    Revoke
                  </Button>
                </span>
              </div>
              <div className="item">
                <span className="item-title">LICENSE CODE</span>
                <span className="item-description">
                  {props.currentProductRestApi?.licenceCode}
                </span>
              </div>
            </div>
          </>
        </Card>
      </div>
    );
  }

  return renderRestApiKeyComponent();
};

export default ProductSettingViewComponent;
