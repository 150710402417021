import React from 'react';
import { Input, Button, Select, Switch } from 'antd';
import { Link } from 'react-router-dom';
import Card from '@Utils/Card';
const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  title: {
    display: 'flex',
    color: '#707070',
    fontSize: 14,
    margin: '15px 10px',
  },
  input: {
    display: 'flex',
    color: '#707070',
    fontSize: 14,
    margin: '10px 5px',
  },
};

class FileStorageUpdateComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileId:
        (this.props.location.pathname.includes('view') &&
          this.props.location.pathname.split('view/').pop()) ||
        (this.props.location.pathname.includes('update') &&
          this.props.location.pathname.split('update/').pop()),
    };
  }
  componentDidMount() {
    if (this.state.fileId) {
      this.props.fileStorageUpdateFetch(this.state.fileId);
      this.props.FileStorageViewFetch(this.state.fileId);
    } else {
      this.props.fileStorageChangeCurrent({ enabled: false, type: 'LOCAL' });
    }
  }
  componentWillUnmount() {
    this.props.fileStorageChangeCurrent({ enabled: false, type: 'LOCAL' });
  }
  hasId() {
    return this.state.fileId;
  }
  isViewMode() {
    return this.props.history.location.pathname.includes('view');
  }
  isEditMode() {
    return this.props.history.location.pathname.includes('update');
  }
  onSave = () => {
    if (this.state.fileId) {
      this.props.fileStorageUpdateSubmitFetch(
        this.state.fileId,
        this.props.currentFileStorage,
        'updatePage'
      );
    } else {
      this.props.FileStorageCreateFetch(this.props.currentFileStorage);
    }
  };
  handleChangeFileStorage = (type, value) => {
    switch (type) {
      case 'name':
        this.props.fileStorageChangeCurrent({
          ...this.props.currentFileStorage,
          name: value,
        });
        break;
      case 'maxSize':
        this.props.fileStorageChangeCurrent({
          ...this.props.currentFileStorage,
          maximumSize: value,
        });
        break;
      case 'username':
        this.props.fileStorageChangeCurrent({
          ...this.props.currentFileStorage,
          username: value,
        });
        break;
      case 'server':
        this.props.fileStorageChangeCurrent({
          ...this.props.currentFileStorage,
          server: value,
        });
        break;
      case 'downloadPath':
        this.props.fileStorageChangeCurrent({
          ...this.props.currentFileStorage,
          downloadPath: value,
        });
        break;
      case 'uploadPath':
        this.props.fileStorageChangeCurrent({
          ...this.props.currentFileStorage,
          uploadPath: value,
        });
        break;
      case 'bucket':
        this.props.fileStorageChangeCurrent({
          ...this.props.currentFileStorage,
          bucket: value,
        });
        break;
      case 'password':
        this.props.fileStorageChangeCurrent({
          ...this.props.currentFileStorage,
          password: value,
        });
        break;
      case 'type':
        this.props.fileStorageChangeCurrent({
          ...this.props.currentFileStorage,
          type: value,
        });
        break;
      case 'enabled':
        this.props.fileStorageChangeCurrent({
          ...this.props.currentFileStorage,
          enabled: value,
        });
        break;
    }
  };
  render() {
    return (
      <Card
        styles={{
          padding: 30,
        }}
        loading={this.props.updateFetchLoading}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginBottom: 30,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-end',
              width: 200,
            }}
          >
            <div style={styles.title}>NAME</div>
            <div style={styles.title}>ENABLED</div>
            <div style={styles.title}>MAXIMUM SIZE</div>
            {this.props.currentFileStorage &&
              this.props.currentFileStorage.type !== 'LOCAL' && (
                <div style={styles.title}>USERNAME</div>
              )}
            <div style={styles.title}>SERVER</div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 5,
              width: 350,
            }}
          >
            <Input
              disabled={this.isViewMode() || this.isEditMode()}
              value={
                this.props.currentFileStorage &&
                this.props.currentFileStorage.name
              }
              style={styles.input}
              onChange={(e) =>
                this.handleChangeFileStorage('name', e.target.value)
              }
            ></Input>
            <Switch
              disabled={this.isViewMode()}
              checked={
                this.props.currentFileStorage &&
                this.props.currentFileStorage.enabled
              }
              style={{ width: 50, marginTop: 10, marginBottom: 20 }}
              onChange={(value) =>
                this.handleChangeFileStorage('enabled', value)
              }
            />
            <Input
              disabled={this.isViewMode()}
              value={
                this.props.currentFileStorage &&
                this.props.currentFileStorage.maximumSize
              }
              style={styles.input}
              onChange={(e) =>
                this.handleChangeFileStorage('maxSize', e.target.value)
              }
            ></Input>
            {this.props.currentFileStorage &&
              this.props.currentFileStorage.type !== 'LOCAL' && (
                <Input
                  disabled={this.isViewMode() || this.isEditMode()}
                  value={
                    this.props.currentFileStorage &&
                    this.props.currentFileStorage.username
                  }
                  style={styles.input}
                  onChange={(e) =>
                    this.handleChangeFileStorage('username', e.target.value)
                  }
                ></Input>
              )}
            <Input
              disabled={this.isViewMode() || this.isEditMode()}
              value={
                this.props.currentFileStorage &&
                this.props.currentFileStorage.server
              }
              style={styles.input}
              onChange={(e) =>
                this.handleChangeFileStorage('server', e.target.value)
              }
            ></Input>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-end',
              width: 200,
              marginLeft: 50,
            }}
          >
            <div style={styles.title}>TYPE</div>
            <div style={styles.title}>DOWNLOAD PATH</div>
            <div style={styles.title}>UPLOAD PATH</div>
            {this.props.currentFileStorage &&
              this.props.currentFileStorage.type !== 'LOCAL' && (
                <div style={styles.title}>PASSWORD</div>
              )}
            {this.props.currentFileStorage &&
              this.props.currentFileStorage.type === 'S3' && (
                <div style={styles.title}>BUCKET</div>
              )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 5,
              width: 350,
            }}
          >
            <Select
              disabled={this.isViewMode() || this.isEditMode()}
              value={
                this.props.currentFileStorage &&
                this.props.currentFileStorage.type
              }
              style={styles.input}
              onChange={(val) => {
                this.handleChangeFileStorage('type', val);
              }}
            >
              <Select.Option value={'LOCAL'} key={'LOCAL'}>
                LOCAL
              </Select.Option>
              <Select.Option value={'FTP'} key={'FTP'}>
                FTP
              </Select.Option>
              <Select.Option value={'S3'} key={'S3'}>
                S3
              </Select.Option>
            </Select>
            <Input
              disabled={this.isViewMode() || this.isEditMode()}
              value={
                this.props.currentFileStorage &&
                this.props.currentFileStorage.downloadPath
              }
              style={styles.input}
              onChange={(e) =>
                this.handleChangeFileStorage('downloadPath', e.target.value)
              }
            ></Input>
            <Input
              autoComplete="new-path"
              disabled={this.isViewMode() || this.isEditMode()}
              value={
                this.props.currentFileStorage &&
                this.props.currentFileStorage.uploadPath
              }
              style={styles.input}
              onChange={(e) =>
                this.handleChangeFileStorage('uploadPath', e.target.value)
              }
            ></Input>
            {this.props.currentFileStorage &&
              this.props.currentFileStorage.type !== 'LOCAL' && (
                <Input.Password
                  autoComplete="new-password"
                  disabled={this.isViewMode() || this.isEditMode()}
                  value={
                    this.props.currentFileStorage &&
                    this.props.currentFileStorage.password
                  }
                  style={styles.input}
                  onChange={(e) =>
                    this.handleChangeFileStorage('password', e.target.value)
                  }
                ></Input.Password>
              )}
            {this.props.currentFileStorage &&
              this.props.currentFileStorage.type === 'S3' && (
                <Input
                  disabled={this.isViewMode() || this.isEditMode()}
                  value={
                    this.props.currentFileStorage &&
                    this.props.currentFileStorage.bucket
                  }
                  style={styles.input}
                  onChange={(e) =>
                    this.handleChangeFileStorage('bucket', e.target.value)
                  }
                ></Input>
              )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Link to={this.state.fileId ? '../list' : 'list'}>
            <Button
              type="primary"
              shape="round"
              size={'large'}
              // loading={this.props.loading}
              className="w-200"
            >
              {'Back'}
            </Button>
          </Link>
          <Button
            onClick={() => this.onSave()}
            type="primary"
            shape="round"
            size={'large'}
            disabled={this.isViewMode()}
            loading={this.props.updateLoading || this.props.submitLoading}
            className="w-200"
          >
            {'Save'}
          </Button>
        </div>
      </Card>
    );
  }
}

export default FileStorageUpdateComponent;
