import React, { useEffect } from 'react';
import {
  Form,
  Input,
  Col,
  Row,
  Divider,
  Switch,
  Select,
  InputNumber,
} from 'antd';
import NumberBetweenInput from './NumberBetweenInput';
import { OPERATORS } from './helpers';
const { Option } = Select;

export default function NumberOfScreensMapper({ form }) {
  useEffect(() => {
    form.validateFields();
  }, []);
  return (
    <div
      className="relay-mapper-area"
      style={{ marginLeft: 0, minHeight: 'auto', height: '75px' }}
    >
      <Row gutter={16}>
        <Col span="6">
          <Input value="Number Of pages viewed" disabled />
        </Col>
        <Col span="4">
          <Form.Item
            shouldUpdate={(prevValues, curValues) => {
              return (
                curValues?.numberOfScreenMapper?.negate !==
                prevValues?.numberOfScreenMapper?.negate
              );
            }}
          >
            {() => {
              const formValues = form.getFieldsValue();
              const checked = formValues.numberOfScreenMapper?.negate;
              return (
                <Form.Item
                  name={['numberOfScreenMapper', 'operator']}
                  rules={[{ required: true, message: 'select operator' }]}
                  style={{ width: '100%' }}
                >
                  <Select
                    style={{ width: '100%' }}
                    defaultValue="EQUAL_TO"
                    onChange={(value) => {
                      const formValues = form.getFieldsValue();
                      if (value === 'IS_NOT_EMPTY') {
                        formValues.numberOfScreenMapper.value = '-';
                      } else {
                        formValues.numberOfScreenMapper.value =
                          value === 'ONE_OF' ? [] : null;
                      }
                      form.setFieldsValue(formValues);
                    }}
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div
                            style={{
                              marginRight: 10,
                              fontSize: 14,
                              fontWeight: 600,
                              color: '#1c1c1c',
                            }}
                          >
                            Negate:
                          </div>
                          <div>
                            <Switch
                              checkedChildren="Yes"
                              unCheckedChildren="No"
                              onChange={(value) => {
                                const formValues = form.getFieldsValue();

                                if (formValues.numberOfScreenMapper) {
                                  formValues.numberOfScreenMapper.negate =
                                    value;
                                } else {
                                  formValues.numberOfScreenMapper = {
                                    negate: value,
                                  };
                                }

                                form.setFieldsValue(formValues);
                                form.submit();
                              }}
                              checked={checked}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  >
                    {OPERATORS.filter((op) => {
                      return op.dataTypes.includes('NUMERIC');
                    }).map((op) => (
                      <Option value={op.name} key={op.name}>
                        {checked ? op.negateLabel : op.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span="8">
          <Form.Item
            shouldUpdate={(prevValues, curValues) => {
              return (
                curValues?.numberOfScreenMapper?.operator !==
                prevValues?.numberOfScreenMapper?.operator
              );
            }}
          >
            {() => {
              const formValues = form.getFieldsValue();
              const operator =
                formValues.numberOfScreenMapper &&
                formValues.numberOfScreenMapper?.operator;
              return (
                <Form.Item
                  name={['numberOfScreenMapper', 'value']}
                  style={{
                    display: operator === 'IS_NOT_EMPTY' ? 'none' : 'block',
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'please enter a value',
                    },
                  ]}
                >
                  {operator === 'ONE_OF' ? (
                    <Select
                      mode="tags"
                      style={{
                        width: '100%',
                      }}
                    />
                  ) : operator === 'BETWEEN' ? (
                    <NumberBetweenInput />
                  ) : (
                    <InputNumber style={{ width: '100%' }} />
                  )}
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
