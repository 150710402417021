import { useCallback, useEffect, useRef, useState } from 'react';

function pad(input) {
  return input.toString().padStart(2, '0');
}

function getTimer(seconds) {
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  return `${pad(minutes)}:${pad(seconds)}`;
}

const STORAGE_KEY = 'time';

function useCounter({ duration, immediate, onReset }) {
  const [count, setCount] = useState(() => {
    const storedCount = sessionStorage.getItem(STORAGE_KEY);

    return storedCount ? Number(storedCount) : duration;
  });

  const [isCounting, setIsCounting] = useState(() => {
    const storedCount = sessionStorage.getItem(STORAGE_KEY);

    return (
      !!storedCount && Number(storedCount) > 0 && Number(storedCount) < duration
    );
  });

  const intervalRef = useRef(null);

  const start = useCallback(() => {
    if (count > 0 && !isCounting) setIsCounting(true);
  }, [count, isCounting]);

  const reset = useCallback(
    (startAfterReset = true) => {
      if (isCounting) return;

      setCount(duration);

      setIsCounting(startAfterReset);

      sessionStorage.setItem(STORAGE_KEY, duration.toString());

      if (onReset) onReset();
    },
    [duration, isCounting, onReset]
  );

  useEffect(() => {
    if (isCounting && count > 0) {
      intervalRef.current = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [isCounting, count]);

  useEffect(() => {
    if (count === 0) {
      setIsCounting(false);

      sessionStorage.removeItem(STORAGE_KEY);

      if (intervalRef.current) clearInterval(intervalRef.current);
    } else {
      sessionStorage.setItem(STORAGE_KEY, count.toString());
    }
  }, [count]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (immediate) start();
  }, [immediate, start]);

  const timer = getTimer(count);

  return { count, timer, start, reset, isCounting };
}

export { useCounter };
