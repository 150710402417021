import React from 'react';
import { Select } from 'antd';

const styles = {
  tagPill: {
    borderRadius: 5,
    borderTop: '1px solid rgba(61,64,78,.2)',
    borderBottom: '1px solid rgba(61,64,78,.2)',
    borderRight: '1px solid rgba(61,64,78,.2)',
    borderLeft: '5px solid',
    padding: '2px 8px 2px 6px',
    position: 'relative',
  },
  pillWrapper: {
    overflow: 'hidden',
  },
  pillBadge: {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: 5,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    backgroundColor: 'gray',
  },
  pillText: {
    fontSize: 12,
  },
};

class TagInput extends React.Component {
  constructor(props) {
    super(props);
  }

  renderTagOptions = () => {
    let result = [];
    if (Array.isArray(this.props.listTags)) {
      this.props.listTags?.forEach((item) => {
        result.push(
          <Select.Option
            key={item.name}
            className={'communication-custom-tag-item'}
          >
            <span className="">
              <span
                style={{ ...styles.tagPill, borderLeftColor: item.color }}
                title={item.name}
              >
                <span style={styles.pillWrapper}>
                  <span style={styles.pillText}>{item.name}</span>
                </span>
              </span>
            </span>
          </Select.Option>
        );
      });
    }

    return result;
  };

  render() {
    return (
      <div
        style={this.props.containerStyle}
        className={'communication-custom-tag-selector'}
      >
        <Select
          id="int-tags"
          data-cy="int-tags"
          disabled={this.props.disabled}
          size={this.props.size ? this.props.size : 'large'}
          mode="tags"
          style={{ width: 600, ...this.props.style }}
          value={
            this.props.tags && Array.isArray(this.props.tags)
              ? this.props.tags
              : []
          }
          onChange={(value) => {
            if (this.props.onChange) {
              this.props.onChange(value);
            }
          }}
        >
          {this.renderTagOptions()}
        </Select>
        {!this.props.hideDescription && (
          <span
            style={{
              color: '#94969e',
              fontSize: '10px',
              paddingLeft: '5px',
              paddingTop: '5px',
              display: 'block',
            }}
          >
            {this.props.description
              ? this.props.description
              : 'This field is optional. Tags help you analyze communication results for a group of communications that belong to a tag.'}
          </span>
        )}
      </div>
    );
  }
}

export default TagInput;

TagInput.propTypes = {};
