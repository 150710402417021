import React, { useEffect, useState } from 'react';
import { NodeIcon } from './../../utils/icons';
import { NodeTypes } from './../../utils/static';
import IconArrowRight from './../../assets/IconArrowRight';
import IconArrowDown from './../../assets/IconArrowDown';
import './sidebar.less';
import { thirdPartyEnums } from '@Modules/admin/thirdParty/components/ThirdPartyTypes';
// import Pkg from "./../../../package.json";

const Sidebar = (props) => {
  const { nodes, currentProduct } = props;
  const [triggerNodes, setTriggerNodes] = useState([]);
  const [actionNodes, setActionNodes] = useState([]);
  const [conditionNodes, setConditionNodes] = useState([]);
  const [flowControlNodes, setFlowControlNodes] = useState([]);
  const [openBox, setOpenBox] = useState(Array(4).fill(true));

  const allTriggerNodes = nodes
    .filter((item) => item.data.nodeType === NodeTypes[0])
    .filter((item) => !item.hide);
  const allActionNodes = nodes.filter(
    (item) => item.data.nodeType === NodeTypes[1]
  );
  const allCondNodes = nodes.filter(
    (item) => item.data.nodeType === NodeTypes[2]
  );
  const allFlowNodes = nodes.filter(
    (item) => item.data.nodeType === NodeTypes[3]
  );

  useEffect(() => {
    setTriggerNodes(allTriggerNodes);
    if (currentProduct?.thirdPartyOption === thirdPartyEnums.PARENT) {
      setActionNodes(
        allActionNodes.filter((item) => item.data.key === 'SEND_SMS') // This could be dynamic
      );
    } else {
      setActionNodes(allActionNodes);
    }

    setConditionNodes(allCondNodes);
    setFlowControlNodes(allFlowNodes);
  }, []);

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  const searchInString = (item, e) => {
    return item.data?.label.toLowerCase().search(e.target.value) >= 0;
  };

  const searchNode = (e) => {
    setTriggerNodes(allTriggerNodes.filter((item) => searchInString(item, e)));
    setActionNodes(allActionNodes.filter((item) => searchInString(item, e)));
    setConditionNodes(allCondNodes.filter((item) => searchInString(item, e)));
    setFlowControlNodes(allFlowNodes.filter((item) => searchInString(item, e)));
  };

  const openController = (v) => {
    setOpenBox((els) => {
      let nod = els.slice();
      nod[v] = !nod[v];
      return nod;
    });
  };

  return (
    <aside className={'aside'}>
      {/* <h3>
        Journey Maker [<b>{Pkg.version}</b>]
      </h3> */}
      {/* <div>
        <button onClick={() => console.log("Export---", elements)}>
          Export
        </button>
      </div> */}
      <br />
      <div className={'aside-search'}>
        <input
          className={'aside-search__input'}
          onChange={searchNode}
          placeholder={'Search Controls'}
        />
      </div>
      <br />
      <span className="description">
        You can drag these nodes to the pane, on the right.
      </span>
      {props.type !== 'RELAY' ? (
        <>
          <div className={'aside-title'} onClick={() => openController(0)}>
            <span>Triggers</span>
            <span>{openBox[0] ? <IconArrowDown /> : <IconArrowRight />}</span>
          </div>
          <div className={'adide-nodes ' + (!openBox[0] ? 'close' : '')}>
            {triggerNodes.map((node, k) => {
              return (
                <div
                  key={k}
                  // eslint-disable-next-line react/no-unknown-property
                  nodeItemID={'node_' + k}
                  className={
                    node?.disabled ? 'dndnode dndnode--disabled' : 'dndnode'
                  }
                  onDragStart={(event) => onDragStart(event, node.data?.key)}
                  draggable={!node?.disabled}
                >
                  <NodeIcon
                    name={node.data?.iconName}
                    type={node.data?.nodeType}
                  />
                  <div className={'dndnode__text'}>{node.data?.label}</div>
                </div>
              );
            })}
          </div>
        </>
      ) : null}
      <div className={'aside-title'} onClick={() => openController(1)}>
        <span>Actions</span>
        <span>{openBox[1] ? <IconArrowDown /> : <IconArrowRight />}</span>
      </div>
      <div className={'adide-nodes ' + (!openBox[1] ? 'close' : '')}>
        {actionNodes.map((node, k) => {
          return (
            <div
              key={k}
              className={
                node?.disabled ? 'dndnode dndnode--disabled' : 'dndnode'
              }
              onDragStart={(event) => onDragStart(event, node.data?.key)}
              draggable={!node?.disabled}
            >
              <NodeIcon name={node.data?.iconName} type={node.data?.nodeType} />
              <div className={'dndnode__text'}>{node.data?.label}</div>
            </div>
          );
        })}
      </div>
      <div className={'aside-title'} onClick={() => openController(2)}>
        <span>Conditions</span>
        <span>{openBox[2] ? <IconArrowDown /> : <IconArrowRight />}</span>
      </div>
      <div className={'adide-nodes ' + (!openBox[2] ? 'close' : '')}>
        {conditionNodes.map((node, k) => {
          return (
            <div
              key={k}
              className={
                node?.disabled ? 'dndnode dndnode--disabled' : 'dndnode'
              }
              onDragStart={(event) => onDragStart(event, node.data?.key)}
              draggable={!node?.disabled}
            >
              <NodeIcon name={node.data?.iconName} type={node.data?.nodeType} />
              <div className={'dndnode__text'}>{node.data?.label}</div>
            </div>
          );
        })}
      </div>
      <div className={'aside-title'} onClick={() => openController(3)}>
        <span>Flow Control</span>
        <span>{openBox[3] ? <IconArrowDown /> : <IconArrowRight />}</span>
      </div>
      <div className={'adide-nodes ' + (!openBox[3] ? 'close' : '')}>
        {flowControlNodes.map((node, k) => {
          return (
            <div
              key={k}
              className={
                node?.disabled ? 'dndnode dndnode--disabled' : 'dndnode'
              }
              onDragStart={(event) => onDragStart(event, node.data?.key)}
              draggable={!node?.disabled}
            >
              <NodeIcon name={node.data?.iconName} type={node.data?.nodeType} />
              <div className={'dndnode__text'}>{node.data?.label}</div>
            </div>
          );
        })}
      </div>
      {/* <div className="dndnode input" onDragStart={(event) => onDragStart(event, 'input')} draggable>
        Input Node
      </div>
      <div className="dndnode" onDragStart={(event) => onDragStart(event, 'default')} draggable>
        Default Node
      </div>
      <div className="dndnode output" onDragStart={(event) => onDragStart(event, 'output')} draggable>
        Output Node
      </div> */}
    </aside>
  );
};

export default Sidebar;
