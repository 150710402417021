import React, { useState } from 'react';
import style from './NavigationHeader.module.less';
import { Dropdown, Input, Menu } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import guideSvg from '../../../../theme/images/icons/guide.svg';
import notificationSvg from '../../../../theme/images/icons/notification.svg';
import tecDocSvg from '../../../../theme/images/icons/tech-doc.svg';
import settingsSvg from '../../../../theme/images/icons/settings.svg';
import userSvg from '../../../../theme/images/icons/user.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const userHasActiveProduct = (currentUser) => {
  return (
    (currentUser && currentUser.login && currentUser.lastProduct) ||
    (currentUser?.products &&
      Array.isArray(currentUser?.products) &&
      currentUser?.products.length > 0 &&
      currentUser?.products[0].id)
  );
};

export const NavigationHeader = ({
  project,
  currentUser,
  onSelectProject,
  logoutFetch,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const pid = userHasActiveProduct(currentUser);

  return (
    <div className={style.navigation_header}>
      <Dropdown
        placement="bottomLeft"
        overlay={
          <div dir="ltr" className={style.project_list}>
            <div style={{ padding: 8 }}>
              <Input
                placeholder="Search"
                value={searchTerm}
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
                className={style.project_search_input}
                suffix={<SearchOutlined />}
              />
            </div>
            <div className={style.scroller}>
              <Menu>
                {(currentUser?.products || [])
                  .filter((p) => p.name?.includes(searchTerm))
                  .map((product) => (
                    <Menu.Item
                      key={`/product/${product.id}/`}
                      onClick={() => onSelectProject(product.id)}
                      style={{
                        color:
                          project?.id === product.id ? '#1d88f6' : undefined,
                      }}
                    >
                      {product.name}
                    </Menu.Item>
                  ))}
              </Menu>
            </div>
          </div>
        }
        trigger={['click']}
      >
        <div className={style.project_list_trigger}>
          <span>{project?.name ?? "project's name"}</span>
          <DownOutlined />
        </div>
      </Dropdown>
      <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
        <Dropdown
          placement="bottom"
          overlay={
            <div dir="ltr" className={style.guideMenu}>
              <Menu>
                <Menu.Item>
                  <a
                    href="https://docs.intrack.ir"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className={style.item_with_icon}>
                      <img src={tecDocSvg} />
                      <span>Technical Doc</span>
                    </div>
                  </a>
                </Menu.Item>
              </Menu>
            </div>
          }
          trigger={['click']}
        >
          <img src={guideSvg} style={{ cursor: 'pointer' }} />
        </Dropdown>
        {/* // TODO might be added later */}
        {false && <img src={notificationSvg} />}
        {false && (
          <div style={{ width: 1, height: 24, background: '#EBEBF0' }} />
        )}
        <Dropdown
          placement="bottomRight"
          overlay={
            <div dir="ltr" className={style.guideMenu}>
              <Menu>
                <Menu.Item>
                  <Link to="/account/profile/update">
                    <div className={style.item_with_icon}>
                      <img src={userSvg} />
                      <span>Profile</span>
                    </div>
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to={`/product/${pid}/settings/general`}>
                    <div className={style.item_with_icon}>
                      <img src={settingsSvg} />
                      <span>General Settings</span>
                    </div>
                  </Link>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item onClick={() => logoutFetch()}>
                  <div className={style.item_with_icon}>
                    <span style={{ color: '#FF4D4F' }}>Logout</span>
                  </div>
                </Menu.Item>
              </Menu>
            </div>
          }
          trigger={['click']}
        >
          <div className={style.project_list_trigger}>
            <span>{currentUser?.presentation ?? '--'}</span>
            <DownOutlined />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};
