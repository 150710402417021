import React, { useState, useEffect } from 'react';
import styles from './HomeComponent.module.less';
import { Input, Button, Form } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { verifyResetPasswordWithOtp } from '@Modules/account/profile/ProfileActions';
import { useDispatch, useSelector } from 'react-redux';
import { passwordValidation } from '@Utils/PasswordValidation';
import { resendOTPCodeFetch } from '@Modules/account/auth/AuthActions';
import { useCounter } from '../../../hook/useCounter';

const ResetPassSecondStep = ({
  goToResetPassFirstStep,
  goToLoginFirstStep,
}) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const userEmail = state?.email ?? '';
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [code, setCode] = useState('');

  const [isDisabled, setIsDisabled] = useState(true);

  const { resetPasswordLoading } = useSelector(
    (state) => state.account.profile
  );

  useEffect(() => {
    handleIsButtonDisabled();
  }, [code, newPassword, confirmNewPassword]);

  const handleIsButtonDisabled = () => {
    if (
      code !== '' &&
      newPassword !== '' &&
      confirmNewPassword !== '' &&
      checkPasswordsMatch() &&
      passwordValidation(newPassword) === null
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const checkPasswordsMatch = () => {
    if (confirmNewPassword === '') return true;
    if (newPassword === confirmNewPassword) return true;
    return false;
  };

  const handleResendOtpBtn = () => {
    dispatch(resendOTPCodeFetch(state?.otpId));
  };

  const { isCounting, timer, reset } = useCounter({
    duration: 180,
    immediate: true,
    onReset: handleResendOtpBtn,
  });

  const handleVerifyOtpCode = () => {
    dispatch(
      verifyResetPasswordWithOtp({
        body: {
          userEmail,
          newPassword: newPassword,
          otpCode: code,
          otpId: state?.otpId,
        },
        cb: goToLoginFirstStep,
      })
    );
  };

  return (
    <div className={styles.form} id="password">
      <div
        className={styles.form__back_button}
        onClick={() => goToResetPassFirstStep(userEmail)}
      >
        <ArrowLeftOutlined />
        <span style={{ marginLeft: '8px' }}>Back</span>
      </div>
      <div className={styles.form__header}>Reset Password</div>
      <div className={styles.form__caption}>
        {`Enter the verification code sent to your email: (${userEmail})`}
      </div>
      <div className={styles['form__item--last']}>
        <div className={styles.form__title}>Verification Code</div>
        <Input
          value={code}
          onChange={(e) => setCode(e.target.value)}
          className={styles.form__input}
          autoComplete="off"
        />
      </div>
      <div className={styles.timer}>
        <span
          className={
            isCounting
              ? styles.disabled_resend__button
              : styles.enabled_resend__button
          }
          onClick={isCounting ? () => {} : reset}
        >
          Resend
        </span>
        {isCounting && <span className={styles.timer}>{`(${timer})`}</span>}
      </div>
      <div className={styles['form__item--last']}>
        <div className={styles.form__title}>New Password</div>
        <Form.Item
          validateStatus={passwordValidation(newPassword) ? 'error' : null}
          help={passwordValidation(newPassword)}
        >
          <Input.Password
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className={styles.form__input}
          />
        </Form.Item>
      </div>
      <div className={styles['form__item--last']}>
        <div className={styles.form__title}>Repeat New Password</div>
        <Form.Item
          validateStatus={checkPasswordsMatch() ? null : 'error'}
          help={checkPasswordsMatch() ? null : 'Your password is not match'}
        >
          <Input.Password
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            className={styles.form__input}
          />
        </Form.Item>
      </div>
      <div className={styles['form__button-container']}>
        <Button
          loading={resetPasswordLoading}
          onClick={handleVerifyOtpCode}
          type="primary"
          className={styles.form__login}
          disabled={isDisabled}
          shape="round"
          lassName={styles.form__login}
        >
          Change Password
        </Button>
      </div>
    </div>
  );
};

export default ResetPassSecondStep;
