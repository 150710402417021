import HeaderComponent from '../component/HeaderComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  currentUserFetch,
  logoutFetch,
  changeActiveProduct,
} from '../../../account/auth/AuthActions';
import {
  productSegmentSaveClicked,
  productSegmentResetClicked,
  productSegmentStaticCreateToggle,
  productSegmentDeleteFetch,
  productSegmentCopyFetch,
} from '../../../insight/productSegment/ProductSegmentActions';
import {
  communicationLaunchFetch,
  communicationStopFetch,
  communicationPauseFetch,
  communicationSplitOverviewFetch,
  communicationOverviewFetch,
  communicationCopyFetch,
  communicationDraftSaveDiscard,
  communicationVariationReportFetch,
  communicationCommunicationReportFetch,
  communicationReportCsvFetch,
  communicationDeliveryCheck,
} from '../../../engage/communication/CommunicationActions';
import { updateLastProductSubmit } from '../../../account/profile/ProfileActions';
import {
  journeyDeleteFetch,
  journeyCopyFetch,
  journeyStop,
  journeyPause,
  journeyViewFetch,
  journeyReportOverviewCsvFetch,
  journeyReportCommunicationsOverviewCsvFetch,
  journeyReportCommunicationReportFetch,
  journeyReportEnterOrExitReportFetch,
  journeyGetReportOverview,
} from '../../../engage/journey/JourneyActions';
import {
  overviewSplitOverviewFetch,
  overviewFetch,
} from '../../../engage/overview/OverviewActions';
import { emailTemplateToggleModal } from '../../../channel/emailTemplate/EmailTemplateActions';
import { customChannelToggleAddModal } from '../../../channel/custom/CustomChannelActions';
import { changeMenuLayout } from '../../../account/auth/AuthActions';
import { webhookModalToggle } from '../../../integration/webhook/WebhookActions';
import { eventChangeCurrent } from '../../../track/events/EventAnalyticsActions';
import { emailTemplateChangeCurrent } from '../../../channel/emailTemplate/EmailTemplateActions';
import {
  uninstallOverviewChangeCurrent,
  uninstallUsersChangeCurrent,
  uninstallAnalyzeChangeCurrent,
} from '@Modules/analytics/uninstalls/UninstallActions';
import {
  apiTokenCreateFetch,
  apiTokenRevokeAllModalToggle,
  apiTokenRevokeAllFetch,
} from '../../../integration/productRestApiToken/ApiTokenActions';

const mapStateToProps = (state) => ({
  currentUser: state.account.auth.currentUser,
  pathname: state.router.location.pathname,
  location: state.router.location,
  user: state.track.users.user,
  userLoading: state.track.users.userLoading,
  currentAudience: state.engage.communication.currentAudience,

  loadingUpdateSubmitSegment: state.insight.productSegment.loadingUpdateSubmit,
  loadingCreateSegment: state.insight.productSegment.loadingCreate,
  currentProductSegment: state.insight.productSegment.currentProductSegment,

  loadingPause: state.engage.communication.loadingPause,
  errorPause: state.engage.communication.errorPause,
  currentCommunication: state.engage.communication.currentCommunication,

  loadingStop: state.engage.communication.loadingStop,
  errorStop: state.engage.communication.errorStop,

  loadingLaunch: state.engage.communication.loadingLaunch,
  errorLaunch: state.engage.communication.errorLaunch,

  pageRequestSplitOverview: state.engage.communication.pageRequestSplitOverview,
  pageRequestOverview: state.engage.communication.pageRequestOverview,

  overviewPageRequestOverview: state.engage.overview.pageRequestOverview,
  overviewPageRequestSplitOverview:
    state.engage.overview.pageRequestSplitOverview,

  loadingCopy: state.engage.communication.loadingCopy,
  errorsCopy: state.engage.communication.errorsCopy,

  loadingCopySegment: state.insight.productSegment.loadingCopy,
  errorsCopySegment: state.insight.productSegment.errorsCopy,
  loadingDeleteSegment: state.insight.productSegment.loadingDelete,
  errorsDeleteSegment: state.insight.productSegment.errorsDelete,

  loadingJourneyDetails: state.engage.journey.loadingJourneyDetails,
  loadingDeleteJourney: state.engage.journey.loadingDelete,
  loadingDuplicateJourney: state.engage.journey.loadingDuplicate,
  loadingStopJourney: state.engage.journey.loadingStop,
  loadingPauseJourney: state.engage.journey.loadingPause,
  journeyDetails: state.engage.journey.journeyDetails,
  journeyPageRequest: state.engage.journey.pageRequest,
  journeyCommunicationReportData:
    state.engage.journey.journeyCommunicationReportData,
  journeyCommunicationReportRequest:
    state.engage.journey.journeyCommunicationReportRequest,

  pageRequest: state.engage.communication.pageRequest,
  eventAnalyticsValue: state.track.events.eventAnalyticsValue,
  uninstallOverviewInterval:
    state.analytics.uninstalls.uninstallOverviewInterval,
  uninstallUsersFilter: state.analytics.uninstalls.uninstallUsersFilter,
  uninstallAnalyzeFilter: state.analytics.uninstalls.uninstallAnalyzeFilter,
  currentTemplate: state.channel.emailTemplate.currentTemplate,

  variationReportPageRequest:
    state.engage.communication.variationReportPageRequest,
  communicationReportPageRequest:
    state.engage.communication.communicationReportPageRequest,

  pageRequestApiTokens: state.integration.productRestApiTokens.pageRequest,
  activeProduct: state.account.auth.activeProduct,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      uninstallOverviewChangeCurrent,
      uninstallUsersChangeCurrent,
      uninstallAnalyzeChangeCurrent,
      emailTemplateChangeCurrent,
      currentUserFetch,
      logoutFetch,
      changeMenuLayout,
      productSegmentSaveClicked,
      productSegmentResetClicked,
      productSegmentDeleteFetch,
      productSegmentCopyFetch,
      communicationLaunchFetch,
      communicationStopFetch,
      communicationPauseFetch,
      emailTemplateToggleModal,
      customChannelToggleAddModal,
      productSegmentStaticCreateToggle,
      webhookModalToggle,
      communicationSplitOverviewFetch,
      communicationOverviewFetch,
      overviewFetch,
      overviewSplitOverviewFetch,
      communicationCopyFetch,
      communicationDraftSaveDiscard,
      journeyCopyFetch,
      journeyDeleteFetch,
      journeyPause,
      journeyStop,
      journeyViewFetch,
      eventChangeCurrent,
      communicationVariationReportFetch,
      communicationCommunicationReportFetch,
      communicationReportCsvFetch,
      communicationDeliveryCheck,
      journeyReportOverviewCsvFetch,
      journeyReportCommunicationsOverviewCsvFetch,
      journeyReportCommunicationReportFetch,
      journeyReportEnterOrExitReportFetch,
      journeyGetReportOverview,
      apiTokenCreateFetch,
      apiTokenRevokeAllModalToggle,
      apiTokenRevokeAllFetch,
      changeActiveProduct,
      updateLastProductSubmit,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);

HeaderComponent.propTypes = {
  currentUser: PropTypes.object.isRequired,
  currentUserFetch: PropTypes.func.isRequired,
  logoutFetch: PropTypes.func.isRequired,
};
