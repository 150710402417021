exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ---blue---*/\n/* ---light theme---*/\n/* ---status---*/\n.FileProgressList-module__root--3fyBQ {\n  background-color: white;\n  margin-top: 20px;\n  border-radius: 5px;\n  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 24px;\n}\n.FileProgressList-module__lists--3MUit {\n  width: 100%;\n}\n.FileProgressList-module__listsHeader--3fuWd {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 13px;\n  border-bottom: 2px solid #f4f9ff;\n}\n.FileProgressList-module__listsHeaderTitle--hKYw2 {\n  display: flex;\n  align-items: center;\n}\n.FileProgressList-module__listsHeaderTitle--hKYw2 span {\n  font-size: 18px;\n}\n.FileProgressList-module__listsHeaderIcon--2spJK {\n  margin-left: 10px;\n  background-color: #1d88f6 !important;\n  border: 1px solid #1d88f6 !important;\n}\n.FileProgressList-module__listsHeaderIcon--2spJK i {\n  margin: 0;\n  padding: 0;\n  font-size: 25px;\n  color: #fff;\n}\n.FileProgressList-module__listsLink--1Ab_u {\n  color: #1d88f6 !important;\n  cursor: pointer;\n}\n.FileProgressList-module__listsDownload--2cE90 {\n  color: #bbbcc1;\n}\n.FileProgressList-module__listsStatusDefult--3NitI {\n  border-color: #a9a9a9;\n  background-color: #a9a9a9;\n  color: #fff;\n  font-size: 11px;\n  padding: 2px 5px;\n  border-radius: 5px;\n}\n.FileProgressList-module__listsStatusError--12hCy {\n  border-color: #f76464;\n  background-color: #f76464;\n}\n.FileProgressList-module__listsStatusSuccess--1kiI0 {\n  border-color: #09d88c;\n  background-color: #09d88c;\n}\n", ""]);

// exports
exports.locals = {
	"root": "FileProgressList-module__root--3fyBQ",
	"lists": "FileProgressList-module__lists--3MUit",
	"listsHeader": "FileProgressList-module__listsHeader--3fuWd",
	"listsHeaderTitle": "FileProgressList-module__listsHeaderTitle--hKYw2",
	"listsHeaderIcon": "FileProgressList-module__listsHeaderIcon--2spJK",
	"listsLink": "FileProgressList-module__listsLink--1Ab_u",
	"listsDownload": "FileProgressList-module__listsDownload--2cE90",
	"listsStatusDefult": "FileProgressList-module__listsStatusDefult--3NitI",
	"listsStatusError": "FileProgressList-module__listsStatusError--12hCy",
	"listsStatusSuccess": "FileProgressList-module__listsStatusSuccess--1kiI0"
};