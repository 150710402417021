import store from '../redux/store';
import { logoutFetch } from '../modules/account/auth/AuthActions';
import { getProductId } from '../utils/AuthorityProvider';
import { showMessageError } from '../utils/RenderUtils';

const BASE_URL = '/api/web';

const replacer = (key, value) => {
  if (value === null || value === '') {
    return null;
  }
  return value;
};

function downloadFile(data, filename, mime) {
  const blob = new Blob([data], { type: mime || 'application/octet-stream' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
}

function resolveFileName(contentDisposition) {
  const apostrophIndex = contentDisposition.lastIndexOf("'");
  if (apostrophIndex >= 0) {
    return contentDisposition.substring(
      contentDisposition.lastIndexOf("'") + 1
    );
  }
  return contentDisposition.substring(contentDisposition.lastIndexOf('=') + 1);
}

function handleError(err) {
  switch (err.status) {
    case 'FORBIDDEN':
      showMessageError(err);
      break;
    case 'UNAUTHORIZED':
      showMessageError(err);
      break;
    case 'INTERNAL_SERVER_ERROR':
      showMessageError({
        status: err.status,
        message: 'Server error. Please contact your system administrator.',
      });
      break;
    case 'NOT_FOUND':
      showMessageError({
        status: err.status,
        message: 'Object not found.',
      });
      break;
    case 'NOT_ACCEPTABLE':
      showMessageError(err);
      break;
    default:
      showMessageError(err);
  }
}

class BaseApi {
  static getImage(url, headerTitles) {
    return fetch(BASE_URL + url, {
      method: 'POST',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response.blob().then((blob) => {
            let customHeaders = {};
            headerTitles.forEach((headerTitle) => {
              customHeaders[headerTitle] = response.headers.get(headerTitle);
            });
            const imageUrl = URL.createObjectURL(blob);
            return { imageUrl, customHeaders };
          });
        }

        if (response.status === 401) {
          store.dispatch(logoutFetch());
        }
        if (response.status === 404) {
          throw Error('404: Object not found!');
        }
        if (response.status === 403) {
          throw Error("403: You don't have access to this section!");
        }
        if (response.status >= 502 && response.status <= 504) {
          showMessageError({
            status: response.status,
            message:
              'Error communicating with the server. Please check your internet connection.',
          });
        } else {
          return response.json().then(Promise.reject.bind(Promise));
        }
      })
      .catch((err) => {
        return handleError(err);
      });
  }

  static postForFile(url, data) {
    return fetch(BASE_URL + url, {
      method: 'POST',
      body: JSON.stringify(data, replacer),
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          const mime = response.headers.get('content-type');
          const fileName = resolveFileName(
            response.headers.get('content-disposition')
          );
          response.blob().then((file) => downloadFile(file, fileName, mime));
          return response;
        }
        const json = response.json(); // there's always a body
        if (response.status >= 502 && response.status <= 504) {
          showMessageError({
            status: response.status,
            message:
              'Error communicating with server. Please check your internet connection.',
          });
          // toastr.error('Error communicating with server. Please check your internet connection.', '' , { closeButton: true, positionClass: 'toast-bottom-right' });
        } else {
          return json.then(Promise.reject.bind(Promise));
        }
      })
      .catch((err) => handleError(err));
  }

  static uploadFile(url, file) {
    const data = new FormData();
    data.append('file', file);
    return fetch(BASE_URL + url, {
      method: 'POST',
      credentials: 'include',
      headers: getHeadersUploadingFile(),
      body: data,
    })
      .then((response) => {
        const json = response.json(); // there's always a body
        if (response.status >= 200 && response.status < 300) {
          return json.then((res) => {
            return res.result;
          });
        }
        if (response.status >= 502 && response.status <= 504) {
          showMessageError({
            status: response.status,
            message:
              'Error communicating with server. Please check your internet connection.',
          });
          // toastr.error('Error communicating with server. Please check your internet connection.', '' , { closeButton: true, positionClass: 'toast-bottom-right' });
        } else {
          return json.then(Promise.reject.bind(Promise));
        }
      })
      .catch((err) => {
        return handleError(err);
      });
  }

  static post(url, data) {
    return fetch(BASE_URL + url, {
      method: 'POST',
      body: JSON.stringify(data, replacer),
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            store.dispatch(logoutFetch());
          }
          if (response.status === 404) {
            throw Error('404: Object not found!');
          }
          if (response.status === 403) {
            throw Error("403: You don't have access to this section!");
          }
          if (response.status >= 502 && response.status <= 504) {
            showMessageError({
              status: response.status,
              message:
                'Error communicating with server. Please check your internet connection.',
            });
          }
        }

        const json = response.json();

        if (response.status >= 200 && response.status < 300) {
          return json.then((res) => {
            return res.result;
          });
        }

        return json.then(Promise.reject.bind(Promise));
      })
      .catch((err) => {
        return handleError(err);
      });
  }

  static get(url, data) {
    return fetch(BASE_URL + url, {
      method: 'GET',
      body: JSON.stringify(data, replacer),
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        const json = response.json(); // there's always a body
        if (response.status >= 200 && response.status < 300) {
          return json.then((res) => {
            return res.result;
          });
        }
        if (response.status === 401) {
          store.dispatch(logoutFetch());
        }
        if (response.status === 404) {
          throw Error('404: Object not found!');
        }
        if (response.status === 403) {
          throw Error("403: You don't have access to this section!");
        }
        if (response.status >= 502 && response.status <= 504) {
          showMessageError({
            status: response.status,
            message:
              'Error communicating with server. Please check your internet connection.',
          });
        } else {
          return json.then(Promise.reject.bind(Promise));
        }
      })
      .catch((err) => {
        if (url.includes('cohort-execution/last')) {
          throw err;
        } else {
          return handleError(err);
        }
      });
  }

  static put(url, data) {
    return fetch(BASE_URL + url, {
      method: 'PUT',
      body: JSON.stringify(data, replacer),
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        const json = response.json(); // there's always a body
        if (response.status >= 200 && response.status < 300) {
          return json.then((res) => {
            return res.result;
          });
        }
        if (response.status === 401) {
          store.dispatch(logoutFetch());
        }
        if (response.status === 404) {
          throw Error('404: Object not found!');
        }
        if (response.status === 403) {
          throw Error("403: You don't have access to this section!");
        }
        if (response.status >= 502 && response.status <= 504) {
          showMessageError({
            status: response.status,
            message:
              'Error communicating with server. Please check your internet connection.',
          });
        } else {
          return json.then(Promise.reject.bind(Promise));
        }
      })
      .catch((err) => {
        return handleError(err);
      });
  }

  static delete(url, data) {
    return fetch(BASE_URL + url, {
      method: 'DELETE',
      body: JSON.stringify(data, replacer),
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        const json = response.json(); // there's always a body
        if (response.status >= 200 && response.status < 300) {
          return json.then((res) => {
            return res.result;
          });
        }
        if (response.status === 401) {
          store.dispatch(logoutFetch());
        }
        if (response.status === 404) {
          throw Error('404: Object not found!');
        }
        if (response.status === 403) {
          throw Error("403: You don't have access to this section!");
        }
        if (response.status >= 502 && response.status <= 504) {
          showMessageError({
            status: response.status,
            message:
              'Error communicating with server. Please check your internet connection.',
          });
        } else {
          return json.then(Promise.reject.bind(Promise));
        }
      })
      .catch((err) => {
        return handleError(err);
      });
  }

  static downLoadFile(url) {
    const currentUser = store.getState().account.auth.currentUser;
    const pid =
      (currentUser && currentUser.login && currentUser.lastProduct) ||
      (currentUser.products &&
        Array.isArray(currentUser.products) &&
        currentUser.products.length > 0 &&
        currentUser.products[0].id);

    return fetch(BASE_URL + url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'PRODUCT-ID': pid,
      }),
      credentials: 'include',
    })
      .then((response) => {
        const res = response.blob(); //response.headers.get("content-type")
        if (response.status >= 200 && response.status < 300) {
          return res.then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = response.headers
              .get('content-disposition')
              ?.replace("filename*=UTF-8''", '');
            document.body.appendChild(a);
            a.click();
            a.remove();
          });
        }

        if (response.status === 401) {
          store.dispatch(logoutFetch());
        }
        if (response.status === 404) {
          throw Error('404: Object not found!');
        }
        if (response.status === 403) {
          throw Error("403: You don't have access to this section!");
        }
        if (response.status >= 502 && response.status <= 504) {
          showMessageError({
            status: response.status,
            message:
              'Error communicating with server. Please check your internet connection.',
          });
        } else {
          return res.then(Promise.reject.bind(Promise));
        }
      })
      .catch((err) => {
        return handleError(err);
      });
  }
}

function getHeaders() {
  let pid = getProductId(store.getState().router.location.pathname);
  let headers = new Headers({
    'Content-Type': 'application/json',
  });
  if (pid) {
    headers.append('PRODUCT-ID', pid);
  }
  return headers;
}

function getHeadersUploadingFile() {
  let pid = getProductId(store.getState().router.location.pathname);
  let headers = new Headers({});
  if (pid) {
    headers.append('PRODUCT-ID', pid);
  }
  return headers;
}

export default BaseApi;
