import {
  fontsDeleteFetch,
  fontsListFetch,
  uploadNewFont,
} from '../ProductSettingActions';
import {
  DeleteOutlined,
  LoadingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Card, Form, Upload, message, List, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { AuthorityProvider, hasAnyAuthority } from '@Utils/AuthorityProvider';

const ProductSettingFonts = () => {
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();

  const loadingUploadedFonts = useSelector(
    (state) => state.setting.productSetting.loadingUploadedFonts
  );

  const deletingUploadedFont = useSelector(
    (state) => state.setting.productSetting.deletingUploadedFont
  );

  const uploadedFonts = useSelector(
    (state) => state.setting.productSetting.uploadedFonts
  );

  useEffect(() => {
    getFontList();
  }, []);

  const getFontList = () => {
    dispatch(fontsListFetch());
  };

  const customRequest = async ({ file, onError }) => {
    if (file.type !== 'font/ttf' && !file.name.endsWith('.ttf')) {
      message.error('Only TTF font files are accepted!');
      onError(new Error('Invalid file type.'));
      return;
    }

    dispatch(
      uploadNewFont({ file, cbLoading: setUploading, cbSuccess: getFontList })
    );
  };

  const hasUploadFontAuthority = hasAnyAuthority(
    AuthorityProvider.ROLE_FONT_UPLOAD
  );

  return (
    <div>
      <Card styles={{ padding: '40px 30px', borderRadius: '5px' }}>
        <Form>
          <Form.Item name="font" style={{ marginBottom: 8 }}>
            <span
              style={{
                color: '#707070',
                fontSize: 14,
                marginRight: 20,
                width: 110,
              }}
            >
              Fonts:
            </span>
            <Upload
              disabled={!hasUploadFontAuthority}
              customRequest={customRequest}
              showUploadList={false}
              accept=".ttf,application/x-font-ttf"
            >
              <Button
                icon={uploading ? <LoadingOutlined /> : <UploadOutlined />}
                disabled={uploading || !hasUploadFontAuthority}
              >
                {uploading ? 'Uploading...' : 'Click to Upload'}
              </Button>
            </Upload>
          </Form.Item>
          {!hasUploadFontAuthority && (
            <Alert
              message="You have no permission to upload font"
              type="warning"
              showIcon
              style={{ marginBottom: 8 }}
            />
          )}
        </Form>

        <List
          loading={loadingUploadedFonts || deletingUploadedFont}
          header={<div style={{ fontWeight: '700' }}>Uploaded Fonts</div>}
          bordered
          dataSource={uploadedFonts}
          renderItem={(item) => (
            <List.Item>
              <span>{item?.file?.fileName}</span>

              <DeleteOutlined
                disabled={deletingUploadedFont}
                onClick={() => {
                  if (deletingUploadedFont) return;

                  dispatch(fontsDeleteFetch(item.id, getFontList));
                }}
                style={{
                  color: 'red',
                  cursor: deletingUploadedFont ? 'default' : 'pointer',
                }}
              />
            </List.Item>
          )}
        />
      </Card>
    </div>
  );
};

export default ProductSettingFonts;
