import React from 'react';
import { Result } from 'antd';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props?.location?.key !== prevProps?.location?.key) {
      this.setState({ hasError: false });
      this.props.cleanAppInternalError();
    }
  }

  componentDidCatch(error, errorInfo) {
    // TODO: send to graylog
    console.log('error', error.toString());
    console.log('componentStack stack', errorInfo?.componentStack);
    this.setState({ hasError: true });
  }

  shouldRenderResult() {
    return this.state.hasError || this.props.status >= 400;
  }

  renderResult() {
    if (this.state.hasError) {
      return (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
        />
      );
    }
    if (this.props.status === 403) {
      return (
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
        />
      );
    }
    if (this.props.status === 404) {
      return (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
        />
      );
    }
  }

  render() {
    return (
      <>
        {this.props.fallback?.(this.shouldRenderResult()) ?? null}
        {this.shouldRenderResult() ? this.renderResult() : this.props.children}
      </>
    );
  }
}
