import ProductSettingGeneralComponent from '../component/ProductSettingGeneralComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  productChangeCurrent,
  productUpdateFetch,
  productUpdateSubmitFetch,
  productTimeZoneListFetch,
} from '../ProductSettingActions';
import { communicationUploadFileFetch } from '../../../engage/communication/CommunicationActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  zones: state.setting.productSetting.zones,
  loadingZones: state.setting.productSetting.loadingZones,
  errorsZones: state.setting.productSetting.errorsZones,
  loadingUpdate: state.setting.productSetting.loadingUpdate,
  loadingUpdateSubmit: state.setting.productSetting.loadingUpdateSubmit,
  errors: state.setting.productSetting.errors,
  currentProduct: state.setting.productSetting.currentProduct,
  pathname: state.router.location.pathname,
  currentUser: state.account.auth.currentUser,
  uploadedFile: state.engage.communication.uploadedFile,
  loadingUploadFile: state.engage.communication.loadingUploadFile,
  deletingUploadedFont: state.engage.communication.deletingUploadedFont,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      productTimeZoneListFetch,
      productUpdateSubmitFetch,
      productUpdateFetch,
      productChangeCurrent,
      communicationUploadFileFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSettingGeneralComponent);

ProductSettingGeneralComponent.propTypes = {
  currentProduct: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  productChangeCurrent: PropTypes.func.isRequired,
  productUpdateFetch: PropTypes.func.isRequired,
  productUpdateSubmitFetch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};
