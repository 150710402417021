export const getProduct = (currentUser, id) => {
  if (!currentUser || !Array.isArray(currentUser?.products)) return null;

  const maybeProduct = currentUser.products.find((p) => p.id === id);

  if (maybeProduct) return maybeProduct;

  return currentUser.products.find(
    (p) => 'lastProduct' in currentUser && p.id === currentUser?.lastProduct
  );
};
