import BaseApi from '../../../api/BaseApi';

const basePath = '/admin/configuration';
class ConfigurationApi {
  static update() {
    return BaseApi.post(basePath + '/update', {});
  }
  static updateSubmit(body) {
    return BaseApi.post(basePath + '/updateSubmit', body);
  }
  static view() {
    return BaseApi.post(basePath + '/view', {});
  }
  static listSystemAttributes() {
    return BaseApi.post(basePath + '/listSystemAttributes', {});
  }

  static uploadSystemFont(file) {
    return BaseApi.uploadFile('/engagement/font/', file);
  }
}

export default ConfigurationApi;
