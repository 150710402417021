exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ---blue---*/\n/* ---light theme---*/\n/* ---status---*/\n.TrOccurrenceBusinessEventReport-module__root--1MoJg {\n  background-color: white;\n  margin-top: 20px;\n  border-radius: 5px;\n  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.TrOccurrenceBusinessEventReport-module__listsHeader--1vhRd {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 14px 0;\n  margin-bottom: 14px;\n  border-bottom: 1px solid #DCDCDC;\n}\n.TrOccurrenceBusinessEventReport-module__listsHeaderTitle--1NXZW {\n  display: flex;\n  align-items: center;\n}\n.TrOccurrenceBusinessEventReport-module__listsHeaderTitle--1NXZW span {\n  font-size: 18px;\n}\n.TrOccurrenceBusinessEventReport-module__listsHeaderIcon--sgXt_ {\n  font-size: 42px;\n  color: #1d88f6;\n  margin-bottom: -6px;\n  cursor: pointer;\n}\n.TrOccurrenceBusinessEventReport-module__listsHeaderIcon--sgXt_ i {\n  margin: 0;\n  padding: 0;\n  font-size: 25px;\n  color: #fff;\n}\n.TrOccurrenceBusinessEventReport-module__listsLoading--2qdsd {\n  display: flex;\n}\n.TrOccurrenceBusinessEventReport-module__listsLink--3a5iX {\n  color: #1d88f6 !important;\n  cursor: pointer;\n}\n.TrOccurrenceBusinessEventReport-module__listsDownload--3-GpW {\n  color: #bbbcc1;\n}\n.TrOccurrenceBusinessEventReport-module__listsStatusDefult--zZpDD {\n  border-color: #a9a9a9;\n  background-color: #a9a9a9;\n  color: #fff;\n  font-size: 11px;\n  padding: 2px 5px;\n  border-radius: 5px;\n}\n.TrOccurrenceBusinessEventReport-module__listsStatusError--3jtSU {\n  border-color: #f76464;\n  background-color: #f76464;\n}\n.TrOccurrenceBusinessEventReport-module__listsStatusSuccess--2b5w4 {\n  border-color: #09d88c;\n  background-color: #09d88c;\n}\n", ""]);

// exports
exports.locals = {
	"root": "TrOccurrenceBusinessEventReport-module__root--1MoJg",
	"listsHeader": "TrOccurrenceBusinessEventReport-module__listsHeader--1vhRd",
	"listsHeaderTitle": "TrOccurrenceBusinessEventReport-module__listsHeaderTitle--1NXZW",
	"listsHeaderIcon": "TrOccurrenceBusinessEventReport-module__listsHeaderIcon--sgXt_",
	"listsLoading": "TrOccurrenceBusinessEventReport-module__listsLoading--2qdsd",
	"listsLink": "TrOccurrenceBusinessEventReport-module__listsLink--3a5iX",
	"listsDownload": "TrOccurrenceBusinessEventReport-module__listsDownload--3-GpW",
	"listsStatusDefult": "TrOccurrenceBusinessEventReport-module__listsStatusDefult--zZpDD",
	"listsStatusError": "TrOccurrenceBusinessEventReport-module__listsStatusError--3jtSU",
	"listsStatusSuccess": "TrOccurrenceBusinessEventReport-module__listsStatusSuccess--2b5w4"
};