/* eslint-disable complexity */
import {
  PUSH_CHANNEL_DELETE_FETCH,
  PUSH_CHANNEL_DELETE_FETCH_FAIL,
  PUSH_CHANNEL_DELETE_FETCH_SUCCESS,
  PUSH_CHANNEL_ANDROID_CREATE_FETCH,
  PUSH_CHANNEL_ANDROID_CREATE_FETCH_FAIL,
  PUSH_CHANNEL_ANDROID_CREATE_FETCH_SUCCESS,
  PUSH_CHANNEL_IOS_CREATE_FETCH,
  PUSH_CHANNEL_IOS_CREATE_FETCH_FAIL,
  PUSH_CHANNEL_IOS_CREATE_FETCH_SUCCESS,
  PUSH_CHANNEL_ANDROID_LIST_FETCH,
  PUSH_CHANNEL_ANDROID_LIST_FETCH_FAIL,
  PUSH_CHANNEL_ANDROID_LIST_FETCH_SUCCESS,
  PUSH_CHANNEL_ANDROID_LIST_CHANGE_CURRENT,
  PUSH_CHANNEL_IOS_LIST_FETCH,
  PUSH_CHANNEL_IOS_LIST_FETCH_FAIL,
  PUSH_CHANNEL_IOS_LIST_FETCH_SUCCESS,
  PUSH_CHANNEL_IOS_LIST_CHANGE_CURRENT,
  PUSH_CHANNEL_UPDATE_SUBMIT_FETCH,
  PUSH_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL,
  PUSH_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS,
  PUSH_CHANNEL_ANDROID_CHANGE_CURRENT,
  PUSH_CHANNEL_IOS_CHANGE_CURRENT,
  PUSH_IOS_CHANNEL_UPDATE_SUBMIT_FETCH,
  PUSH_IOS_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL,
  PUSH_IOS_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS,
  PUSH_CHANNEL_FIREBASE_CHANGE_CURRENT,
  PUSH_CHANNEL_FIREBASE_CREATE_FETCH,
  PUSH_CHANNEL_FIREBASE_CREATE_FETCH_SUCCESS,
  PUSH_CHANNEL_FIREBASE_CREATE_FETCH_FAIL,
  PUSH_CHANNEL_BROWSER_CHANGE_CURRENT,
  PUSH_CHANNEL_BROWSER_CREATE_FETCH,
  PUSH_CHANNEL_BROWSER_CREATE_FETCH_SUCCESS,
  PUSH_CHANNEL_BROWSER_CREATE_FETCH_FAIL,
  PUSH_BROWSER_CHANNEL_UPDATE_SUBMIT_FETCH,
  PUSH_BROWSER_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL,
  PUSH_BROWSER_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS,
  PUSH_CHANNEL_FIREBASE_LIST_FETCH,
  PUSH_CHANNEL_FIREBASE_LIST_FETCH_FAIL,
  PUSH_CHANNEL_FIREBASE_LIST_FETCH_SUCCESS,
  PUSH_FIREBASE_CHANNEL_UPDATE_SUBMIT_FETCH,
  PUSH_FIREBASE_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL,
  PUSH_FIREBASE_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS,
  PUSH_CHANNEL_BROWSER_LIST_FETCH,
  PUSH_CHANNEL_BROWSER_LIST_FETCH_SUCCESS,
  PUSH_CHANNEL_BROWSER_LIST_FETCH_FAIL,
  PUSH_CHANNEL_FETCH_ITEM_FAILED,
} from './PushChannelConstants';

const initialState = {
  androidList: [],
  loadingAndroidList: false,
  errorsAndroid: [],

  iosList: [],
  loadingIosList: false,
  errorsIos: [],

  toggleChangeAndroidList: false,
  toggleChangeIosList: false,

  currentAndroidPushChannel: {
    pushVersion: 'V1',
  },
  currentIosPushChannel: {},
  currentfirebaseChannel: {},
  currentBrowserChannel: {},

  errorsUpdateSubmit: [],
  errorsUpdateBrowserSubmit: [],

  errorsDelete: [],
  loadingDelete: false,
  loadingUpdateSubmit: false,
  loadingIosUpdateSubmit: false,
  loadingFirebaseUpdateSubmit: false,
  loadingBrowserUpdateSubmit: false,

  errorsCreateAndroid: [],
  loadingCreateAndroid: false,

  errorsCreateIos: [],
  loadingCreateIos: false,

  errorsCreateFirebase: [],
  loadingCreateFirebase: false,

  errorsCreateBrowser: [],
  loadingCreateBrowser: false,

  loadingFirebaseList: false,
  firebaseList: [],

  loadingBrowserList: false,
  browserList: [],
};

export default function PushChannelState(state = initialState, action) {
  switch (action.type) {
    case PUSH_CHANNEL_ANDROID_LIST_FETCH:
      return { ...state, loadingAndroidList: true };
    case PUSH_CHANNEL_ANDROID_LIST_FETCH_SUCCESS:
      return {
        ...state,
        androidList: action.payload,
        loadingAndroidList: false,
      };
    case PUSH_CHANNEL_ANDROID_LIST_FETCH_FAIL:
      return {
        ...state,
        errorsAndroid: action.payload.errors,
        loadingAndroidList: false,
      };

    case PUSH_CHANNEL_ANDROID_LIST_CHANGE_CURRENT:
      return {
        ...state,
        androidList: action.payload,
        toggleChangeAndroidList: !state.toggleChangeAndroidList,
      };

    case PUSH_CHANNEL_IOS_LIST_FETCH:
      return { ...state, loadingIosList: true };
    case PUSH_CHANNEL_IOS_LIST_FETCH_SUCCESS:
      return { ...state, iosList: action.payload, loadingIosList: false };
    case PUSH_CHANNEL_IOS_LIST_FETCH_FAIL:
      return {
        ...state,
        errorsIos: action.payload.errors,
        loadingIosList: false,
      };

    case PUSH_CHANNEL_IOS_LIST_CHANGE_CURRENT:
      return {
        ...state,
        iosList: action.payload,
        toggleChangeIosList: !state.toggleChangeIosList,
      };

    case PUSH_CHANNEL_UPDATE_SUBMIT_FETCH:
      return { ...state, loadingUpdateSubmit: true };
    case PUSH_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        errorsUpdateSubmit: action.payload.errors,
        loadingUpdateSubmit: false,
      };
    case PUSH_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS:
      return { ...state, loadingUpdateSubmit: false };

    case PUSH_IOS_CHANNEL_UPDATE_SUBMIT_FETCH:
      return { ...state, loadingIosUpdateSubmit: true };
    case PUSH_IOS_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        errorsUpdateSubmit: action.payload.errors,
        loadingIosUpdateSubmit: false,
      };
    case PUSH_IOS_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS:
      return { ...state, loadingIosUpdateSubmit: false };

    case PUSH_CHANNEL_ANDROID_CREATE_FETCH:
      return { ...state, loadingCreateAndroid: true };
    case PUSH_CHANNEL_ANDROID_CREATE_FETCH_FAIL:
      return {
        ...state,
        errorsCreateAndroid: action.payload.errors,
        loadingCreateAndroid: false,
      };
    case PUSH_CHANNEL_ANDROID_CREATE_FETCH_SUCCESS:
      return { ...state, loadingCreateAndroid: false };

    case PUSH_CHANNEL_IOS_CREATE_FETCH:
      return { ...state, errorsCreateIos: true };
    case PUSH_CHANNEL_IOS_CREATE_FETCH_FAIL:
      return {
        ...state,
        loadingCreateIos: action.payload.errors,
        errorsCreateIos: false,
      };
    case PUSH_CHANNEL_IOS_CREATE_FETCH_SUCCESS:
      return { ...state, errorsCreateIos: false };
    case PUSH_CHANNEL_FIREBASE_CREATE_FETCH:
      return { ...state, loadingCreateFirebase: true };
    case PUSH_CHANNEL_FIREBASE_CREATE_FETCH_FAIL:
      return {
        ...state,
        errorsCreateFirebase: action.payload.errors,
        loadingCreateFirebase: false,
      };
    case PUSH_CHANNEL_FIREBASE_CREATE_FETCH_SUCCESS:
      return { ...state, loadingCreateFirebase: false };
    case PUSH_CHANNEL_BROWSER_CREATE_FETCH:
      return { ...state, loadingCreateBrowser: true };
    case PUSH_CHANNEL_BROWSER_CREATE_FETCH_FAIL:
      return {
        ...state,
        errorsCreateBrowser: action.payload.errors,
        loadingCreateBrowser: false,
      };
    case PUSH_CHANNEL_BROWSER_CREATE_FETCH_SUCCESS:
      return { ...state, loadingCreateBrowser: false };

    case PUSH_CHANNEL_DELETE_FETCH:
      return { ...state, loadingDelete: true };
    case PUSH_CHANNEL_DELETE_FETCH_FAIL:
      return {
        ...state,
        errorsDelete: action.payload.errors,
        loadingDelete: false,
      };
    case PUSH_CHANNEL_DELETE_FETCH_SUCCESS:
      return { ...state, loadingDelete: false };

    case PUSH_CHANNEL_ANDROID_CHANGE_CURRENT:
      return {
        ...state,
        currentAndroidPushChannel: action.payload,
        loadingAndroidList: false,
      };

    case PUSH_CHANNEL_IOS_CHANGE_CURRENT:
      return {
        ...state,
        currentIosPushChannel: action.payload,
        loadingIosList: false,
      };
    case PUSH_CHANNEL_FIREBASE_CHANGE_CURRENT:
      return {
        ...state,
        currentfirebaseChannel: action.payload,
        loadingFirebaseList: false,
      };
    case PUSH_CHANNEL_BROWSER_CHANGE_CURRENT:
      return {
        ...state,
        currentBrowserChannel: action.payload,
        loadingBrowserList: false,
      };

    case PUSH_BROWSER_CHANNEL_UPDATE_SUBMIT_FETCH:
      return { ...state, loadingBrowserUpdateSubmit: true };
    case PUSH_BROWSER_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        errorsUpdateBrowserSubmit: action.payload.errors,
        loadingBrowserUpdateSubmit: false,
      };
    case PUSH_BROWSER_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS:
      return { ...state, loadingBrowserUpdateSubmit: false };

    case PUSH_CHANNEL_FIREBASE_LIST_FETCH:
      return { ...state, loadingFirebaseList: true };
    case PUSH_CHANNEL_FIREBASE_LIST_FETCH_SUCCESS:
      return {
        ...state,
        firebaseList: action.payload,
        currentfirebaseChannel: action.payload[0],
        loadingFirebaseList: false,
      };
    case PUSH_CHANNEL_FIREBASE_LIST_FETCH_FAIL:
      return {
        ...state,
        errorsIos: action.payload.errors,
        loadingFirebaseList: false,
      };

    case PUSH_FIREBASE_CHANNEL_UPDATE_SUBMIT_FETCH:
      return { ...state, loadingFirebaseUpdateSubmit: true };
    case PUSH_FIREBASE_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        errorsUpdateSubmit: action.payload.errors,
        loadingFirebaseUpdateSubmit: false,
      };
    case PUSH_FIREBASE_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS:
      return { ...state, loadingFirebaseUpdateSubmit: false };
    case PUSH_CHANNEL_BROWSER_LIST_FETCH:
      return { ...state, loadingBrowserList: true };
    case PUSH_CHANNEL_BROWSER_LIST_FETCH_SUCCESS:
      return {
        ...state,
        browserList: action.payload,
        currentBrowserChannel: action.payload[0],
        loadingBrowserList: false,
      };
    case PUSH_CHANNEL_BROWSER_LIST_FETCH_FAIL:
      return {
        ...state,
        errorsIos: action.payload.errors,
        loadingBrowserList: false,
      };
    case PUSH_CHANNEL_FETCH_ITEM_FAILED:
      return {
        ...state,
        loadingAndroidList: false,
        loadingIosList: false,
        loadingFirebaseList: false,
        loadingBrowserList: false,
      };
    default:
      return state;
  }
}
