import React, { useState } from 'react';
import { THEME_OPTIONS as ON_SITE_THEME_OPTIONS } from './helpers';
import mobilePreviewContainer from '@Theme/icons/mobile-preview-container.png';
import desktopPreviewContainer from '@Theme/icons/desktop-preview-container.png';
import noImage from '@Theme/icons/noImage.png';
import {
  CloseOutlined,
  CopyOutlined,
  DesktopOutlined,
  MobileOutlined,
} from '@ant-design/icons';
import vars from '@Theme/styles/vars';
import { Segmented } from 'antd';
import { getInlineThemeStyle } from './getInlineThemeStyle';

export default function OnSitePreview({ variation, previewPage = false }) {
  const type = variation?.onSiteLayoutType;
  const classNameModifier = `onSitePreview--${type?.toLocaleLowerCase()}`;
  const [previewType, setPreviewType] = useState('mobile');

  if (previewPage) {
    console.log('this is preview page, just for passing eslint!');
  }

  const theme = {};
  ON_SITE_THEME_OPTIONS.forEach((item) => {
    theme[item.key] =
      (variation?.theme &&
        item.key in variation?.theme &&
        variation?.theme[item.key]) ||
      item.default;
  });

  const details = (
    <div className="onSitePreview__content">
      {['TEXT'].includes(type) && variation?.image && (
        <img
          className="onSitePreview__banner"
          src={variation.image}
          style={{
            width: '100%',
          }}
        />
      )}
      <div className="onSitePreview__content__description">
        {['TEXT'].includes(type) && variation?.label && (
          <div
            className="onSitePreview__label"
            style={{ ...getInlineThemeStyle(variation, 'label') }}
          >
            {variation?.label}
          </div>
        )}
        <div
          className="onSitePreview__title"
          style={{
            ...getInlineThemeStyle(variation, 'title'),
          }}
        >
          {variation?.subject}
        </div>
        {variation?.message && (
          <div
            className="onSitePreview__desc"
            style={{
              ...getInlineThemeStyle(variation, 'message'),
            }}
          >
            {variation?.message}
          </div>
        )}
        {['TEXT'].includes(type) && variation?.androidButton1Label && (
          <div
            className="onSitePreview__actions"
            style={{
              background: variation?.theme?.['actions']?.[0]?.bg_color,
            }}
          >
            <span
              style={{
                ...getInlineThemeStyle(variation, 'actions', 0),
                margin: '0 auto',
              }}
            >
              {variation?.androidButton1Label}
            </span>
          </div>
        )}
        {['COUPON'].includes(type) && variation?.couponCode && (
          <div
            className="onSitePreview__coupon-code"
            style={{
              background: variation?.theme?.cta_background_coupon,
            }}
          >
            <span
              className="onSitePreview__coupon_text"
              style={{
                ...getInlineThemeStyle(variation, 'coupon'),
              }}
            >
              {variation?.couponCode}
            </span>
            <span
              style={{
                background: vars.primaryColor,
                borderRadius: '4px',
                padding: '4px',
                width: '20px',
                height: '20px',
              }}
            >
              <CopyOutlined style={{ fontSize: '12px', color: '#fff' }} />
            </span>
          </div>
        )}
      </div>
    </div>
  );

  const mobilePlatformIconStyle = { fontSize: 16, color: '#777' };
  const mobilePlatformOptions = [
    {
      value: 'mobile',
      icon: <MobileOutlined style={mobilePlatformIconStyle} />,
    },
    {
      value: 'desktop',
      icon: <DesktopOutlined style={mobilePlatformIconStyle} />,
    },
  ];

  return (
    <section
      style={{
        direction: 'ltr',
        padding: 8,
        backgroundColor: 'white',
        height: 'auto',
      }}
      className={`message-container__preview ${
        previewPage ? 'message-container__preview--static' : ''
      }
      ${
        previewPage && previewType === 'desktop' ? 'top-width-fix' : ''
      } overflow-hidden`}
    >
      <div className="margin-left-auto message-container__options">
        <Segmented
          style={{ borderRadius: 8 }}
          onChange={setPreviewType}
          options={mobilePlatformOptions}
        />
      </div>
      {previewType === 'mobile' ? (
        <div className="push-notification push-notification--android">
          <img
            src={mobilePreviewContainer}
            alt="preview"
            style={{
              maxWidth: '100%',
            }}
          />
          <div className="push-notification__block">
            {(variation.image ||
              variation.subject ||
              variation.message ||
              variation.label ||
              variation.couponCode) && (
              <div
                className={`onSitePreview ${classNameModifier}`}
                style={{
                  backgroundColor:
                    variation.onSiteLayoutType !== 'IMAGE'
                      ? theme.bg_color
                      : 'transparent',
                  borderRadius: `${theme.border_radius}px`,
                  ...(variation.onSiteLayoutType === 'COUPON' && {
                    ...(variation.image && {
                      backgroundImage: `url(
                      ${variation.image}
                    )`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                    }),
                  }),
                }}
              >
                <CloseOutlined className="onSitePreview__close" />
                {variation.onSiteLayoutType === 'IMAGE' && (
                  <div className="onSitePreview__image">
                    <img
                      src={variation?.image || noImage}
                      style={{ borderRadius: `${theme.border_radius}px` }}
                    />
                  </div>
                )}
                {details}
              </div>
            )}
          </div>
        </div>
      ) : previewType === 'desktop' ? (
        <div className={`push-notification push-notification--desktop`}>
          <img
            src={desktopPreviewContainer}
            alt="preview"
            style={{ maxWidth: '100%', ...(previewPage ? { width: 700 } : {}) }}
          />
          <div className="push-notification__block">
            {(variation.image ||
              variation.subject ||
              variation.message ||
              variation.label) && (
              <div
                className={`onSitePreview ${classNameModifier}`}
                style={{
                  backgroundColor:
                    variation.onSiteLayoutType !== 'IMAGE'
                      ? theme.bg_color
                      : 'transparent',
                  borderRadius: `${theme.border_radius}px`,

                  ...(variation.onSiteLayoutType === 'COUPON' && {
                    ...(variation.image && {
                      backgroundImage: `url(
                      ${variation.image}
                    )`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                    }),
                  }),
                }}
              >
                <CloseOutlined className="onSitePreview__close" />
                {variation.onSiteLayoutType === 'IMAGE' && (
                  <div className="onSitePreview__image">
                    <img
                      src={variation?.image || noImage}
                      style={{ borderRadius: `${theme.border_radius}px` }}
                    />
                  </div>
                )}
                {details}
              </div>
            )}
          </div>
        </div>
      ) : null}
    </section>
  );
}
