export class HTMLBuilder {
  constructor(defaultClasses, customFonts) {
    this.html = document.createElement('html');
    this.head = document.createElement('head');
    this.body = document.createElement('body');
    this.root = document.createElement('div');
    this.style = document.createElement('style');

    this.style.innerHTML = defaultClasses.replace(/[\t\n]/g, '');

    this.root.classList.add('container--default');

    this.head.appendChild(this.style);
    this.body.appendChild(this.root);

    this.injectFonts(customFonts);
  }

  injectFonts(customFonts) {
    if (!customFonts || !Array.isArray(customFonts)) return;

    for (const font of customFonts) {
      if (!font?.file?.fileName || !font?.file?.publicPath) continue;

      const name = font.file.fileName.replace(/\.(ttf|woff|woff2)$/, '');

      const src = font.file.publicPath;

      const fontdef = `@font-face{font-family:"${name}";src:url('${src}') format('woff2');}`;

      const fontface = fontdef.replace(/[\t\n]/g, '');

      if (!this.style.innerHTML.includes(fontface)) {
        this.style.innerHTML = fontface + this.style.innerHTML;
      }
    }
  }

  setBackground(url) {
    if (!url) return;

    this.root.style.background = `url("${url}")`;
    this.root.style.backgroundSize = 'cover';
    this.root.style.backgroundPosition = 'center';
  }

  setBorderRadius(radius) {
    if (!radius) return;

    if (typeof radius === 'number' || !radius.endsWith('px')) {
      radius = radius.toString() + 'px';
    }

    this.root.style.borderRadius = radius;
  }

  matchProps(element, props) {
    for (const key in props) {
      const value = props[key];

      if (!value) continue;

      switch (key) {
        case 'text':
          element.innerText = value;
          break;
        case 'src':
          element.src = value;
          break;
        case 'action':
          element.setAttribute('onclick', `window.open('${value}', '_blank')`);
          break;
        default: {
          element.style[key] = typeof value === 'number' ? value + 'px' : value;
        }
      }
    }
  }

  wrapStyled(children, { className, ...props }) {
    const element = document.createElement('div');

    if (className) element.classList.add(className);

    this.matchProps(element, props);

    element.append(...children);

    return element;
  }

  wrap(children, className) {
    const element = document.createElement('div');

    if (className) element.classList.add(className);

    element.append(...children);

    return element;
  }

  block({ innerHTML, ...props } = {}) {
    const element = document.createElement('div');

    if (innerHTML) element.innerHTML = innerHTML;

    this.matchProps(element, props);

    return element;
  }

  text({ hasDefaultClassName, ...props } = {}) {
    const element = document.createElement('span');

    if (hasDefaultClassName) element.classList.add('text--default');

    this.matchProps(element, props);

    return element;
  }

  action(props = {}) {
    const element = document.createElement('button');

    this.matchProps(element, props);

    return element;
  }

  image(props = {}) {
    const element = document.createElement('img');

    this.matchProps(element, props);

    return element;
  }

  injectChildren(...children) {
    this.root.append(...children);
  }

  build() {
    this.html.append(this.head, this.body);

    const template = '<!DOCTYPE html>' + this.html.outerHTML.toString();

    const cleanTemplate = template.replace(/(?<=>)\s+(?=<)/g, '');

    return cleanTemplate.replace(/"/g, "'");
  }
}
