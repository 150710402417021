exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NavigationHeader-module__navigation_header--2j7m7 {\n  width: 100%;\n  height: 60px;\n  padding-inline: 28px;\n  box-shadow: 0 2px 8px #00000014;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n}\n.NavigationHeader-module__project_search_input--1OyLv {\n  padding-inline: 8px !important;\n}\n.NavigationHeader-module__project_list--37Lmf {\n  width: 250px;\n  height: 220px;\n  box-shadow: 0px 9px 28px 8px #0000000d;\n  display: flex;\n  flex-direction: column;\n  background: #fff;\n}\n.NavigationHeader-module__project_list--37Lmf ul {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  min-height: 100% !important;\n  background-color: #fff !important;\n}\n.NavigationHeader-module__guideMenu--2xNyw {\n  width: 170px;\n  box-shadow: 0px 9px 28px 8px #0000000d;\n  display: flex;\n  flex-direction: column;\n  background: #fff;\n}\n.NavigationHeader-module__scroller--2PRLt {\n  width: 100%;\n  height: 100%;\n  overflow-y: auto;\n}\n.NavigationHeader-module__project_list_trigger--1jbfk {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  cursor: pointer;\n}\n.NavigationHeader-module__item_with_icon--328yv {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n", ""]);

// exports
exports.locals = {
	"navigation_header": "NavigationHeader-module__navigation_header--2j7m7",
	"project_search_input": "NavigationHeader-module__project_search_input--1OyLv",
	"project_list": "NavigationHeader-module__project_list--37Lmf",
	"guideMenu": "NavigationHeader-module__guideMenu--2xNyw",
	"scroller": "NavigationHeader-module__scroller--2PRLt",
	"project_list_trigger": "NavigationHeader-module__project_list_trigger--1jbfk",
	"item_with_icon": "NavigationHeader-module__item_with_icon--328yv"
};