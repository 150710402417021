import {
  PRODUCT_CHANGE_CURRENT,
  PRODUCT_UPDATE_FETCH,
  PRODUCT_UPDATE_FETCH_FAIL,
  PRODUCT_UPDATE_FETCH_SUCCESS,
  PRODUCT_UPDATE_SUBMIT_FETCH,
  PRODUCT_UPDATE_SUBMIT_FETCH_FAIL,
  PRODUCT_UPDATE_SUBMIT_FETCH_SUCCESS,
  PRODUCT_LIST_TIMEZONE_FETCH,
  PRODUCT_LIST_TIMEZONE_FETCH_FAIL,
  PRODUCT_LIST_TIMEZONE_FETCH_SUCCESS,
  FC_CHANGE_CURRENT,
  FC_UPDATE_FETCH,
  FC_UPDATE_FETCH_FAIL,
  FC_UPDATE_FETCH_SUCCESS,
  FC_UPDATE_SUBMIT_FETCH,
  FC_UPDATE_SUBMIT_FETCH_FAIL,
  FC_UPDATE_SUBMIT_FETCH_SUCCESS,
  THROTTLING_CHANGE_CURRENT,
  THROTTLING_UPDATE_FETCH,
  THROTTLING_UPDATE_FETCH_FAIL,
  THROTTLING_UPDATE_FETCH_SUCCESS,
  THROTTLING_UPDATE_SUBMIT_FETCH,
  THROTTLING_UPDATE_SUBMIT_FETCH_FAIL,
  THROTTLING_UPDATE_SUBMIT_FETCH_SUCCESS,
  DND_CHANGE_CURRENT,
  DND_UPDATE_FETCH,
  DND_UPDATE_FETCH_FAIL,
  DND_UPDATE_FETCH_SUCCESS,
  DND_UPDATE_SUBMIT_FETCH,
  DND_UPDATE_SUBMIT_FETCH_FAIL,
  DND_UPDATE_SUBMIT_FETCH_SUCCESS,
  CUSTOM_DOMAIN_UPDATE_FETCH_FAIL,
  CUSTOM_DOMAIN_UPDATE_FETCH,
  CUSTOM_DOMAIN_UPDATE_FETCH_SUCCESS,
  CUSTOM_DOMAIN_UPDATE_SUBMIT_FETCH,
  CUSTOM_DOMAIN_UPDATE_SUBMIT_FETCH_FAIL,
  CUSTOM_DOMAIN_UPDATE_SUBMIT_FETCH_SUCCESS,
  CUSTOM_DOMAIN_CHANGE_CURRENT,
  UPLOADED_FONTS_SUBMIT_FETCH_SUCCESS,
  UPLOADED_FONTS_SUBMIT_FETCH_FAIL,
  UPLOADED_FONTS_SUBMIT_FETCH,
  DELETED_FONT_SUBMIT_FETCH,
  DELETED_FONT_SUBMIT_FETCH_SUCCESS,
  DELETED_FONT_SUBMIT_FETCH_FAIL,
} from './ProductSettingConstants';
import ProductSettingApi from './ProductSettingApi';
import toastr from 'toastr';
import { showMessageError } from '../../../utils/RenderUtils';
import { message } from 'antd';

export function productChangeCurrent(product) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_CHANGE_CURRENT,
      payload: product,
    });
  };
}

export function fcChangeCurrent(fc) {
  return (dispatch) => {
    dispatch({
      type: FC_CHANGE_CURRENT,
      payload: fc,
    });
  };
}
export function throttlingChangeCurrent(throttling) {
  return (dispatch) => {
    dispatch({
      type: THROTTLING_CHANGE_CURRENT,
      payload: throttling,
    });
  };
}

export function dndChangeCurrent(dnd) {
  return (dispatch) => {
    dispatch({
      type: DND_CHANGE_CURRENT,
      payload: dnd,
    });
  };
}

export function productUpdateFetch() {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_UPDATE_FETCH,
    });
    ProductSettingApi.productUpdate()
      .then((response) => {
        dispatch({
          type: PRODUCT_UPDATE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_UPDATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function customDomainUpdateSubmitFetch(body) {
  return (dispatch) => {
    dispatch({
      type: CUSTOM_DOMAIN_UPDATE_SUBMIT_FETCH,
    });
    ProductSettingApi.customDomainUpdateSubmit(body)
      .then((response) => {
        dispatch({
          type: CUSTOM_DOMAIN_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been saved successfully');
      })
      .catch((response) => {
        dispatch({
          type: CUSTOM_DOMAIN_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
export function productUpdateSubmitFetch(body) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_UPDATE_SUBMIT_FETCH,
    });
    ProductSettingApi.productUpdateSubmit(body)
      .then((response) => {
        dispatch({
          type: PRODUCT_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been saved successfully');
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
export function customDomainChangeCurrent(customDomain) {
  return (dispatch) => {
    dispatch({
      type: CUSTOM_DOMAIN_CHANGE_CURRENT,
      payload: customDomain,
    });
  };
}
export function fcUpdateFetch() {
  return (dispatch) => {
    dispatch({
      type: FC_UPDATE_FETCH,
    });
    ProductSettingApi.fcUpdate()
      .then((response) => {
        dispatch({
          type: FC_UPDATE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: FC_UPDATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function fcUpdateSubmitFetch(body) {
  return (dispatch) => {
    dispatch({
      type: FC_UPDATE_SUBMIT_FETCH,
    });
    ProductSettingApi.fcUpdateSubmit(body)
      .then((response) => {
        dispatch({
          type: FC_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been saved successfully');
      })
      .catch((response) => {
        dispatch({
          type: FC_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
export function throttlingUpdateFetch() {
  return (dispatch) => {
    dispatch({
      type: THROTTLING_UPDATE_FETCH,
    });
    ProductSettingApi.throttlingUpdate()
      .then((response) => {
        dispatch({
          type: THROTTLING_UPDATE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: THROTTLING_UPDATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function throttlingUpdateSubmitFetch(body) {
  return (dispatch) => {
    dispatch({
      type: THROTTLING_UPDATE_SUBMIT_FETCH,
    });
    ProductSettingApi.throttlingUpdateSubmit(body)
      .then((response) => {
        dispatch({
          type: THROTTLING_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been saved successfully');
      })
      .catch((response) => {
        dispatch({
          type: THROTTLING_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function dndUpdateFetch() {
  return (dispatch) => {
    dispatch({
      type: DND_UPDATE_FETCH,
    });
    ProductSettingApi.dndUpdate()
      .then((response) => {
        dispatch({
          type: DND_UPDATE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: DND_UPDATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function customDomainUpdateFetch() {
  return (dispatch) => {
    dispatch({
      type: CUSTOM_DOMAIN_UPDATE_FETCH,
    });
    ProductSettingApi.customaDomainUpdate()
      .then((response) => {
        dispatch({
          type: CUSTOM_DOMAIN_UPDATE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: CUSTOM_DOMAIN_UPDATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function dndUpdateSubmitFetch(body) {
  return (dispatch) => {
    dispatch({
      type: DND_UPDATE_SUBMIT_FETCH,
    });
    ProductSettingApi.dndUpdateSubmit(body)
      .then((response) => {
        dispatch({
          type: DND_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been saved successfully');
      })
      .catch((response) => {
        dispatch({
          type: DND_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function productTimeZoneListFetch() {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_LIST_TIMEZONE_FETCH,
    });
    ProductSettingApi.productListTimezones()
      .then((response) => {
        dispatch({
          type: PRODUCT_LIST_TIMEZONE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_LIST_TIMEZONE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function fontsListFetch() {
  return (dispatch) => {
    dispatch({
      type: UPLOADED_FONTS_SUBMIT_FETCH,
    });
    ProductSettingApi.fontList()
      .then((response) => {
        dispatch({
          type: UPLOADED_FONTS_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: UPLOADED_FONTS_SUBMIT_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function fontsDeleteFetch(id, cb) {
  return (dispatch) => {
    dispatch({
      type: DELETED_FONT_SUBMIT_FETCH,
    });
    ProductSettingApi.fontDelete(id)
      .then(() => {
        dispatch({
          type: DELETED_FONT_SUBMIT_FETCH_SUCCESS,
        });
        cb();
      })
      .catch((response) => {
        message.error(`An error occured while deleting the font`);
        dispatch({
          type: DELETED_FONT_SUBMIT_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function uploadNewFont({ file, cbLoading, cbSuccess }) {
  return () => {
    cbLoading(true);
    ProductSettingApi.uploadFont(file)
      .then(() => {
        message.success(`${file.name} uploaded successfully.`);
        cbSuccess();
      })
      .catch(() => {
        message.error(`${file.name} upload failed.`);
      })
      .finally(() => cbLoading(false));
  };
}
