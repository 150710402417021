import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Button, Modal, Popconfirm, Tooltip } from 'antd';
import vars from '@Theme/styles/vars.js';
const { Header } = Layout;
import onetimeSvg from '../../../../theme/images/onetime.svg';
import triggerdSvg from '../../../../theme/images/triggerd.svg';
import recurringSvg from '../../../../theme/images/recurring.svg';
import { DatePicker } from 'antd';
import {
  getProductId,
  getCommunicationId,
  getSegmentId,
  getJourneyId,
  getUserId_,
  hasAnyAuthority,
  AuthorityProvider,
  authorizedForDelayedDeliveryCheck,
  authorizedForInstantDeliveryCheck,
} from '../../../../utils/AuthorityProvider';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { history } from '../../../../redux/store';
import moment from 'moment';
import { renderCampaignStatus } from '../../../../utils/RenderUtils';
import texts from '../../../../utils/texts';
import FunnelReportHeaderActionsContainer from '@Modules/analytics/funnel/container/FunnelReportHeaderActionsContainer';
import { findProductThrottlingEnabled } from '../../../../utils/hasThrottling';
import style from './HeaderComponent.module.less';
import ActionMenu from './ActionMenu';
import {
  featureAccessCheckUsersAnalyzeTab,
  featureAccessCheckUsersListTab,
  featureAccessCheckSettingGeneralTab,
  featureAccessCheckSettingFCTab,
  featureAccessCheckSettingDNDTab,
  featureAccessCheckSettingCustomDomainTab,
  featureAccessCheckCSVExport,
  featureAccessCheckSideMenuEngagePush,
  featureAccessCheckSideMenuEngageSms,
  featureAccessCheckSideMenuEngageEmail,
  featureAccessCheckSideMenuEngageWhatsapp,
  featureAccessCheckSideMenuEngageCustomChannel,
} from '@Utils/AccessManagement';
import { thirdPartyEnums } from '@Modules/admin/thirdParty/components/ThirdPartyTypes';
import { isParentProduct } from '@Utils/isParentProduct';
import { getProduct } from '@Utils/getProduct';
import { NavigationHeader } from './NavigationHeader';

const styles = {
  dataRowContainer: {
    padding: 30,
  },
  dataContainer: {
    flex: 1,
    direction: 'ltr',
    textAlign: 'left',
  },
  dataLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
  },
  infoBoxHeader: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: -12,
    paddingBottom: '20px',
    borderBottom: '1px solid #e7e8e9',
    backgroundColor: '#fdfdfd',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headTitleContainer: {
    fontSize: 18,
    fontWeight: '500',
    color: '#3d404e',
    display: 'flex',
    flexDirection: 'row',
  },
  headIcon: {
    border: '1px solid',
    borderRadius: '50%',
    fontSize: '28px',
    color: '#94969e',
    marginLeft: 10,
  },
  menuActive: {
    color: vars.primaryColor,
    textAlign: 'center',
    fontSize: 13,
    width: 'auto',
    borderBottom: '2px solid',
    borderColor: vars.primaryColor,
    cursor: 'pointer',
    position: 'relative',
    height: '39px',
    lineHeight: '31px',
    paddingRight: 10,
    paddingLeft: 10,
  },
  menuNormal: {
    color: vars.light800,
    opacity: '80%',
    fontSize: 13,
    width: 'auto',
    cursor: 'pointer',
    height: '39px',
    lineHeight: '31px',
    textAlign: 'center',
    paddingRight: 10,
    paddingLeft: 10,
  },
  methodsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  methodContainer: {
    border: '1px solid #e7e8e9',
    borderRadius: '5px',
    padding: '20px',
    height: 256,
    width: 247,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  methodImage: {
    width: 'auto',
    height: 58,
    marginTop: 30,
    marginBottom: 20,
  },
  methodTitle: {
    fontWeight: '600',
    color: '#3d404e',
    lineHeight: '1.43',
    fontSize: 13,
  },
  learnMore: {
    color: vars.primaryColor,
    fontSize: 13,
    cursor: 'pointer',
    paddingTop: 20,
  },
  methodDescription: {
    textAlign: 'center',
    fontSize: 13,
    lineHeight: 1.5,
    color: 'rgb(61, 64, 78)',
  },
  addButton: {
    fontSize: 24,
    color: vars.iconColorAddButton,
    cursor: 'pointer',
    marginLeft: 16,
  },
  holderTitle: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: 4,
  },
  holderJourneyName: {},
  iconBack: {
    marginLeft: 10,
    // fontSize: '28px',
    color: '#fff',
    cursor: 'pointer',
  },
};

const HeaderTitle = (props) => {
  const { journeyId, journeyDetails } = props;
  const pathname = history.location.pathname;

  useEffect(() => {
    props.fetchJourney(journeyId);
  }, [journeyId]);

  return (
    <div
      style={styles.holderTitle}
      title={`Show ${journeyDetails?.type === 'RELAY' ? 'RELAY' : 'Journey'}`}
      onClick={() => {
        const uId = getUserId_(pathname);
        history.push(
          `/product/${uId}/${
            journeyDetails?.type === 'RELAY' ? 'relay' : 'journey'
          }/${journeyId}/overview`
        );
      }}
    >
      <span style={styles.holderJourneyName}>
        {journeyDetails ? journeyDetails?.name : ''}
        <ArrowRightOutlined style={styles.iconBack} />
      </span>
      <span style={{ color: '#1d88f6', fontWeight: 'normal', fontSize: 14 }}>
        {props.children}
      </span>
    </div>
  );
};

class HeaderComponent extends React.Component {
  state = {
    activeMenu: 'overview',
    addPushModalVisible: false,
    transactionalModalVisible: false,
    disabled: false,
  };

  isNotParent = () => {
    return !isParentProduct(this.props.currentUser, {
      pathname: this.props.pathname,
    });
  };

  gotoCampaign = () => {
    this.props.communicationDraftSaveDiscard();
    history.push('../campaigns/audience');
  };

  gotoUser = () => {
    history.push('create');
  };

  gotoRole = () => {
    history.push('create');
  };

  gotoProduct = () => {
    history.push('create');
  };

  gotoReleaseNote = () => {
    history.push('create');
  };

  gotoFunnel = () => {
    history.push('new');
  };

  gotoFileStorage = () => {
    if (hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_FILE_STORAGE_CREATE)) {
      history.push('create');
    }
  };

  gotoTemplate = () => {
    this.props.emailTemplateChangeCurrent({});
    this.props.emailTemplateToggleModal(true, null);
  };

  gotoCustomChannel = () => {
    this.props.customChannelToggleAddModal(true);
  };

  gotoWebhook = () => {
    this.props.webhookModalToggle(true);
  };

  gotoApiToken = () => {
    // TODO: A default pageRequest is used here.  It should be fixed to use the
    //  currently set pageRequest
    this.props.apiTokenCreateFetch({ order: 'createdDate', direction: 'DESC' });
  };

  revokeAllTokens = () => {
    Modal.confirm({
      title: 'Confirmation',
      content:
        'Are you sure you want to revoke all currently active API tokens?',
      onOk: () => {
        this.props.apiTokenRevokeAllFetch(this.props.pageRequestApiTokens);
      },
    });
  };

  gotoLiveSegment = () => {
    history.push('create');
  };

  gotoStaticSegment = () => {
    this.props.productSegmentStaticCreateToggle(true);
  };

  gotoJourney = () => {
    // this.props.changeMenuLayout(false);
    history.push('new');
  };

  getProductName() {
    let pid = getProductId(this.props.pathname);
    if (!pid || !this.props.currentUser?.products) {
      return null;
    }
    const product = getProduct(this.props.currentUser, pid);
    if (!product) {
      return null;
    }
    return product?.name;
  }

  renderMenuItem = (title, link) => {
    let active = false;
    if (
      this.props.pathname.endsWith('users/search') &&
      title?.includes('Search')
    ) {
      active = true;
    } else if (
      this.props.pathname.endsWith('users/overview') &&
      title?.includes('Overview')
    ) {
      active = true;
    } else if (this.props.pathname.endsWith('/event-analytics')) {
      active = true;
    } else if (
      (this.props.pathname.includes('segments/live') ||
        this.props.pathname.includes('segments/static')) &&
      this.props.pathname.endsWith('overview') &&
      title?.includes('Overview')
    ) {
      active = true;
    } else if (
      (this.props.pathname.includes('segments/live') ||
        this.props.pathname.includes('segments/static')) &&
      this.props.pathname.endsWith('search') &&
      title?.includes('Search')
    ) {
      active = true;
    } else if (
      (this.props.pathname.includes('segments/live') ||
        this.props.pathname.includes('segments/static')) &&
      this.props.pathname.endsWith('segment-analytics') &&
      title?.includes('Analyze')
    ) {
      active = true;
    } else if (
      this.props.pathname.endsWith('users/user-analytics') &&
      title?.includes('Analyze')
    ) {
      active = true;
    } else if (
      this.props.pathname.endsWith('/data-management/system/attributes') &&
      title?.includes('System')
    ) {
      active = true;
    } else if (
      this.props.pathname.endsWith('/data-management/user/attributes') &&
      title?.includes('User')
    ) {
      active = true;
    } else if (
      this.props.pathname.endsWith('/data-management/events/attributes') &&
      title?.includes('Custom')
    ) {
      active = true;
    } else if (
      this.props.pathname.endsWith('/data-management/events/revenue') &&
      title?.includes('Revenue')
    ) {
      active = true;
    } else if (
      (this.props.pathname.endsWith('/data-management/events/business') ||
        this.props.pathname.endsWith(
          '/data-management/events/business-events-new'
        )) &&
      title?.includes('Business')
    ) {
      active = true;
    } else if (
      this.props.pathname.endsWith('/data-management/csv/csv-export-log') &&
      title?.includes('CSV')
    ) {
      active = true;
    } else if (
      this.props.pathname.endsWith('/upload-data/users/upload') &&
      title?.includes('User')
    ) {
      active = true;
    } else if (
      this.props.pathname.endsWith('/account/profile/update') &&
      title?.includes('Profile')
    ) {
      active = true;
    } else if (
      this.props.pathname.endsWith('/account/profile/csv-export-log') &&
      title?.includes('CSV')
    ) {
      active = true;
    } else if (
      this.props.pathname.endsWith('/upload-data/event/upload') &&
      title?.includes('Events')
    ) {
      active = true;
    } else if (
      this.props.pathname.endsWith('uninstalls/overview') &&
      title?.includes('Overview')
    ) {
      active = true;
    } else if (
      this.props.pathname.endsWith('uninstalls/analyze') &&
      title?.includes('Analyze')
    ) {
      active = true;
    } else if (
      this.props.pathname.endsWith('uninstalls/users') &&
      title?.includes('Users')
    ) {
      active = true;
    } else if (
      this.props.pathname.endsWith('/channels/sms') &&
      title?.includes('SMS')
    ) {
      active = true;
    } else if (
      this.props.pathname.endsWith('/channels/email') &&
      title?.includes('Email') &&
      !title?.includes('Template')
    ) {
      active = true;
    } else if (
      this.props.pathname.endsWith('/channels/whatsapp') &&
      title?.includes('WhatsApp')
    ) {
      active = true;
    } else if (
      this.props.pathname.endsWith('/channels/template') &&
      title?.includes('Template')
    ) {
      active = true;
    } else if (
      this.props.pathname.endsWith('/channels/custom') &&
      title?.includes('Custom')
    ) {
      active = true;
    } else if (
      this.props.pathname.endsWith('/channels/push') &&
      title?.includes('Push')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/users/view/') &&
      this.props.pathname.includes('basic') &&
      title?.includes('Basic')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/users/view/') &&
      this.props.pathname.includes('attributes') &&
      title?.includes('Attributes')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/users/view/') &&
      this.props.pathname.includes('devices') &&
      title?.includes('Devices')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/users/view/') &&
      this.props.pathname.includes('channels') &&
      title?.includes('Channels')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/users/view/') &&
      this.props.pathname.includes('interactions') &&
      title?.includes('Interactions')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/campaign-list/all') &&
      title?.includes('List')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/campaign-list/archive') &&
      title?.includes('Archive')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/campaign-list/overview') &&
      title?.includes('Overview')
    ) {
      active = true;
    } else if (
      (this.props.pathname.includes('/journeys/list') ||
        this.props.pathname.includes('/relays/list')) &&
      title?.includes('List')
    ) {
      active = true;
    } else if (
      (this.props.pathname.includes('/journeys/overview') ||
        this.props.pathname.includes('/relays/overview')) &&
      title?.includes('Overview')
    ) {
      active = true;
    } else if (
      (this.props.pathname.includes('/journeys/archive') ||
        this.props.pathname.includes('/relays/archive')) &&
      title?.includes('Archive')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/settings/general') &&
      title?.includes('General')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/settings/fc') &&
      title?.includes('FC')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/settings/dnd') &&
      title?.includes('DND')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/settings/custom-domain') &&
      title?.includes('Custom Domain')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/settings/throttling') &&
      title?.includes('Throttling')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/settings/fonts') &&
      title?.includes('Fonts')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/settings/report') &&
      title?.includes('Reports')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/settings/audit') &&
      title?.includes('Audit')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/members') &&
      title?.includes('Members')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/roles') &&
      title?.includes('Roles')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/campaigns/') &&
      this.props.pathname.includes('/users') &&
      title?.includes('Engaged Users')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/campaigns/') &&
      this.props.pathname.includes('/overview') &&
      title?.includes('Overview')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/campaigns/') &&
      this.props.pathname.includes('/details') &&
      title?.includes('Details')
    ) {
      active = true;
    } else if (
      (this.props.pathname.includes('/journey/') ||
        this.props.pathname.includes('/relay/')) &&
      this.props.pathname.includes('/details') &&
      title?.includes('Details')
    ) {
      active = true;
    } else if (
      (this.props.pathname.includes('/journey/') ||
        this.props.pathname.includes('/relay/')) &&
      this.props.pathname.includes('/overview') &&
      title?.includes('Overview')
    ) {
      active = true;
    } else if (
      (this.props.pathname.includes('/journey/') ||
        this.props.pathname.includes('/relay/')) &&
      this.props.pathname.includes('/users') &&
      title?.includes('Engaged Users')
    ) {
      active = true;
    } else if (
      (this.props.pathname.includes('/journey/') ||
        this.props.pathname.includes('/relay/')) &&
      this.props.pathname.includes('/view') &&
      title?.includes('View')
    ) {
      active = true;
    } else if (
      (this.props.pathname.includes('segments/live/list') ||
        this.props.pathname.includes('segments/static/list')) &&
      title?.includes('Active')
    ) {
      active = true;
    } else if (
      (this.props.pathname.includes('segments/live/archive') ||
        this.props.pathname.includes('segments/static/archive')) &&
      title?.includes('Archive')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/admin/adminProduct/update/') &&
      this.props.pathname.includes('/product') &&
      title?.includes('Product')
    ) {
      active = true;
    } else if (
      this.props.pathname.includes('/admin/adminProduct/update/') &&
      this.props.pathname.includes('/contract') &&
      title?.includes('Contract')
    ) {
      active = true;
    }
    if (active) {
      return (
        <Link to={link}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div style={styles.menuActive}>{title}</div>
          </div>
        </Link>
      );
    } else {
      return (
        <Link to={link}>
          <div
            style={{
              ...styles.menuNormal,
            }}
          >
            {title}
          </div>
        </Link>
      );
    }
  };

  getCurrentProduct = () => {
    let id = null;

    let pid = getProductId(this.props.pathname);

    if (this.props.activeProduct) {
      id = this.props.activeProduct;
    } else if (pid) {
      id = pid;
    } else if (this.props.currentUser.lastProduct) {
      id = this.props.currentUser.lastProduct;
    }

    return this.props.currentUser.products?.find(
      (product) => product.id === id
    );
  };

  changeActiveProductId = (pid) => {
    const product = getProduct(this.props.currentUser, pid);

    this.props.updateLastProductSubmit({ lastProduct: pid });
    this.props.changeActiveProduct(pid);
    if (product.thirdPartyOption === thirdPartyEnums.PARENT) {
      history.push(`/admin/third-party/${pid}/list`);
    } else {
      history.push(`/product/${pid}/users/overview`);
    }
  };

  renderMenuItems = () => {
    let pid = getProductId(this.props.pathname);
    if (
      this.props.pathname.includes('/users/overview') ||
      this.props.pathname.includes('/users/user-analytics') ||
      this.props.pathname.includes('/users/search')
    ) {
      return (
        <React.Fragment>
          {this.renderMenuItem('Overview', `/product/${pid}/users/overview`)}
          {hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_USER_ANALYSE) &&
            featureAccessCheckUsersAnalyzeTab() &&
            this.renderMenuItem(
              'Analyze',
              `/product/${pid}/users/user-analytics`
            )}
          {hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_USER_LIST) &&
            featureAccessCheckUsersListTab() &&
            this.renderMenuItem('Search Users', `/product/${pid}/users/search`)}
        </React.Fragment>
      );
    }
    if (
      ((this.props.pathname.includes('/segments/live') ||
        this.props.pathname.includes('segments/static')) &&
        this.props.pathname.includes('overview')) ||
      ((this.props.pathname.includes('/segments/live') ||
        this.props.pathname.includes('segments/static')) &&
        this.props.pathname.includes('search')) ||
      ((this.props.pathname.includes('/segments/live') ||
        this.props.pathname.includes('segments/static')) &&
        this.props.pathname.includes('segment-analytics'))
    ) {
      return (
        <React.Fragment>
          {this.renderMenuItem('Overview', './overview')}
          {this.renderMenuItem('Analyze', './segment-analytics')}
          {this.renderMenuItem('Search Users', './search')}
        </React.Fragment>
      );
    } else if (this.props.pathname.includes('data-management')) {
      return (
        <React.Fragment>
          {hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_SETTINGS_VIEW) &&
            this.renderMenuItem(
              'System Attributes',
              `/product/${pid}/data-management/system/attributes`
            )}
          {hasAnyAuthority(
            AuthorityProvider.ROLE_PRODUCT_CUSTOM_ATTRIBUTE_LIST
          ) &&
            this.renderMenuItem(
              'User Attributes',
              `/product/${pid}/data-management/user/attributes`
            )}
          {hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CUSTOM_EVENT_LIST) &&
            this.renderMenuItem(
              'Custom Events',
              `/product/${pid}/data-management/events/attributes`
            )}
          {hasAnyAuthority(
            AuthorityProvider.ROLE_PRODUCT_REVENUE_MAPPING_VIEW
          ) &&
            this.renderMenuItem(
              'Revenue Mapping',
              `/product/${pid}/data-management/events/revenue`
            )}
          {hasAnyAuthority(
            AuthorityProvider.ROLE_PRODUCT_BUSINESS_EVENT_LIST
          ) &&
            this.renderMenuItem(
              'Business Events',
              `/product/${pid}/data-management/events/business`
            )}
          {hasAnyAuthority(
            AuthorityProvider.ROLE_ADMIN_PRODUCT_CSV_EXPORT_LOG
          ) &&
            this.renderMenuItem(
              'CSV Export Log',
              `/product/${pid}/data-management/csv/csv-export-log`
            )}
        </React.Fragment>
      );
    } else if (this.props.pathname.includes('account/profile')) {
      return (
        <React.Fragment>
          {this.renderMenuItem('Update Profile', `/account/profile/update`)}
          {hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CSV_EXPORT_LOG) &&
            this.props.currentUser?.emailVerified &&
            this.renderMenuItem(
              'CSV Export Log',
              `/account/profile/csv-export-log`
            )}
        </React.Fragment>
      );
    } else if (this.props.pathname.includes('upload-data')) {
      return (
        <>
          {hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_UPLOAD_CSV_USER) &&
            this.renderMenuItem(
              'Upload User Data',
              `/product/${pid}/upload-data/users/upload`
            )}
          {hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_UPLOAD_CSV_EVENT) &&
            this.renderMenuItem(
              'Upload Events Data',
              `/product/${pid}/upload-data/event/upload`
            )}
        </>
      );
    } else if (this.props.pathname.includes('uninstalls')) {
      return (
        <React.Fragment>
          {hasAnyAuthority(AuthorityProvider.ROLE_APP_UNINSTALL_OVERVIEW) &&
            this.renderMenuItem('Overview', './overview')}
          {hasAnyAuthority(AuthorityProvider.ROLE_APP_UNINSTALL_ANALYSE) &&
            this.renderMenuItem('Analyze', './analyze')}
          {hasAnyAuthority(AuthorityProvider.ROLE_APP_UNINSTALL_LIST) &&
            this.renderMenuItem('List Of Users', './users')}
        </React.Fragment>
      );
    } else if (
      this.props.pathname.includes('/channels') &&
      !this.props.pathname.includes('users/view') &&
      (this.props.pathname.includes('sms') ||
        this.props.pathname.includes('email') ||
        this.props.pathname.includes('channels'))
    ) {
      return (
        <React.Fragment>
          {hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CHANNEL_PUSH_LIST) &&
            featureAccessCheckSideMenuEngagePush() &&
            this.renderMenuItem('Push', `/product/${pid}/channels/push`)}
          {hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CHANNEL_SMS_LIST) &&
            featureAccessCheckSideMenuEngageSms() &&
            this.renderMenuItem('SMS', `/product/${pid}/channels/sms`)}
          {hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CHANNEL_EMAIL_LIST) &&
            featureAccessCheckSideMenuEngageEmail() &&
            this.renderMenuItem('Email', `/product/${pid}/channels/email`)}
          {hasAnyAuthority(
            AuthorityProvider.ROLE_PRODUCT_CHANNEL_WHATSAPP_LIST
          ) &&
            featureAccessCheckSideMenuEngageWhatsapp() &&
            this.renderMenuItem(
              'WhatsApp',
              `/product/${pid}/channels/whatsapp`
            )}
          {hasAnyAuthority(
            AuthorityProvider.ROLE_PRODUCT_EMAIL_TEMPLATE_LIST
          ) &&
            featureAccessCheckSideMenuEngageEmail() &&
            this.renderMenuItem(
              'Email Templates',
              `/product/${pid}/channels/template`
            )}
          {hasAnyAuthority(
            AuthorityProvider.ROLE_PRODUCT_CHANNEL_CUSTOM_LIST
          ) &&
            featureAccessCheckSideMenuEngageCustomChannel() &&
            this.renderMenuItem(
              'Custom Channel',
              `/product/${pid}/channels/custom`
            )}
        </React.Fragment>
      );
    } else if (
      this.props.pathname.includes('/users/') &&
      this.props.pathname.includes('/view/')
    ) {
      return (
        <React.Fragment>
          {this.renderMenuItem('Basic Info', './basic')}
          {this.renderMenuItem('Attributes', './attributes')}
          {this.renderMenuItem('Devices', './devices')}
          {this.renderMenuItem('Channels', './channels')}
          {this.renderMenuItem('Interactions', './interactions')}
        </React.Fragment>
      );
    } else if (this.props.pathname.includes('campaign-list')) {
      return (
        <React.Fragment>
          {this.renderMenuItem('Overview', 'overview')}
          {this.renderMenuItem('List', 'all')}
          {this.renderMenuItem('Archive', 'archive')}
        </React.Fragment>
      );
    } else if (
      this.props.pathname.includes('journeys/list') ||
      this.props.pathname.includes('relays/list') ||
      this.props.pathname.includes('journeys/archive') ||
      this.props.pathname.includes('relays/archive') ||
      this.props.pathname.includes('journeys/overview') ||
      this.props.pathname.includes('relays/overview')
    ) {
      return (
        <React.Fragment>
          {this.isNotParent() && this.renderMenuItem('Overview', 'overview')}
          {this.renderMenuItem('List', 'list')}
          {this.isNotParent() && this.renderMenuItem('Archive', 'archive')}
        </React.Fragment>
      );
    } else if (this.props.pathname.includes('/settings/')) {
      return (
        <React.Fragment>
          {hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_SETTINGS_VIEW) &&
            featureAccessCheckSettingGeneralTab() &&
            this.renderMenuItem('General', 'general')}
          {hasAnyAuthority(
            AuthorityProvider.ROLE_PRODUCT_FREQUENCY_CAPPING_VIEW
          ) &&
            featureAccessCheckSettingFCTab() &&
            this.renderMenuItem('FC', 'fc')}
          {hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_DND_VIEW) &&
            featureAccessCheckSettingDNDTab() &&
            this.renderMenuItem('DND', 'dnd')}
          {hasAnyAuthority(
            AuthorityProvider.ROLE_PRODUCT_CUSTOM_CAMPAIGN_URL_VIEW
          ) &&
            featureAccessCheckSettingCustomDomainTab() &&
            this.renderMenuItem('Custom Domain', 'custom-domain')}
          {hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_THROTTLING_VIEW) &&
            this.props.currentUser &&
            findProductThrottlingEnabled(this.props.currentUser, pid) &&
            this.renderMenuItem('Throttling', 'throttling')}
          {this.props.currentUser &&
            // findProductThrottlingEnabled(this.props.currentUser, pid) &&
            this.renderMenuItem('Fonts', 'fonts')}
          {/*{this.renderMenuItem('Throttling', 'throttling')}*/}
          {/*{this.renderMenuItem('Reports', 'report')}*/}
          {/*{this.renderMenuItem('Audit Log', 'audit')}*/}
        </React.Fragment>
      );
    } else if (
      this.props.pathname.includes('/campaigns/') &&
      (this.props.pathname.includes('/users') ||
        this.props.pathname.includes('/overview') ||
        this.props.pathname.includes('/details'))
    ) {
      return (
        <React.Fragment>
          {this.renderMenuItem('Overview', 'overview')}
          {this.renderMenuItem('Details', 'details')}
          {this.renderMenuItem('Engaged Users', 'users')}
        </React.Fragment>
      );
    } else if (
      (this.props.pathname.includes('/journey/') ||
        this.props.pathname.includes('/relay/')) &&
      (this.props.pathname.includes('/overview') ||
        this.props.pathname.includes('/details') ||
        this.props.pathname.includes('/view') ||
        this.props.pathname.includes('/users'))
    ) {
      return (
        <React.Fragment>
          {this.renderMenuItem('Overview', 'overview')}
          {this.renderMenuItem('Details', 'details')}
          {this.renderMenuItem('Engaged Users', 'users')}
          {this.renderMenuItem('Live View', 'report')}
        </React.Fragment>
      );
    } else if (
      ((this.props.pathname.includes('segments/live') ||
        this.props.pathname.includes('segments/static')) &&
        this.props.pathname.includes('/list')) ||
      ((this.props.pathname.includes('segments/live') ||
        this.props.pathname.includes('segments/static')) &&
        this.props.pathname.includes('/archive'))
    ) {
      return (
        <React.Fragment>
          {this.renderMenuItem('Active', './list')}
          {this.renderMenuItem('Archive', './archive')}
        </React.Fragment>
      );
    } else if (this.props.pathname.includes('adminProduct/update/')) {
      return (
        <React.Fragment>
          {this.renderMenuItem('Product', 'product')}
          {hasAnyAuthority(AuthorityProvider.ROLE_CONTRACT_VIEW) &&
            this.renderMenuItem('Contract', 'contract')}
        </React.Fragment>
      );
    }
    return null;
  };

  renderBackButton = () => {
    // let pid = getProductId(this.props.pathname);
    let segmentId = getSegmentId(this.props.pathname);
    const journeyId = getJourneyId(this.props.pathname);
    const isTabUsers = this.props.pathname.includes('/users/view/');
    const isTabJourney =
      this.props.pathname.includes('/journey') &&
      (this.props.pathname.endsWith(`/${journeyId}/overview`) ||
        this.props.pathname.includes('details') ||
        this.props.pathname.includes('users'));
    const isTabRelay =
      this.props.pathname.includes('/relay') &&
      (this.props.pathname.endsWith(`/${journeyId}/overview`) ||
        this.props.pathname.includes('details') ||
        this.props.pathname.includes('users'));
    const isTabCampaign =
      this.props.pathname.includes('/campaigns') &&
      (this.props.pathname.includes('overview') ||
        this.props.pathname.includes('details') ||
        this.props.pathname.includes('users'));
    const isUpdateProfile = this.props.pathname.endsWith('/changePassword');
    const isTabSegment =
      this.props.pathname.includes('/segments') &&
      (this.props.pathname.includes('overview') ||
        this.props.pathname.includes('/segment-analytics') ||
        this.props.pathname.endsWith(`/${segmentId}/search`));
    const isTabFunnel =
      !!this.props.pathname.includes('funnels') &&
      !!this.props.pathname.includes('overview');
    const isNotTab = this.props.pathname.includes('/edit');
    if (isTabUsers) {
      return (
        <ArrowLeftOutlined
          onClick={() => history.push('../../../users/search')}
          className={style.icon_back}
        />
      );
    } else if (isTabJourney || isTabRelay) {
      return (
        <ArrowLeftOutlined
          onClick={() => history.goBack()}
          className={style.icon_back}
        />
      );
    } else if (isTabCampaign) {
      return (
        <ArrowLeftOutlined
          onClick={() => history.goBack()}
          className={style.icon_back}
        />
      );
    } else if (isUpdateProfile) {
      return (
        <ArrowLeftOutlined
          onClick={() => history.push('./update')}
          className={style.icon_back}
        />
      );
    } else if (isTabSegment) {
      return (
        <ArrowLeftOutlined
          onClick={() => history.push('../list')}
          className={style.icon_back}
        />
      );
    } else if (isTabFunnel) {
      return (
        <ArrowLeftOutlined
          onClick={() => history.push('../list')}
          className={style.icon_back}
        />
      );
    } else if (isNotTab) {
      return (
        <ArrowLeftOutlined
          onClick={() => history.goBack()}
          className={style.icon_back}
        />
      );
    }
  };

  renderHeaderTitle = () => {
    let result = '';
    let displayJourneyButton = false;
    if (this.props.pathname.includes('/funnels/')) {
      result = 'Funnels';
    } else if (
      this.props.pathname.includes('/data-management') ||
      this.props.pathname.includes('/upload-data') ||
      this.props.pathname.includes('/revenue')
    ) {
      result = 'Data Management ';
    } else if (this.props.pathname.includes('/account/profile')) {
      result = 'Profile';
    } else if (this.props.pathname.includes('/webhooks')) {
      result = 'Webhooks';
    } else if (this.props.pathname.includes('/audit-log')) {
      result = 'Audit Log';
    } else if (this.props.pathname.includes('members')) {
      result = 'Team Members';
    } else if (this.props.pathname.includes('roles')) {
      result = 'Roles';
    } else if (
      this.props.pathname.includes('/channels/sms') ||
      this.props.pathname.includes('/channels/email') ||
      this.props.pathname.includes('/channels/push') ||
      this.props.pathname.includes('/channels/whatsapp')
    ) {
      result = 'Channels';
    } else if (this.props.pathname.includes('/engagement/overview/all')) {
      result = 'Overview';
    } else if (this.props.pathname.includes('/channels/template')) {
      result = 'Email Template';
    } else if (this.props.pathname.includes('channels/custom')) {
      result = 'Custom Channel';
    } else if (
      this.props.pathname.includes('/users/') &&
      !this.props.pathname.includes('/view/') &&
      !this.props.pathname.includes('event-analytics')
    ) {
      result = 'Users';
    } else if (this.props.pathname.includes('/event-analytics')) {
      result = 'Event Analytics';
    } else if (
      this.props.pathname.includes('/users/') &&
      this.props.pathname.includes('/view/')
    ) {
      result = 'User Details';
    } else if (
      this.props.pathname.includes('/campaign-list/') &&
      this.props.pathname.includes('/push-notifications/')
    ) {
      result = 'Push';
    } else if (this.props.pathname.includes('/uninstalls/')) {
      result = 'Uninstalls';
    } else if (
      this.props.pathname.includes('/campaign-list/') &&
      this.props.pathname.includes('/sms-messages/')
    ) {
      result = 'SMS';
    } else if (
      this.props.pathname.includes('/campaign-list/') &&
      this.props.pathname.includes('/web-notifications/')
    ) {
      result = 'Web Push';
    } else if (
      this.props.pathname.includes('/campaign-list/') &&
      this.props.pathname.includes('/in-app/')
    ) {
      result = 'In App Messages';
    } else if (
      this.props.pathname.includes('/campaign-list/') &&
      this.props.pathname.includes('/onSiteChannel/')
    ) {
      result = 'On Site';
    } else if (
      this.props.pathname.includes('/campaign-list/') &&
      this.props.pathname.includes('/whatsapp/')
    ) {
      result = 'WhatsApp';
    } else if (
      this.props.pathname.includes('/campaign-list/') &&
      this.props.pathname.includes('/emails/')
    ) {
      result = 'Email';
    } else if (
      this.props.pathname.includes('/campaign-list/') &&
      this.props.pathname.includes('/custom-channel/')
    ) {
      result = 'Custom Channel';
    } else if (
      this.props.pathname.includes('push-notifications') &&
      (this.props.pathname.includes('/audience') ||
        this.props.pathname.includes('/when') ||
        this.props.pathname.includes('/message') ||
        this.props.pathname.includes('/conversion') ||
        this.props.pathname.includes('/test') ||
        this.props.pathname.includes('/preview'))
    ) {
      result = 'Push';
    } else if (
      this.props.pathname.includes('web-notifications') &&
      (this.props.pathname.includes('/audience') ||
        this.props.pathname.includes('/when') ||
        this.props.pathname.includes('/message') ||
        this.props.pathname.includes('/conversion') ||
        this.props.pathname.includes('/test') ||
        this.props.pathname.includes('/preview'))
    ) {
      result = 'Web Push';
    } else if (
      this.props.pathname.includes('sms-messages') &&
      (this.props.pathname.includes('/audience') ||
        this.props.pathname.includes('/when') ||
        this.props.pathname.includes('/message') ||
        this.props.pathname.includes('/conversion') ||
        this.props.pathname.includes('/test') ||
        this.props.pathname.includes('/preview'))
    ) {
      result = 'SMS';
    } else if (
      this.props.pathname.includes('in-app') &&
      (this.props.pathname.includes('/audience') ||
        this.props.pathname.includes('/when') ||
        this.props.pathname.includes('/message') ||
        this.props.pathname.includes('/conversion') ||
        this.props.pathname.includes('/test') ||
        this.props.pathname.includes('/preview'))
    ) {
      result = 'In App Message';
    } else if (
      this.props.pathname.includes('onSiteChannel') &&
      (this.props.pathname.includes('/audience') ||
        this.props.pathname.includes('/when') ||
        this.props.pathname.includes('/message') ||
        this.props.pathname.includes('/conversion') ||
        this.props.pathname.includes('/test') ||
        this.props.pathname.includes('/preview'))
    ) {
      result = 'On Site';
    } else if (
      this.props.pathname.includes('emails') &&
      (this.props.pathname.includes('/audience') ||
        this.props.pathname.includes('/when') ||
        this.props.pathname.includes('/message') ||
        this.props.pathname.includes('/conversion') ||
        this.props.pathname.includes('/test') ||
        this.props.pathname.includes('/preview'))
    ) {
      result = 'Email';
    } else if (
      this.props.pathname.includes('custom-channel') &&
      (this.props.pathname.includes('/audience') ||
        this.props.pathname.includes('/when') ||
        this.props.pathname.includes('/message') ||
        this.props.pathname.includes('/conversion') ||
        this.props.pathname.includes('/test') ||
        this.props.pathname.includes('/preview'))
    ) {
      result = 'Custom Channel';
    } else if (
      this.props.pathname.includes('whatsapp') &&
      (this.props.pathname.includes('/audience') ||
        this.props.pathname.includes('/when') ||
        this.props.pathname.includes('/message') ||
        this.props.pathname.includes('/conversion') ||
        this.props.pathname.includes('/test') ||
        this.props.pathname.includes('/preview'))
    ) {
      result = 'WhatsApp';
    } else if (this.props.pathname.includes('rest-api-key')) {
      result = 'Rest API';
    } else if (this.props.pathname.includes('rest-api-token')) {
      result = 'Rest API Tokens';
    } else if (this.props.pathname.includes('/sdk/')) {
      result = 'SDK';
    } else if (this.props.pathname.includes('/settings/')) {
      result = 'Settings';
    } else if (
      this.props.pathname.includes('/segments/live/list') ||
      this.props.pathname.includes('/segments/live/archive')
    ) {
      result = 'Live Segments';
    } else if (
      this.props.pathname.includes('/segments/static/list') ||
      this.props.pathname.includes('/segments/static/archive')
    ) {
      result = 'Static Segments';
    } else if (this.props.pathname.includes('/segments/live/create')) {
      result = 'Create a Live Segment';
    } else if (this.props.pathname.includes('/segments/static/create')) {
      result = 'Create a Static Segment';
    } else if (this.props.pathname.includes('/segments/live/update')) {
      result = 'Update Live Segment';
    } else if (this.props.pathname.includes('/segments/static/update')) {
      result = 'Update Static Segment';
    } else if (this.props.pathname.includes('/segments/live/duplicate')) {
      result = 'Duplicate a Live Segment';
    } else if (this.props.pathname.includes('/segments/static/duplicate')) {
      result = 'Duplicate a Static Segment';
    } else if (
      (this.props.pathname.includes('/segments/live') ||
        this.props.pathname.includes('segments/static')) &&
      this.props.pathname.includes('overview')
    ) {
      result =
        this.props.currentProductSegment &&
        this.props.currentProductSegment.name
          ? this.props.currentProductSegment.name
          : 'Segment Overview';
    } else if (
      (this.props.pathname.includes('/segments/live') ||
        this.props.pathname.includes('segments/static')) &&
      this.props.pathname.includes('segment-analytics')
    ) {
      result =
        this.props.currentProductSegment &&
        this.props.currentProductSegment.name
          ? this.props.currentProductSegment.name
          : 'Segment Analytics';
    } else if (
      (this.props.pathname.includes('/segments/live') ||
        this.props.pathname.includes('segments/static')) &&
      this.props.pathname.includes('search')
    ) {
      result =
        this.props.currentProductSegment &&
        this.props.currentProductSegment.name
          ? this.props.currentProductSegment.name
          : 'Segment Users';
    } else if (
      this.props.pathname.includes('campaigns/') &&
      (this.props.pathname.includes('/overview') ||
        this.props.pathname.includes('/details'))
    ) {
      result =
        this.props.currentCommunication && this.props.currentCommunication.name
          ? this.props.currentCommunication.name
          : 'Communication Overview';
      displayJourneyButton = true;
    } else if (
      this.props.pathname.includes('campaigns/') &&
      this.props.pathname.includes('/users')
    ) {
      result =
        this.props.currentCommunication && this.props.currentCommunication.name
          ? this.props.currentCommunication.name
          : 'Engaged Users';
      displayJourneyButton = true;
    } else if (
      this.props.pathname.includes('journeys/list') ||
      this.props.pathname.includes('journeys/overview') ||
      this.props.pathname.includes('journeys/archive')
    ) {
      let pid = getProductId(this.props.pathname);
      if (!pid || !this.props.currentUser.products) {
        return null;
      }
      const product = getProduct(this.props.currentUser, pid);
      if (product?.thirdPartyOption === thirdPartyEnums.PARENT) {
        result = 'Journey Templates';
      } else {
        result = 'Journeys';
      }
    } else if (
      this.props.pathname.includes('relays/list') ||
      this.props.pathname.includes('relays/overview') ||
      this.props.pathname.includes('relays/archive')
    ) {
      result = 'Relays';
    } else if (this.props.pathname.includes('account/profile/changePassword')) {
      result = 'Change Password';
    } else if (this.props.pathname.includes('/account/user/list')) {
      result = 'Dashboard Users List';
    } else if (this.props.pathname.includes('/account/role/list')) {
      result = 'Dashboard Roles List';
    } else if (this.props.pathname.includes('/account/releaseNote/list')) {
      result = 'Dashboard ReleaseNote List';
    } else if (this.props.pathname.includes('/account/releaseNote/update')) {
      result = 'Update ReleaseNote';
    } else if (this.props.pathname.includes('/account/releaseNote/create')) {
      result = 'Create ReleaseNote';
    } else if (this.props.pathname.includes('/admin/adminProduct/list')) {
      result = 'Admin Product List';
    } else if (this.props.pathname.includes('/account/user/create')) {
      result = 'Create Admin User';
    } else if (this.props.pathname.includes('/account/user/update')) {
      result = 'Edit Admin User';
    } else if (this.props.pathname.includes('/account/user/view')) {
      result = 'View Admin User';
    } else if (this.props.pathname.includes('/account/user/changePassword/')) {
      result = 'Change User Password';
    } else if (this.props.pathname.includes('/account/role/view/')) {
      result = 'View Role';
    } else if (this.props.pathname.includes('/account/role/create')) {
      result = 'Create Role';
    } else if (this.props.pathname.includes('/account/role/update/')) {
      result = 'Edit Role';
    } else if (this.props.pathname.includes('/admin/adminProduct/view/')) {
      result = 'View Admin Product';
    } else if (this.props.pathname.includes('/admin/adminProduct/update/')) {
      result = 'Edit Admin Product';
    } else if (this.props.pathname.includes('/admin/adminProduct/create')) {
      result = 'Create Admin Product';
    } else if (this.props.pathname.includes('/account/file-storage/list')) {
      result = 'File Storage List';
    } else if (this.props.pathname.includes('/account/file-storage/create')) {
      result = 'Create File Storage';
    } else if (
      (this.props.pathname.includes('/journey/') ||
        this.props.pathname.includes('/relay/')) &&
      (this.props.pathname.includes('/overview') ||
        this.props.pathname.includes('/details') ||
        this.props.pathname.includes('/users'))
    ) {
      result =
        this.props.journeyDetails && this.props.journeyDetails.name
          ? this.props.journeyDetails.name
          : this.props.pathname.includes('/relay/')
          ? 'Relay Details'
          : 'Journey Details';
    } else if (this.props.pathname.includes('base/configuration/update')) {
      result = 'Update Configuration';
    }

    const journeyId = this.props.currentCommunication?.journeyId;
    if (journeyId && displayJourneyButton) {
      return (
        <HeaderTitle
          journeyId={journeyId}
          fetchJourney={this.props.journeyViewFetch}
          journeyDetails={this.props.journeyDetails}
        >
          {result}
        </HeaderTitle>
      );
    }
    return result;
  };

  disabledDate = (current, singleCommunication = false) => {
    return (
      current &&
      (current > moment().startOf('day') ||
        (singleCommunication
          ? current <
            moment(
              this.props.currentCommunication?.startDate ||
                this.props.currentCommunication?.createdDate
            )
          : current < moment().subtract(93, 'day')))
    );
  };

  renderOverviewTimeSelector = (isTotal = false) => {
    const lastWeek = moment().subtract(7, 'days');
    let startDate =
      this.props.pageRequestSplitOverview &&
      this.props.pageRequestSplitOverview.fromDate
        ? this.props.pageRequestSplitOverview.fromDate
        : lastWeek;
    let endDate =
      this.props.pageRequestSplitOverview &&
      this.props.pageRequestSplitOverview.toDate
        ? this.props.pageRequestSplitOverview.toDate
        : moment();
    if (isTotal) {
      startDate =
        this.props.overviewPageRequestSplitOverview &&
        this.props.overviewPageRequestSplitOverview.fromDate
          ? this.props.overviewPageRequestSplitOverview.fromDate
          : lastWeek;
      endDate =
        this.props.overviewPageRequestSplitOverview &&
        this.props.overviewPageRequestSplitOverview.toDate
          ? this.props.overviewPageRequestSplitOverview.toDate
          : moment();
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: '10px',
        }}
      >
        <DatePicker.RangePicker
          allowClear={false}
          disabled={[false, false]}
          disabledDate={(date) => this.disabledDate(date)}
          value={[
            moment(startDate).isValid() ? moment(startDate) : null,
            moment(endDate).isValid() ? moment(endDate) : null,
          ]}
          onChange={(val) => {
            if (isTotal) {
              this.props.overviewSplitOverviewFetch({
                ...this.props.overviewPageRequestSplitOverview,
                fromDate: val[0].format('YYYY-MM-DD'),
                toDate: val[1].format('YYYY-MM-DD'),
              });
              this.props.overviewFetch({
                ...this.props.overviewPageRequestOverview,
                fromDate: val[0].format('YYYY-MM-DD'),
                toDate: val[1].format('YYYY-MM-DD'),
              });
            } else {
              this.props.communicationSplitOverviewFetch({
                ...this.props.pageRequestSplitOverview,
                fromDate: val[0].format('YYYY-MM-DD'),
                toDate: val[1].format('YYYY-MM-DD'),
              });
              this.props.communicationOverviewFetch({
                ...this.props.pageRequestOverview,
                fromDate: val[0].format('YYYY-MM-DD'),
                toDate: val[1].format('YYYY-MM-DD'),
              });
            }
          }}
        />{' '}
      </div>
    );
  };
  disabledDateEventAnalytics = (current) => {
    return (
      current &&
      (current > moment().startOf('day') ||
        current < moment().subtract(91, 'day'))
    );
  };
  renderEventAnalyticsTimeSelector = () => {
    return (
      <div
        style={{
          marginLeft: '10px',
        }}
      >
        <DatePicker.RangePicker
          allowClear={false}
          disabled={[false, false]}
          disabledDate={(date) => this.disabledDateEventAnalytics(date)}
          value={[
            moment(this.props.eventAnalyticsValue?.fromDate).isValid()
              ? moment(this.props.eventAnalyticsValue?.fromDate)
              : null,
            moment(this.props.eventAnalyticsValue?.toDate).isValid()
              ? moment(this.props.eventAnalyticsValue?.toDate)
              : null,
          ]}
          onChange={(val) => {
            this.props.eventChangeCurrent({
              ...this.props.eventAnalyticsValue,
              fromDate: val[0].format('YYYY-MM-DD'),
              toDate: val[1].format('YYYY-MM-DD'),
            });
          }}
        />{' '}
      </div>
    );
  };

  renderUninstallTimeSelector = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: '10px',
        }}
      >
        <DatePicker.RangePicker
          allowClear={false}
          disabled={[false, false]}
          disabledDate={(date) => this.disabledDateEventAnalytics(date)}
          value={[
            moment(this.props.uninstallOverviewInterval?.fromDate).isValid()
              ? moment(this.props.uninstallOverviewInterval?.fromDate)
              : null,
            moment(this.props.uninstallOverviewInterval?.toDate).isValid()
              ? moment(this.props.uninstallOverviewInterval?.toDate)
              : null,
          ]}
          onChange={(val) => {
            this.props.uninstallOverviewChangeCurrent({
              ...this.props.uninstallOverviewInterval,
              fromDate: val[0].format('YYYY-MM-DD'),
              toDate: val[1].format('YYYY-MM-DD'),
            });
          }}
        />{' '}
      </div>
    );
  };

  renderUninstallAnalyzeTimeSelector = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: '10px',
        }}
      >
        <DatePicker.RangePicker
          allowClear={false}
          disabled={[false, false]}
          disabledDate={(date) => this.disabledDateEventAnalytics(date)}
          value={[
            moment(this.props.uninstallAnalyzeFilter?.fromDate).isValid()
              ? moment(this.props.uninstallOverviewInterval?.fromDate)
              : null,
            moment(this.props.uninstallAnalyzeFilter?.toDate).isValid()
              ? moment(this.props.uninstallOverviewInterval?.toDate)
              : null,
          ]}
          onChange={(val) => {
            this.props.uninstallAnalyzeChangeCurrent({
              ...this.props.uninstallAnalyzeFilter,
              fromDate: val[0].format('YYYY-MM-DD'),
              toDate: val[1].format('YYYY-MM-DD'),
            });
          }}
        />{' '}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginRight: 10,
            marginBottom: 5,
          }}
        ></div>
      </div>
    );
  };

  renderTimeSelectorLeft = () => {
    return null;
  };

  handleChangeDateRange = (dates) => {
    this.communicationId = getCommunicationId(this.props.pathname);

    if (!dates || !Array.isArray(dates)) return;

    const variationReportDuration = {
      fromDate: dates[0].format('YYYY-MM-DD'),
      toDate: dates[1].format('YYYY-MM-DD'),
    };
    this.props.communicationVariationReportFetch({
      ...this.props.variationReportPageRequest,
      ...variationReportDuration,
    });
    this.props.communicationCommunicationReportFetch(
      this.communicationId,
      variationReportDuration
    );
  };

  JourneyDisabledDate = (current) => {
    return current && current > moment().startOf('day');
  };

  handleJourneyChangeDateRange = (dates) => {
    if (!dates || !Array.isArray(dates)) return;

    const reportDuration = {
      fromDate: dates[0].format('YYYY-MM-DD'),
      toDate: dates[1].format('YYYY-MM-DD'),
    };
    this.props.journeyReportCommunicationReportFetch({
      ...this.props.journeyCommunicationReportRequest,
      ...reportDuration,
    });
    this.props.journeyReportEnterOrExitReportFetch({
      ...this.props.journeyCommunicationReportRequest,
      ...reportDuration,
    });
    this.props.journeyGetReportOverview(
      this.props.journeyCommunicationReportRequest.journeyId,
      reportDuration
    );
  };

  renderTimeSelectorRight = () => {
    if (this.props.pathname.includes('/event-analytics')) {
      return (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div>event analytics in: </div>
            <div> {this.renderEventAnalyticsTimeSelector()}</div>
          </div>
        </React.Fragment>
      );
    } else if (this.props.pathname.includes('engagement/overview/all')) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>Overview of communications relays and journeys in: </div>
          <div> {this.renderOverviewTimeSelector(true)}</div>
        </div>
      );
    } else if (
      this.props.pathname.includes('push-notifications/campaign-list/overview')
    ) {
      return (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={{ display: 'felx', flexDirection: 'row' }}>
              Overview of push notification communications in:{' '}
            </div>
            <div> {this.renderOverviewTimeSelector(false)}</div>
          </div>
        </React.Fragment>
      );
    } else if (this.props.pathname.includes('in-app/campaign-list/overview')) {
      return (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={{ display: 'felx', flexDirection: 'row' }}>
              Overview of In App communications in:{' '}
            </div>
            <div> {this.renderOverviewTimeSelector(false)}</div>
          </div>
        </React.Fragment>
      );
    } else if (
      this.props.pathname.includes('onSiteChannel/campaign-list/overview')
    ) {
      return (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              Overview of On Site communications in:{' '}
            </div>
            <div> {this.renderOverviewTimeSelector(false)}</div>
          </div>
        </React.Fragment>
      );
    } else if (this.props.pathname.includes('emails/campaign-list/overview')) {
      return (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              Overview of emails communications in:{' '}
            </div>{' '}
            <div>{this.renderOverviewTimeSelector(false)}</div>
          </div>
        </React.Fragment>
      );
    } else if (
      this.props.pathname.includes('whatsapp/campaign-list/overview')
    ) {
      return (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              Overview of emails communications in:{' '}
            </div>{' '}
            <div>{this.renderOverviewTimeSelector(false)}</div>
          </div>
        </React.Fragment>
      );
    } else if (
      this.props.pathname.includes('sms-messages/campaign-list/overview')
    ) {
      return (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              Overview of sms messages communications in:{' '}
            </div>
            <div> {this.renderOverviewTimeSelector(false)}</div>
          </div>
        </React.Fragment>
      );
    } else if (
      this.props.pathname.includes('web-notifications/campaign-list/overview')
    ) {
      return (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              Overview of web push communications in:{' '}
            </div>{' '}
            <div>{this.renderOverviewTimeSelector(false)}</div>
          </div>
        </React.Fragment>
      );
    } else if (
      this.props.pathname.includes('uninstalls') &&
      this.props.pathname.includes('overview')
    ) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>Overview of uninstalls in: </div>{' '}
          <div>{this.renderUninstallTimeSelector()}</div>
        </div>
      );
    } else if (
      this.props.pathname.includes('uninstalls') &&
      this.props.pathname.includes('analyze')
    ) {
      return (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div>Uninstall analytics in: </div>{' '}
            <div>{this.renderUninstallAnalyzeTimeSelector()}</div>
          </div>
        </React.Fragment>
      );
    } else if (
      this.props.pathname.includes('journeys/overview') ||
      this.props.pathname.includes('relays/overview')
    ) {
      return (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              Overview of{' '}
              {this.props.pathname.includes('relays/overview')
                ? 'relays'
                : 'journeys'}{' '}
              in:{' '}
            </div>{' '}
            <div>{this.renderOverviewTimeSelector(false)}</div>
          </div>
        </React.Fragment>
      );
    } else if (
      this.props.pathname.includes('custom-channel/campaign-list/overview')
    ) {
      return (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              Overview of Custom Channles in:{' '}
            </div>{' '}
            <div>{this.renderOverviewTimeSelector(false)}</div>
          </div>
        </React.Fragment>
      );
    } else if (
      this.props.pathname.includes('campaigns') &&
      this.props.pathname.includes('overview')
    ) {
      return (
        <DatePicker.RangePicker
          allowClear={false}
          disabledDate={(date) => this.disabledDate(date, true)}
          value={[
            moment(this.props.variationReportPageRequest?.fromDate).isValid()
              ? moment(this.props.variationReportPageRequest?.fromDate)
              : null,
            moment(this.props.variationReportPageRequest?.toDate).isValid()
              ? moment(this.props.variationReportPageRequest?.toDate)
              : null,
          ]}
          onChange={(dates) => this.handleChangeDateRange(dates)}
        />
      );
    } else if (
      (this.props.pathname.includes('journey') ||
        this.props.pathname.includes('relay')) &&
      this.props.pathname.includes('overview') &&
      this.props.journeyCommunicationReportRequest?.fromDate
    ) {
      return (
        <DatePicker.RangePicker
          value={[
            moment(
              this.props.journeyCommunicationReportRequest?.fromDate
            ).isValid()
              ? moment(this.props.journeyCommunicationReportRequest?.fromDate)
              : null,
            moment(
              this.props.journeyCommunicationReportRequest?.toDate
            ).isValid()
              ? moment(this.props.journeyCommunicationReportRequest?.toDate)
              : null,
          ]}
          disabledDate={(date) => this.JourneyDisabledDate(date)}
          onChange={(dates) => this.handleJourneyChangeDateRange(dates)}
          allowClear={false}
        />
      );
    }

    return null;
  };

  renderHeaderDescription = () => {
    if (
      this.props.pathname.includes('/data-management') ||
      this.props.pathname.includes('/revenue')
    ) {
      return null; // (<React.Fragment>A View of data. {false && <span style={{ color: vars.primaryColor, fontSize: 10 }}>Learn More</span>}</React.Fragment>);
    } else if (
      this.props.pathname.includes('/channels/sms') ||
      this.props.pathname.includes('/channels/email')
    ) {
      return null; // (<React.Fragment>Manage communication providers. {false && <span style={{ color: vars.primaryColor, fontSize: 10 }}>Learn More</span>}</React.Fragment>);
    } else if (this.props.pathname.includes('/channels/template')) {
      return null; // (<React.Fragment>Manage Email templates. {false && <span style={{ color: vars.primaryColor, fontSize: 10 }}>Learn More</span>}</React.Fragment>);
    } else if (this.props.pathname.includes('/channels/custom')) {
      return null;
    } else if (
      this.props.pathname.includes('/users/') &&
      !this.props.pathname.includes('/view/') &&
      !this.props.pathname.includes('/event-analytics')
    ) {
      return null; // (<React.Fragment>High-level Userbase Overview {false && <span style={{ color: vars.primaryColor, fontSize: 10 }}>Learn More</span>}</React.Fragment>);
    } else if (
      this.props.pathname.includes('/users/') &&
      this.props.pathname.includes('/view/')
    ) {
      // eslint-disable-next-line no-nested-ternary
      if (this.props.userLoading) {
        return (
          <React.Fragment>
            <span style={{ color: '#707070', fontSize: 12, marginRight: 15 }}>
              {this.props?.user?.known ? 'User ID' : 'Anonymous ID'}:
            </span>
            <span style={{ color: '#1c1c1c', fontSize: 12 }}>Loading ...</span>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          <span style={{ color: '#707070', fontSize: 12, marginRight: 15 }}>
            {this.props?.user?.known ? 'User ID' : 'Anonymous ID'}:
          </span>
          <span style={{ color: '#1c1c1c', fontSize: 12 }}>
            {this.props?.user?.userId || this.props?.user?.anonymousId || '-'}
          </span>
        </React.Fragment>
      );
    } else if (
      this.props.pathname.includes('push-notifications/campaign-list/all')
    ) {
      return null; // (<React.Fragment>List of push notification communications. {false && <span style={{ color: vars.primaryColor, fontSize: 10 }}>Learn More</span>}</React.Fragment>);
    } else if (this.props.pathname.includes('sms-messages/campaign-list/all')) {
      return null; // (<React.Fragment>List of sms messages communications. {false && <span style={{ color: vars.primaryColor, fontSize: 10 }}>Learn More</span>}</React.Fragment>);
    } else if (this.props.pathname.includes('emails/campaign-list/all')) {
      return null; //(<React.Fragment>List of emails communications. {false && <span style={{ color: vars.primaryColor, fontSize: 10 }}>Learn More</span>}</React.Fragment>);
    } else if (
      (this.props.pathname.includes('push-notifications') ||
        this.props.pathname.includes('sms-messages') ||
        this.props.pathname.includes('emails')) &&
      (this.props.pathname.includes('/audience') ||
        this.props.pathname.includes('/when') ||
        this.props.pathname.includes('/message') ||
        this.props.pathname.includes('/conversion') ||
        this.props.pathname.includes('/test') ||
        this.props.pathname.includes('/preview'))
    ) {
      return null; // (<React.Fragment>Communication Details</React.Fragment>);
    } else if (this.props.pathname.includes('rest-api-key')) {
      return null; // (<React.Fragment>Transfer information to inTrack endpoints {false && <span style={{ color: vars.primaryColor, fontSize: 10 }}>Learn More</span>}</React.Fragment>);
    } else if (this.props.pathname.includes('/sdk/')) {
      return null; // (<React.Fragment>Transfer information to intrack endpoints {false && <span style={{ color: vars.primaryColor, fontSize: 10 }}>Learn More</span>}</React.Fragment>);
    } else if (this.props.pathname.includes('/segments/live/list')) {
      return null; // (<React.Fragment>Live Segment if you intended to use these segments regularly such as in journey, recurring communications etc. {false && <span style={{ color: vars.primaryColor, fontSize: 10 }}>Learn More</span>}</React.Fragment>);
    } else if (this.props.pathname.includes('/segments/static/list')) {
      return null; // (<React.Fragment>Static Segment if you intended to use these segments regularly such as in journey, recurring communications etc. {false && <span style={{ color: vars.primaryColor, fontSize: 10 }}>Learn More</span>}</React.Fragment>);
    } else if (
      this.props.pathname.includes('journeys/list') ||
      this.props.pathname.includes('relays/list')
    ) {
      return null; // (<React.Fragment>List of user journeys {false && <span style={{ color: vars.primaryColor, fontSize: 10 }}>Learn More</span>}</React.Fragment>);
    } else if (this.props.pathname.includes('/account/user/list')) {
      return null; // (<React.Fragment>List of users with access to the dashboard {false && <span style={{ color: vars.primaryColor, fontSize: 10 }}>Learn More</span>}</React.Fragment>);
    } else if (this.props.pathname.includes('/account/role/list')) {
      return null; // (<React.Fragment>List of users' role in dashboard {false && <span style={{ color: vars.primaryColor, fontSize: 10 }}>Learn More</span>}</React.Fragment>);
    } else if (this.props.pathname.includes('/account/releaseNote/list')) {
      return null; // (<React.Fragment>List of users' role in dashboard {false && <span style={{ color: vars.primaryColor, fontSize: 10 }}>Learn More</span>}</React.Fragment>);
    } else if (this.props.pathname.includes('/admin/adminProduct/list')) {
      return null; // (<React.Fragment>List of products in dashboard {false && <span style={{ color: vars.primaryColor, fontSize: 10 }}>Learn More</span>}</React.Fragment>);
    } else if (this.props.pathname.includes('/account/user/create')) {
      return null; // (<React.Fragment>Create dashboard user {false && <span style={{ color: vars.primaryColor, fontSize: 10 }}>Learn More</span>}</React.Fragment>);
    } else if (this.props.pathname.includes('/account/user/view')) {
      return null; // (<React.Fragment>View dashboard user {false && <span style={{ color: vars.primaryColor, fontSize: 10 }}>Learn More</span>}</React.Fragment>);
    } else if (this.props.pathname.includes('/account/user/update')) {
      return null; // (<React.Fragment>Update dashboard user {false && <span style={{ color: vars.primaryColor, fontSize: 10 }}>Learn More</span>}</React.Fragment>);
    }
    return null;
  };
  renderStatus = () => {
    if (
      (this.props.pathname.includes('/push-notifications/campaigns/') &&
        (this.props.pathname.endsWith('/overview') ||
          this.props.pathname.endsWith('/details') ||
          this.props.pathname.endsWith('/users'))) ||
      (this.props.pathname.includes('/sms-messages/campaigns/') &&
        (this.props.pathname.endsWith('/overview') ||
          this.props.pathname.endsWith('/details') ||
          this.props.pathname.endsWith('/users'))) ||
      (this.props.pathname.includes('/emails/campaigns/') &&
        (this.props.pathname.endsWith('/overview') ||
          this.props.pathname.endsWith('/details') ||
          this.props.pathname.endsWith('/users'))) ||
      (this.props.pathname.includes('/custom-channel/campaigns/') &&
        (this.props.pathname.endsWith('/overview') ||
          this.props.pathname.endsWith('/details') ||
          this.props.pathname.endsWith('/users'))) ||
      (this.props.pathname.includes('/in-app/campaigns/') &&
        (this.props.pathname.endsWith('/overview') ||
          this.props.pathname.endsWith('/details') ||
          this.props.pathname.endsWith('/users'))) ||
      (this.props.pathname.includes('/onSiteChannel/campaigns/') &&
        (this.props.pathname.endsWith('/overview') ||
          this.props.pathname.endsWith('/details') ||
          this.props.pathname.endsWith('/users'))) ||
      (this.props.pathname.includes('/web-notifications/campaigns') &&
        (this.props.pathname.endsWith('/overview') ||
          this.props.pathname.endsWith('/details') ||
          this.props.pathname.endsWith('/users'))) ||
      (this.props.pathname.includes('/whatsapp/campaigns') &&
        (this.props.pathname.endsWith('/overview') ||
          this.props.pathname.endsWith('/details') ||
          this.props.pathname.endsWith('/users')))
    ) {
      if (
        this.props.currentCommunication &&
        this.props.currentCommunication.status
      ) {
        return (
          <span className={style.status}>
            {renderCampaignStatus(this.props.currentCommunication.status)}
          </span>
        );
      }
      return null;
    } else if (
      this.props.pathname.includes('/journey/') ||
      this.props.pathname.includes('/relay/') ||
      this.props.pathname.includes('details')
    ) {
      if (this.props.journeyDetails && this.props.journeyDetails.status) {
        return (
          <span className={style.status}>
            {renderCampaignStatus(this.props.journeyDetails.status)}
          </span>
        );
      }
      return null;
    }
  };
  handleSaveSegment = () => {
    this.props.productSegmentSaveClicked();
  };

  handleResetSegment = () => {
    this.props.productSegmentResetClicked();
  };

  renderSegmentCreateButtons = () => {
    if (
      this.props.pathname.includes('segments/live/create') ||
      this.props.pathname.includes('/segments/live/update') ||
      this.props.pathname.includes('/segments/live/duplicate') ||
      this.props.pathname.includes('/segments/static/create') ||
      this.props.pathname.includes('/segments/static/duplicate') ||
      this.props.pathname.includes('/segments/static/update')
    ) {
      const segmentWarning = this.props.pathname.includes(
        '/segments/live/update'
      );
      const showBackButton =
        (this.props.pathname.includes('/segments/live/update') ||
          this.props.pathname.includes('/segments/live/create')) &&
        (this.props.pathname.includes('/audience') ||
          this.props.pathname.includes('/test'));
      return (
        <div
          style={{
            display: 'flex',
            alignSelf: 'center',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Popconfirm
            title="Are you sure to reset all conditions?"
            onConfirm={() => this.handleResetSegment()}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="link"
              style={{ fontSize: 12, fontWeight: 600, width: 104 }}
            >
              RESET ALL
            </Button>
          </Popconfirm>
          {showBackButton && (
            <Button
              type="link"
              onClick={() => history.goBack()}
              style={{ fontSize: 12, fontWeight: 600, marginRight: 20 }}
            >
              DISCARD & BACK
            </Button>
          )}
          {segmentWarning ? (
            <Popconfirm
              title={
                <div style={{ width: 400 }}>
                  If the segment is used in Exit Scenario of journeys, changes
                  will not create any impact on user recognition of any Exit
                  Scenario.
                </div>
              }
              onConfirm={() => this.handleSaveSegment()}
              okText="OK"
            >
              <Button
                type="primary"
                shape="round"
                loading={
                  this.props.loadingCreateSegment ||
                  this.props.loadingUpdateSubmitSegment
                }
              >
                SAVE
              </Button>
            </Popconfirm>
          ) : (
            <Button
              type="primary"
              shape="round"
              loading={
                this.props.loadingCreateSegment ||
                this.props.loadingUpdateSubmitSegment
              }
              onClick={() => this.handleSaveSegment()}
            >
              SAVE
            </Button>
          )}
        </div>
      );
    }

    return null;
  };

  cancel = (e) => {
    e.stopPropagation();
  };

  confirmCopySegment = () => {
    const segmentId = getSegmentId(this.props.pathname);
    this.props.productSegmentCopyFetch(segmentId, null, true);
  };

  confirmEditSegment = () => {
    const segmentId = getSegmentId(this.props.pathname);
    history.push('../update/' + segmentId);
  };

  confirmEditJourney = () => {
    if (
      this.props.journeyDetails &&
      ['DRAFT', 'STOPPED', 'PAUSED'].includes(this.props.journeyDetails.status)
    ) {
      history.push('view');
    }
  };

  confirmCopyJourney = () => {
    const journeyId = getJourneyId(this.props.pathname);
    this.props.journeyCopyFetch(
      journeyId,
      this.props.journeyPageRequest,
      this.props.pathname.includes('/relay') ? 'RELAY' : 'JOURNEY'
    );
  };

  confirmPauseJourney = () => {
    const journeyId = getJourneyId(this.props.pathname);
    this.props.journeyPause(
      journeyId,
      this.props.journeyPageRequest,
      this.props.pathname.includes('/relay') ? 'RELAY' : 'JOURNEY'
    );
  };

  confirmStopJourney = () => {
    const journeyId = getJourneyId(this.props.pathname);
    this.props.journeyStop(
      journeyId,
      this.props.journeyPageRequest,
      this.props.pathname.includes('/relay') ? 'RELAY' : 'JOURNEY'
    );
  };

  confirmDeleteJourney = () => {
    const journeyId = getJourneyId(this.props.pathname);
    this.props.journeyDeleteFetch(
      journeyId,
      this.props.journeyPageRequest,
      this.props.pathname.includes('/relay') ? 'RELAY' : 'JOURNEY'
    );
  };

  confirmDeleteSegment = () => {
    const segmentId = getSegmentId(this.props.pathname);
    this.props.productSegmentDeleteFetch(segmentId, null, true);
  };

  confirmEdit = () => {
    this.communicationId = getCommunicationId(this.props.pathname);
    history.push('./audience');
  };

  confirmCopy = () => {
    this.communicationId = getCommunicationId(this.props.pathname);
    this.props.communicationCopyFetch(this.communicationId, null, true);
  };

  confirmPause = () => {
    this.communicationId = getCommunicationId(this.props.pathname);
    this.props.communicationPauseFetch(this.communicationId);
  };

  confirmResume = () => {
    this.communicationId = getCommunicationId(this.props.pathname);
    this.props.communicationLaunchFetch(this.communicationId);
  };

  confirmStop = () => {
    this.communicationId = getCommunicationId(this.props.pathname);
    this.props.communicationStopFetch(this.communicationId);
  };

  confirmDeliveryCheck = () => {
    this.communicationId = getCommunicationId(this.props.pathname);
    this.props.communicationDeliveryCheck(this.communicationId);
  };

  renderHeaderCreateButtons = () => {
    let pid = getProductId(this.props.pathname);
    const isJourneyReportPage = !!(
      (this.props.pathname.includes('/journey/') ||
        this.props.pathname.includes('/relay/')) &&
      (this.props.pathname.includes('/overview') ||
        this.props.pathname.includes('/details') ||
        this.props.pathname.includes('users'))
    );
    const isTemplatePage = !!this.props.pathname.includes('/channels/template');
    const isCustomChannelPage =
      !!this.props.pathname.includes('/channels/custom');
    const inCampaignPage = !!this.props.pathname.includes('/campaign-list/');
    const isStaticSegmentPage =
      this.props.pathname.includes('/segments/static/list') ||
      this.props.pathname.includes('/segments/static/archive');
    const isLiveSegmentPage =
      this.props.pathname.includes('segments/live/list') ||
      this.props.pathname.includes('/segments/live/archive');
    const isWebhookList = !!this.props.pathname.includes('webhooks/view');
    const isApiTokenList = !!this.props.pathname.includes(
      'rest-api-token/view'
    );
    const isDynamicSegmentReportPage =
      this.props.pathname.includes('segments/live/') &&
      (this.props.pathname.includes('search') ||
        this.props.pathname.includes('overview'));
    const isStaticSegmentReportPage =
      this.props.pathname.includes('segments/static/') &&
      (this.props.pathname.includes('search') ||
        this.props.pathname.includes('overview'));
    const isJourneyListPage = !!(
      this.props.pathname.includes('journeys/list') ||
      this.props.pathname.includes('relays/list') ||
      this.props.pathname.includes('journeys/archive') ||
      this.props.pathname.includes('relays/archive')
    );
    const isJourneyOverviewPage = !!(
      this.props.pathname.includes('journeys/overview') ||
      this.props.pathname.includes('relays/overview')
    );
    const isDashboardUsersList =
      !!this.props.pathname.includes('account/user/list');
    const isDashboardRoleList =
      !!this.props.pathname.includes('account/role/list');
    const isDashboardProductList = !!this.props.pathname.includes(
      'admin/adminProduct/list'
    );
    const isDashboardReleaseNoteList = !!this.props.pathname.includes(
      'account/releaseNote/list'
    );
    const communicationReportPage =
      (this.props.pathname.includes('sms-messages/campaigns') &&
        this.props.pathname.includes('overview')) ||
      (this.props.pathname.includes('push-notifications/campaigns') &&
        this.props.pathname.includes('overview')) ||
      (this.props.pathname.includes('emails/campaigns') &&
        this.props.pathname.includes('overview')) ||
      (this.props.pathname.includes('web-notifications/campaigns') &&
        this.props.pathname.includes('overview')) ||
      (this.props.pathname.includes('custom-channel/campaigns') &&
        this.props.pathname.includes('overview')) ||
      (this.props.pathname.includes('in-app/campaigns') &&
        this.props.pathname.includes('overview')) ||
      (this.props.pathname.includes('onSiteChannel/campaigns') &&
        this.props.pathname.includes('overview')) ||
      (this.props.pathname.includes('whatsapp/campaigns') &&
        this.props.pathname.includes('overview')) ||
      (this.props.pathname.includes('sms-messages/campaigns') &&
        this.props.pathname.includes('users')) ||
      (this.props.pathname.includes('push-notifications/campaigns') &&
        this.props.pathname.includes('users')) ||
      (this.props.pathname.includes('emails/campaigns') &&
        this.props.pathname.includes('users')) ||
      (this.props.pathname.includes('web-notifications/campaigns') &&
        this.props.pathname.includes('users')) ||
      (this.props.pathname.includes('custom-channel/campaigns') &&
        this.props.pathname.includes('users')) ||
      (this.props.pathname.includes('in-app/campaigns') &&
        this.props.pathname.includes('users')) ||
      (this.props.pathname.includes('onSiteChannel/campaigns') &&
        this.props.pathname.includes('users')) ||
      (this.props.pathname.includes('whatsapp/campaigns') &&
        this.props.pathname.includes('users')) ||
      (this.props.pathname.includes('sms-messages/campaigns') &&
        this.props.pathname.includes('details')) ||
      (this.props.pathname.includes('push-notifications/campaigns') &&
        this.props.pathname.includes('details')) ||
      (this.props.pathname.includes('emails/campaigns') &&
        this.props.pathname.includes('details')) ||
      (this.props.pathname.includes('custom-channel/campaigns') &&
        this.props.pathname.includes('details')) ||
      (this.props.pathname.includes('in-app/campaigns') &&
        this.props.pathname.includes('details')) ||
      (this.props.pathname.includes('onSiteChannel/campaigns') &&
        this.props.pathname.includes('details')) ||
      (this.props.pathname.includes('whatsapp/campaigns') &&
        this.props.pathname.includes('details')) ||
      (this.props.pathname.includes('web-notifications/campaigns') &&
        this.props.pathname.includes('details'));
    const isFunnelList = !!this.props.pathname.includes('funnels/list');
    const isTeam = this.props.pathname.includes('team/members');
    const isFileStorageList = this.props.pathname.includes('file-storage/list');
    const isFunnelReport =
      !!this.props.pathname.includes('funnels') &&
      !!this.props.pathname.includes('overview');
    const isChannelType =
      ((this.props.pathname.includes('/journeys/') ||
        this.props.pathname.includes('/relays/')) &&
        this.props.pathname.includes('/overview')) ||
      this.props.pathname.includes('/campaign-list/overview');
    if (isFunnelReport) {
      return <FunnelReportHeaderActionsContainer />;
    } else if (isFunnelList) {
      return (
        <Button type="primary" onClick={() => this.gotoFunnel()}>
          New
        </Button>
      );
    } else if (isTeam) {
      return (
        <Button
          type="primary"
          onClick={() => {
            history.push('members/create');
          }}
        >
          New
        </Button>
      );
    } else if (isFileStorageList) {
      return (
        <Button type="primary" onClick={() => this.gotoFileStorage()}>
          New
        </Button>
      );
    } else if (isTemplatePage) {
      return (
        <Button type="primary" onClick={() => this.gotoTemplate()}>
          {' '}
          New
        </Button>
      );
    } else if (isCustomChannelPage) {
      return (
        <Button
          type="primary"
          disabled={
            !hasAnyAuthority(
              AuthorityProvider.ROLE_PRODUCT_CHANNEL_CUSTOM_UPDATE,
              AuthorityProvider.ROLE_PRODUCT_CHANNEL_CUSTOM_CREATE
            )
          }
          onClick={() => this.gotoCustomChannel()}
        >
          {' '}
          New
        </Button>
      );
    } else if (isWebhookList) {
      return (
        <Button type="primary" onClick={() => this.gotoWebhook()}>
          {' '}
          New
        </Button>
      );
    } else if (isApiTokenList) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
          <Button onClick={() => this.revokeAllTokens()}>Revoke All</Button>
          <Button type="primary" onClick={() => this.gotoApiToken()}>
            Generate
          </Button>
        </div>
      );
    } else if (isDashboardUsersList) {
      return (
        <Button type="primary" onClick={() => this.gotoUser()}>
          {' '}
          New
        </Button>
      );
    } else if (isDashboardRoleList) {
      return (
        <Button type="primary" onClick={() => this.gotoRole()}>
          {' '}
          New
        </Button>
      );
    } else if (isDashboardProductList) {
      return (
        <Button
          type="primary"
          disabled={
            !hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_PRODUCT_CREATE)
          }
          onClick={() => this.gotoProduct()}
        >
          {' '}
          New
        </Button>
      );
    } else if (isDashboardReleaseNoteList) {
      return (
        <Button type="primary" onClick={() => this.gotoReleaseNote()}>
          {' '}
          New
        </Button>
      );
    } else if (inCampaignPage) {
      if (isChannelType) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button type="primary" onClick={() => this.gotoCampaign()}>
              {' '}
              New
            </Button>
          </div>
        );
      } else {
        return (
          <Button type="primary" onClick={() => this.gotoCampaign()}>
            New
          </Button>
        );
      }
    } else if (isLiveSegmentPage) {
      return (
        <Button type="primary" onClick={() => this.gotoLiveSegment()}>
          New
        </Button>
      );
    } else if (isStaticSegmentPage) {
      return (
        <Button type="primary" onClick={() => this.gotoStaticSegment()}>
          New
        </Button>
      );
    } else if (isJourneyOverviewPage || isJourneyListPage) {
      if (isChannelType) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              type="primary"
              onClick={() => this.gotoJourney()}
              disabled={
                !hasAnyAuthority(
                  AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_CREATE
                )
              }
            >
              New
            </Button>
          </div>
        );
      } else {
        return (
          <Button
            type="primary"
            onClick={() => this.gotoJourney()}
            disabled={
              !hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_CREATE)
            }
          >
            New
          </Button>
        );
      }
    } else if (isDynamicSegmentReportPage) {
      const result = [];
      const items = [
        {
          key: 'edit',
          title: 'Edit',
          isPrimary: true,
          enabled: true,
          onClick: () => this.confirmEditSegment(),
        },
        {
          key: 'duplicate',
          title: 'Duplicate',
          isPrimary: false,
          enabled: !this.props.loadingCopySegment,
          action: () => this.confirmCopySegment(),
        },
        {
          key: 'delete',
          title: 'Delete',
          isPrimary: false,
          enabled: !this.props.loadingDeleteSegment,
          action: () => this.confirmDeleteSegment(),
        },
      ];
      result.push(<ActionMenu actions={items}></ActionMenu>);
      return <span>{result}</span>;
    } else if (isStaticSegmentReportPage) {
      const result = [];
      const items = [
        {
          key: 'delete',
          title: 'Delete',
          isPrimary: false,
          enabled: !this.props.loadingDeleteSegmen,
          action: () => this.confirmDeleteSegment(),
        },
      ];
      result.push(<ActionMenu actions={items}></ActionMenu>);
      return <div className={style.action}>{result}</div>;
    } else if (isJourneyReportPage) {
      const result = [];
      const items = [
        {
          key: 'duplicate',
          title: 'Duplicate',
          isPrimary: false,
          action: () => this.confirmCopyJourney(),
          enabled: !this.props.loadingDuplicateJourney,
        },
      ];
      this.props.journeyDetails &&
        ['RUNNING', 'W_RUNNING'].includes(this.props.journeyDetails.status) &&
        items.push({
          key: 'pause',
          title: 'Pause',
          isPrimary: true,
          enabled: true,
          onClick: () => this.confirmPauseJourney(),
        });
      this.props.journeyDetails &&
        ['RUNNING', 'W_RUNNING', 'PAUSED'].includes(
          this.props.journeyDetails.status
        ) &&
        items.push({
          key: 'stop',
          title: 'Stop',
          isPrimary: false,
          enabled: true,
          action: () => this.confirmStopJourney(),
        });
      this.props.journeyDetails &&
        ['RUNNING', 'W_RUNNING', 'STOPPED', 'PAUSED'].includes(
          this.props.journeyDetails.status
        ) &&
        this.props.pathname.includes('/overview') &&
        featureAccessCheckCSVExport() &&
        items.push({
          key: 'overviewExport',
          title: 'Journey Overview Export',
          isPrimary: false,
          enabled: true,
          onClick: () =>
            this.props.journeyReportOverviewCsvFetch({
              productId: pid,
              ...this.props.journeyCommunicationReportRequest,
            }),
        });
      this.props.journeyDetails &&
        ['RUNNING', 'W_RUNNING', 'STOPPED', 'PAUSED'].includes(
          this.props.journeyDetails.status
        ) &&
        this.props.pathname.includes('/overview') &&
        featureAccessCheckCSVExport() &&
        items.push({
          key: 'communicationsOverviewExport',
          title: 'Communications Overview Export',
          isPrimary: false,
          enabled: true,
          onClick: () =>
            this.props.journeyReportCommunicationsOverviewCsvFetch({
              productId: pid,
              ...this.props.journeyCommunicationReportRequest,
            }),
        });
      this.props.journeyDetails &&
        ['DRAFT', 'STOPPED', 'PAUSED'].includes(
          this.props.journeyDetails.status
        ) &&
        items.push({
          key: 'edit',
          title: 'Edit',
          isPrimary: true,
          enabled: true,
          onClick: () => this.confirmEditJourney(),
        }) &&
        items.push({
          key: 'delete',
          title: 'Delete',
          isPrimary: false,
          action: () => this.confirmDeleteJourney(),
          enabled: !this.props.loadingDeleteJourney,
        });

      result.push(<ActionMenu actions={items}></ActionMenu>);
      return <div className={style.action}>{result}</div>;
    } else if (communicationReportPage) {
      const result = [];
      const items = [];
      this.props.currentCommunication &&
        this.props.currentCommunication.status &&
        [
          'ENDED',
          'RUNNING',
          'W_RUNNING',
          'PAUSED',
          'STOPPED',
          'UPCOMING',
          'W_UPCOMING',
        ].includes(this.props.currentCommunication.status) &&
        items.push({
          key: 'edit',
          title: 'Edit',
          isPrimary: false,
          enabled: true,
          onClick: () => this.confirmEdit(),
        }) &&
        this.props.pathname.includes('/overview') &&
        featureAccessCheckCSVExport() &&
        items.push({
          key: 'overviewExport',
          title: 'Overview Export',
          isPrimary: false,
          enabled: true,
          onClick: () =>
            this.props.communicationReportCsvFetch({
              productId: pid,
              ...this.props.variationReportPageRequest,
            }),
        }) &&
        this.props.pathname.includes('/overview') &&
        (authorizedForDelayedDeliveryCheck() ||
          authorizedForInstantDeliveryCheck()) &&
        this.props.currentCommunication.channel === 'SMS' &&
        items.push({
          key: 'deliveryCheck',
          title: 'Check Delivery',
          isPrimary: false,
          enabled: true,
          onClick: () => this.confirmDeliveryCheck(),
        });
      this.props.currentCommunication &&
        this.props.currentCommunication.status &&
        [
          'UPCOMING',
          'W_UPCOMING',
          'PAUSED',
          'STOPPED',
          'RUNNING',
          'W_RUNNING',
        ].includes(this.props.currentCommunication.status) &&
        items.push({
          key: 'duplicate',
          title: 'Duplicate',
          isPrimary: false,
          action: () => this.confirmCopy(),
          enabled: !this.props.loadingCopy,
        });
      this.props.currentCommunication &&
        this.props.currentCommunication.status &&
        ['ENDED'].includes(this.props.currentCommunication.status) &&
        items.push({
          key: 'duplicate',
          title: 'Duplicate',
          isPrimary: true,
          onClick: () => this.confirmCopy(),
          enabled: !this.props.loadingCopy,
        });
      this.props.currentCommunication &&
        this.props.currentCommunication.status &&
        ['PAUSED', 'RUNNING', 'W_RUNNING', 'ENDED'].includes(
          this.props.currentCommunication.status
        ) &&
        items.push({
          key: 'stop',
          title: 'Stop',
          isPrimary: false,
          action: () => this.confirmStop(),
          enabled: !this.props.loadingStop,
        });
      this.props.currentCommunication &&
        this.props.currentCommunication.status &&
        ['RUNNING', 'W_RUNNING'].includes(
          this.props.currentCommunication.status
        ) &&
        items.push({
          key: 'pause',
          title: 'Pause',
          isPrimary: true,
          action: () => this.confirmPause(),
          enabled: !this.props.loadingPause,
        });
      this.props.currentCommunication &&
        this.props.currentCommunication.status &&
        ['STOPPED', 'PAUSED'].includes(
          this.props.currentCommunication.status
        ) &&
        items.push({
          key: 'play',
          title: 'Play',
          isPrimary: true,
          onClick: () => this.confirmResume(),
          enabled: !this.props.loadingLaunch,
        });
      if (
        this.props.currentCommunication &&
        this.props.currentCommunication.type !== 'JOURNEY' &&
        this.props.currentCommunication.type !== 'RELAY'
      ) {
        result.push(<ActionMenu actions={items}></ActionMenu>);
        if (
          this.props.currentCommunication &&
          this.props.currentCommunication.type &&
          this.props.currentCommunication.type === 'TRANSACTIONAL'
        ) {
          result.push(
            <Tooltip
              onClick={() => {
                this.setState({ transactionalModalVisible: true });
              }}
              placement={'top'}
              title={'View API Code'}
            >
              <i
                className="fl-code subhead__action subhead__action--with-border"
                style={{ ...styles.headIcon, cursor: 'pointer' }}
              ></i>
            </Tooltip>
          );
        }
      }
      return <span style={{ position: 'relative', top: 5 }}>{result}</span>;
    }
    return null;
  };
  renderTransactionalAPIModal = () => {
    let pid = getProductId(this.props.pathname);
    let cid = getCommunicationId(this.props.pathname);
    const apiCallCurlText =
      'curl -X POST <HOST>/api/sdk/accounts/<YOUR_' +
      texts.BRANDNAME +
      '_LICENSE_CODE>/transactionCommunication \\  \n ' +
      '–header `Authorization: Bearer <YOUR_' +
      texts.BRANDNAME +
      '_API_KEY>` + \\  \n' +
      "–header 'Content-Type: application/json' \\  \n" +
      "–data '{  \n" +
      '"communicationId ": ' +
      cid +
      ',  \n' +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"userId": "userId",  \n' +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"anonymousId": null,  \n' +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"variationTokens": {  \n' +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"someMessageToken": "desired value"  \n' +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 },  \n' +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"overrideData": {  \n' +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"email": "12@3",  \n' +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"phone": "09121234567", \n' +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"queueMinutes": 2 \n' +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0}  \n' +
      "}'";
    if (this.state.transactionalModalVisible) {
      return (
        <Modal
          width={810}
          title={'API Call Details to Send Messages to Users'}
          centered
          footer={null}
          visible={this.state.transactionalModalVisible}
          onOk={() => this.setState({ transactionalModalVisible: false })}
          onCancel={() => this.setState({ transactionalModalVisible: false })}
        >
          <div style={{ backgroundColor: 'white' }}>
            <div
              style={{ paddingLeft: 20, paddingRight: 20, marginBottom: 30 }}
            >
              Once this campaign is launched on the {texts.brandName}&nbsp;
              dashboard, please call our{' '}
              <a href={texts.campaignApi} target={'blank'}>
                {' '}
                transactional campaign API{' '}
              </a>
              to trigger messages to users. Refer to our{' '}
              <a href={texts.campaignApi} target={'blank'}>
                {' '}
                documentation{' '}
              </a>
              documentation for more details on transactional campaigns. Here’s
              a sample cURL request for this campaign. In the code snippet
              below, make sure you replace: (1) &lt;Your API key&gt; with your{' '}
              <a href={`/product/${pid}/rest-api/view`} target={'blank'}>
                {' '}
                {texts.brandName}&nbsp; API Key{' '}
              </a>
              , and (2) token values with the relevant token values
            </div>
            <div
              style={{
                ...styles.dataRowFirst,
                padding: 30,
                marginRight: 20,
                marginLeft: 20,
                marginTop: 30,
                marginBottom: 20,
                textAlign: 'left',
                direction: 'ltr',
                position: 'relative',
                backgroundColor: '#f4f4f6',
                borderRadius: '5px',
                whiteSpace: 'pre-line',
              }}
            >
              {apiCallCurlText}
            </div>
          </div>
        </Modal>
      );
    }
    return null;
  };
  renderAddPushModal = () => {
    if (this.state.addPushModalVisible) {
      return (
        <Modal
          width={810}
          title={'Select a Communication Type'}
          centered
          footer={null}
          visible={this.state.addPushModalVisible}
          onOk={() => this.setState({ addPushModalVisible: false })}
          onCancel={() => this.setState({ addPushModalVisible: false })}
          okText={'Delete'}
        >
          <div style={styles.methodsContainer}>
            <div
              onClick={() => this.gotoCampaign('ONE_TIME')}
              className="method-push-container"
              style={styles.methodContainer}
            >
              <div style={styles.methodTitle}>One-Time</div>
              <div>
                <img src={onetimeSvg} style={styles.methodImage} alt="" />
              </div>
              <div style={styles.methodDescription}>
                Send the communication only once at a specified date and time
              </div>
            </div>
            <div
              onClick={() => this.gotoCampaign('TRIGGERED')}
              className="method-push-container"
              style={styles.methodContainer}
            >
              <div style={styles.methodTitle}>Triggered</div>
              <div>
                <img src={triggerdSvg} style={styles.methodImage} alt="" />
              </div>
              <div style={styles.methodDescription}>
                Send the communication on the occurrence of a custom event
              </div>
            </div>
            <div
              onClick={() => this.gotoCampaign('RECURRING')}
              className="method-push-container"
              style={styles.methodContainer}
            >
              <div style={styles.methodTitle}>Recurring</div>
              <div>
                <img src={recurringSvg} style={styles.methodImage} alt="" />
              </div>
              <div style={styles.methodDescription}>
                Schedule the communication to be sent at regular intervals
              </div>
            </div>
          </div>
        </Modal>
      );
    }
    return null;
  };

  render() {
    let pathname = this.props.pathname || '/';
    let split = pathname.split('/');
    if (split.length > 2) {
      pathname = '/' + split[1] + '/' + split[2] + '/';
    }
    if (!this.props.currentUser || !this.props.currentUser.login) {
      return <React.Fragment />;
    }

    const hasTopHeader = !(
      (this.props.pathname.includes('journey') ||
        this.props.pathname.includes('relay')) &&
      !this.props.pathname.includes('journeys/archive') &&
      !this.props.pathname.includes('relays/archive') &&
      !this.props.pathname.includes('journeys/list') &&
      !this.props.pathname.includes('relays/list') &&
      !this.props.pathname.includes('journeys/overview') &&
      !this.props.pathname.includes('relays/overview') &&
      !(
        (this.props.pathname.includes('/journey/') ||
          this.props.pathname.includes('/relay/')) &&
        (this.props.pathname.includes('/overview') ||
          this.props.pathname.includes('/details') ||
          this.props.pathname.includes('users'))
      )
    );
    return (
      <Header className={style.mainHeader}>
        <NavigationHeader
          project={this.getCurrentProduct()}
          currentUser={this.props.currentUser}
          onSelectProject={this.changeActiveProductId}
          logoutFetch={this.props.logoutFetch}
        />
        {!this.props.disableGlobalHeader && hasTopHeader && (
          <>
            <div className={style.top_header}>
              <div className={style.left_header}>
                <div className={style.header_title}>
                  {this.renderBackButton()}
                  <div>
                    <h6 style={{ fontSize: 20 }}>{this.renderHeaderTitle()}</h6>
                    <div
                      className={
                        this.renderTimeSelectorLeft() !== null
                          ? style.time_picker_left
                          : ''
                      }
                    >
                      {this.renderTimeSelectorLeft()}
                    </div>
                  </div>
                  {this.renderStatus()}
                </div>
                <div
                  className={
                    this.renderHeaderDescription()
                      ? style.header_description
                      : ''
                  }
                >
                  {this.renderHeaderDescription()}
                </div>
              </div>
              <div className={style.right_header}>
                {this.renderHeaderCreateButtons()}
                <div>{this.renderSegmentCreateButtons()}</div>
                <div
                  className={
                    (this.renderSegmentCreateButtons() ||
                      this.renderHeaderCreateButtons()) &&
                    this.renderTimeSelectorRight()
                      ? style.time_selector
                      : ''
                  }
                >
                  {this.renderTimeSelectorRight()}
                </div>
              </div>
            </div>
            <div className={this.renderMenuItems() ? style.tab_menu : ''}>
              {this.renderMenuItems()}
            </div>
            {this.renderAddPushModal()}
            {this.renderTransactionalAPIModal()}
          </>
        )}
      </Header>
    );
  }
}

export default HeaderComponent;
