import live from './theme/images/icons/Live.svg';
import inApp from './theme/images/icons/In-App.svg';
import onSite from './theme/images/icons/On-Site.svg';
import SDKNew from './theme/images/icons/SDK-new.svg';
import webhook from './theme/images/icons/Webhook.svg';
import auditLog from './theme/images/icons/Audit-log.svg';
import restApi from './theme/images/icons/Rest-API.svg';
import help from './theme/images/icons/Help.svg';
import billing from './theme/images/icons/Billing-new.svg';
import VendorManagement from './theme/images/icons/Vendor_Management.svg';
import Dashboard from './theme/images/icons/Dashboard.svg';
import UserNew from './theme/images/icons/User-new.svg';
import Analytics from './theme/images/icons/Analytics.svg';
import Event from './theme/images/icons/Event.svg';
import Funnel from './theme/images/icons/Funnel.svg';
import Cohort from './theme/images/icons/Cohort-new.svg';
import Uninstall from './theme/images/icons/Uninstall.svg';
import Segment from './theme/images/icons/Segment.svg';
import WebPersonalization from './theme/images/icons/WebPersonalization.svg';
import AppPersonalization from './theme/images/icons/AppPersonalization.svg';
import LiveSegment from './theme/images/icons/Live-Segment.svg';
import StaticSegment from './theme/images/icons/Static-Segment.svg';
import DataPlatform from './theme/images/icons/Data-Platform.svg';
import DataManagment from './theme/images/icons/Data-managment.svg';
import UploadData from './theme/images/icons/Upload-Data.svg';
import Alert from './theme/images/icons/Alert-new.svg';
import Overview from './theme/images/icons/Overview-new.svg';
import Channels from './theme/images/icons/Channels.svg';
import PushNew from './theme/images/icons/Push-new.svg';
import SMSNew from './theme/images/icons/SMS-new.svg';
import WebPush from './theme/images/icons/Web-Push.svg';
import EmailNew from './theme/images/icons/Email-new.svg';
import CustomChannel from './theme/images/icons/Custom-Channel.svg';
import WhatsAppNew from './theme/images/icons/WhatsApp-new.svg';
import Journey from './theme/images/icons/Journey.svg';
import Relayes from './theme/images/icons/Relayes.svg';
import ChannelsNew from './theme/images/icons/Channels-new.svg';
import Team from './theme/images/icons/Team.svg';
import Financial from './theme/images/icons/Financial.svg';
import Role from './theme/images/icons/Role.svg';
import Users from './theme/images/icons/Users-new.svg';
import ManageProducts from './theme/images/icons/Manage-products.svg';
import CreateProduct from './theme/images/icons/Create-product.svg';
import Contracts from './theme/images/icons/Contracts.svg';
import FileStorage from './theme/images/icons/File-storage.svg';
import Configuration from './theme/images/icons/Configuration.svg';
import {
  authorizedSideMenuAdminConfiguration,
  authorizedSideMenuAdminCreateProduct,
  authorizedSideMenuAdminFileStorage,
  authorizedSideMenuAdminManageProducts,
  authorizedSideMenuAdminReleaseNote,
  authorizedSideMenuAdminRoleList,
  authorizedSideMenuAdminUserList,
  authorizedSideMenuAnalyticsSection,
  authorizedSideMenuBilling,
  authorizedSideMenuChannels,
  authorizedSideMenuCohorts,
  authorizedSideMenuContractTemplate,
  authorizedSideMenuDataPlatformData,
  authorizedSideMenuDataPlatformEventBusiness,
  authorizedSideMenuDataPlatformEventsAttributes,
  authorizedSideMenuDataPlatformEventsRevenue,
  authorizedSideMenuDataPlatformEventUpload,
  authorizedSideMenuDataPlatformSection,
  authorizedSideMenuDataPlatformSystemAttributes,
  authorizedSideMenuDataPlatformUpload,
  authorizedSideMenuDataPlatformUserAttributes,
  authorizedSideMenuDataPlatformUsersUpload,
  authorizedSideMenuEngageCustomChannel,
  authorizedSideMenuEngageEmail,
  authorizedSideMenuEngageinApp,
  authorizedSideMenuEngageJourneys,
  authorizedSideMenuEngageOnSite,
  authorizedSideMenuEngageOverview,
  authorizedSideMenuEngagePush,
  authorizedSideMenuEngageRelays,
  authorizedSideMenuEngageSection,
  authorizedSideMenuEngageSms,
  authorizedSideMenuEngageWebPush,
  authorizedSideMenuEngageWhatsapp,
  authorizedSideMenuEventsOption,
  authorizedSideMenuFinancialSection,
  authorizedSideMenuFunnels,
  authorizedSideMenuLive,
  authorizedSideMenuManagementDashboard,
  authorizedSideMenuSegmentSection,
  authorizedSideMenuSettingChannelCustom,
  authorizedSideMenuSettingEmail,
  authorizedSideMenuSettingPush,
  authorizedSideMenuSettingRestApiKey,
  authorizedSideMenuSettingRestApiToken,
  authorizedSideMenuSettingSdk,
  authorizedSideMenuSettingSms,
  authorizedSideMenuSettingTeamMember,
  authorizedSideMenuSettingTemplate,
  authorizedSideMenuSettingWebhooks,
  authorizedSideMenuSettingWhatsApp,
  authorizedSideMenuThirdParty,
  authorizedSideMenuUninstalls,
  authorizedSideMenuUsersOption,
  authorizedSideMenuSettingAuditLog,
  authorizedSideMenuAlert,
  dataAndInsight,
  campaignManager,
  personalization,
  settings,
  backOffice,
} from './utils/AuthorityProvider';
import {
  featureAccessCheckSideMenuAnalyticsSection,
  featureAccessCheckSideMenuAuditLog,
  featureAccessCheckSideMenuChannel,
  featureAccessCheckSideMenuDataManagement,
  featureAccessCheckSideMenuDataPlatformSection,
  featureAccessCheckSideMenuEngageCustomChannel,
  featureAccessCheckSideMenuEngageEmail,
  featureAccessCheckSideMenuEngageInApp,
  featureAccessCheckSideMenuEngageJourneys,
  featureAccessCheckSideMenuEngageOnSite,
  featureAccessCheckSideMenuEngageOverview,
  featureAccessCheckSideMenuEngagePush,
  featureAccessCheckSideMenuEngageRelays,
  featureAccessCheckSideMenuEngageSection,
  featureAccessCheckSideMenuEngageSms,
  featureAccessCheckSideMenuEngageWebPush,
  featureAccessCheckSideMenuEngageWhatsapp,
  featureAccessCheckSideMenuEventsOption,
  featureAccessCheckSideMenuFunnels,
  featureAccessCheckSideMenuRestApiKey,
  featureAccessCheckSideMenuRestApiToken,
  featureAccessCheckSideMenuSDK,
  featureAccessCheckSideMenuSegmentLive,
  featureAccessCheckSideMenuSegmentSection,
  featureAccessCheckSideMenuSegmentStatic,
  featureAccessCheckSideMenuTeam,
  featureAccessCheckSideMenuUninstalls,
  featureAccessCheckSideMenuUploadData,
  featureAccessCheckSideMenuUsersOption,
  featureAccessCheckSideMenuWebhook,
  featureAccessCheckSideMenuCohorts,
} from './utils/AccessManagement';
import { thirdPartyEnums } from '@Modules/admin/thirdParty/components/ThirdPartyTypes';
import { getProduct } from './utils/getProduct';

function routes(pid, props) {
  const currentProduct = getProduct(props.currentUser, pid);

  const navData = [
    ...(authorizedSideMenuThirdParty() &&
    currentProduct?.thirdPartyOption === thirdPartyEnums.PARENT
      ? [
          {
            path: `/admin/third-party/${pid}/list`,
            basePath: `/admin/third-party/${pid}`,
            icon: VendorManagement,
            testid: '',
            title: 'Vendor Management',
          },
        ]
      : []),
    ...(dataAndInsight()
      ? [
          {
            label: 'DATA AND INSIGHT',
            data: [
              ...(authorizedSideMenuUsersOption() &&
              featureAccessCheckSideMenuUsersOption()
                ? [
                    {
                      path: `/product/${pid}/users/overview`,
                      basePath: `/product/${pid}/users/`,
                      icon: UserNew,
                      testid: 'track_users',
                      title: 'Users',
                      menuIds: 'users',
                      category: 'Third-Party Management',
                    },
                  ]
                : []),

              ...(authorizedSideMenuAnalyticsSection() &&
              featureAccessCheckSideMenuAnalyticsSection()
                ? [
                    {
                      key: 'analytics',
                      title: 'Analytics',
                      icon: Analytics,
                      subRoutes: [
                        ...(authorizedSideMenuEventsOption() &&
                        featureAccessCheckSideMenuEventsOption()
                          ? [
                              {
                                path: `/product/${pid}/event-analytics`,
                                basePath: `/product/${pid}/event-analytics`,
                                icon: Event,
                                testid: 'track-event-analytic',
                                title: 'Events',
                                menuIds: 'event-analytics',
                              },
                            ]
                          : []),
                        ...(authorizedSideMenuFunnels() &&
                        featureAccessCheckSideMenuFunnels()
                          ? [
                              {
                                path: `/product/${pid}/funnels/list`,
                                basePath: `/product/${pid}/funnels`,
                                icon: Funnel,
                                testid: '',
                                title: 'Funnels',
                                menuIds: 'funnels',
                              },
                            ]
                          : []),
                        ...(authorizedSideMenuCohorts() &&
                        featureAccessCheckSideMenuCohorts()
                          ? [
                              {
                                path: `/product/${pid}/cohorts/list`,
                                basePath: `/product/${pid}/cohorts/list`,
                                icon: Cohort,
                                testid: '',
                                title: 'Cohorts',
                                menuIds: 'cohorts',
                              },
                            ]
                          : []),
                        ...(authorizedSideMenuUninstalls() &&
                        featureAccessCheckSideMenuUninstalls()
                          ? [
                              {
                                path: `/product/${pid}/uninstalls/overview`,
                                basePath: `/product/${pid}/uninstalls`,
                                icon: Uninstall,
                                testid: '',
                                title: 'Uninstalls',
                                menuIds: 'uninstalls',
                              },
                            ]
                          : []),
                        ...(authorizedSideMenuLive()
                          ? [
                              {
                                path: `/product/${pid}/live/overview`,
                                basePath: `/product/${pid}/live/overview`,
                                icon: live,
                                testid: '',
                                title: 'Live',
                                menuIds: 'live',
                              },
                            ]
                          : []),
                      ],
                    },
                  ]
                : []),
              ...(authorizedSideMenuSegmentSection() &&
              featureAccessCheckSideMenuSegmentSection()
                ? [
                    {
                      key: 'segment',
                      title: 'Segment',
                      icon: Segment,
                      subRoutes: [
                        ...(!currentProduct?.sendify &&
                        featureAccessCheckSideMenuSegmentLive()
                          ? [
                              {
                                path: `/product/${pid}/segments/live/list`,
                                basePath: `/product/${pid}/segments/live`,
                                icon: LiveSegment,
                                testid: 'live-segment',
                                title: 'Live Segment',
                              },
                            ]
                          : []),

                        ...(featureAccessCheckSideMenuSegmentStatic()
                          ? [
                              {
                                path: `/product/${pid}/segments/static/list`,
                                basePath: `/product/${pid}/segments/static/`,
                                icon: StaticSegment,
                                testid: 'static-segment',
                                title: 'Static Segment',
                              },
                            ]
                          : []),
                      ],
                    },
                  ]
                : []),
              ...(authorizedSideMenuDataPlatformSection() &&
              featureAccessCheckSideMenuDataPlatformSection()
                ? [
                    {
                      key: 'dataplatform',
                      title: 'Data Platform',
                      icon: DataPlatform,
                      subRoutes: [
                        ...(authorizedSideMenuDataPlatformData() &&
                        featureAccessCheckSideMenuDataManagement()
                          ? [
                              {
                                path: `/product/${pid}/data-management/${dataManagementPath()}`,
                                basePath: `/product/${pid}/data-management/`,
                                icon: DataManagment,
                                testid: 'setting-data-management',
                                title: 'Data Management',
                              },
                            ]
                          : []),

                        ...(authorizedSideMenuDataPlatformUpload() &&
                        featureAccessCheckSideMenuUploadData()
                          ? [
                              {
                                path: `/product/${pid}/upload-data/${uploadDataPath()}`,
                                basePath: `/product/${pid}/upload-data/`,
                                icon: UploadData,
                                testid: 'upload-data',
                                title: 'Upload Data',
                              },
                            ]
                          : []),
                        ...(authorizedSideMenuAlert()
                          ? [
                              {
                                path: `/product/${pid}/alerts/list`,
                                basePath: `/product/${pid}/alerts/list`,
                                icon: Alert,
                                testid: 'alerts',
                                title: 'Alerts',
                              },
                            ]
                          : []),
                      ],
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),

    ...(campaignManager()
      ? [
          {
            label: 'CAMPAIGN MANAGER',
            data: [
              ...(authorizedSideMenuEngageOverview() &&
              featureAccessCheckSideMenuEngageOverview()
                ? [
                    {
                      path: `/product/${pid}/engagement/overview/all`,
                      basePath: `/product/${pid}/engagement/overview/all`,
                      icon: Overview,
                      testid: 'engage-overview',
                      title: 'Overview',
                      menuIds: 'engagement/overview',
                    },
                  ]
                : []),
              ...(authorizedSideMenuEngageSection() &&
              featureAccessCheckSideMenuEngageSection()
                ? [
                    {
                      key: 'engage',
                      title: 'Engage',
                      icon: Channels,
                      subRoutes: [
                        ...(authorizedSideMenuEngagePush() &&
                        featureAccessCheckSideMenuEngagePush()
                          ? [
                              {
                                path: `/product/${pid}/push-notifications/campaign-list/all`,
                                basePath: `/product/${pid}/push-notifications/`,
                                icon: PushNew,
                                testid: 'engage-push',
                                title: 'Push',
                              },
                            ]
                          : []),
                        ...(authorizedSideMenuEngageWebPush() &&
                        featureAccessCheckSideMenuEngageWebPush()
                          ? [
                              {
                                path: `/product/${pid}/web-notifications/campaign-list/all`,
                                basePath: `/product/${pid}/web-notifications/`,
                                icon: WebPush,
                                testid: 'engage-web-push',
                                title: 'Web Push',
                              },
                            ]
                          : []),
                        ...(authorizedSideMenuEngageSms() &&
                        featureAccessCheckSideMenuEngageSms()
                          ? [
                              {
                                path: `/product/${pid}/sms-messages/campaign-list/all`,
                                basePath: `/product/${pid}/sms-messages/`,
                                icon: SMSNew,
                                testid: 'engage-sms',
                                title: 'SMS',
                              },
                            ]
                          : []),

                        ...(authorizedSideMenuEngageEmail() &&
                        featureAccessCheckSideMenuEngageEmail()
                          ? [
                              {
                                path: `/product/${pid}/emails/campaign-list/all`,
                                basePath: `/product/${pid}/emails/`,
                                icon: EmailNew,
                                testid: 'engage-email',
                                title: 'Email',
                              },
                            ]
                          : []),
                        ...(authorizedSideMenuEngageCustomChannel() &&
                        featureAccessCheckSideMenuEngageCustomChannel()
                          ? [
                              {
                                path: `/product/${pid}/custom-channel/campaign-list/all`,
                                basePath: `/product/${pid}/custom-channel/`,
                                icon: CustomChannel,
                                testid: 'engage-custom-channel',
                                title: 'Custom Channel',
                              },
                            ]
                          : []),
                        ...(authorizedSideMenuEngageWhatsapp() &&
                        featureAccessCheckSideMenuEngageWhatsapp()
                          ? [
                              {
                                path: `/product/${pid}/whatsapp/campaign-list/all`,
                                basePath: `/product/${pid}/whatsapp/`,
                                icon: WhatsAppNew,
                                testid: '',
                                title: 'WhatsApp',
                              },
                            ]
                          : []),
                      ],
                    },
                  ]
                : []),
              ...(authorizedSideMenuEngageJourneys() &&
              featureAccessCheckSideMenuEngageJourneys()
                ? [
                    {
                      path: `/product/${pid}/journeys/list`,
                      basePath: `/product/${pid}/journey`,
                      icon: Journey,
                      testid: 'engage-journys',
                      title:
                        currentProduct?.thirdPartyOption !==
                        thirdPartyEnums.PARENT
                          ? 'Journeys'
                          : 'Journey Templates',
                      menuIds: 'journeys',
                    },
                  ]
                : []),
              ...(authorizedSideMenuEngageRelays() &&
              featureAccessCheckSideMenuEngageRelays()
                ? [
                    {
                      path: `/product/${pid}/relays/list`,
                      basePath: `/product/${pid}/relay`,
                      icon: Relayes,
                      testid: 'engage-relays',
                      title: 'Relays',
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(personalization()
      ? [
          {
            label: 'PERSONALIZATION',
            data: [
              ...(authorizedSideMenuEngageOnSite() &&
              featureAccessCheckSideMenuEngageOnSite()
                ? [
                    {
                      key: 'WebPersonalization',
                      title: 'Web Personalization',
                      icon: WebPersonalization,
                      subRoutes: [
                        ...(authorizedSideMenuEngageOnSite() &&
                        featureAccessCheckSideMenuEngageOnSite()
                          ? [
                              {
                                path: `/product/${pid}/onSiteChannel/campaign-list/all`,
                                basePath: `/product/${pid}/onSiteChannel/`,
                                icon: onSite,
                                testid: '',
                                title: 'On-Site',
                              },
                            ]
                          : []),
                      ],
                    },
                  ]
                : []),
              ...(authorizedSideMenuEngageinApp() &&
              featureAccessCheckSideMenuEngageInApp()
                ? [
                    {
                      key: 'AppPersonalization',
                      title: 'App Personalization',
                      icon: AppPersonalization,
                      subRoutes: [
                        ...(authorizedSideMenuEngageinApp() &&
                        featureAccessCheckSideMenuEngageInApp()
                          ? [
                              {
                                path: `/product/${pid}/in-app/campaign-list/all`,
                                basePath: `/product/${pid}/in-app/`,
                                icon: inApp,
                                testid: '',
                                title: 'In-App',
                              },
                            ]
                          : []),
                      ],
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(settings()
      ? [
          {
            label: 'SETTINGS',
            data: [
              ...(authorizedSideMenuChannels() &&
              featureAccessCheckSideMenuChannel()
                ? [
                    {
                      path: `/product/${pid}/channels${channelsPath()}`,
                      basePath: `/product/${pid}/channels`,
                      icon: ChannelsNew,
                      testid: 'setting-channels',
                      title: 'Channels',
                    },
                  ]
                : []),
              ...(authorizedSideMenuSettingSdk() &&
              featureAccessCheckSideMenuSDK()
                ? [
                    {
                      path: `/product/${pid}/sdk/view`,
                      basePath: `/product/${pid}/sdk/`,
                      icon: SDKNew,
                      testid: 'setting-sdk',
                      title: 'SDK',
                    },
                  ]
                : []),
              ...(authorizedSideMenuSettingWebhooks() &&
              featureAccessCheckSideMenuWebhook()
                ? [
                    {
                      path: `/product/${pid}/webhooks/view`,
                      basePath: `/product/${pid}/webhooks/`,
                      icon: webhook,
                      testid: 'setting-webhook',
                      title: 'Webhooks',
                    },
                  ]
                : []),
              ...(authorizedSideMenuSettingAuditLog() &&
              featureAccessCheckSideMenuAuditLog()
                ? [
                    {
                      path: `/product/${pid}/audit-log`,
                      basePath: `/product/${pid}/audit-log`,
                      icon: auditLog,
                      testid: 'audit-log',
                      title: 'Audit Log',
                    },
                  ]
                : []),
              ...(authorizedSideMenuSettingRestApiKey() &&
              featureAccessCheckSideMenuRestApiKey()
                ? [
                    {
                      path: `/product/${pid}/rest-api-key/view`,
                      basePath: `/product/${pid}/rest-api-key/`,
                      icon: restApi,
                      testid: 'restApi',
                      title: 'Rest API',
                    },
                  ]
                : []),
              ...(authorizedSideMenuSettingRestApiToken() &&
              featureAccessCheckSideMenuRestApiToken()
                ? [
                    {
                      path: `/product/${pid}/rest-api-token/view`,
                      basePath: `/product/${pid}/rest-api-token/`,
                      icon: restApi,
                      testid: 'restApiToken',
                      title: 'Rest API Token',
                    },
                  ]
                : []),
              ...(authorizedSideMenuSettingTeamMember() &&
              featureAccessCheckSideMenuTeam()
                ? [
                    {
                      path: `/product/${pid}/team/members`,
                      basePath: `/product/${pid}/team/members`,
                      icon: Team,
                      testid: '',
                      title: 'Team',
                    },
                  ]
                : []),
              ...(authorizedSideMenuBilling()
                ? [
                    {
                      path: `/product/${pid}/billing/sla`,
                      basePath: `/product/${pid}/billing/`,
                      icon: billing,
                      testid: '',
                      title: 'Billing',
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(backOffice()
      ? [
          {
            label: 'BACK-OFFICE',
            data: [
              ...(authorizedSideMenuFinancialSection()
                ? [
                    {
                      key: 'back-office',
                      title: 'Financial',
                      path: `/back-office/invoice`,
                      basePath: `/back-office/`,
                      icon: Financial,
                      testid: 'back-office',
                    },
                  ]
                : []),
              ...(authorizedSideMenuAdminRoleList()
                ? [
                    {
                      path: `/account/role/list`,
                      basePath: `/account/role/`,
                      icon: Role,
                      testid: 'admin-roles',
                      title: 'Roles',
                    },
                  ]
                : []),
              ...(authorizedSideMenuAdminUserList()
                ? [
                    {
                      path: `/account/user/list`,
                      basePath: `/account/user/`,
                      icon: Users,
                      testid: 'admin-users',
                      title: 'Users',
                    },
                  ]
                : []),
              ...(authorizedSideMenuAdminReleaseNote()
                ? [
                    {
                      path: `/account/releaseNote/list`,
                      basePath: `/account/releaseNote/`,
                      icon: help,
                      testid: '',
                      title: 'Release Notes',
                    },
                  ]
                : []),
              ...(authorizedSideMenuAdminManageProducts()
                ? [
                    {
                      path: `/admin/adminProduct/list`,
                      basePath: `/admin/adminProduct/`,
                      icon: ManageProducts,
                      testid: 'admin-manage-product',
                      title: 'Manage Products',
                    },
                  ]
                : []),
              ...(authorizedSideMenuAdminCreateProduct()
                ? [
                    {
                      path: `/admin/create-product`,
                      basePath: `/admin/create-product`,
                      icon: CreateProduct,
                      testid: '',
                      title: 'Create Product',
                    },
                  ]
                : []),

              ...(authorizedSideMenuContractTemplate()
                ? [
                    {
                      path: `/admin/contract-template/list`,
                      basePath: `/admin/contract-template/`,
                      icon: Contracts,
                      testid: 'admin-contract-template',
                      title: 'Contracts',
                    },
                  ]
                : []),
              ...(authorizedSideMenuAdminFileStorage()
                ? [
                    {
                      path: `/account/file-storage/list`,
                      basePath: `/account/file-storage/`,
                      icon: FileStorage,
                      testid: '',
                      title: 'File Storage',
                    },
                  ]
                : []),
              ...(authorizedSideMenuAdminConfiguration()
                ? [
                    {
                      path: `/base/configuration/update`,
                      basePath: `/base/configuration/`,
                      icon: Configuration,
                      testid: '',
                      title: 'Configuration',
                    },
                  ]
                : []),
              ...(authorizedSideMenuManagementDashboard()
                ? [
                    {
                      path: `/admin/management-dashboard`,
                      basePath: `/admin/management-dashboard`,
                      icon: Dashboard,
                      testid: '',
                      title: 'Dashboard',
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
  ];
  return { navData };
}

function dataManagementPath() {
  if (authorizedSideMenuDataPlatformSystemAttributes()) {
    return 'system/attributes';
  } else if (authorizedSideMenuDataPlatformUserAttributes()) {
    return 'user/attributes';
  } else if (authorizedSideMenuDataPlatformEventsAttributes()) {
    return 'events/attributes';
  } else if (authorizedSideMenuDataPlatformEventsRevenue()) {
    return 'events/revenue';
  } else if (authorizedSideMenuDataPlatformEventBusiness()) {
    return 'events/business';
  } else {
    return '';
  }
}

function uploadDataPath() {
  return authorizedSideMenuDataPlatformUsersUpload()
    ? 'users/upload'
    : authorizedSideMenuDataPlatformEventUpload()
    ? 'event/upload'
    : '';
}

function channelsPath() {
  return authorizedSideMenuSettingPush() &&
    featureAccessCheckSideMenuEngagePush()
    ? '/push'
    : authorizedSideMenuSettingSms() && featureAccessCheckSideMenuEngageSms()
    ? '/sms'
    : authorizedSideMenuSettingEmail() &&
      featureAccessCheckSideMenuEngageEmail()
    ? '/email'
    : authorizedSideMenuSettingWhatsApp() &&
      featureAccessCheckSideMenuEngageWhatsapp()
    ? '/whatsapp'
    : authorizedSideMenuSettingTemplate() &&
      featureAccessCheckSideMenuEngageEmail()
    ? '/template'
    : authorizedSideMenuSettingChannelCustom() &&
      featureAccessCheckSideMenuEngageCustomChannel()
    ? '/custom'
    : '';
}

export default routes;
